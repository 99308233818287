/**
 * Utility class for various request Headers.
 */

export const createRequestHeader = additionalHeaders => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    ...additionalHeaders,
  },
});
