import { ADD_UPLOADED_IMAGE, REMOVE_UPLOADED_IMAGE, CLEAR_UPLOADED_IMAGES } from '../../constants/action-types';

export const uploadedImagesReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_UPLOADED_IMAGE: {
      const { imgUrl } = action.payload;
      return [...state, imgUrl];
    }
    case REMOVE_UPLOADED_IMAGE: {
      const { imgUrl } = action.payload;
      const index = state.findIndexOf(imgUrl);
      if (index === -1) return state;

      return state.slice(0, index).concat(state.slice(index + 1));
    }
    case CLEAR_UPLOADED_IMAGES: {
      return [];
    }
    default:
      return state;
  }
};
