import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import FacebookTemplate22 from 'src/templates/facebook/pemberton/FacebookTemplate22';

class PembertonFacebook22 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout postFacebook={true} templateKey={FacebookTemplate22._name} type={type} listing={currentListing}>
        <Scale
          width={FacebookTemplate22._width}
          height={FacebookTemplate22._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <FacebookTemplate22 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(PembertonFacebook22);
