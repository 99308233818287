import React, { useState, useEffect } from 'react';
import { Input, Icon, Form, Button, Container } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { GooglePicker } from 'react-color';
import FontPicker from 'font-picker-react';

import { createTheme, getAllFonts } from 'src/redux/actions/theme-creator';
import FontPreview from 'src/components/FontPreview';
import AdminLayout from 'src/layouts/admin-layout';

import './ThemeCreator.scss';
import { useTitle } from 'react-use';

const ThemeCreator = () => {
  useTitle('Theme Creator - Realvault');

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { addToast } = useToasts();
  const { currentBroker, allFonts } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allFonts: state.theme.allFonts,
  }));
  const [themeData, setThemeData] = useState({
    name: '',
    font: '',
    backgroundColor: '',
    foregroundColor: '',
  });

  const [loading, setLoading] = useState(false);

  const FONT_OPTIONS = allFonts?.map(font => ({
    ...font,
    key: font.family,
    text: font.family,
    value: font.family,
  }));

  useEffect(() => {
    dispatch(getAllFonts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFormHandler = (key, value) => {
    setThemeData(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onThemeCreated = () => {
    setLoading(true);
    dispatch(
      createTheme({
        ...themeData,
        broker: currentBroker.id.toString(),
      })
    )
      .then(() => {
        setLoading(false);
        setThemeData({
          name: '',
          font: '',
          backgroundColor: '',
          foregroundColor: '',
        });
        addToast('Theme Created Successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        push('/list-themes');
      })
      .catch(err => {
        setLoading(false);
        setThemeData({
          name: '',
          font: '',
          backgroundColor: '',
          foregroundColor: '',
        });
        console.log('ERR => while creating theme', err.message);
      });
  };

  return (
    <AdminLayout
      pageTitle="Create New Theme"
      rightContent={
        <Link to="/themes/additional">
          <Button color="green">View All Themes</Button>
        </Link>
      }
    >
      <Container>
        <div className="white mt-10 theme-container">
          <div className="flex space-between items-center mb-4"></div>
          <Form onSubmit={onThemeCreated}>
            <Form.Field>
              <label>Theme name</label>
              <Input
                fluid
                placeholder="Enter name of the theme"
                value={themeData.name}
                onChange={event => changeFormHandler('name', event.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Font Type</label>
              <FontPicker
                apiKey="AIzaSyC2Q2CfcyWZAe4dABQncovnxXETju6ZPwE"
                className="font-picker"
                placeholder="Choose a font type"
                activeFontFamily={themeData.font}
                onChange={font => {
                  changeFormHandler('font', font.family);
                }}
              />
            </Form.Field>
            <div className="mb-8">
              <FontPreview name={themeData.font} />
            </div>
            <div className="mb-8 color-picker-grid">
              <Form.Field>
                <label>Select Your Color</label>
                <GooglePicker
                  color={themeData.backgroundColor}
                  onChangeComplete={color => changeFormHandler('backgroundColor', color.hex)}
                  className="mr-8"
                />
              </Form.Field>
            </div>
            <Button className="ui button" type="submit" loading={loading}>
              <Icon name="check" />
              Save Theme
            </Button>
          </Form>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default ThemeCreator;
