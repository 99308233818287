import React, { Component } from 'react';
import Logo from 'src/images/pemberton/ph-stacked-green-logo.png';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './FacebookTemplate7.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_7';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate7 extends Component {
  constructor(props) {
    super(props);

    let { title, content, clients, adjustedHeight } = props;

    this.state = {
      title,
      content,
      clients,
      adjustedHeight,
    };
  }

  render() {
    const { title, content, clients, adjustedHeight, themeClassName } = this.state;
    const _shareUrlQuery = `&t=${title || ''}&d=${content || ''}`;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate7._cached = { title, content, clients, _shareUrlQuery };

    return (
      <div className={`facebook-7-wrapper ${theme}`}>
        <EditableInput self={this} className="title" fieldName="title" placeholder="Testimonial" />
        <hr style={{ width: '20%', margin: '0 auto', backgroundColor: '#112F23' }} />
        <div className="content-wrapper">
          <EditableTextarea
            self={this}
            onHeightChange={v => (FacebookTemplate7._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="content"
            fieldName="content"
            placeholder="Lorem ipsum dolor sit amet, adipiscing elit. Nullam lobortis sem vitae nisl scelerisque, nec aliquet leo pharetra. Cras rutrum est et velit eleifend ac at nisi."
          />
          <EditableInput self={this} className="clients" fieldName="clients" placeholder="Client Name(s)" />
        </div>
        <div className="logo">
          <img src={Logo} width={200} base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

FacebookTemplate7._name = TEMPLATE_NAME;
FacebookTemplate7._width = TEMPLATE_WIDTH;
FacebookTemplate7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

export default FacebookTemplate7;
