import React, { Component } from 'react';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import MacdonaldFacebookTemplate12 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate12';

class MacdonaldFacebook12 extends Component {
  render() {
    const { type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout postFacebook={true} templateKey={MacdonaldFacebookTemplate12._name} type={type}>
        <Scale
          width={MacdonaldFacebookTemplate12._width}
          height={MacdonaldFacebookTemplate12._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <MacdonaldFacebookTemplate12 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

export default MacdonaldFacebook12;
