import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import PublicLayout from 'src/layouts/public-layout';
import ResetPassword from './ResetPassword';

const ResetPasswordPage = () => {
  return (
    <PublicLayout>
      <Header as="h1">Reset Password</Header>
      <Divider section />
      <ResetPassword />
    </PublicLayout>
  );
};

export default ResetPasswordPage;
