export const transformData = data => {
  return {
    ID: data.id || data.ListingId,
    title: data.title || 'N/A',
    subtitle: data.subtitle || 'N/A',

    emailLegend: 'E',
    websiteLegend: 'W',
    agents: {
      emailLegend: 'E',
      websiteLegend: 'W',
      agentBanner: 'n/a',
      agentEmail: data.ListAgentEmail || 'n/a',
      agentId: data.ListAgentKey || 'n/a',
      agentImageUrl: 'n/a',
      agentLogo: 'n/a',
      agentName: data.ListAgentFullName || '',
      agentPhone: data.ListAgentDirectPhone || data.ListAgentOfficePhone || 'n/a',
      agentTitle: data.ListAgentDesignation || 'n/a',
      agentWebsite: data.ListAgentURL || data.ListOfficeURL || 'n/a',
    },
    description: data.PublicRemarks,
    details: {
      lotSizeDimensions: 'n/a',
      lotSizeTotal: 'n/a',
      heading: 'NEIGHBOURHOOD HIGHLIGHTS',
      features: 'FEATURES',
      downloadUrl: '(Phone Number)',
      downloadText: 'CONTACT:',
      neighborhood: 'n/a',
      neighborhood_highlights: 'n/a',
      propertyType: data.PropertyType || 'n/a',
      bedroomsText: 'Bedrooms:',
      qtyBedrooms: data.BedroomsTotal || 'n/a',
      bathroomsText: 'Bathrooms:',
      qtyFullBathrooms: data.BathroomsTotal || 'n/a',
      qtyHalfBathrooms: data.BathroomsHalf || 'n/a',
      year: 'Year Built:',
      grossTax: 'n/a',
      grossTaxLegend: 'Gross Taxes:',
      totalFloorArea: 'Total Floor Area:',
      totalLotSize: 'Total Lot Size:',
      squareFeet: data.BuildingAreaTotal || 'n/a',
      strataFee: 'n/a',
      yearBuilt: 'n/a',
    },
    features: {
      centralVacuum: 'n/a',
      taxes: 'n/a',
      dishwasher: '',
      dryer: '',
      fireplace: data.FireplacesTotal || '0',
      fireplaceType: '',
      flooring: data.Flooring || '',
      fridge: 'n/a',
      hottub: '',
      washer: '',
    },
    listingDate: data?.ListingContractDate || 'n/a',
    location: {
      city: data?.City,
      latitude: data?.Latitude,
      longitude: data?.Longitude,
      stateCode: data?.StateOrProvince,
      streetAddress: data?.UnparsedAddress,
      streetAddress2: data?.UnparsedAddress,
      zipCode: data?.PostalCode,
    },
    mlsId: data.ListingId,
    // photos: {
    //   large: data.photos?.map(photo => photo.MediaUrl),
    //   small: data.photos?.map(photo => photo.MediaUrl),
    // },
    photos: data.photos?.map(photo => photo.MediaUrl) || [],
    pictures: data.photos?.map(photo => photo.MediaUrl) || [],
    price: {
      price: data.ListPrice || '',
      priceDisplay: data.ListPrice || '',
    },
    brokerage_url: data?.MoreInformationLink,
  };
};

export const defaultListingData = {
  ID: '0001',
  title: 'N/A',
  subtitle: 'N/A',
  emailLegend: 'E',
  websiteLegend: 'W',
  agents: {
    emailLegend: 'E',
    websiteLegend: 'W',
    agentBanner: 'n/a',
    agentEmail: 'sallysmith@realtor-realvault.com',
    agentId: '01',
    agentImageUrl: 'n/a',
    agentLogo: 'n/a',
    agentName: 'Sally Smith',
    agentPhone: '555-915-9999',
    agentTitle: 'Real Estate Agent',
    agentWebsite: 'https://realtor-realvault.com ',
    coAgentEmail: 'john.smith@realtor-realvault.com',
    coAgentId: 'n/a',
    coAgentImageUrl: 'n/a',
    coAgentTitle: 'Real Estate Agent',
    coAgentLogo: 'n/a',
    coAgentName: 'John Smith',
    coAgentPhone: '555-5455-9999',
    coAgentWebsite: 'https://realtor-realvault.com ',
  },
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus in metus vulputate eu scelerisque felis imperdiet proin fermentum. Sagittis vitae et leo duis ut diam. Quisque egestas diam in arcu cursus euismod. Nunc sed augue lacus viverra vitae congue eu consequat ac. Elit pellentesque habitant morbi tristique senectus et netus et. Et ultrices neque ornare aenean euismod elementum nisi quis eleifend. Placerat orci nulla pellentesque dignissim enim sit amet venenatis. Varius sit amet mattis vulputate enim nulla aliquet porttitor lacus. Diam vel quam elementum pulvinar etiam non quam lacus. Dolor morbi non arcu risus quis. Varius duis at consectetur lorem donec massa sapien faucibus. Risus nec feugiat in fermentum posuere urna nec. Enim ut sem viverra aliquet. A diam sollicitudin tempor id eu nisl nunc.',
  alternateDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque venenatis lacinia sapien, quis tincidunt dolor porttitor a. Etiam feugiat risus ex, ut volutpat odio lacinia ut. Mauris sollicitudin nibh suscipit lorem consequat, sed mattis sapien imperdiet. Suspendisse potenti. Duis ac scelerisque libero. Duis ac neque a est efficitur tristique. Duis justo lacus, elementum ultricies nibh et, congue pretium est. Proin feugiat efficitur accumsan. Integer orci quam, pretium ac orci sit amet, vestibulum porta justo. Nullam mattis, justo eget posuere iaculis, lacus nunc aliquam justo, vel convallis orci libero eget metus. Quisque pretium, magna at malesuada elementum, magna tortor scelerisque nunc, ac facilisis quam est eu enim. Ut a convallis lacus, eu vestibulum dolor. Etiam posuere eros ligula, id vestibulum risus consectetur nec.',
  details: {
    lotSizeDimensions: 'n/a',
    lotSizeTotal: 'n/a',
    heading: 'NEIGHBOURHOOD HIGHLIGHTS',
    downloadUrl: '(Phone Number)',
    downloadText: 'CONTACT:',
    features: 'FEATURES',
    neighborhood: 'n/a',
    neighborhood_highlights: 'n/a',
    propertyType: 'n/a',
    bedroomsText: 'Bedrooms:',
    qtyBedrooms: '0' || 'n/a',
    bathroomsText: 'Bathrooms:',
    year: 'Year Built:',
    grossTax: 'n/a',
    grossTaxLegend: 'Gross Taxes:',
    totalFloorArea: 'Total Floor Area:',
    totalLotSize: 'Total Lot Size:',
    qtyFullBathrooms: '0' || 'n/a',
    qtyHalfBathrooms: '0',
    squareFeet: 'n/a',
    strataFee: 'n/a',
    yearBuilt: 'n/a',

    // macdonald key/values
    lotSizeWidth: 'n/a',
    lotSizeLength: 'n/a',
  },
  features: {
    centralVacuum: 'n/a',
    dishwasher: 'n/a',
    dryer: 'n/a',
    fireplace: 'n/a',
    fireplaceType: 'n/a',
    flooring: 'n/a',
    fridge: 'n/a',
    hottub: 'n/a',
    washer: 'n/a',
    taxes: 'n/a',

    // macdonald key/values
    lotSzSqFt: 'n/a',
    maintenanceFee: 'n/a',
    maintFeeIncludes: 'n/a',
  },
  listingDate: 'n/a',
  location: {
    city: 'Victoria',
    latitude: 'n/a',
    longitude: 'n/a',
    stateCode: 'BC',
    streetAddress: '9999 Sea Shell Way',
    streetAddress2: '9999 Sea Shell Way',
    zipCode: 'n/a',
  },
  mlsId: 'demo123',
  photos: [],
  pictures: [],
  price: {
    price: '225,500',
    priceDisplay: '$225,500',
  },
  brokerage_url: '',
  // used for Macdonald Two page folded brochures to map to the KVCORE_LISTING_DEF response model
  priceTitle: 'Price:',
  MLSTitle: 'MLS® #:',
  bedroomTitle: 'Bedrooms:',
  bathroomTitle: 'Bathrooms:',
  yearTitle: 'Year Built:',
  subAreaTitle: 'Sub-area:',
  taxTitle: 'Gross Taxes:',
  totalFloorAreaTitle: 'Total Floor Area:',
  lotSizeWidthTitle: 'Lot Size (Width):',
  lotSizeLengthTitle: 'Lot Size (Length):',
  lotSizeTotalTitle: 'Total Lot Size (sf):',
  maintFeeTitle: 'Maint. Fee:',
  maintFeeInclTitle: 'Maint. Fee Incl:',
};

export const transformDataViva = data => {
  return {
    ID: data.id || data.ListingId,
    title: data.title || 'N/A',
    subtitle: data.subtitle || 'N/A',
    agents: {
      agentBanner: 'n/a',
      agentEmail: data.ListAgentEmail || 'n/a',
      agentId: data.ListAgentKeyNumeric || 'n/a',
      agentImageUrl: (data?.ListAgent?.Media && data?.ListAgent?.Media[0]?.MediaURL) || 'n/a',
      agentLogo: 'n/a',
      agentName: data.ListAgentFullName || '',
      agentPhone: data.ListAgentDirectPhone || data.ListAgentOfficePhone || 'n/a',
      agentTitle: data.ListAgentDesignation || 'n/a',
      agentWebsite: data.ListAgentURL || data.ListOfficeURL || 'n/a',
    },
    description: data.PublicRemarks,
    alternateDescription: 'n/a',
    details: {
      lotSizeDimensions: 'n/a',
      lotSizeTotal: 'n/a',
      heading: 'NEIGHBOURHOOD HIGHLIGHTS',
      features: 'FEATURES',
      downloadUrl: '(Phone Number)',
      downloadText: 'CONTACT:',
      neighborhood: 'n/a',
      neighborhood_highlights: 'n/a',
      propertyType: data.PropertyType || 'n/a',
      bedroomsText: 'Bedrooms:',
      qtyBedrooms: data.BedroomsTotal || 'n/a',
      bathroomsText: 'Bathrooms:',
      qtyFullBathrooms: data.BathroomsTotalInteger || 'n/a',
      qtyHalfBathrooms: data.BathroomsHalf || '0',
      year: 'Year Built:',
      grossTax: 'n/a',
      grossTaxLegend: 'Gross Taxes:',
      totalFloorArea: 'Total Floor Area:',
      totalLotSize: 'Total Lot Size:',
      squareFeet: data.BuildingAreaTotal || 'n/a',
      strataFee: 'n/a',
      yearBuilt: 'n/a',
    },
    features: {
      centralVacuum: 'n/a',
      dishwasher: '',
      dryer: '',
      fireplace: data.FireplacesTotal || '0',
      fireplaceType: '',
      flooring: data.Flooring || '',
      fridge: 'n/a',
      hottub: '',
      washer: '',
      taxes: 'n/a',
    },
    listingDate: data?.ListingContractDate || 'n/a',
    location: {
      city: data?.City,
      latitude: data?.Latitude,
      longitude: data?.Longitude,
      stateCode: data?.StateOrProvince,
      streetAddress: data?.UnparsedAddress,
      streetAddress2: data?.UnparsedAddress,
      zipCode: data?.PostalCode,
    },
    mlsId: data.ListingId,
    // photos: {
    //   large: data.photos?.map(photo => photo.MediaUrl),
    //   small: data.photos?.map(photo => photo.MediaUrl),
    // },
    photos: data.Media?.map(photo => photo.MediaURL) || [],
    pictures: data.Media?.map(photo => photo.MediaURL) || [],
    price: {
      price: data.ListPrice || '',
      priceDisplay: data.ListPrice || '',
    },
    brokerage_url: data?.MoreInformationLink,
  };
};

export const transformDataMLS = data => {
  return {
    ID: data.source_id,
    title: data.title || 'N/A',
    subtitle: data.subtitle || 'N/A',
    agents: {
      agentBanner: 'n/a',
      agentEmail: data.listing_agent_email || 'n/a',
      agentId: 'n/a',
      agentImageUrl: 'n/a',
      agentLogo: 'n/a',
      agentName: data.listing_agent_name || '',
      agentPhone: data.listing_agent_phone || data.listing_office_phone || 'n/a',
      agentTitle: 'n/a',
      agentWebsite: data.listing_agent_website || data.listing_office_website || 'n/a',
    },
    description: data.description,
    alternateDescription: 'n/a',
    details: {
      lotSizeDimensions: 'n/a',
      lotSizeTotal: data.lot_size || 'n/a',
      heading: 'NEIGHBOURHOOD HIGHLIGHTS',
      features: 'FEATURES',
      downloadUrl: '(Phone Number)',
      downloadText: 'CONTACT:',
      neighborhood: 'n/a',
      neighborhood_highlights: 'n/a',
      propertyType: 'n/a',
      bedroomsText: 'Bedrooms:',
      qtyBedrooms: data.bedrooms || 'n/a',
      bathroomsText: 'Bathrooms:',
      qtyFullBathrooms: data.bathrooms || 'n/a',
      qtyHalfBathrooms: '0',
      year: 'Year Built:',
      grossTax: 'n/a',
      grossTaxLegend: 'Gross Taxes:',
      totalFloorArea: 'Total Floor Area:',
      totalLotSize: 'Total Lot Size:',
      squareFeet: data.floor_size || 'n/a',
      strataFee: 'n/a',
      yearBuilt: data.build_year || 'n/a',
    },
    features: {
      centralVacuum: 'n/a',
      dishwasher: '',
      dryer: '',
      fireplace: '0',
      fireplaceType: '',
      flooring: '',
      fridge: 'n/a',
      hottub: '',
      washer: '',
      taxes: 'n/a',
    },
    listingDate: 'n/a',
    location: {
      city: data.city,
      latitude: '',
      longitude: '',
      stateCode: data.province,
      streetAddress: data.street_address,
      streetAddress2: '',
      zipCode: data.postal_code,
    },
    mlsId: data.source_id,
    photos: data.photos?.map(photo => photo.url) || [],
    pictures: data.photos?.map(photo => photo.url) || [],
    price: {
      price: Math.trunc(data.list_price) || '',
      priceDisplay: Math.trunc(data.list_price) || '',
    },
    brokerage_url: data.mls_listing_link,
  };
};

