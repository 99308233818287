import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';

const ApplyFont = ({ fontName, text, children }) => {
  return (
    <>
      {!fontName ? (
        children || <span>{text}</span>
      ) : (
        <>
          <GoogleFontLoader
            fonts={[
              {
                font: fontName,
                weights: [400],
              },
            ]}
          />
          {children ? (
            <div style={{ fontFamily: `${fontName} !important` }}>{children}</div>
          ) : (
            <p style={{ fontFamily: `${fontName} !important` }}>{text}</p>
          )}
        </>
      )}
    </>
  );
};

export default ApplyFont;
