import React, { useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { decode } from 'jsonwebtoken';
import { Container, Loader, Button } from 'semantic-ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { accountSettingsSidebarData } from 'src/utils/account-settings-sidebar';

import PageTitle from 'src/components/PageTitle/page-title';
import { getStripeProducts } from 'src/redux/actions/stripeActions';

import { fetchCurrentUser } from 'src/redux/actions/userActions';
import { fetchCurrentBroker } from 'src/redux/actions/brokerActions';

import './admin-layout.scss';

const ADMIN_EXIT_REDIRECT_PATH = '/dashboard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AdminLayout = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [expandMenu, setExpandMenu] = useState(true);
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // mount all the plans
    dispatch(getStripeProducts());
    const user = localStorage.getItem('jwt') && decode(localStorage.getItem('jwt'));
    if (!currentUser) {
      setLoading(true);
      dispatch(fetchCurrentUser(user?.user_id))
        .then(response => setLoading(false))
        .catch(err => {
          console.log('Failed to fetch the user', err);
          setLoading(false);
        });
      dispatch(fetchCurrentBroker(user?.broker_id))
        .then(response => setLoading(false))
        .catch(err => {
          console.log('Failed to fetch the broker', err);
          setLoading(false);
        });
    }
  }, [currentUser, dispatch]);

  const renderPaths = accountSettingsSidebarData.reduce((acc, curr) => {
    acc[curr.path] = curr.allowRender({ currentUser, currentBroker });
    return acc;
  }, {});

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex justify-center items-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Grid columns={2} divided padded className="app-container">
          <i
            className={`fa fa-bars fa-2x menu-icon ${expandMenu ? 'menu-icon-expand' : 'menu-icon-collapse'}`}
            onClick={e => {
              e.stopPropagation();
              setExpandMenu(prev => !prev);
            }}
          ></i>
          <Grid.Row className="app-row bg-white">
            {expandMenu ? (
              <Grid.Column className="sidebar">
                <div className="admin-layout">
                  <div>
                    {accountSettingsSidebarData
                      .filter(sidebar => renderPaths[sidebar.path])
                      .map(sidebar => (
                        <div className="navbar-container" key={sidebar.path}>
                          <NavLink
                            className="admin-nav"
                            activeClassName={!sidebar?.subNavs?.length && 'active-class'}
                            to={sidebar.redirect ? sidebar.redirect : sidebar.path}
                          >
                            {sidebar.title}
                          </NavLink>
                          {sidebar.subNavs?.map(subNav => (
                            <NavLink
                              className="admin-sub-nav"
                              activeClassName="active-class"
                              to={subNav.path}
                              key={subNav.path}
                            >
                              {subNav.title}
                            </NavLink>
                          ))}
                        </div>
                      ))}
                  </div>

                  <Button color="green" onClick={() => history.replace(ADMIN_EXIT_REDIRECT_PATH)}>
                    Back to Dashboard
                  </Button>
                </div>
              </Grid.Column>
            ) : null}
            <Grid.Column className="main-container" style={{ width: expandMenu ? 'calc(100% - 300px)' : '100%' }}>
              {props.pageTitle && <PageTitle title={props.pageTitle} rightContent={props.rightContent} />}
              {props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Elements>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AdminLayout;
