import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import MacdonaldFacebookTemplate16 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate16';

class MacdonaldFacebook16 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout
        postFacebook={false}
        templateKey={MacdonaldFacebookTemplate16._name}
        type={type}
        listing={currentListing}
      >
        <Scale
          width={MacdonaldFacebookTemplate16._width}
          height={MacdonaldFacebookTemplate16._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <MacdonaldFacebookTemplate16 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebook16);
