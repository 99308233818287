import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/ph-stacked-white-logo.png';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import stockPhoto from 'src/images/blog-stock-photo.jpg';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './FacebookTemplate2.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_24';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate24 extends Component {
  constructor(props) {
    super(props);

    let { headline, image1, currentListing } = props;
    // const { photos, location, details } = currentListing;

    if (!image1) image1 = PlaceHolderImage1;
    // if (!image1) image1 = photos[0] || PlaceHolderImage1;

    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { headline, image1, themeClassName } = this.state;
    const { currentListing } = this.props;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate24._cached = { headline, image1 };

    return (
      <div className={`facebook-2-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
        />
        <div className="content-wrapper">
          <EditableTextarea self={this} className="headline" fieldName="headline" placeholder="Headline" />
          <hr style={{ width: '60%', margin: '0 auto' }} />
          <div className="logo">
            <img src={Logo} width="45%" height="45%" base64="true" alt="logo" style={{ margin: '0 auto' }} />
          </div>
        </div>
      </div>
    );
  }
}

FacebookTemplate24._name = TEMPLATE_NAME;
FacebookTemplate24._width = TEMPLATE_WIDTH;
FacebookTemplate24._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate24, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate24);
