import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Header, Dropdown, Image, Button, Grid, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import PageTitle from 'src/components/PageTitle/page-title';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import { getAllFonts } from 'src/redux/actions/theme-creator';
import { isFileLarge } from 'src/utils/generic-utils';
import '../OnboardingBroker/default-branding.scss';
import Swal from 'sweetalert2';
import config from '../../utils/config';
import { useTitle } from 'react-use';
import { ONBOARDING_SKIP_SELECT_LOGO, track } from 'src/utils/analytics';

const SelectLogos = ({ onSubmit }) => {
  useTitle('Select Logos - Realvault');

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  let defultImageBaseUrl = '';
  if (process.env.REACT_APP_TEMPLATE_API === 'https://dev-api.covault.app') {
    defultImageBaseUrl = 'https://covault-dev-bastion.s3.ca-central-1.amazonaws.com';
  } else if (process.env.REACT_APP_TEMPLATE_API === 'https://staging-api.covault.app') {
    defultImageBaseUrl = 'https://realvault-app-staging.s3.ca-central-1.amazonaws.com';
  } else {
    defultImageBaseUrl = 'https://realvault-app.s3.ca-central-1.amazonaws.com';
  }

  const {
    brokerOnboarding: { basicDetailsResponse },
  } = useSelector(state => state.stripe);

  const brandLogoRef = useRef();
  const localBasicDetails = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
  const [loading, setLoading] = useState(false);
  const [imgPreviewArray, setimgPreviewArray] = useState([]);

  useEffect(() => {
    dispatch(getAllFonts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  const setLogo = (type, event) => {
    const files = event.target.files;
    let fileArray = Object.values(files);
    console.log('fileArray', fileArray);
    console.log('event.target.files', event.target.files);
    let temp = [...imgPreviewArray];
    fileArray.forEach(file => {
      if (!isFileLarge(file.size, 2)) {
        addToast('File size exceeds than maximum 2mb limit!', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      if (temp.length >= 3) {
        addToast('Can not added more images', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      temp.push({ id: generateUUID(), preview: URL.createObjectURL(file), raw: file });
    });
    setimgPreviewArray(temp);
  };

  const onChangeSettings = async event => {
    event.preventDefault();
    setLoading(true);

    // upload the files first get their url and then continue to make the setDefault settings

    let arr = [];
    let imgFiles = [...imgPreviewArray];

    if (imgFiles && imgFiles.length > 0) {
      for (const v of imgFiles) {
        console.log('v', v.raw);
        let formData = new FormData();
        formData.append('file', v.raw);
        formData.append('broker_id', localBasicDetails?.broker_id);
        formData.append('user_id', localBasicDetails?.user_id);
        let resp = await axios.post(`${process.env.REACT_APP_API}/file-upload-onboarding`, formData, {
          'content-type': 'multipart/form-data',
          Origin: `${window.location.origin}`,
        });
        arr.push(resp.data.file_public_url);
      }
    }
    let objArr = [];
    if (arr.length > 0) {
      objArr = arr.map((v, i) => {
        let selected = false;
        if (i === 0) {
          selected = true;
        }
        return { url: v, selected };
      });
    }

    console.log('arr', objArr);
    let resBody = {
      logo: objArr,
      broker_id: localBasicDetails?.broker_id,
    };

    try {
      let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, resBody);

      if (saveRes.statusText === 'OK' && saveRes.status === 200) {
        addToast('Settings changed successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        if (arr.length >= 1) {
          localStorage.setItem('defaultImg', arr[0]);
        }

        onSubmit();
      }
    } catch (e) {
      setLoading(false);
      addToast(e?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const onRemoveLogo = id => {
    return Swal.fire({
      title: 'Remove Image',
      text: 'Do you want to remove this image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E74942',
      customClass: { container: 'realvault-swal' },
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        let temp = [...imgPreviewArray];
        let newArr = temp.filter(v => {
          console.log('v', v);
          if (v.id !== id) return v;
        });
        setimgPreviewArray(newArr);
        return false;
      }
      return false;
    });
  };

  return (
    <div className="default-branding">
      <div className="w-full mx-auto md:w-4/5">
        <div className="mx-auto">
          <PageTitle title="Set Up Your Branding" hideContainer />
          <div className="w-full p-6 bg-white rounded-sm shadow-md md:p-8">
            <Form onSubmit={onChangeSettings}>
              {/* Brand logo Upload */}
              <Form.Field className="mt">
                <Header as="h5" style={{ textAlign: 'center', marginBottom: '30px' }}>
                  Upload Your Logo(s)
                </Header>
                <div className="file-wrap">
                  <div className="flex items-center justify-between upload-img-field">
                    <input
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      multiple
                      ref={brandLogoRef}
                      onChange={e => setLogo('brand', e)}
                    />
                    <i className="bx bx-plus"></i>
                  </div>

                  {imgPreviewArray.map((img, i) => {
                    return (
                      <div className="img-wrap-preview">
                        <Image id={img.id} key={i} className="uploaded-image" src={img.preview} size="small" bordered />
                        <button
                          onClick={() => {
                            onRemoveLogo(img.id);
                          }}
                          className="remove-img"
                          type="button"
                        >
                          <i className="bx bx-x"></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </Form.Field>

              <div className="text-center mt-14">
                <OutlinedButton
                  onClick={onChangeSettings}
                  disabled={imgPreviewArray.length <= 0}
                  style={{
                    border: '1px solid #233349',
                    backgroundColor: '#233349',
                    color: 'white',
                    textTransform: 'uppercase',
                  }}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Loader active size="mini" inline inverted />
                    </div>
                  ) : (
                    'Update Now '
                  )}
                </OutlinedButton>
              </div>
              <div className="mt-8 text-center">
                <div
                  onClick={async () => {
                    track(ONBOARDING_SKIP_SELECT_LOGO, { basicDetails: localBasicDetails });

                    let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, {
                      logo: [
                        {
                          url: defultImageBaseUrl + config.defaultImgUrl,
                          selected: true,
                        },
                      ],
                      broker_id: localBasicDetails?.broker_id,
                    });

                    if (saveRes.statusText === 'OK' && saveRes.status === 200) {
                      addToast('Settings changed successfully!', {
                        appearance: 'success',
                        autoDismiss: true,
                      });

                      onSubmit();
                    }
                  }}
                  className="skip-link"
                >
                  <u>Skip</u>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLogos;
