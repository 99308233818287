import React, { useState } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { Modal, Button, Form, Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import { setCurrentUser } from 'src/redux/actions/userActions';
import { createRequestHeader } from 'src/services/request-headers';

const EditPasswordModal = props => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    first_name: props.user?.first_name,
    last_name: props.user?.last_name,
    email: props.user?.email,
    password: '',
    user_role_id: props.user.user_role_id,
    user_status_id: props.user.user_status_id,
    requested_broker: props.user.requested_broker,
    broker_id: props.user.broker_id,
    phone_number: props.user.phone_number,
  });

  const [open, setOpenModal] = useState(false);
  const [formError, setFormError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const passwordError = () => {
    if (!formValues.password && formError) {
      return { content: 'This is a required field' };
    } else if (formValues.password?.length < 8 && formError) {
      return { content: 'Password must be 8 characters long' };
    } else {
      return false;
    }
  };

  const handlePasswordValidation = async () => {
    if (!formValues.password || formValues.password.length < 8) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      return axios
        .put(`${process.env.REACT_APP_API}/user/${props.user.id}`, formValues, createRequestHeader())
        .then(res => {
          dispatch(setCurrentUser(res.data));
          addToast(`Successfully reset your password, ${formValues.first_name}`, {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
          setOpenModal(false);
        })
        .catch(error => {
          addToast(error.message, { appearance: 'error', autoDismiss: true });
          setLoading(false);
          setDisabled(false);
        });
    }
  };

  return (
    <Modal
      dimmer={'blurrying'}
      size="small"
      trigger={
        <Button className="ui button" onClick={() => setOpenModal(true)}>
          Edit Password
        </Button>
      }
      open={open}
      centered={false}
    >
      <Modal.Header>Edit Password</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            error={passwordError()}
            fluid
            type="password"
            required={true}
            placeholder="Password"
            onChange={e => setFormValues({ ...formValues, password: e.target.value })}
            value={formValues.password}
            label="Password"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" size="tiny" onClick={() => setOpenModal(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          loading={loading}
          disabled={disabled}
          icon="check"
          content="Save"
          size="tiny"
          color="green"
          onClick={async event => {
            event.preventDefault();
            handlePasswordValidation();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditPasswordModal;
