import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/sm-green-ph-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate27.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_27';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate27 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, detail } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    const bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    const sqft = `${squareFeet} SQFT`;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms}   |   Bath: ${bath}   |   ${sqft}`;

    this.state = {
      headline,
      image1,
      address,
      detail,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1, address, detail } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate27._cached = { headline, image1, address, detail };

    return (
      <div className={`facebook-27-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper" style={{ backgroundColor: theme === 'black' ? '#fff' : '#fff' }}>
          <div className="logo">
            <img src={Logo} width="180" base64="true" alt="logo" />
          </div>
          <div className="border"></div>
          <div className="content-wrapper--header">
            <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
            <EditableInput
              self={this}
              className="address"
              fieldName="address"
              placeholder="#123 - 456W Georgia St, Vancouver"
            />
            <EditableInput
              self={this}
              className="detail"
              fieldName="detail"
              placeholder="Bed: X | Bath: X | XXX SQFT"
            />
          </div>
        </div>
      </div>
    );
  }
}

FacebookTemplate27._name = TEMPLATE_NAME;
FacebookTemplate27._width = TEMPLATE_WIDTH;
FacebookTemplate27._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate27, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate27);
