import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import UpdatePhotoModal from 'src/components/update-photo-modal';
import BackgroundImage from 'src/components/background-image';
import Scale from 'src/components/scale';

import './editable-image.scss';

const EditableImageV2 = ({
  src = '',
  onUpdate = () => {},
  style = {},
  className = '',
  size = 'cover',
  opacity = 1,
  pictures = [],
  position = EditableImageV2.CENTER,
  buttonSize = 'medium',
  buttonText = 'Change Photo',
}) => {
  // Photos are used in PDF downloads, Pictures are for reference
  return (
    <div className={`editable-image ${className}`} style={style}>
      <BackgroundImage url={src} className="image-content" size={size} opacity={opacity} />
      <div className="upload not-in-pdf">
        <div className="upload-wrapper" />
        <UpdatePhotoModal
          photos={pictures}
          updatePhoto={url => {
            onUpdate(url);
          }}
          trigger={handleClick => (
            <Scale.Back
              className={`${position} upload-button-wrapper`}
              style={{
                top: 'calc(50% - 17px)',
                left: 'calc(50% - 30px)',
                zIndex: 1000,
                marginRight: 0,
              }}
            >
              <Button primary size={buttonSize} onClick={handleClick}>
                <Icon name="edit" />
                {buttonText}
              </Button>
            </Scale.Back>
          )}
        />
      </div>
    </div>
  );
};

EditableImageV2.TOP = 'top';
EditableImageV2.CENTER = 'center';
EditableImageV2.TOP_LEFT = 'top-left';
EditableImageV2.LEFT = 'left';

export default EditableImageV2;
