import React from 'react';
import { connect } from 'react-redux';
import '../brochures/reset.css';
import './UniversalPembertonBrochure4.scss';
import { register } from 'src/services/template-renderer';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import UniversalListingInfo from '../brochures/fragments/universal/universal-listing-info';
import UniversalFloorplan from '../brochures/fragments/universal/universal-floorplan';
import PlaceholderImage from 'src/images/banner.png';
const TEMPLATE_NAME = 'UNIVERSAL_FEATURE_SHEET';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL }) => {
  return (
    <div className={`scaled template-dark template pem-template-4 ${theme} page-2 ui-only-outline`}>
      <UniversalListingInfo
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        mergeCurrentListing={mergeCurrentListing}
        font={font}
        moreImages={true}
        dark={true}
        twoAgents={true}
        layout="large"
        listing={listing}
        theme={theme}
      />
    </div>
  );
};

const Page2 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL }) => {
  return (
    <div className={`scaled template-dark template pem-template-3 ${theme} page-1 ui-only-outline`}>
      <UniversalFloorplan
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        mergeCurrentListing={mergeCurrentListing}
        dark={true}
        listing={listing}
        showHeader={false}
        theme={theme}
        font={font}
      />
    </div>
  );
};

const UniversalPembertonBrochure4 = ({
  searchedListing,
  currentListing,
  themeClassName,
  mergeCurrentListing,
  theme,
  ...rest
}) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };

  return (
    <div>
      <ApplyFont
        fontName={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
      <Page1
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
      <br className="not-in-pdf" />
      <Page2
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
    </div>
  );
};

UniversalPembertonBrochure4._name = TEMPLATE_NAME;
UniversalPembertonBrochure4._width = TEMPLATE_WIDTH;
UniversalPembertonBrochure4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: UniversalPembertonBrochure4,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return {
    searchedListing,
    currentListing,
    appliedTheme: theme.appliedTheme,
    currentBroker,
    defaultSettings: theme.defaultSettings,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalPembertonBrochure4);
