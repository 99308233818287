import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './PembertonBrochure4.scss';
import { register } from 'src/services/template-renderer';
import Floorplan from 'src/templates/brochures/fragments/pemberton/floorplan';
import ListingInfo from 'src/templates/brochures/fragments/pemberton/listing-info';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
const TEMPLATE_NAME = 'PEMBERTON_HOLMES_FEATURE_SHEET';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, mergeCurrentListing }) => {
  return (
    <div className={`scaled template-dark template pem-template-4 ${theme} page-2 ui-only-outline`}>
      <ListingInfo
        mergeCurrentListing={mergeCurrentListing}
        moreImages={true}
        dark={true}
        layout="large"
        listing={listing}
        theme={theme}
        customMarginParentDiv={'-14px'}
        customMarginTopParentDiv={'-17.5px'}
      />
    </div>
  );
};

const Page2 = ({ listing, theme, mergeCurrentListing }) => {
  return (
    <div className={`scaled template-dark template pem-template-3 ${theme} page-1 ui-only-outline`}>
      <Floorplan
        mergeCurrentListing={mergeCurrentListing}
        dark={true}
        listing={listing}
        showHeader={false}
        theme={theme}
      />
    </div>
  );
};

const PembertonBrochure4 = ({ searchedListing, currentListing, themeClassName, mergeCurrentListing }) => {
  const theme = themeClassName || localStorage.getItem('themeClassName');
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <>
      <Page1 mergeCurrentListing={customMergeListing} listing={currentListing} theme={theme} />
      <br className="not-in-pdf" />
      <Page2 mergeCurrentListing={customMergeListing} listing={currentListing} theme={theme} />
    </>
  );
};

PembertonBrochure4._name = TEMPLATE_NAME;
PembertonBrochure4._width = TEMPLATE_WIDTH;
PembertonBrochure4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: PembertonBrochure4,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(PembertonBrochure4);
