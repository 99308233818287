import Swal from 'sweetalert2';
import { USER_ROLES } from 'src/constants/permissions';
import { getBrokerTotalUsersAPI } from 'src/redux/actions/stripeActions';
import { getSubscriptionStatusAPI } from 'src/redux/actions/stripeActions';
import { OPEN_MAX_USER_LIMIT_REACHED_MODAL } from 'src/redux/reducers/appPermissionReducer';
import { OPEN_CHOOSE_PLAN_MODAL } from 'src/redux/reducers/appPermissionReducer';
import { OPEN_CONTACT_ADMIN_MODAL } from 'src/redux/reducers/appPermissionReducer';

const STRIPE_ONBOARDING_INITIAL_ROUTE = '/onboarding/pricing?step=1';

export const getTotalStripeUsers = planData => {
  if (!planData) {
    return;
  }
  return planData.reduce((acc, curr) => {
    const totalUsers = acc + curr.quantity * +curr.plan.metadata.max_user;
    return totalUsers;
  }, 0);
};

const openChoosePlanModal = dispatch => {
  return Swal.fire({
    title: 'No Active Subscription Plan!',
    text: 'Seems like you have no active subscription plan, Choose a plan to continue!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#E74942',
    customClass: { container: 'realvault-swal' },
    reverseButtons: true,
  }).then(({ isConfirmed }) => {
    if (isConfirmed) {
      dispatch({
        type: OPEN_CHOOSE_PLAN_MODAL,
      });
      return false;
    }
    return false;
  });
};

const openTakeStripeSubscriptionPopup = (dispatch, history) => {
  return Swal.fire({
    title: 'No Stripe Subscription Found!',
    text: 'Seems like you have not taken stripe subscription, Choose a plan to continue!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#E74942',
    customClass: { container: 'realvault-swal' },
    reverseButtons: true,
  }).then(({ isConfirmed }) => {
    if (isConfirmed) {
      history.replace(STRIPE_ONBOARDING_INITIAL_ROUTE);
      return false;
    }
    return false;
  });
};

export const continueNextFunctionality = async ({
  currentBroker,
  currentUser,
  dispatch,
  includeUserCount = false,
  history = () => {},
}) => {
  // check the user role first
  if (USER_ROLES.BROKER_ADMIN.toString() === currentUser.user_role_id.toString()) {
    // USER IS BROKER ADMIN
    if (!currentBroker.manual_billing || currentBroker.manual_billing.toString() === '1') {
      // MANUAL BILLING IS ENABLED
      // DONT NEED TO CHECK THE SUBSCRIPTION FURTHER
      return true;
    } else {
      // MANUAL BILLING IS NOT ENABLED, SO CHECK FOR THE SUBSCRIPTION,
      // TODO - call the api first to know the subscription status
      return getSubscriptionStatusAPI({
        broker_id: currentBroker.id,
        user_id: currentUser.id,
      })
        .then(({ data }) => {
          if (data.status !== 'fail') {
            if (data?.values?.status === 'active') {
              // if include user count is specified
              if (includeUserCount) {
                return getBrokerTotalUsersAPI(currentBroker?.id)
                  .then(({ data: brokerResponse }) => {
                    const { count } = brokerResponse;
                    // THE USER COUNT FROM STRIPE < USER IN A BROKER
                    const stripeMaxUserAllowed = getTotalStripeUsers(data?.values?.items?.data);

                    if (count < stripeMaxUserAllowed) {
                      return true;
                    } else {
                      // OPEN THE POPUP Telling 1 User will be added to the subscription
                      dispatch({
                        type: OPEN_MAX_USER_LIMIT_REACHED_MODAL,
                      });
                      return false;
                    }
                  })
                  .catch(err => {
                    console.log('ERR => while getting subscription status', err);
                  });
              } else {
                return true;
              }
            } else {
              // NEITHER MANUAL BILLING IS NOT ENABLED, NOR HAVE THE SUBSCRIPTION
              // PROMPT THE USER TO CHOOSE A PLAN
              return openChoosePlanModal(dispatch);
            }
          } else if (data.status === 'fail') {
            if (data.msg === 'No relation with stripe' || data.msg.includes('No such subscription')) {
              openTakeStripeSubscriptionPopup(dispatch, history);
              return false;
            }
          }
        })
        .catch(err => {
          console.log('ERR => while getting subscription status', err);
        });
    }
  } else {
    // USER IS NOT BROKER ADMIN
    if (!currentBroker.manual_billing || currentBroker.manual_billing.toString() === '1') {
      // MANUAL BILLING IS ENABLED
      // DONT NEED TO CHECK THE SUBSCRIPTION FURTHER
      return true;
    } else {
      // MANUAL BILLING IS NOT ENABLED,

      // FIRST CHECK THE SUBSCRIPTION
      // TODO - call the api first to know the subscription status
      return getSubscriptionStatusAPI({
        broker_id: currentBroker.id,
        user_id: currentUser.id,
      })
        .then(({ data }) => {
          if (data.status !== 'fail') {
            if (data?.values?.status === 'active') {
              return true;
            } else {
              // NEITHER MANUAL BILLING IS NOT ENABLED, NOR HAVE THE SUBSCRIPTION
              // SHOW THE CONTACT YOUR ADMIN NOW MODAL
              dispatch({
                type: OPEN_CONTACT_ADMIN_MODAL,
              });
              return false;
            }
          }
        })
        .catch(err => {
          console.log('ERR => while getting subscription status', err);
        });
    }
  }
};
