import React from 'react';

import './background-image.css';

const BackgroundImage = ({ url, width, height, className = '', size = 'cover', opacity = 1 }) => {
  const w = width ? `${width}px` : '100%';
  const h = height ? `${height}px` : '100%';

  return (
    <div className={`${className}`}>
      <div
        className={`background-image`}
        style={{
          width: w,
          height: h,
          backgroundImage: 'url(' + url + ')',
          backgroundSize: `${size}`,
          opacity,
        }}
      ></div>
    </div>
  );
};

export default BackgroundImage;
