import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure3 from 'src/templates/brochures/macdonald/MacdonaldBrochure3';

class MacdonaldBrochurePage3 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure3._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure3._width} height={MacdonaldBrochure3._height} pages={2} scale={1.2}>
          <MacdonaldBrochure3 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage3);
