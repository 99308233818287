import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { register } from 'src/services/template-renderer';
import EditableImage from 'src/components/editable-image';
import FilePicker from 'src/components/file-picker';
import Scale from 'src/components/scale';
import VantageWestBrochureTemplate from 'src/templates/base/VantageWestBrochureTemplate';
import BackgroundImage from 'src/components/background-image';
import PlaceholderImage from 'src/images/banner.png';
import logoPlaceholder from '../../../images/vantagewest/logo-placeholder.png';
import _ from 'src/lodash';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { mergeCurrentListing } from 'src/redux/actions/actions';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

import './VantageWestBrochure1.scss';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import UniversalLegalText from '../fragments/common/universal-legal-text';

import EditableImageV2 from 'src/components/editable-image-v2';

const TEMPLATE_NAME = '2_PG_Brochure_1';

const TEMPLATE_WIDTH = 816;
// const TEMPLATE_HEIGHT = 870;
const TEMPLATE_HEIGHT = 1110;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1900;
const neighborhood_highlights = 'NEIGHBORHOOD HIGHLIGHTS';

const Page1 = ({
  defaultURL,
  footerURL,
  self,
  listing,
  theme,
  defaultSettings,
  currentBroker,
  mergeCurrentListing,
  logo,
}) => {
  return (
    <div
      className="scaled template page-1 ui-only-outline"
      style={{ padding: '0 0 0px 0', height: '1056px', width: '100%', margin: '0 0 55px 0', outline: '0' }}
    >
      <div className="hero-image" style={{ height: '430px' }}>
        <div
          className="logo-div"
          style={{
            padding: currentBroker?.template_code === 'PMB' ? '15px' : '0',
            width: currentBroker?.template_code === 'PMB' ? '125px' : '100px',
            height: currentBroker?.template_code === 'PMB' ? '125px' : '100px',
          }}
        >
          <EditableImageV2
            fieldName="logo"
            className="brand-logo"
            alt="Brand Logo"
            size="contain"
            style={{ width: 100, height: 100, position: 'relative' }}
            position={EditableImageV2.CENTER}
            buttonSize={'tiny'}
            buttonText={''}
            src={defaultURL ||   defaultSettings?.horizontalLogo || PlaceholderImage}
            onUpdate={url => {
              const pictures = [...listing.pictures];
              pictures[100] = url;
              mergeCurrentListing({
                pictures,
              });
            }}
          />
        </div>
        <EditableImage
          self={self}
          fieldName="image1"
          className="hero-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={0}
          style={{ height: '430px' }}
        />
      </div>
      <div className="image-container" style={{ height: '285px', width: '100%' }}>
        <EditableImage
          self={self}
          fieldName="image2"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={1}
          style={{ width: '100%', height: '285px' }}
        />
        <EditableImage
          self={self}
          fieldName="image3"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={2}
          style={{ width: '100%', height: '285px' }}
        />
        <EditableImage
          self={self}
          fieldName="image4"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={3}
          style={{ width: '100%', height: '285px' }}
        />
      </div>
      <div
        className="footer"
        style={{
          height: '480px',
        }}
      >
        {/* About section */}
        <div className="about-section" style={{ height: '220px', padding: '22px 30px 0px' }}>
          <div
            className="address"
            style={{
              color: theme?.backgroundColor || defaultSettings?.primaryColor,
              fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
            }}
          >
            <div style={{ display: 'flex' }}>
              <input
                className="mobile-p"
                style={{
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={
                  listing.location.streetAddress.length > 60
                    ? listing.location.streetAddress.slice(0, 60)
                    : listing.location.streetAddress
                }
                maxLength={60}
                onChange={e => {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div
            className="about-content"
            style={{
              borderBottomColor: theme?.backgroundColor || defaultSettings?.primaryColor,
            }}
          >
            <div
              className="description"
              style={{
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                height: '140px',
              }}
            >
              <div
                className="bed-icon"
                style={{ backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor }}
              >
                {listing.details.qtyBedrooms} <i className="fa fa-bed" aria-hidden="true"></i>{' '}
                {listing.details.qtyFullBathrooms} <i className="fa fa-bath" aria-hidden="true"></i>
              </div>
              <textarea
                contentHeight
                className="description-input"
                value={listing.description.length > 600 ? listing.description.slice(0, 600) : listing.description}
                rows={6}
                maxLength={600}
                style={{
                  wordBreak: 'break-word',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                }}
                onChange={e => mergeCurrentListing({ description: e.target.value })}
              />
            </div>
          </div>
        </div>

        {/* agent-section */}
        <div
          className="agent-section"
          style={{
            height: 'auto',
            marginTop: '20px',
          }}
        >
          <div className="agent-div">
            <div className="left-side">
              <div className="agent-item">
                <div className="agent-pic-wrapper">
                  <BackgroundImage
                    className="agent-pic"
                    url={
                      listing.agents.agentImageUrl === 'n/a'
                        ? require('../../../images/vantagewest/avatar-placeholder.jpg')
                        : listing.agents.agentImageUrl
                    }
                    width={95}
                    height={100}
                    size="contain"
                  />
                  <div className="edit-btn-hover not-in-pdf">
                    <Scale.Back>
                      <FilePicker
                        onSelect={async dataUrl => {
                          mergeCurrentListing({
                            agents: {
                              ...listing.agents,
                              agentImageUrl: dataUrl,
                            },
                          });
                        }}
                        accept="image/*"
                        className="agent-pic-wrapper"
                      >
                        <Button primary size="tiny">
                          <Icon name="upload" /> Upload
                        </Button>
                      </FilePicker>
                    </Scale.Back>
                  </div>
                </div>

                <div className="agent-info" style={{ padding: '10px 0px' }}>
                  <input
                    className="agent-name"
                    style={{
                      color: theme?.backgroundColor || defaultSettings?.primaryColor,
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      fontSize: '1.2rem',
                    }}
                    value={
                      listing.agents?.agentName?.length > 20
                        ? listing.agents?.agentName.slice(0, 20)
                        : listing.agents?.agentName
                    }
                    onChange={e => {
                      if (e.target.value.length < 20) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentName: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  <input
                    className="agent-title"
                    style={{
                      marginTop: -10,
                      color: '#777',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      width: '100%',
                    }}
                    value={
                      listing.agents?.agentTitle?.length > 22
                        ? listing.agents?.agentTitle?.slice(0, 22)
                        : listing.agents?.agentTitle
                    }
                    onChange={e => {
                      if (e.target.value.length < 22) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentTitle: e.target.value,
                          },
                        });
                      }
                    }}
                  />

                  <input
                    className="mobile-p"
                    style={{
                      marginTop: 5,
                      fontSize: '1.2rem',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      width: '100%',
                    }}
                    value={
                      listing.agents?.agentPhone?.replace(/-/g, '.').length > 15
                        ? listing.agents?.agentPhone?.replace(/-/g, '.').slice(0, 15)
                        : listing.agents?.agentPhone?.replace(/-/g, '.')
                    }
                    onChange={e => {
                      if (e.target.value.length < 15) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentPhone: e.target.value,
                          },
                        });
                      }
                    }}
                  />

                  <input
                    className="agent-email"
                    style={{
                      width: '100%',
                      marginTop: 5,
                      color: '#777',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    }}
                    value={
                      listing.agents?.agentEmail?.length > 35
                        ? listing.agents?.agentEmail?.slice(0, 35)
                        : listing.agents?.agentEmail
                    }
                    onChange={e => {
                      if (e.target.value.length < 35) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentEmail: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="right-side">
              <EditableImage
                self={self}
                fieldName="image11"
                className="agent-logo"
                size="contain"
                position={EditableImage.CENTER}
                pictures={listing.pictures}
                imgKey={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page2 = ({
  defaultURL,
  footerURL,
  self,
  listing,
  theme,
  defaultSettings,
  currentBroker,
  mergeCurrentListing,
}) => {
  const [neighborhoodHighlights, setNeighbourHoodHighlight] = useState('NEIGHBORHOOD HIGHLIGHTS');

  return (
    <div
      className="scaled page-2 ui-only-outline"
      style={{ height: '1056px', margin: '0px 0 0 0', padding: '0px 0 0 0', width: '100%' }}
    >
      <div className="image-container" style={{ height: '275px', width: '100%' }}>
        <EditableImage
          self={self}
          fieldName="image5"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
          style={{ height: '270px' }}
        />
        <EditableImage
          self={self}
          fieldName="image6"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={5}
          style={{ height: '270px' }}
        />
      </div>
      <div className="image-container" style={{ width: '100%', height: '275px' }}>
        <EditableImage
          self={self}
          fieldName="image7"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={6}
          style={{ height: '270px' }}
        />
        <EditableImage
          self={self}
          fieldName="image8"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={7}
          style={{ height: '270px' }}
        />
      </div>
      {/* Highlight section */}
      <div className="highlight-section" style={{ width: '100%', height: '240px', minHeight: '240px' }}>
        <div className="high-div">
          <div className="highlight-image-container">
            <EditableImage
              self={self}
              fieldName="image9"
              className="highlight-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={8}
              style={{ height: '240px' }}
            />
          </div>
          <div className="h-item">
            <div
              className="neighborhood-highlights"
              style={{
                backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
              }}
            >
              <input
                className="feature"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={listing.details.heading}
                onChange={e =>
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      heading: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="highlight-description">
              <div className="features-list" style={{ backgroundColor: '#777' }}>
                <textarea
                  className="feature"
                  style={{
                    width: '100%',
                    height: '178px',
                    marginTop: -5,
                    textAlign: 'justify',
                    whiteSpace: 'normal',
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                  }}
                  rows={9}
                  value={listing.details.neighborhood_highlights}
                  onChange={e =>
                    mergeCurrentListing({ details: { ...listing.details, neighborhood_highlights: e.target.value } })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="feature-section"
        style={{ width: '100%', height: '315px', minHeight: '315px', backgroundColor: '#fff' }}
      >
        <div className="high-div">
          <div className="feature-image-container">
            <EditableImage
              self={self}
              fieldName="image10"
              className="feature-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={9}
            />
          </div>
          <div className="h-item" style={{ width: '100%' }}>
            <div
              className="feature-title"
              style={{
                backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                width: '100%',
              }}
            >
              <input
                className="feature"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={listing.details.features}
                onChange={e =>
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      features: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="feature-container" style={{ height: '260px' }}>
              <div className="feature-description">
                <ul
                  className="list"
                  style={{
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    listStyle: 'none',
                  }}
                >
                  {listing.isMLSVisible && (
                    <>
                      WLS Id: <div>{`${listing.mlsId}`}</div>
                    </>
                  )}
                  <li
                    style={{
                      listStyle: listing.details.bedroomsText === '' ? 'none' : '',
                      paddingBottom: '10px',
                    }}
                  >
                    {/* Bedrooms:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={listing.details.bedroomsText}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            bedroomsText: e.target.value,
                          },
                        })
                      }
                    ></input>
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      value={listing.details.qtyBedrooms}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            qtyBedrooms: e.target.value,
                          },
                        })
                      }
                    />{' '}
                  </li>
                  <li style={{ listStyle: listing.details.bathroomsText === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Bathrooms:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={listing.details.bathroomsText}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            bathroomsText: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={listing.details.qtyFullBathrooms}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            qtyFullBathrooms: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.year === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Year Built:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing.details.year}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            year: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing.details.yearBuilt}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            yearBuilt: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                </ul>
                <ul
                  className="list"
                  style={{
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    listStyle: 'none',
                  }}
                >
                  <li style={{ listStyle: listing.features?.taxes === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Gross Taxes:{' '} */}

                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing?.details?.grossTaxLegend}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            grossTaxLegend: e.target.value,
                          },
                        })
                      }
                    />

                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={
                        listing.details?.grossTax?.length > 6
                          ? listing.details?.grossTax.slice(0, 6)
                          : listing.details?.grossTax
                      }
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            grossTax: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.totalFloorArea === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Total Floor Area:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing.details.totalFloorArea}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            totalFloorArea: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing.details.squareFeet}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            squareFeet: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.totalLotSize === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Total Lot Size:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                      }}
                      value={`${listing.details.totalLotSize}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            totalLotSize: e.target.value,
                          },
                        })
                      }
                    />
                    {currentBroker?.feed_type === 'KVC ' ? (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                        }}
                        value={`${listing.features.lotSzSqFt}`}
                        onChange={e =>
                          mergeCurrentListing({
                            features: {
                              ...listing.features,
                              lotSzSqFt: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                        }}
                        value={`${listing.details.lotSizeTotal}`}
                        onChange={e =>
                          mergeCurrentListing({
                            details: {
                              ...listing.details,
                              lotSizeTotal: e.target.value,
                            },
                          })
                        }
                      />
                    )}
                  </li>
                </ul>
              </div>
              <div
                // className="break-line"
                style={{
                  backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                }}
              />
              {/* <div className={`legal ${!showAgentInfo && 'small-margin'}`}>

              </div> */}
              <div className="footer-logo-container" style={{ position: 'absolute', bottom: '25px', right: '0' }}>
                <EditableImageV2
                  fieldName="logo"
                  className="logo-footer"
                  alt="Brand Logo"
                  size="contain"
                  style={{ width: 95, height: 65, position: 'relative' }}
                  position={EditableImageV2.CENTER}
                  buttonSize={'tiny'}
                  buttonText={''}
                  src={ footerURL || defaultSettings?.horizontalLogo || PlaceholderImage}
                  onUpdate={url => {
                    const pictures = [...listing.pictures];
                    pictures[102] = url;
                    mergeCurrentListing({
                      pictures,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '-12px',
                  fontSize: '6px',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                }}
              >
                <UniversalLegalText />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class VantageWestBrochure1 extends Component {
  constructor(props) {
    super(props);

    let {
      currentListing,
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11 = logoPlaceholder,
      address,
    } = props;

    const { photos, description, location } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;
    if (!image6) image6 = photos[5] || PlaceHolderImage6;
    if (!image7) image7 = photos[6] || PlaceHolderImage7;
    if (!image8) image8 = photos[7] || PlaceHolderImage1;
    if (!image9) image9 = photos[8] || PlaceHolderImage2;
    if (!image10) image10 = photos[9] || PlaceHolderImage3;
    if (!image11) image11 = photos[10] || PlaceHolderImage4;

    if (!address) address = `${location.streetAddress}, ${location.city}`;

    const defaultURL = this.props.defaultURL;
    const defaultSettings = this.props.defaultSettings;
    const logo = defaultURL || defaultSettings?.squareLogo || PlaceholderImage;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      address,
      description,
      logo,
    };
  }

  customMergeListing = obj => {
    const newListing = _.omit({ ...this.props.currentListing, ...obj }, true);
    this.props.mergeCurrentListing(newListing);
  };

  render() {
    const { currentListing, appliedTheme, defaultSettings, currentBroker, defaultURL } = this.props;
    const { image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, address, logo } =
      this.state;

    VantageWestBrochure1._cached = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      address,
      appliedTheme,
      defaultSettings,
      staticBrokerFonts,
      currentBroker,
    };

    return (
      <VantageWestBrochureTemplate className="vw-brochure-1-wrapper">
        <ApplyFont fontName={appliedTheme?.font || defaultSettings?.font}>
          <Page1
            self={this}
            listing={this.props.currentListing}
            theme={appliedTheme}
            logo={logo}
            defaultSettings={defaultSettings}
            currentBroker={currentBroker}
            mergeCurrentListing={this.customMergeListing}
            defaultURL={defaultURL}
            footerURL={this.props.footerURL}
          />

          <br className="not-in-pdf" />
          <Page2
            self={this}
            listing={this.props.currentListing}
            theme={appliedTheme}
            defaultSettings={defaultSettings}
            currentBroker={currentBroker}
            mergeCurrentListing={this.customMergeListing}
            defaultURL={this.props.defaultURL}
            footerURL={this.props.footerURL}
          />
        </ApplyFont>
      </VantageWestBrochureTemplate>
    );
  }
}

VantageWestBrochure1._name = TEMPLATE_NAME;
VantageWestBrochure1._width = TEMPLATE_WIDTH;
VantageWestBrochure1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: VantageWestBrochure1,
  width: TEMPLATE_WIDTH,
  height: 1056,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return { currentListing, appliedTheme: theme.appliedTheme, defaultSettings: theme.defaultSettings, currentBroker };
};

export default connect(mapStateToProps, { mergeCurrentListing })(VantageWestBrochure1);
