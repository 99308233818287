import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure2 from 'src/templates/brochures/macdonald/MacdonaldBrochure2';

class MacdonaldBrochurePage2 extends Component {
  render() {
    const { currentListing, brokers } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure2._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure2._width} height={MacdonaldBrochure2._height} pages={2} scale={1}>
          <MacdonaldBrochure2 broker={brokers?.currentBroker} />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing, brokers } = state;
  return { currentListing, brokers };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage2);
