import axios from 'axios';
import { DEFAULT_HEADERS } from 'src/utils/headers';
import { SET_ALL_FONTS, SET_DEFAULT_SETTINGS, SET_THEMES } from '../reducers/theme-creator';

export const createTheme = themeData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/themes`, themeData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateTheme = themeData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.patch(`${baseURL}/themes/${themeData.id}`, themeData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteTheme = themeData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.delete(`${baseURL}/themes/${themeData.id}`, themeData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getThemeByBroker = brokerId => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/themes/broker/${brokerId}`, null, DEFAULT_HEADERS);
      resolve(data);

      // get the default settings
      const {
        theme: { defaultSettings },
      } = getState();
      // add the default setting as the first theme for the array
      const defaultTheme = {
        backgroundColor: defaultSettings?.primaryColor,
        broker: defaultSettings?.broker,
        createdTime: defaultSettings?.createdTime,
        font: defaultSettings?.font,
        foregroundColor: defaultSettings?.secondaryColor,
        id: defaultSettings?.id,
        lastUpdatedTime: defaultSettings?.lastUpdatedTime,
        name: 'Default Theme',
      };
      dispatch({
        type: SET_THEMES,
        payload: [defaultTheme, ...data],
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllFonts = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBiGQHee7kLBX4F8IEQHimF6o49sGHOeu8`,
        null,
        DEFAULT_HEADERS
      );
      resolve(data.items);
      dispatch({
        type: SET_ALL_FONTS,
        payload: data.items,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getDefaultSettings = brokerId => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/broker-settings/${brokerId}`, null, DEFAULT_HEADERS);
      resolve(data);
      const dataIsArray = Array.isArray(data);
      dispatch({
        type: SET_DEFAULT_SETTINGS,
        payload: dataIsArray ? (data.length === 0 ? null : data) : data,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setDefaultSettings = (brokerId, settingsData) => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/broker-settings/${brokerId}`, settingsData, DEFAULT_HEADERS);
      resolve(data);
      dispatch(getDefaultSettings(brokerId));
    } catch (error) {
      reject(error);
    }
  });
};

export const saveUploadedBrandLogo = brandLogoData => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/saveUploadedBrokrageBanner`, brandLogoData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
