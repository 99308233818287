import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { setCurrentBroker } from 'src/redux/actions/brokerActions';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Header, Divider, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentUser } from 'src/redux/actions/userActions';
import { handleFetchUser } from 'src/utils/login-utils';
import ResetPassword from 'src/components/authorization/ResetPassword';
import PublicLayout from '../../layouts/public-layout';
import { useTitle } from 'react-use';
import { track, SIGN_UP_EVENT } from 'src/utils/analytics';
import { LOGIN_EVENT } from 'src/utils/analytics';

/**
 * @constant Login - Logic for authentication
 */

const propTypes = { redirectAfterLogin: PropTypes.bool, forgotPassword: PropTypes.func };
const defaultProps = { redirectAfterLogin: false };

const Login = props => {
  useTitle('Login - Realvault');

  const { addToast } = useToasts();
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [redirectRegister, setRedirectRegister] = useState(false);

  const history = useHistory();

  // oauth login
  useEffect(() => {
    const params = new URLSearchParams(history.location.hash.replace(/^#/, ''));
    const accessToken = params.get('accessToken');
    const refreshToken = params.get('refreshToken');

    if (accessToken && refreshToken) {
      setLoading(true);
      setDisabled(true);
      afterLogin({ accessToken, refreshToken });
    }
  }, [history.location.hash]);

  useEffect(() => {
    track(SIGN_UP_EVENT);
  }, []);

  async function afterLogin({ accessToken, refreshToken }) {
    const decodedToken = jwt.decode(accessToken);
    localStorage.setItem('jwt', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    await handleFetchUser(decodedToken.user_id, props.setCurrentUser, props.setCurrentBroker);
    setLoading(false);
    setDisabled(false);
    props.history.push('/dashboard');
  }

  if (redirectRegister) {
    // return (<Redirect to="/register" />);
    localStorage.setItem('broker_onboarding_step', 1);
    return <Redirect to="/onboarding/pricing" />;
  } else if (resetPassword) {
    return (
      <PublicLayout>
        <Header as="h1">Reset Password</Header>
        <Divider section />
        <ResetPassword />
      </PublicLayout>
    );
  } else {
    return (
      <PublicLayout>
        <div>
          <Header as="h1" className="text-center uppercase">
            Welcome Back
          </Header>
          <div className="background white">
            <Form>
              <Form.Field>
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={e => setLoginInfo({ ...loginInfo, email: e.target.value })}
                  value={loginInfo.email}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input
                  placeholder="Password"
                  type="password"
                  onChange={e => setLoginInfo({ ...loginInfo, password: e.target.value })}
                  value={loginInfo.password}
                />
              </Form.Field>
              <Button
                primary
                loading={loading}
                disabled={disabled}
                size="tiny"
                onClick={async event => {
                  event.preventDefault();
                  setLoading(true);
                  setDisabled(true);
                  axios
                    .post(`${process.env.REACT_APP_API}/auth/login`, loginInfo)
                    .then(async res => {
                      track(LOGIN_EVENT);
                      await afterLogin({ accessToken: res.data.access_token, refreshToken: res.data.refresh_token });
                    })
                    .catch(error => {
                      addToast(error.response ? error.response.data.error : 'Error', {
                        appearance: 'error',
                        autoDismiss: true,
                      });
                      setLoading(false);
                      setDisabled(false);
                    });
                }}
              >
                Login
              </Button>
              <p className="link-text" onClick={() => setResetPassword(true)}>
                Forgot Password?
              </p>
              <Divider horizontal>OR</Divider>
              <div className="mt-12">
                <Button
                  loading={loading}
                  icon
                  labelPosition="left"
                  className="fluid"
                  onClick={event => {
                    event.preventDefault();
                    setLoading(true);
                    setDisabled(true);

                    console.info('redirecting to google....');
                    window.location.href = `${process.env.REACT_APP_API}/auth/google`;
                  }}
                >
                  <Icon name="google" />
                  Sign in with Google
                </Button>
              </div>
            </Form>
          </div>
          <Header as="h4">
            Not a user?{' '}
            <Button onClick={() => setRedirectRegister(true)} className="link-button">
              Register
            </Button>
          </Header>
        </div>
      </PublicLayout>
    );
  }
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({ setCurrentUser, setCurrentBroker }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
