import React from 'react';

import './outlined-button.scss';

const OutlinedButton = ({ onClick, children, className = '', style = {}, disabled }) => {
  return (
    <div
      className={`outlined-btn ${className} ${disabled ? 'disabled-btn' : ''}`}
      onClick={disabled ? null : onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default OutlinedButton;
