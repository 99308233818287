import React from 'react';
import { Table, Label, Header } from 'semantic-ui-react';
import UserModal from 'src/components/admin/UserModal';

const RequestTable = props => {
  return (
    <>
      <br />
      <Header as="h3">Requests</Header>
      <Table>
        <Table.Header compact>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Requested Broker</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.users
            ?.filter(({ user_status_id }) => user_status_id === 1)
            .map(user => (
              <Table.Row>
                <Table.Cell>
                  <Label ribbon color="blue">
                    New
                  </Label>
                  {user.email}
                </Table.Cell>
                <Table.Cell>
                  {user.first_name} {user.last_name}
                </Table.Cell>
                <Table.Cell>{user.phone_number}</Table.Cell>
                <Table.Cell>{user.requested_broker}</Table.Cell>
                <Table.Cell textAlign="right">
                  <UserModal user={user} edit editPermission getUsers={props.getUsers} />
                </Table.Cell>
              </Table.Row>
            ))}
          {props.users.filter(({ user_status_id }) => user_status_id === 1).length === 0 && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell>No Incoming Requests</Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default RequestTable;
