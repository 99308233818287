import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import BackgroundImage from 'src/images/BlankTemplateImages/background_onbording.png';
import OnboardingLayout from 'src/layouts/onboarding-layout';
import SelectPricing from './select-pricing';
import ContinueWith from './continue-with';
import { useToasts } from 'react-toast-notifications';

import './onboarding-broker.scss';
import CollectBasicDetails from './collect-basic-details';
import DefaultBranding from './default-branding';
import Payment from './payment';
import { generateTemplateCode } from 'src/utils/generic-utils';
import { updateCurrentUserBroker } from 'src/redux/actions/userActions';
import { createBrokerBasicDetailsOnboarding } from 'src/redux/actions/stripeActions';
import SelectLogos from '../SelectLogos/SelectLogos';
import SetColors from '../../components/SetColors/SetColors';
import {
  ONBOARDING_PRICING_PLAN_SELECTED_EVENT,
  ONBOARDING_BASIC_DETAILS_SUBMITTED_EVENT,
  ONBOARDING_LOGOS_SELECTED_EVENT,
  track,
  ONBOARDING_FONT_SELECTED_EVENT,
  ONBOARDING_COLOR_SELECTED_EVENT,
} from 'src/utils/analytics';
import omit from 'lodash/omit';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const OnboardingBroker = ({ history, match }) => {
  const dispatch = useDispatch();
  const {
    brokerOnboarding: { basicDetailsResponse },
    user,
    broker,
    isAuthenticated,
  } = useSelector(state => ({
    brokerOnboarding: state.stripe.brokerOnboarding,
    user: state.users.currentUser,
    broker: state.brokers.currentBroker,
    isAuthenticated: state.users.isAuthenticated,
  }));

  const { addToast } = useToasts();

  const [step, setStep] = useState(parseInt(localStorage.getItem('broker_onboarding_step')) || 1);

  const [isUserAlreadyOnboarded, setIsUserAlreadyOnboarded] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [basicDetails, setBasicDetails] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
    hasAgreedTerms: true,
    loading: false,
  });
  const savedDetails = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
  const [paymentDetails, setPaymentDetails] = useState({
    name_on_card: savedDetails ? `${savedDetails.first_name} ${savedDetails.last_name}` : '',
    // address
    street: '',
    city: '',
    province: '',
    postal_code: '',
    country: '',
  });

  useEffect(() => {
    if (basicDetails.first_name || basicDetails.last_name) {
      setPaymentDetails(prevState => ({
        ...prevState,
        name_on_card: `${basicDetails.first_name} ${basicDetails.last_name}`.trim(),
      }));
    }
  }, [basicDetails.first_name, basicDetails.last_name]);

  useEffect(() => {
    // redirect the user back to dashboard if they are authenticated & they have customer id and stripe subscription id
    if (user) {
      if (isAuthenticated && broker.stripe_customer_id && broker.stripe_subscription_id) {
        history.replace('/dashboard');
      } else {
        setBasicDetails({
          first_name: user?.first_name,
          last_name: user?.last_name,
          company_name: broker?.name,
          email: user?.email,
          phone_number: user?.phone_number,
          password: '',
          confirmPassword: '',
          hasAgreedTerms: true,
          loading: false,
        });
        setIsUserAlreadyOnboarded(!!user?.first_name);
      }
    }
  }, [broker, history, isAuthenticated, user]);

  // check if there is already a selected plan provided
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    const partnerId = searchParams.get('partnerId');
    if (partnerId) localStorage.setItem('partnerId', partnerId);

    const selectedPlanId = searchParams.get('selectedPlanId');
    if (!selectedPlanId) return;

    fetch(`${process.env.REACT_APP_TEMPLATE_API}/plannew`).then(res => {
      res.json().then(products => {
        console.log('products', products, 'selectedPlanId', selectedPlanId);
        const selectedProduct = products.find(product => product.plan_id === selectedPlanId);
        const selectedPlan = selectedProduct?.plan?.[0];
        if (!selectedPlan) {
          setStep(1);
          addToast('Invalid plan, please select one of the following plans', {
            appearance: 'error',
            autoDismiss: true,
          });
          return;
        }

        setSelectedPlan(selectedPlan);
        localStorage.setItem('broker_onboarding_selected_plan', JSON.stringify(selectedPlan));

        if (step === 1) setStep(2);
      });
    });
  }, [history]);

  const onSubmitSelectPricing = pricing => {
    setSelectedPlan(pricing);
    history.push(`${history.location.pathname}`);
    if (isUserAlreadyOnboarded) {
      setStep(3);
    } else {
      setStep(2);
    }

    track(ONBOARDING_PRICING_PLAN_SELECTED_EVENT, { pricing });

    localStorage.setItem('broker_onboarding_step', 1);
    localStorage.setItem('broker_onboarding_selected_plan', JSON.stringify(pricing));
  };

  const onSubmitBasicDetails = () => {
    setBasicDetails(prev => ({ ...prev, loading: true }));
    const partnerId = parseInt(localStorage.getItem('partnerId')) || 0;
    const apiData = {
      ...basicDetails,
      template_code: generateTemplateCode(basicDetails.company_name),
    };
    if (partnerId) apiData.partnerId = partnerId;
  
    if (isUserAlreadyOnboarded && user.broker_id) {
      history.push(`${history.location.pathname}`);
      setStep(5);
    } else {
      dispatch(createBrokerBasicDetailsOnboarding(apiData))
        .then(response => {
          if (partnerId) localStorage.removeItem('partnerId');
          if (response.broker_id && response.broker_id !== user.broker_id && user.email) {
            dispatch(updateCurrentUserBroker({ broker_id: response.broker_id }));
          }
        })
        .then(response => {
          setBasicDetails(prev => ({ ...prev, loading: true }));
          history.push(`${history.location.pathname}`);
          setStep(4);
        })
        .catch(err => {
          setBasicDetails(prev => ({ ...prev, loading: false }));
          console.error(err, 'ERR => while create broker onboarding');
          addToast('Failed to register, please try again later', {
            appearance: 'error',
            autoDismiss: true,
          });
          throw err;
        });
    }

    track(ONBOARDING_BASIC_DETAILS_SUBMITTED_EVENT, {
      basicDetails: omit(basicDetails, ['password', 'confirmPassword']),
    });
  };

  const STEP_COMPONENT = {
    1: <SelectPricing onRegister={onSubmitSelectPricing} />,
    2: (
      <ContinueWith
        onContinueWithEmail={() => {
          history.push(`${history.location.pathname}`);
          setStep(3);
          localStorage.setItem('broker_onboarding_step', 1);
        }}
      />
    ),
    3: (
      <CollectBasicDetails
        {...{
          basicDetails,
          setBasicDetails,
          isUserAlreadyOnboarded,
          onSubmit: onSubmitBasicDetails,
        }}
      />
    ),
    4: (
      <SelectLogos
        onSubmit={() => {
          history.push(`${history.location.pathname}`);
          setStep(5);
          localStorage.setItem('broker_onboarding_step', 4);

          track(ONBOARDING_LOGOS_SELECTED_EVENT);
        }}
      />
    ),
    5: (
      <SetColors
        onSubmit={() => {
          history.push(`${history.location.pathname}`);
          setStep(6);
          localStorage.setItem('broker_onboarding_step', 5);

          track(ONBOARDING_COLOR_SELECTED_EVENT);
        }}
      />
    ),
    6: (
      <DefaultBranding
        onSubmit={() => {
          history.push(`${history.location.pathname}`);
          setStep(7);
          localStorage.setItem('broker_onboarding_step', 6);

          track(ONBOARDING_FONT_SELECTED_EVENT);
        }}
      />
    ),
    7: (
      <Payment
        {...{
          basicDetails,
          paymentDetails,
          setPaymentDetails,
          selectedPlan,
          isUserAlreadyOnboarded,
        }}
      />
    ),
  };

  return (
    <OnboardingLayout backgroundImage={step === 2 ? BackgroundImage : null} user={user}>
      <Elements stripe={stripePromise}>{STEP_COMPONENT[step]}</Elements>
    </OnboardingLayout>
  );
};

export default OnboardingBroker;
