import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure5.scss';
import { register } from 'src/services/template-renderer';
import WindowPoster from 'src/templates/brochures/fragments/macdonald/window-poster';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';

const TEMPLATE_NAME = 'MACDONALD_REALTY_WINDOW_SHEET_1_AGENT';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const MacdonaldBrochure5 = ({ searchedListing, currentListing, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <div className="template mac-template-5 ui-only-outline">
      <WindowPoster
        customStyleForAgentBrandingBroacher5={true}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        customLegalMargin={true}
      />
    </div>
  );
};

MacdonaldBrochure5._name = TEMPLATE_NAME;
MacdonaldBrochure5._width = TEMPLATE_WIDTH;
MacdonaldBrochure5._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure5,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure5);
