import React, { useState, useEffect } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import AdminLayout from 'src/layouts/admin-layout';

import EditBillingContactInfo from 'src/components/EditBillingContactInfoModal/edit-billing-contact-info';
import UpdateBillingModal from 'src/components/UpdateBillingModal/update-billing-modal';
import ViewCreditCard from 'src/components/ViewCreditCard/view-credit-card';
import { getBrokerCardList } from 'src/redux/actions/stripeActions';
import {
  addStripeCard,
  updateStripeCard,
  removeStripeCard,
  makeDefaultStripeCard,
} from 'src/redux/actions/stripeActions';

import COUNTRIES from 'src/utils/countries.json';
import './billing-information.scss';
import OutlinedInverseButton from 'src/components/OutlinedInverseButton/outlined-inverse-button';
import { useTitle } from 'react-use';

const BillingInformation = () => {
  useTitle('Billing Information - Realvault');

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { currentUser, currentBroker, brokerCards } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
    brokerCards: state.stripe.brokerCards,
  }));

  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [removeCardLoading, setRemoveCardLoading] = useState('');
  const [makeDefaultCardLoading, setMakeDefaultCardLoading] = useState('');

  const [cardModal, setCardModal] = useState({
    visible: false,
    mode: 'add',
    cardId: null,
    isDefault: null,
  });

  const getCountryNameFromCountryCode = country_code => COUNTRIES.find(country => country.iso2 === country_code)?.name;

  useEffect(() => {
    setLoading(true);
    if (currentUser?.id) {
      // get broker cards list
      dispatch(getBrokerCardList(currentUser?.id))
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log('ERR => while fetching broker cards', err);
        });
    }
  }, [currentUser, dispatch]);

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex justify-center items-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  const onRemoveCard = cardId => {
    if (brokerCards?.cards?.length === 1) {
      addToast('This card is the default payment method for the active subscription, Operation not allowed!', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setRemoveCardLoading(cardId);
    dispatch(
      removeStripeCard({
        user_id: currentUser?.id,
        card_id: cardId,
      })
    )
      .then(() => {
        setRemoveCardLoading('');
        addToast('Card removed successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(err => {
        setRemoveCardLoading('');
        addToast('Failed to remove card!', {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log('ERR => while removing card', err);
      });
  };

  const onUpdateCard = cardToken => {
    dispatch(
      updateStripeCard({
        user_id: currentUser?.id,
        card_token: cardToken,
        card_id: cardModal.cardId,
        type: cardModal.isDefault ? 'default' : '',
      })
    )
      .then(() => {
        setCardLoading(false);
        setCardModal({
          visible: false,
          mode: 'add',
          cardId: null,
          isDefault: null,
        });
        addToast('Card updated successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(err => {
        setCardLoading(false);
        addToast('Failed to update card!', {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log('ERR => while updating card', err);
      });
  };

  const onAddCard = cardToken => {
    dispatch(
      addStripeCard({
        user_id: currentUser?.id,
        card_token: cardToken,
      })
    )
      .then(() => {
        setCardLoading(false);
        setCardModal({
          visible: false,
          mode: 'add',
          cardId: null,
          isDefault: null,
        });
        addToast('Card added successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(err => {
        setCardLoading(false);
        addToast('Failed to add card!', {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log('ERR => while adding card', err);
      });
  };

  const onMakeDefaultCard = cardId => {
    setMakeDefaultCardLoading(cardId);
    dispatch(
      makeDefaultStripeCard({
        user_id: currentUser?.id,
        card_id: cardId,
      })
    )
      .then(() => {
        setMakeDefaultCardLoading('');
        addToast('Card set as default successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(err => {
        setMakeDefaultCardLoading('');
        addToast('Failed to set default card!', {
          appearance: 'error',
          autoDismiss: true,
        });
        console.log('ERR => while adding card', err);
      });
  };

  return (
    <AdminLayout pageTitle="Billing Information">
      <Container className="billing-information">
        <div className="user-details">
          <h1 className="user-name">{brokerCards?.customer?.name}</h1>
          <h4 className="title mt-8">{currentBroker?.name}</h4>
          <p className="description mt-12">{brokerCards?.customer?.email}</p>
        </div>

        <div className="billing-address mt-12">
          <h4 className="title">Billing Address</h4>
          <div className="mt-4">
            <p className="description">
              {brokerCards?.customer?.address?.line1} {brokerCards?.customer?.address?.line2}
              {brokerCards?.customer?.address?.city}, {brokerCards?.customer?.address?.state}
            </p>
            <p className="description">{brokerCards?.customer?.address?.postal_code}</p>
            <p className="description">{getCountryNameFromCountryCode(brokerCards?.customer?.address?.country)}</p>
          </div>

          <div className="mt-10">
            <EditBillingContactInfo
              defaultValues={{
                full_name: brokerCards?.customer?.name,
                company_name: '',
                email: brokerCards?.customer?.email,
                phone_number: brokerCards?.customer?.phone,
                street: brokerCards?.customer?.address?.line1,
                city: brokerCards?.customer?.address?.city,
                province: brokerCards?.customer?.address?.state,
                postal_code: brokerCards?.customer?.address?.postal_code,
                country: brokerCards?.customer?.address?.country,
              }}
              customerId={brokerCards?.customer?.id}
            />
          </div>
        </div>

        {brokerCards?.cards?.length > 0 && (
          <div className="credit-card">
            <h4 className="title">Credit Card</h4>
            <div className="credit-card-section lg:flex lg:items-center">
              {brokerCards?.cards?.map(card => (
                <ViewCreditCard
                  key={card.id}
                  card={card}
                  updateCard={() =>
                    setCardModal({
                      visible: true,
                      mode: 'edit',
                      cardId: card.id,
                      isDefault: card.id === brokerCards.customer?.default_source,
                    })
                  }
                  defaultCard={card.id === brokerCards.customer?.default_source}
                  removeCardLoading={removeCardLoading === card.id}
                  removeCard={() => onRemoveCard(card.id)}
                  defaultCardLoading={makeDefaultCardLoading === card.id}
                  onMakeDefaultCard={() => onMakeDefaultCard(card.id)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Add Card Button */}
        <OutlinedInverseButton
          onClick={() =>
            setCardModal({
              visible: true,
              mode: 'add',
              cardId: null,
              isDefault: null,
            })
          }
        >
          Add Card
        </OutlinedInverseButton>
        {/* Add - Update card modal */}
        <UpdateBillingModal
          open={cardModal.visible}
          setOpen={visible =>
            setCardModal(prev => ({
              ...prev,
              visible,
            }))
          }
          loading={cardLoading}
          setLoading={cardLoading}
          customer={brokerCards?.customer}
          onSubmit={cardModal.mode === 'add' ? onAddCard : onUpdateCard}
        />
      </Container>
    </AdminLayout>
  );
};

export default BillingInformation;
