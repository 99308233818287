import React, { Component } from 'react';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import FacebookTemplate27 from 'src/templates/facebook/pemberton/facebookTemplate27';

class PembertonFacebook27 extends Component {
  render() {
    const { type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout postFacebook={true} templateKey={FacebookTemplate27._name} type={type}>
        <Scale
          width={FacebookTemplate27._width}
          height={FacebookTemplate27._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <FacebookTemplate27 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

export default PembertonFacebook27;
