import React from 'react';
import { connect } from 'react-redux';
import '../brochures/reset.css';
import './UniversalPembertonBrochure2.scss';
import { register } from 'src/services/template-renderer';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import UniversalHalfListingFeature from '../brochures/fragments/universal/universal-half-listing-feature';
import UniversalListingInfo from '../brochures/fragments/universal/universal-listing-info';
import UniversalTwoPageFloorPlan from '../brochures/fragments/universal/universal-two-page-floorplan';
import PlaceholderImage from 'src/images/banner.png';

const TEMPLATE_NAME = 'FOLDED_BROCHURE_WITH_TWO_PAGE_FLOORPLAN';
const TEMPLATE_WIDTH = 1224;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL }) => {
  return (
    <div className={`scaled template template-dark pem-template-2 ${theme} page-1 ui-only-outline`}>
      <UniversalHalfListingFeature
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        mergeCurrentListing={mergeCurrentListing}
        listing={listing}
        theme={theme}
        font={font}
      />
      <UniversalListingInfo
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        mergeCurrentListing={mergeCurrentListing}
        listing={listing}
        dark={true}
        theme={theme}
        font={font}
      />
    </div>
  );
};

const Page2 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL }) => {
  return (
    <div className={`scaled template pem-template-2 template-dark ${theme} page-2 ui-only-outline`}>
      <UniversalTwoPageFloorPlan
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        mergeCurrentListing={mergeCurrentListing}
        dark={true}
        listing={listing}
        agents={listing.agents}
        theme={theme}
        font={font}
      />
    </div>
  );
};

const UniversalPembertonBrochure2 = ({
  searchedListing,
  currentListing,
  themeClassName,
  mergeCurrentListing,
  theme,
  ...rest
}) => {
  const customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  const fontName =
    rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular;

  return (
    <div className="universalPemberton2" style={{ fontFamily: fontName }}>
      <ApplyFont fontName={fontName} />
      <Page1
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={fontName}
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
      />
      <br className="not-in-pdf" />
      <Page2
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={fontName}
      />
    </div>
  );
};

UniversalPembertonBrochure2._name = TEMPLATE_NAME;
UniversalPembertonBrochure2._width = TEMPLATE_WIDTH;
UniversalPembertonBrochure2._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: UniversalPembertonBrochure2,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return {
    searchedListing,
    currentListing,
    appliedTheme: theme.appliedTheme,
    currentBroker,
    defaultSettings: theme.defaultSettings,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalPembertonBrochure2);
