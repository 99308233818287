import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';

class WhistlerBrochurePage extends Component {
  render() {
    const { Component, pages, scale, currentListing } = this.props;

    return (
      <TemplateLayout templateKey={Component._name} currentListing={currentListing}>
        <Scale width={Component._width} height={Component._height} pages={pages} scale={scale}>
          <Component />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerBrochurePage);
