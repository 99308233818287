import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from 'react-use';
import { Container, Loader } from 'semantic-ui-react';
import { getStripeProducts } from 'src/redux/actions/stripeActions';
import { ONBOARDING_PRICING_PLAN_SELECTED_EVENT, track } from 'src/utils/analytics';
import { formatStripeAmount } from 'src/utils/generic-utils';
import './select-pricing.scss';

export const PLAN_TITLES = ['Individual', 'Team', 'Enterprise'];
export const SUB_TITLE = ['per month', 'Up to 5 Users', 'Up to 50 Users'];

const SelectPricing = ({ onRegister }) => {
  useTitle('Select Pricing - Realvault');

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getStripeProducts())
      .then(() => setLoading(false))
      .catch(err => {
        console.log('Something went wrong while fetching stripe products', err);
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TEMPLATE_API}/plannew`).then(res => {
      res.json().then(data => {
        setProducts(data);
      });
    });
  }, []);

  if (loading) {
    return (
      <Container>
        <div className="flex justify-center items-center">
          <Loader active={loading} />
        </div>
      </Container>
    );
  }

  return (
    <div className="select-pricing">
      <div className="text-center mb-8">
        <div className="heading py-4 px-6 md:inline-block text-xl md:text-2xl lg:text-3xl xl:text-4xl w-full md:w-1/2">
          Introductory Pricing
        </div>
      </div>

      <div className="choose-plan-container mb-8" style={{ marginTop: '40px' }}>
        <div className="content flex-wrap justify-center md:flex w-full">
          {products.length > 0 &&
            products?.map((product, index) => (
              <div className="bg-white text-center w-full md:w-1/3 mb-10" key={product.plan[0].id}>
                <div className={`flex justify-center items-center text-white header-${index + 1}`}>
                  <h1 className="text-xl font-semibold uppercase py-3">{product.title}</h1>
                </div>
                <div className="flex flex-col justify-around border-right">
                  <div className="flex flex-col justify-between items-center py-4">
                    <div className="py-4">
                      <div>
                        <span className={`text-4xl font-bold price-${index + 1}`}>
                          ${formatStripeAmount(product.plan[0].amount)?.integer}
                          <sup className="text-2xl">.{formatStripeAmount(product.plan[0].amount)?.mantissa}</sup>
                        </span>
                      </div>
                      {product.plan[0]?.metadata?.max_user ? (
                        <div className="text-sm font-semibold my-8">
                          {product.plan[0]?.metadata?.max_user == 1
                            ? 'per month'
                            : `Up to ${product.plan[0]?.metadata?.max_user} Users`}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    {/* Plan Features */}
                    <div>
                      <ul className="py-4 text-sm">
                        {product.description.split('\n').map((v, i) => {
                          return (
                            <li key={i} className="my-1 capitalize">
                              {v}
                            </li>
                          );
                        })}
                      </ul>
                      {/* <div className={`my-2 text-sm ${index < 2 ? 'invisible' : ''}`}>Contact to add
                                            more users
                                        </div>*/}
                    </div>
                  </div>

                  {/* Switch Plan Button */}
                  <div className="my-10">
                    {product.url ? (
                      <a
                        className={`reg-link cursor-pointer py-3 px-10 text-base text-white font-medium uppercase rounded-md inline-block btn-${
                          index + 1
                        }`}
                        href={product.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Register
                      </a>
                    ) : (
                      <div
                        className={`cursor-pointer py-3 px-10 text-base text-white font-medium uppercase rounded-md inline-block btn-${
                          index + 1
                        }`}
                        onClick={() => {
                          onRegister({
                            ...product.plan[0],
                            plan_title: PLAN_TITLES[index],
                          });
                        }}
                      >
                        Register
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SelectPricing;
