import React from 'react';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';

/**
 * Wrapper for Social Media Templates to render scalable Components using Social Layout
 *
 * @param {Component} Component Template component to be rendered
 * @returns Page with social template
 */

const BaseSocialMediaPage = ({ defaultURL, Component, props, postToFacebook = true, scale = 0.8 }) => {
  const { currentListing, type } = props;
  const headline = _.startCase(type);
  return (
    <SocialLayout
      defaultURL={defaultURL}
      postFacebook={postToFacebook}
      templateKey={Component._name}
      type={type}
      listing={currentListing}
    >
      <Scale width={Component._width} height={Component._height} scale={scale} style={{ outline: 'grey 1px solid' }}>
        <Component defaultURL={defaultURL} headline={headline} />
      </Scale>
    </SocialLayout>
  );
};

export default BaseSocialMediaPage;
