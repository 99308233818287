import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Icon } from 'semantic-ui-react';
import Image from 'src/components/image';
import FilePicker from 'src/components/file-picker';
import Logo from 'src/images/whistler/Wordmark_horizontal_white.png';
import EditableImage from 'src/components/editable-image';
import { register } from 'src/services/template-renderer';
import BackgroundImage from 'src/components/background-image';
import { formatCurrencyNoDecimal } from 'src/utils/format-number';
import EditTextModal from 'src/components/edit-text-modal';
import Scale from 'src/components/scale';

import './WhistlerBrochure3-6.scss';
import WhistlerBrochureTemplate from 'src/templates/base/WhistlerBrochureTemplate';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import { createRequestHeader } from 'src/services/request-headers';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

import _ from 'src/lodash';

const TEMPLATE_NAME = '4PG Email – Custom Agent Banner';
const TEMPLATE_WIDTH = 816;
const TEMPLATE_HEIGHT = 1056;

const Page1 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div
      className={`scaled template page-1 ui-only-outline`}
      style={{ outline: 'none', width: '100%', height: '1056px' }}
    >
      <div className="hero-image" style={{ height: '850px' }}>
        <EditableImage
          self={self}
          fieldName="image1"
          className="hero-image"
          style={{ height: '850px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={0}
        />
      </div>
      <div className="content-wrapper" style={{ height: '100px' }}>
        <div
          className="address"
          style={{
            width: '100%',
          }}
        >
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
              fontFamily: 'AcuminR',
              fontSize: '20px',
              paddingLeft: 0,
              fontWeight: 'bold',
              color: '#414042',
              textAlign: 'center',
            }}
            value={
              listing.location.streetAddress.length > 30
                ? listing.location.streetAddress.slice(0, 30)
                : listing.location.streetAddress
            }
            onChange={e => {
              if (e.target.value.length < 30) {
                mergeCurrentListing({
                  location: {
                    ...listing.location,
                    streetAddress: e.target.value,
                  },
                });
              }
            }}
          />
        </div>
      </div>
      <div className="footer" style={{ height: '106px' }}>
        <div className="logo">
          <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
        </div>
      </div>
    </div>
  );
};

const Page2 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div
      className={`scaled template page-2 ui-only-outline`}
      style={{ width: '100%', outline: 'none', height: '1056px' }}
    >
      <div className="hero-image" style={{ height: '450px' }}>
        <EditableImage
          self={self}
          fieldName="image2"
          className="hero-image"
          style={{ height: '450px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={1}
        />
      </div>
      <div className="content-wrapper no-hover" style={{ height: '285px' }}>
        <div className="description">
          <textarea
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
              height: '130px',
              overflow: 'hidden',
              resize: 'none',
              wordBreak: 'break-word',
              lineHeight: '15px',
            }}
            value={listing.description.length > 1060 ? listing.description.slice(0, 1060) : listing.description}
            onChange={e => {
              mergeCurrentListing({
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="image-container" style={{ height: '269px' }}>
        <EditableImage
          self={self}
          fieldName="image3"
          className="listing-image"
          style={{ width: '100%', marginRight: '5px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={self}
          fieldName="image4"
          className="listing-image"
          style={{ width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={3}
        />
      </div>

      <div className="footer" style={{ height: '52px' }}></div>
    </div>
  );
};

const Page3 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div
      className={`scaled template page-3 ui-only-outline`}
      style={{ width: '100%', outline: 'none', height: '1056px' }}
    >
      <div className="image-container" style={{ height: '413.5px' }}>
        <EditableImage
          self={self}
          fieldName="image5"
          className="image-left"
          style={{ width: '480.5px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
        />
        <div className="two-image-container" style={{ width: '330px' }}>
          <EditableImage
            self={self}
            fieldName="image6"
            className="image-right"
            style={{ height: '203.5px' }}
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={5}
          />
          <EditableImage
            self={self}
            fieldName="image7"
            className="image-right"
            style={{ height: '203.5px' }}
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={6}
          />
        </div>
      </div>

      <div className="features-container no-hover" style={{ height: '320px' }}>
        <div className="border" style={{ margin: '0 40px' }}></div>
        <div className="features-list">
          <div className="feature">
            Price:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  formatCurrencyNoDecimal(listing.price.priceDisplay).length > 15
                    ? formatCurrencyNoDecimal(listing.price.priceDisplay).slice(0, 15)
                    : formatCurrencyNoDecimal(listing.price.priceDisplay)
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {listing.isMLSVisible && (
            <div className="feature">
              WLS Id:
              <span style={{ width: '150px', marginLeft: '6px' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={listing.mlsId.length > 30 ? listing.mlsId.slice(0, 30) : listing.mlsId}
                  maxLength={30}
                  onChange={e => {
                    if (e.target.value.length <= 30) {
                      mergeCurrentListing({
                        mlsId: e.target.value,
                      });
                    }
                  }}
                />
              </span>
            </div>
          )}
          <div className="feature">
            Bedrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details.qtyBedrooms.length > 15
                    ? listing.details.qtyBedrooms.slice(0, 15)
                    : listing.details.qtyBedrooms
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Bathrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details.qtyFullBathrooms.length > 15
                    ? listing.details.qtyFullBathrooms.slice(0, 15)
                    : listing.details.qtyFullBathrooms
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Year Built:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details.yearBuilt.length > 15
                    ? listing.details.yearBuilt.slice(0, 15)
                    : listing.details.yearBuilt
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        yearBuilt: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Sub-area:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={30}
                value={
                  listing.details.neighborhood.length > 30
                    ? listing.details.neighborhood.slice(0, 30)
                    : listing.details.neighborhood
                }
                onChange={e => {
                  if (e.target.value.length <= 30) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Maint. Fee:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details.strataFee.length > 15
                    ? listing.details.strataFee.slice(0, 15)
                    : listing.details.strataFee
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        strataFee: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Gross Taxes:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details?.grossTax?.length > 15
                    ? listing.details?.grossTax.slice(0, 15)
                    : listing.details?.grossTax
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        grossTax: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Floor Area:
            <span style={{ display: 'flex', whiteSpace: 'nowrap', width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '75%',
                  marginLeft: '3px',
                }}
                maxLength={15}
                value={
                  listing.details.squareFeet.length > 15
                    ? listing.details.squareFeet.slice(0, 15)
                    : listing.details.squareFeet
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        squareFeet: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Lot Size:
            <span style={{ display: 'flex', whiteSpace: 'nowrap', width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '75%',
                }}
                maxLength={15}
                value={
                  listing.details.lotSizeTotal.length > 15
                    ? listing.details.lotSizeTotal.slice(0, 15)
                    : listing.details.lotSizeTotal
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        lotSizeTotal: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {/*<div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={0} triggerTitle="Edit Features" />
            </Scale.Back>
          </div>*/}
        </div>
      </div>

      <div className="image-container-bottom" style={{ height: '270px' }}>
        <EditableImage
          self={self}
          fieldName="image8"
          className="listing-image"
          style={{ width: '100%', marginRight: '5px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={7}
        />
        <EditableImage
          self={self}
          fieldName="image9"
          className="listing-image"
          style={{ width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={8}
        />
      </div>
      <div className="footer" style={{ height: '52px' }} />
    </div>
  );
};

const Page4 = ({ self, listing, mergeCurrentListing }) => {
  const [floorPlanLoading, setFloorPlanLoading] = React.useState(false);

  const onFileSelect = async file => {
    setFloorPlanLoading(true);
    const formData = new FormData();
    formData.append('pdf', file);
    const response = await axios.post('/api/extract-pdf', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...createRequestHeader(),
    });
    setFloorPlanLoading(false);
    mergeCurrentListing({
      floorplanPicture: response.data.dataUrls[0],
      floorplanPictureTwo: response.data.dataUrls[1] ? response.data.dataUrls[1] : listing.floorplanPictureTwo,
    });
  };
  return (
    <div className={`scaled template page-4 ui-only-outline`} style={{ width: '100%', height: '1056px' }}>
      <div className="main-area" style={{ width: '100%', height: '850px' }}>
        <div className="floorplan" style={{ width: '100%', height: '850px' }}>
          <div className="floorplan-img">
            <Image
              url={listing.floorplanPicture}
              onChange={(newImgUrl, mergeCurrentListing) => {
                mergeCurrentListing({ floorplanPicture: newImgUrl });
              }}
              buttonTitle="Add Floor Plan IMG"
              width={'100%'}
              height={850}
            />
            <span className="upload-floorplan-pdf">
              <FilePicker onSelect={onFileSelect} dataUrl={false} accept="application/pdf">
                <Scale.Back>
                  <Button primary size="tiny" loading={floorPlanLoading}>
                    <Icon name="file pdf" /> Upload Floor Plan PDF
                  </Button>
                </Scale.Back>
              </FilePicker>
            </span>
          </div>
        </div>
      </div>

      <hr />
      <div className="agent-banner-container">
        <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
        {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
          <span className="empty-placeholder not-in-pdf"></span>
        )}
        <div className="edit-btn-hover not-in-pdf">
          <Scale.Back>
            <div style={{ position: 'relative', left: '-27%' }}>
              <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
            </div>
          </Scale.Back>
        </div>
      </div>
    </div>
  );
};
class WhistlerBrochure3 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, address } =
      props;

    const { photos, description, location } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;
    if (!image6) image6 = photos[5] || PlaceHolderImage6;
    if (!image7) image7 = photos[6] || PlaceHolderImage7;
    if (!image8) image8 = photos[7] || PlaceHolderImage1;
    if (!image9) image9 = photos[8] || PlaceHolderImage2;
    if (!image10) image10 = photos[9] || PlaceHolderImage3;

    if (!address) address = `${location.streetAddress}, ${location.city}`;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      address,
      description,
    };
  }

  render() {
    const { currentListing, mergeCurrentListing } = this.props;
    const { image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, address } = this.state;

    WhistlerBrochure3._cached = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      address,
    };

    let customMergeListing = obj => {
      const newListing = _.omit({ ...currentListing, ...obj }, true);
      mergeCurrentListing(newListing);
    };

    return (
      <WhistlerBrochureTemplate className="whistler-brochure-3-wrapper">
        <Page1 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page2 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page3 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page4 self={this} listing={currentListing} mergeCurrentListing={this.props.mergeCurrentListing} />
      </WhistlerBrochureTemplate>
    );
  }
}

WhistlerBrochure3._name = TEMPLATE_NAME;
WhistlerBrochure3._width = TEMPLATE_WIDTH;
WhistlerBrochure3._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerBrochure3, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(WhistlerBrochure3);
