export const SET_STRIPE_PRODUCTS = 'SET_STRIPE_PRODUCTS';
export const SET_BROKER_ONBOARDING_BASIC_DETAILS = 'SET_BROKER_ONBOARDING_BASIC_DETAILS';
export const SET_BROKER_CARDS = 'SET_BROKER_CARDS';
export const SET_INVOICE_HISTORY = 'SET_INVOICE_HISTORY';
export const SET_INVOICE_ITEMS = 'SET_INVOICE_ITEMS';
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION';
export const SET_BROKERAGE_USER_COUNT = 'SET_BROKERAGE_USER_COUNT';

const initialState = {
  products: [],
  brokerOnboarding: {
    basicDetailsResponse: null,
  },
  brokerCards: null,
  invoiceHistory: [],
  pendingItems: [],
  currentSubscription: null,
  brokerageUserCount: 0,
};

export const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRIPE_PRODUCTS:
      return { ...state, products: action.payload };

    case SET_BROKER_ONBOARDING_BASIC_DETAILS:
      return {
        ...state,
        brokerOnboarding: {
          ...state.brokerOnboarding,
          basicDetailsResponse: action.payload,
        },
      };

    case SET_BROKER_CARDS:
      return {
        ...state,
        brokerCards: action.payload,
      };

    case SET_INVOICE_HISTORY:
      return {
        ...state,
        invoiceHistory: action.payload,
      };

    case SET_INVOICE_ITEMS:
      return {
        ...state,
        pendingItems: action.payload,
      };

    case SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload,
      };

    case SET_BROKERAGE_USER_COUNT:
      return {
        ...state,
        brokerageUserCount: action.payload,
      };

    default:
      return state;
  }
};
