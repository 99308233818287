import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { setBothListing } from 'src/redux/actions/actions';
import { defaultListingData } from 'src/utils/crea-mapping';
import EmptyListingDashboard from 'src/pages/EmptyListingDashboard';
import { getDefaultSettings } from 'src/redux/actions/theme-creator';

import './blank-listings.scss';

const BlankListing = () => {
  const dispatch = useDispatch();
  const { brokers } = useSelector(state => ({
    brokers: state.brokers,
  }));

  const currentBrokerExists = Object.keys(brokers.currentBroker).length > 0;

  useEffect(() => {
    // load the empty listing data
    dispatch(setBothListing(defaultListingData));
  }, [dispatch]);

  useEffect(() => {
    if (currentBrokerExists) {
      // load the default theme
      dispatch(getDefaultSettings(brokers.currentBroker.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrokerExists]);

  const renderLoadingState = () => (
    <div className="loader-container">
      <div>
        <Loader active inline content="Fetching templates..." />
      </div>
    </div>
  );

  return (
    <div className="blank-listings">{currentBrokerExists ? <EmptyListingDashboard /> : renderLoadingState()} </div>
  );
};

export default BlankListing;
