import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/brand-logo-color.png';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './FacebookTemplate16.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_16';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class Facebook16 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;

    const { pictures } = currentListing;

    if (!image1) image1 = pictures[0];

    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1 } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    Facebook16._cached = { headline, image1 };

    return (
      <div className={`facebook-16-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
        />
        <EditableTextarea self={this} className="headline" fieldName="headline" placeholder="headline" />
        <hr />
        <div className="logo">
          <img src={Logo} width="90%" height="90%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

Facebook16._name = TEMPLATE_NAME;
Facebook16._width = TEMPLATE_WIDTH;
Facebook16._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: Facebook16, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(Facebook16);
