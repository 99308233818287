import {
  SET_TEXT_SIZE,
  SET_INPUT_TEXT_SIZE,
  RESET_TEXT_SIZE
} from '../../constants/action-types';

const initialState = {
};

export const templateStyleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXT_SIZE: {
      const { templateId, size } = action.payload;
      return {
        ...state,
        [templateId] : size
      };
    }
    case SET_INPUT_TEXT_SIZE: {
      const { templateId, size } = action.payload;
      return {
        ...state,
        [templateId] : size
      };
    }
    case RESET_TEXT_SIZE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
