export const SET_THEMES = 'SET_CREATE_THEME';
export const SET_APPLY_THEME = 'SET_APPLY_THEME';
export const SET_ALL_FONTS = 'SET_ALL_FONTS';
export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS';

const initialState = {
  allThemes: [],
  allFonts: [],
  appliedTheme: null,
  defaultSettings: null,
};

export const themeCreatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEMES:
      return { ...state, allThemes: action.payload };

    case SET_APPLY_THEME:
      return { ...state, appliedTheme: action.payload };

    case SET_ALL_FONTS:
      return { ...state, allFonts: action.payload };

    case SET_DEFAULT_SETTINGS:
      return { ...state, defaultSettings: action.payload };

    default:
      return state;
  }
};
