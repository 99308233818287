import React from 'react';
import './listing-feature.scss';
import Image from 'src/components/image';
import { default as formatValue, formatType } from 'src/utils/format-na-value';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

const ListingFeature = ({ listing, mergeCurrentListing, broker, dark = false }) => {
  return (
    <div className="mac-listing-feature">
      <div>
        <Image imgKey="5" url={listing.photos[5] || PlaceHolderImage5} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="6" url={listing.photos[6] || PlaceHolderImage6} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="7" url={listing.photos[7] || PlaceHolderImage7} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="8" url={listing.photos[8] || PlaceHolderImage1} width={379} height={261} margin={'8px 0'} />
      </div>
      <div className="features no-hover">
        <div
          className="title"
          style={{
            fontFamily: "'UbuntuR', Verdana, Tahoma",
            color: dark ? '#3d3d3d' : 'var(--mac-font-color)',
          }}
        >
          Features:
        </div>
        <div className="feature-list no-hover">
          <div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.priceTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        priceTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    listing.price.priceDisplay.length > 18
                      ? listing.price.priceDisplay.slice(0, 18)
                      : listing.price.priceDisplay
                  }
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
            {listing.isMLSVisible && (
              <div style={{ display: 'flex' }}>
                <span className="feature-name">
                  <input
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      width: '100%',
                      fontFamily: "'UbuntuR', Verdana, Tahoma",
                    }}
                    value={listing.MLSTitle}
                    onChange={e => {
                      if (e.target.value.length < 18) {
                        mergeCurrentListing({
                          ...listing,
                          MLSTitle: e.target.value,
                        });
                      }
                    }}
                  />
                </span>
                <span style={{ display: 'flex', fontFamily: "'UbuntuR', Verdana, Tahoma" }} data-test-id="mls-id">
                  <input
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      width: '100%',
                      fontFamily: "'UbuntuR', Verdana, Tahoma",
                    }}
                    value={listing.mlsId}
                    onChange={e => {
                      if (e.target.value.length < 18) {
                        mergeCurrentListing({
                          mlsId: e.target.value,
                        });
                      }
                    }}
                  />
                </span>
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.bedroomTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        bedroomTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={formatValue(listing.details.qtyBedrooms)}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyBedrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name" style={{ fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.bathroomTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        bathroomTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={formatValue(listing.details.qtyFullBathrooms)}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyFullBathrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.yearTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        yearTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>

              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.details.yearBuilt}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          yearBuilt: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>

            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.taxTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        taxTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.details?.grossTax)?.length > 18
                      ? formatValue(listing.details?.grossTax).slice(0, 18)
                      : formatValue(listing.details?.grossTax)
                  }
                  onChange={e => {
                    if (e.target.value.length <= 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.features,
                          grossTax: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <span className="feature-name">
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuR', Verdana, Tahoma",
                }}
                value={listing.subAreaTitle}
                onChange={e => {
                  if (e.target.value.length < 18) {
                    mergeCurrentListing({
                      ...listing,
                      subAreaTitle: e.target.value,
                    });
                  }
                }}
              />
            </span>
            <span style={{ display: 'flex', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuR', Verdana, Tahoma",
                }}
                value={
                  formatValue(listing.details?.neighborhood)?.length > 18
                    ? formatValue(listing.details?.neighborhood).slice(0, 18)
                    : formatValue(listing.details?.neighborhood)
                }
                onChange={e => {
                  if (e.target.value.length <= 18) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.totalFloorAreaTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        totalFloorAreaTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.details?.squareFeet)?.length > 18
                      ? formatValue(listing.details?.squareFeet).slice(0, 18)
                      : formatValue(listing.details?.squareFeet)
                  }
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          squareFeet: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {/* <span style={{ marginLeft: '0px', whiteSpace: 'nowrap', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                {formatType.AREA}
              </span> */}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.lotSizeWidthTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        lotSizeWidthTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.details?.lotSizeWidth)?.length > 18
                      ? formatValue(listing.details?.lotSizeWidth).slice(0, 18)
                      : formatValue(listing.details?.lotSizeWidth)
                  }
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          lotSizeWidth: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {/* <span style={{ marginLeft: '0px', whiteSpace: 'nowrap', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                {formatType.AREA}
              </span> */}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.lotSizeLengthTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        lotSizeLengthTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.details?.lotSizeLength)?.length > 18
                      ? formatValue(listing.details?.lotSizeLength).slice(0, 18)
                      : formatValue(listing.details?.lotSizeLength)
                  }
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          lotSizeLength: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {/* <span style={{ marginLeft: '0px', whiteSpace: 'nowrap', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                {formatType.AREA}
              </span> */}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.lotSizeTotalTitle}
                  onChange={e => {
                    if (e.target.value.length < 20) {
                      mergeCurrentListing({
                        ...listing,
                        lotSizeTotalTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.features?.lotSzSqFt)?.length > 18
                      ? formatValue(listing.features?.lotSzSqFt).slice(0, 18)
                      : formatValue(listing.features?.lotSzSqFt)
                  }
                  onChange={e => {
                    if (e.target.value.length <= 18) {
                      mergeCurrentListing({
                        features: {
                          ...listing.features,
                          lotSzSqFt: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {/* <span style={{ marginLeft: '0px', whiteSpace: 'nowrap', fontFamily: "'UbuntuR', Verdana, Tahoma" }}>
                {formatType.AREA}
              </span> */}
              </span>
            </div>
          </div>
        </div>

        <div className="feature-inc">
          {broker?.template_code === 'MCD' && (
            <div style={{ display: 'flex', alignItems: 'baseline', height: '16px', paddingTop: '1px' }}>
              <span className="feature-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={listing.maintFeeTitle}
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        ...listing,
                        maintFeeTitle: e.target.value,
                      });
                    }
                  }}
                />
              </span>
              <span style={{ display: 'flex', alignItems: 'baseline' }}>
                {/* {formatType.CURRENCY} */}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                    marginLeft: '-5px',
                    fontFamily: "'UbuntuR', Verdana, Tahoma",
                  }}
                  value={
                    formatValue(listing.features.maintenanceFee)?.length > 18
                      ? formatValue(listing.features.maintenanceFee).slice(0, 18)
                      : formatValue(listing.features.maintenanceFee)
                  }
                  onChange={e => {
                    if (e.target.value.length < 18) {
                      mergeCurrentListing({
                        features: {
                          ...listing.features,
                          maintenanceFee: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'baseline', height: '50px', marginTop: '0', paddingTop: '0' }}>
            <span className="feature-name">
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuR', Verdana, Tahoma",
                }}
                value={listing.maintFeeInclTitle}
                onChange={e => {
                  if (e.target.value.length < 18) {
                    mergeCurrentListing({
                      ...listing,
                      maintFeeInclTitle: e.target.value,
                    });
                  }
                }}
              />
            </span>
            <span
              style={{
                display: 'inline-block',
                width: '250px',
                fontFamily: "'UbuntuR', Verdana, Tahoma",
                marginTop: '-5px',
              }}
            >
              <textarea
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  height: '60px',
                  marginLeft: '-5px',
                  fontFamily: "'UbuntuR', Verdana, Tahoma",
                  marginTop: '0',
                  paddingTop: '0',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  resize: 'none',
                }}
                rows={3}
                value={
                  formatValue(listing.features.maintFeeIncludes)?.length > 300
                    ? formatValue(listing.features.maintFeeIncludes).slice(0, 300)
                    : formatValue(listing.features.maintFeeIncludes)
                }
                onChange={e => {
                  if (e.target.value.length < 300) {
                    mergeCurrentListing({
                      features: {
                        ...listing.features,
                        maintFeeIncludes: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
        </div>
      </div>

      <div>
        <Image imgKey="9" url={listing.photos[9] || PlaceHolderImage2} width={379} height={261} margin={'8px 0'} />
      </div>
      <div>
        <Image imgKey="10" url={listing.photos[10] || PlaceHolderImage3} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="11" url={listing.photos[11] || PlaceHolderImage4} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="12" url={listing.photos[12] || PlaceHolderImage5} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="13" url={listing.photos[13] || PlaceHolderImage6} width={282} height={192} />
      </div>
    </div>
  );
};

export default ListingFeature;
