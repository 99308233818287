import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/sm-white-ph-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate20.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_20';
const TEMPLATE_WIDTH = 1632;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate20 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, detail, adjustedHeight } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    const bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    const sqft = `${squareFeet} SQFT`;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms}   |   Bath: ${bath}     ${sqft}`;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;

    this.state = {
      headline,
      image1,
      address,
      detail,
      adjustedHeight,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1, address, detail, adjustedHeight } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate20._cached = { headline, image1, address, detail };

    return (
      <div className={`facebook-20-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper">
          <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
          <hr />
          <EditableInput self={this} className="address" fieldName="address" placeholder="Street Address" />
          <EditableTextarea
            self={this}
            onHeightChange={v => (FacebookTemplate20._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="detail"
            fieldName="detail"
            placeholder="Bed: X | Bath: X    XXX SQFT"
          />
          <div className="logo">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </div>
    );
  }
}

FacebookTemplate20._name = TEMPLATE_NAME;
FacebookTemplate20._width = TEMPLATE_WIDTH;
FacebookTemplate20._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate20, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate20);
