import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Container, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAllPrintTemplates,
  getPrintTemplatesByBrokerId,
  addBrokerPrintTemplate,
  removeBrokerPrintTemplate,
} from 'src/redux/actions/brokerActions';
import 'src/components/preview-list.scss';
import './manage-print-templates.scss';

import * as AllBrochureTemplates from 'src/templates/brochures';
import PreviewItem from 'src/components/preview-item';
import Resizable from 'src/components/resizable';
import AdminLayout from 'src/layouts/admin-layout';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Image, Header } from 'semantic-ui-react';
import { OldTemplates } from '../../constants/oldTemplates';
import config from '../../utils/config';

const headline = 'Add Text';

const ManagePrintTemplates = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { currentBroker, allBrokerPrintTemplates, allowedBrokerPrintTemplates } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allBrokerPrintTemplates: state.brokers.allBrokerPrintTemplates,
    allowedBrokerPrintTemplates: state.brokers.allowedBrokerPrintTemplates,
  }));
  let templateId = localStorage.getItem('templateId');
  let token = localStorage.getItem('jwt');
  let decodedToken;
  if (!token) {
    token = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
    decodedToken = token;
  } else {
    decodedToken = jwt.decode(token);
  }
  const localBasicDetails = decodedToken;
  const [allowedImages, setAllowedImages] = useState(allowedBrokerPrintTemplates);
  const [loading, setLoading] = useState(false);
  const [allowedTemplateId, setAllowedTemplateId] = useState([]);
  const [imgArray, setimgArray] = useState([]);

  const [myTemplatyes, setMyTemplates] = useState([]);

  useEffect(() => {
    setLoading(true);
    // get all print templates
    dispatch(getAllPrintTemplates())
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERR => while fetching all print templates');
        setLoading(false);
      });
    // get brokers print template (allowed)
    dispatch(getPrintTemplatesByBrokerId(currentBroker.id))
      .then(data => {
        setMyTemplates(data);
        setLoading(false);
      })
      .catch(err => {
        console.log('ERR => while fetching all print templates');
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allBrokerPrintTemplates.length > 0) {
      const allowedTemplateIds = allowedBrokerPrintTemplates.map(template => template.name);
      const allowedTemplateIdsForBroker = allBrokerPrintTemplates
        .filter(template => allowedTemplateIds.includes(template.name))
        .map(template => template.id);
      setAllowedTemplateId(allowedTemplateIdsForBroker);
      if (allowedBrokerPrintTemplates && allowedBrokerPrintTemplates.length > 0) {
        console.log('[...allowedBrokerPrintTemplates]', [...allowedBrokerPrintTemplates]);
        setAllowedImages([...allowedBrokerPrintTemplates]);
      }
    }
  }, [allBrokerPrintTemplates, allowedBrokerPrintTemplates]);

  const onToggleTemplate = (checked, templateId) => {
    if (checked) {
      // call add api

      let defaultImg = imgArray.map(v => {
        if (v.selected == true) {
          return v.url;
        }
      });

      setAllowedTemplateId(prev => prev.concat(templateId));
      let defultImageBaseUrl = '';
      if (process.env.REACT_APP_TEMPLATE_API === 'https://dev-api.covault.app') {
        defultImageBaseUrl = 'https://covault-dev-bastion.s3.ca-central-1.amazonaws.com';
      } else if (process.env.REACT_APP_TEMPLATE_API === 'https://staging-api.covault.app') {
        defultImageBaseUrl = 'https://realvault-app-staging.s3.ca-central-1.amazonaws.com';
      } else {
        defultImageBaseUrl = 'https://realvault-app.s3.ca-central-1.amazonaws.com';
      }

      dispatch(
        addBrokerPrintTemplate(currentBroker.id, {
          id: templateId,
          banner_default_URL: defaultImg[0] || defultImageBaseUrl + config.defaultImgUrl,
          footer_default_URL: defaultImg[0] || defultImageBaseUrl + config.defaultImgUrl,
        })
      )
        .then(() => {
          addToast('Print Template linked to broker successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });

          dispatch(getPrintTemplatesByBrokerId(currentBroker.id))
            .then(data => {
              setMyTemplates(data);
              setLoading(false);
            })
            .catch(err => {
              console.log('ERR => while fetching all print templates');
              setLoading(false);
            });
        })
        .catch(err => {
          console.log('ERR => while adding the template to broker');
        });
    } else {
      // call remove api

      setAllowedTemplateId(prev => prev.filter(tempId => tempId !== templateId));
      dispatch(
        removeBrokerPrintTemplate(currentBroker.id, {
          id: templateId,
        })
      )
        .then(() => {
          addToast('Print Template removed from broker successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
        })
        .catch(err => {
          console.log('ERR => while removing the template from broker');
        });
    }
  };

  useEffect(() => {
    if (!OldTemplates.includes(templateId)) {
      async function getLogo() {
        let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
        return resp?.data?.logo;
      }

      getLogo().then(res => {
        if (res) {
          let newArr = [...res];
          setimgArray(newArr);
        }
      });
    }
  }, [localBasicDetails.broker_id, templateId]);

  const setDefaultImage = (templateId, url, type, category, isDefault) => {
    let headerURL = '';
    let footerURL = '';
    myTemplatyes.forEach(v => {
      if (v.id === templateId) {
        if (category !== 'banner') {
          headerURL = v.banner_default_URL;
        }
        if (category !== 'footer') {
          footerURL = v.footer_default_URL;
        }
      }
    });
    if (!isDefault) {
      let data = {
        type: type,
        id: templateId,
      };
      if (category === 'banner') {
        data['banner_default_URL'] = url;
        data['footer_default_URL'] = footerURL;
      }
      if (category === 'footer') {
        data['banner_default_URL'] = headerURL;
        data['footer_default_URL'] = url;
      }

      axios
        .post(`${process.env.REACT_APP_TEMPLATE_API}/defaultImage/${localBasicDetails?.broker_id}`, data, {
          'content-type': 'application/json',
        })
        .then(res => {
          if (res.data.status === true) {
            let temp = [...allowedImages];

            temp.forEach(v => {
              if (v.id === templateId) {
                if (category === 'banner') {
                  v.banner_default_URL = url;
                }
                if (category === 'footer') {
                  v.footer_default_URL = url;
                }
              }
            });
            setAllowedImages(temp);
            addToast('Settings changed successfully!', {
              appearance: 'success',
              autoDismiss: true,
            });
          }
        });
    } else {
      addToast('Image already set to default', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex justify-center items-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  const cached = ManagePrintTemplates._cached || {};

  return (
    <AdminLayout pageTitle="Manage Print Templates">
      <Container>
        <div className="white template-container main-table-wrap">
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Template Name</Table.HeaderCell>
                <Table.HeaderCell className="text-center">Preview Template</Table.HeaderCell>
                <Table.HeaderCell>Use template</Table.HeaderCell>

                {!OldTemplates.includes(templateId) ? (
                  <>
                    <Table.HeaderCell>Logos Available</Table.HeaderCell>
                    <Table.HeaderCell>Use Logo</Table.HeaderCell>
                  </>
                ) : (
                  ''
                )}
              </Table.Row>
            </Table.Header>
            {allBrokerPrintTemplates.length > 0 && !loading && (
              <Table.Body>
                {allBrokerPrintTemplates
                  .filter(template => AllBrochureTemplates[template.componentId])
                  .map(template => (
                    <Table.Row key={template.id}>
                      <Table.Cell>
                        <div
                          style={{
                            width: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {template.title || '---'}
                        </div>
                      </Table.Cell>
                      <Table.Cell style={{ width: '200px' }}>
                        <Resizable {...cached} className="preview-list-container template-td">
                          {({ widthCol6, widthCol4, widthCol3 }) => {
                            ManagePrintTemplates._cached = {
                              widthCol6,
                              widthCol4,
                              widthCol3,
                            };
                            console.log('widthCol6widthCol6', widthCol6);
                            return (
                              <PreviewItem
                                title={template.title}
                                targetWidth={200}
                                Component={AllBrochureTemplates[template.componentId]}
                                data={{ headline }}
                                pages={template.pages}
                              />
                            );
                          }}
                        </Resizable>
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          toggle
                          checked={allowedTemplateId.includes(template.id)}
                          onChange={(event, { checked }) => onToggleTemplate(checked, template.id)}
                        />
                      </Table.Cell>
                      {!OldTemplates.includes(templateId) ? (
                        <>
                          <Table.Cell>
                            <Header as="h3">Banner Logo</Header>

                            <div className="logo-wrapper">
                              {imgArray.map((img, i) => {
                                return (
                                  <div className="img-wrap-preview">
                                    <Image
                                      style={i === 0 ? { marginLeft: 0 } : {}}
                                      key={i}
                                      className="uploaded-image"
                                      src={img.url}
                                      size="small"
                                      bordered
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            <Header as="h3">Footer Logo</Header>

                            <div className="logo-wrapper">
                              {imgArray.map((img, i) => {
                                return (
                                  <div className="img-wrap-preview">
                                    <Image
                                      style={i === 0 ? { marginLeft: 0 } : {}}
                                      key={i}
                                      className="uploaded-image"
                                      src={img.url}
                                      size="small"
                                      bordered
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              className="checkbox-wrap v-align banner"
                              style={{
                                display: allowedTemplateId.includes(template.id) ? 'flex' : 'none',
                              }}
                            >
                              <Header as="h3">&nbsp;</Header>
                              {imgArray.map((img, i) => {
                                return (
                                  <>
                                    <Checkbox
                                      toggle
                                      checked={
                                        allowedImages.some(v => {
                                          if (v.id === template.id) {
                                            return v.banner_default_URL === img.url;
                                          }
                                        })
                                          ? true
                                          : false
                                      }
                                      onChange={(event, { checked }) => {
                                        let defURL = '';
                                        allowedImages.map(v => {
                                          if (v.id === template.id) {
                                            defURL = v.banner_default_URL;
                                            return v.banner_default_URL === img.url;
                                          }
                                        });
                                        setDefaultImage(template.id, img.url, 'print', 'banner', img.url === defURL);
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="checkbox-wrap v-align footer"
                              style={{
                                display: allowedTemplateId.includes(template.id) ? 'flex' : 'none',
                              }}
                            >
                              <Header as="h3">&nbsp;</Header>
                              {imgArray.map((img, i) => {
                                return (
                                  <>
                                    <Checkbox
                                      toggle
                                      checked={
                                        allowedImages.some(v => {
                                          if (v.id === template.id) {
                                            console.log('vvv', v);
                                            console.log('img', img);
                                            return v.footer_default_URL === img.url;
                                          }
                                        })
                                          ? true
                                          : false
                                      }
                                      onChange={(event, { checked }) => {
                                        let defURL = '';
                                        allowedImages.map(v => {
                                          if (v.id === template.id) {
                                            defURL = v.footer_default_URL;
                                            return v.footer_default_URL === img.url;
                                          }
                                        });
                                        setDefaultImage(template.id, img.url, 'print', 'footer', img.url === defURL);
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </Table.Cell>
                        </>
                      ) : (
                        ''
                      )}
                    </Table.Row>
                  ))}
              </Table.Body>
            )}

            {allBrokerPrintTemplates.length === 0 && !loading && (
              <div className="flex justify-center items-center">
                <div className="p-20">
                  <p>No print template available yet. Contact System Administrator</p>
                </div>
              </div>
            )}
          </Table>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default ManagePrintTemplates;
