import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Icon } from 'semantic-ui-react';
import { register } from 'src/services/template-renderer';
import EditableImage from 'src/components/editable-image';
import Image from 'src/components/image';
import FilePicker from 'src/components/file-picker';
import Scale from 'src/components/scale';
import VantageWestBrochureTemplate from 'src/templates/base/VantageWestBrochureTemplate';
import BackgroundImage from 'src/components/background-image';

import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import { createRequestHeader } from 'src/services/request-headers';
import PlaceholderImage from 'src/images/banner.png';
import logoPlaceholder from '../../../images/vantagewest/logo-placeholder.png';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';
import _ from 'src/lodash';

import './VantageWestBrochure2a.scss';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import UniversalLegalText from '../fragments/common/universal-legal-text';

const TEMPLATE_NAME = '4_PG_Brochure_2';

const TEMPLATE_WIDTH = 816;
const TEMPLATE_HEIGHT = 1090;

const Page1 = ({
  defaultURL,
  footerURL,
  self,
  listing,
  theme,
  defaultSettings,
  currentBroker,
  mergeCurrentListing,
}) => {
  // const [width1, setWidth1] = useState(0);
  // const span1 = useRef();

  // useEffect(() => {
  //   setWidth1(span1.current.offsetWidth);
  // }, [listing.location.streetAddress]);
  // const [width2, setWidth2] = useState(0);
  // const span2 = useRef();

  // useEffect(() => {
  //   setWidth2(span2.current.offsetWidth);
  // }, [listing.location.city]);

  return (
    <div
      className="scaled template page-1 ui-only-outline"
      style={{ width: '100%', height: '1056px', marginBottom: '40px', outline: 'none' }}
    >
      <div className="hero-image" style={{ height: '430px' }}>
        <div
          className="logo-div"
          style={{
            padding: currentBroker?.template_code === 'PMB' ? '15px' : '0',
            width: currentBroker?.template_code === 'PMB' ? '125px' : '100px',
            height: currentBroker?.template_code === 'PMB' ? '125px' : '100px',
          }}
        >
          <img src={defaultURL || defaultSettings?.squareLogo || PlaceholderImage} alt="brand-logo888" />
        </div>
        <EditableImage
          self={self}
          fieldName="image1"
          className="hero-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={0}
          style={{ height: '430px' }}
        />
      </div>
      <div className="image-container" style={{ height: '200px', marginTop: '5px' }}>
        <EditableImage
          self={self}
          fieldName="image2"
          className="listing-image"
          style={{ paddingRight: '5px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={1}
        />
        <EditableImage
          self={self}
          fieldName="image3"
          className="listing-image"
          style={{ paddingRight: '5px' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={self}
          fieldName="image4"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={3}
        />
      </div>
      <div className="footer" style={{ height: '460px' }}>
        {/* About section */}
        <div className="about-section" style={{ height: '320px' }}>
          <div
            className="address"
            style={{
              color: theme?.backgroundColor || defaultSettings?.primaryColor,
              fontFamily:
                theme?.font ||
                defaultSettings?.font ||
                staticBrokerFonts[currentBroker?.id]?.medium ||
                (currentBroker?.feed_type === 'CRE ' ? 'MontserratM' : ''),
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              {/* <span style={{position: 'absolute',opacity:0,zIndex:'-1000'}} ref={span1}>{listing.location.streetAddress}</span> */}
              <input
                style={{
                  width: '100%',
                  fontSize: '21px',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={
                  listing.location.streetAddress.length > 62
                    ? listing.location.streetAddress.slice(0, 62)
                    : listing.location.streetAddress
                }
                onChange={e => {
                  if (e.target.value.length < 62) {
                    mergeCurrentListing({
                      location: {
                        ...listing.location,
                        streetAddress: e.target.value,
                      },
                    });
                  }
                }}
              />
              {/* {', '}{' '}
              <input
                className="mobile-p"
                style={{
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={listing.location.city}
                onChange={e => {
                  mergeCurrentListing({ location: { ...listing.location, city: e.target.value } });
                }}
              /> */}
              {/* ,
                  <span style={{position: 'absolute',opacity:0,zIndex:'-1000'}} ref={span2}>{listing.location.city}</span>
                <input

                  className="mobile-p"
                  style={{
                    width:width2,
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    marginLeft:5
                  }}
                  value={listing.location.city}
                  onChange={(e)=>{mergeCurrentListing({location:{ ...listing.location, city : e.target.value}})}}/> */}
            </div>
          </div>
          <div
            className="about-content"
            style={{
              borderBottomColor: theme?.backgroundColor || defaultSettings?.primaryColor,
            }}
          >
            <div
              className="description"
              style={{
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
              }}
            >
              <span
                className="bed-icon"
                style={{ backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor }}
              >
                {listing.details.qtyBedrooms} <i className="fa fa-bed" aria-hidden="true"></i>{' '}
                {listing.details.qtyFullBathrooms} <i className="fa fa-bath" aria-hidden="true"></i>
              </span>
              <textarea
                contentHeight
                className="description-input"
                value={listing.description.length > 1619 ? listing.description.slice(0, 1619) : listing.description}
                style={{
                  height: '150px',
                  resize: 'none',
                  fontFamily:
                    theme?.font ||
                    defaultSettings?.font ||
                    staticBrokerFonts[currentBroker?.id]?.medium ||
                    (currentBroker?.feed_type === 'CRE ' ? 'MontserratR' : ''),
                  wordBreak: 'break-word',
                }}
                rows={8}
                maxLength={1619}
                onChange={e => mergeCurrentListing({ description: e.target.value })}
              />

              {/* <ApplyFont fontName={theme?.font || defaultSettings?.font} text={`${listing.description}`} /> */}
            </div>
          </div>

          {/* <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={0} triggerTitle="Edit Description" />
            </Scale.Back>
          </div> */}
        </div>

        {/* agent-section */}
        <div className="agent-section" style={{ height: '140px' }}>
          <div className="agent-div">
            <div className="left-side">
              <div className="agent-item">
                <div className="agent-pic-wrapper">
                  {/* <EditableImage
          self={self}
          fieldName="agentImageUrl"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.agents}
          imgKey={1}
        /> */}
                  {/* <FilePicker
                onSelect={async dataUrl => {
                  mergeCurrentListing({agents:{ ...listing.agents, agentImageUrl : dataUrl}})}
                }
                accept="image/*"
                className="agent-pic-wrapper"
              >
                {listing.agents.agentImageUrl === undefined || listing.agents.agentImageUrl.length < 5 ? (
                  <div className="agent-photo">
                    <span className="empty-placeholder"></span>
                  </div>
                ) : (
                  <Image src={listing.agents.agentImageUrl} size="medium" className="agent-photo" width={95}
                  height={100}/>
                )}
                <Button primary size="tiny" className="change-photo-btn">
                  <Icon name="upload" /> Upload
                </Button>
              </FilePicker> */}
                  <BackgroundImage
                    className="agent-pic"
                    url={
                      listing.agents.agentImageUrl === 'n/a'
                        ? require('../../../images/vantagewest/avatar-placeholder.jpg')
                        : listing.agents.agentImageUrl
                    }
                    width={95}
                    height={100}
                    size="contain"
                  />
                  <div className="edit-btn-hover not-in-pdf">
                    <Scale.Back>
                      {/* <EditTextModal activeTab={1} triggerTitle="Edit" /> */}
                      <FilePicker
                        onSelect={async dataUrl => {
                          mergeCurrentListing({
                            agents: {
                              ...listing.agents,
                              agentImageUrl: dataUrl,
                            },
                          });
                        }}
                        accept="image/*"
                        className="agent-pic-wrapper"
                      >
                        <Button primary size="tiny">
                          <Icon name="upload" /> Change
                        </Button>
                      </FilePicker>
                    </Scale.Back>
                  </div>
                </div>

                <div className="agent-info" style={{ padding: '10px 0px' }}>
                  <input
                    className="agent-name"
                    style={{
                      fontSize: '18px',
                      color: theme?.backgroundColor || defaultSettings?.primaryColor,
                      fontFamily:
                        theme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.medium ||
                        (currentBroker?.feed_type === 'CRE ' ? 'MontserratM' : ''),
                    }}
                    value={
                      listing.agents?.agentName?.length > 30
                        ? listing.agents?.agentName.slice(0, 30)
                        : listing.agents?.agentName
                    }
                    onChange={e => {
                      if (e.target.value.length < 30) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentName: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  {/* <ApplyFont fontName={theme?.font} text={listing.agents?.agentName} />
                  </div> */}
                  <input
                    className="agent-title"
                    style={{
                      marginTop: -10,
                      color: '#777',
                      fontSize: '10px',
                      width: '100%',
                      fontFamily:
                        theme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.regular ||
                        (currentBroker?.feed_type === 'CRE ' ? 'MontserratR' : ''),
                    }}
                    value={
                      listing.agents?.agentTitle?.length > 22
                        ? listing.agents?.agentTitle.slice(0, 22)
                        : listing.agents?.agentTitle
                    }
                    onChange={e => {
                      if (e.target.value.length < 22) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentTitle: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  <input
                    className="mobile-p"
                    style={{
                      marginTop: 5,
                      fontSize: '16px',
                      width: '100%',
                      fontFamily:
                        theme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.regular ||
                        (currentBroker?.feed_type === 'CRE ' ? 'MontserratR' : ''),
                    }}
                    value={
                      listing.agents?.agentPhone?.replace(/-/g, '.').length > 15
                        ? listing.agents?.agentPhone?.replace(/-/g, '.').slice(0, 15)
                        : listing.agents?.agentPhone?.replace(/-/g, '.')
                    }
                    onChange={e => {
                      if (e.target.value.length < 15) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentPhone: e.target.value,
                          },
                        });
                      }
                    }}
                  />

                  {/* </div> */}

                  {/* </div> */}
                  <input
                    className="agent-email"
                    style={{
                      width: '100%',
                      marginTop: 5,
                      color: '#777',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    }}
                    value={
                      listing.agents?.agentEmail?.length > 35
                        ? listing.agents?.agentEmail.slice(0, 35)
                        : listing.agents?.agentEmail
                    }
                    onChange={e => {
                      if (e.target.value.length < 35) {
                        mergeCurrentListing({
                          agents: {
                            ...listing.agents,
                            agentEmail: e.target.value,
                          },
                        });
                      }
                    }}
                  />

                  {/* </div> */}
                  {/* <input
                    className="agent-website"
                    style={{
                      width: '100%',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    }}
                    value={listing.agents?.agentWebsite}
                    onChange={e => mergeCurrentListing({ agents: { ...listing.agents, agentWebsite: e.target.value } })}
                  /> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="right-side">
              <EditableImage
                self={self}
                fieldName="image16"
                className="agent-logo"
                size="contain"
                position={EditableImage.CENTER}
                pictures={listing.pictures}
                imgKey={15}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page2 = ({
  defaultURL,
  footerURL,
  self,
  listing,
  theme,
  defaultSettings,
  currentBroker,
  mergeCurrentListing,
}) => {
  return (
    <div
      className="scaled page-2 ui-only-outline"
      style={{ width: '100%', height: '1056px', marginBottom: '40px', outline: 'none' }}
    >
      <div className="image-container" style={{ height: '270px', width: '100%' }}>
        <EditableImage
          self={self}
          fieldName="image5"
          className="listing-image"
          style={{ marginRight: '5px', marginBottom: '5px', width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
        />
        <EditableImage
          self={self}
          fieldName="image6"
          className="listing-image"
          style={{ marginBottom: '5px', marginLeft: '0', width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={5}
        />
      </div>
      <div className="image-container" style={{ height: '270px', width: '100%' }}>
        <EditableImage
          self={self}
          fieldName="image7"
          className="listing-image"
          style={{ marginRight: '5px', marginBottom: '5px', width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={6}
        />
        <EditableImage
          self={self}
          fieldName="image8"
          className="listing-image"
          style={{ marginBottom: '5px', marginLeft: '0', width: '100%' }}
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={7}
        />
      </div>
      {/* Highlight section */}
      <div className="highlight-section" style={{ height: '220px' }}>
        <div className="high-div">
          <div className="highlight-image-container" style={{ width: '320px' }}>
            <EditableImage
              self={self}
              fieldName="image9"
              className="highlight-image"
              style={{ height: '220px', width: '320px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={8}
            />
          </div>
          <div className="h-item">
            <div
              className="neighborhood-highlights"
              style={{
                backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
              }}
            >
              <input
                className="feature"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={listing.details.heading}
                onChange={e =>
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      heading: e.target.value,
                    },
                  })
                }
              />
              {/* <ApplyFont fontName={theme?.font || defaultSettings?.font} text="NEIGHBORHOOD HIGHLIGHTS" /> */}
            </div>
            <div className="highlight-description">
              <div className="features-list">
                <textarea
                  className="feature"
                  style={{
                    width: '100%',
                    marginTop: -10,
                    textAlign: 'justify',
                    whiteSpace: 'normal',
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                  }}
                  rows={8}
                  value={listing.details.neighborhood_highlights}
                  onChange={e =>
                    mergeCurrentListing({ details: { ...listing.details, neighborhood_highlights: e.target.value } })
                  }
                />
                {/* <ApplyFont
                    fontName={theme?.font || defaultSettings?.font}
                    text={listing.details.neighborhood_highlights}
                  />
                </div> */}
              </div>
            </div>
            {/* <div className="edit-btn-hover not-in-pdf">
              <Scale.Back>
                <EditTextModal activeTab={0} triggerTitle="Edit Highlights" />
              </Scale.Back>
            </div> */}
          </div>
        </div>
      </div>

      {/* Feature overview */}
      <div
        className="feature-section"
        style={{ height: '334px', paddingBottom: '5px', paddingTop: '5px', background: '#fff' }}
      >
        <div className="high-div">
          <div className="feature-image-container" style={{ width: '320px' }}>
            <EditableImage
              self={self}
              fieldName="image10"
              className="feature-image"
              style={{ width: '320px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={9}
            />
          </div>
          <div className="h-item">
            <div
              className="feature-title"
              style={{
                backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
              }}
            >
              <input
                className="feature"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                }}
                value={listing.details.features}
                onChange={e =>
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      features: e.target.value,
                    },
                  })
                }
              />
              {/* <ApplyFont fontName={theme?.font || defaultSettings?.font} text="FEATURES OVERVIEWS" /> */}
            </div>
            <div className="feature-container" style={{ height: '100%' }}>
              <div className="feature-description">
                <ul
                  className="list"
                  style={{
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    listStyle: 'none',
                  }}
                >
                  {listing.isMLSVisible && (
                    <>
                      WLS Id: <div style={{ paddingBottom: '10px' }}>{`${listing.mlsId}`}</div>
                    </>
                  )}
                  <li style={{ listStyle: listing.details.bedroomsText === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Bedrooms:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={listing.details.bedroomsText}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            bedroomsText: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={listing.details.qtyBedrooms}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            qtyBedrooms: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.bathroomsText === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Bathrooms:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={listing.details.bathroomsText}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            bathroomsText: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={listing.details.qtyFullBathrooms}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            qtyFullBathrooms: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.year === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Year Built:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={`${listing.details.year}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            year: e.target.value,
                          },
                        })
                      }
                    />

                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={`${listing.details.yearBuilt}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            yearBuilt: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                </ul>
                <ul
                  className="list"
                  style={{
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                    listStyle: 'none',
                  }}
                >
                  <li style={{ listStyle: listing.features.taxes === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Gross Taxes:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={listing.details?.grossTaxLegend}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            grossTaxLegend: e.target.value,
                          },
                        })
                      }
                    />
                    {currentBroker?.feed_type === 'BRG ' ? (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                        }}
                        value={`${listing?.details?.grossTax}`}
                        onChange={e =>
                          mergeCurrentListing({
                            details: {
                              ...listing.details,
                              grossTax: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                        }}
                        value={`${listing?.features?.fridge}`}
                        onChange={e =>
                          mergeCurrentListing({
                            features: {
                              ...listing.features,
                              fridge: e.target.value,
                            },
                          })
                        }
                      />
                    )}
                  </li>
                  <li style={{ listStyle: listing.details.totalFloorArea === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Total Floor Area:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={`${listing.details.totalFloorArea}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            totalFloorArea: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={`${listing.details.squareFeet}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            squareFeet: e.target.value,
                          },
                        })
                      }
                    />
                  </li>
                  <li style={{ listStyle: listing.details.totalLotSize === '' ? 'none' : '', paddingBottom: '10px' }}>
                    {/* Total Lot Size:{' '} */}
                    <input
                      fontName={theme?.font || defaultSettings?.font}
                      style={{
                        fontFamily:
                          theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                      }}
                      value={`${listing.details.totalLotSize}`}
                      onChange={e =>
                        mergeCurrentListing({
                          details: {
                            ...listing.details,
                            totalLotSize: e.target.value,
                          },
                        })
                      }
                    />
                    {currentBroker?.feed_type === 'KVC ' ? (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                        }}
                        value={`${listing.features.lotSzSqFt}`}
                        onChange={e =>
                          mergeCurrentListing({
                            features: {
                              ...listing.features,
                              lotSzSqFt: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <input
                        fontName={theme?.font || defaultSettings?.font}
                        style={{
                          fontFamily:
                            theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                        }}
                        value={`${listing.details.lotSizeTotal}`}
                        onChange={e =>
                          mergeCurrentListing({
                            details: {
                              ...listing.details,
                              lotSizeTotal: e.target.value,
                            },
                          })
                        }
                      />
                    )}
                  </li>
                </ul>
              </div>
              {/* <div
                className="break-line"
                style={{
                  backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
                }}
              /> */}
              {/* <div className="edit-btn-hover not-in-pdf">
                <Scale.Back>
                  <EditTextModal activeTab={0} triggerTitle="Edit Features" />
                </Scale.Back>
              </div> */}
              <div className="footer-logo-container" style={{ position: 'absolute', bottom: '0', right: '-30px' }}>
                <img
                  src={footerURL || defaultSettings?.horizontalLogo || PlaceholderImage}
                  className="logo-footer"
                  alt="logo-footer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page3 = ({ defaultURL, footerURL, self, listing }) => {
  return (
    <div
      className="scaled page-3 ui-only-outline"
      style={{ width: '100%', height: '1056px', marginBottom: '40px', outline: 'none' }}
    >
      <div className="banner-image" style={{ height: '550px' }}>
        <EditableImage
          self={self}
          fieldName="image11"
          className="feature-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={10}
        />
      </div>

      <div className="grid-images-container" style={{ height: '540px', width: '100%', marginTop: '5px' }}>
        <div className="grid-row-1" style={{ height: '265px' }}>
          <div className="grid-col-1" style={{ width: '100%', marginRight: '5px' }}>
            <EditableImage
              self={self}
              fieldName="image12"
              className="grid-image"
              style={{ width: '420px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={11}
            />
          </div>
          <div className="grid-col-2" style={{ width: '100%', marginLeft: '0' }}>
            <EditableImage
              self={self}
              fieldName="image13"
              className="grid-image"
              style={{ width: '420px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={12}
            />
          </div>
        </div>
        <div className="grid-row-2" style={{ height: '265px', marginTop: '5px' }}>
          <div className="grid-col-1" style={{ width: '100%', marginRight: '5px' }}>
            <EditableImage
              self={self}
              fieldName="image14"
              className="grid-image"
              style={{ width: '420px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={13}
            />
          </div>
          <div className="grid-col-2" style={{ width: '100%' }}>
            <EditableImage
              self={self}
              fieldName="image15"
              className="grid-image"
              style={{ width: '420px' }}
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={14}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Page4 = ({
  defaultURL,
  footerURL,
  self,
  listing,
  theme,
  defaultSettings,
  mergeCurrentListing,
  currentBroker,
}) => {
  const [floorPlanLoading, setFloorPlanLoading] = React.useState(false);

  const onFileSelect = async file => {
    setFloorPlanLoading(true);
    const formData = new FormData();
    formData.append('pdf', file);
    const response = await axios.post('/api/extract-pdf', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...createRequestHeader(),
    });
    setFloorPlanLoading(false);
    mergeCurrentListing({
      floorplanPicture: response.data.dataUrls[0],
      floorplanPictureTwo: response.data.dataUrls[1] ? response.data.dataUrls[1] : listing.floorplanPictureTwo,
    });
  };

  return (
    <div className="scaled page-4 ui-only-outline" style={{ width: '100%', height: '1056px' }}>
      <div
        className="top-header"
        style={{
          backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
        }}
      >
        <div
          className="address"
          style={{
            width: '100%',
            fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
          }}
        >
          <input
            style={{
              backgroundColor: 'transparent',
              width: '100%',
              fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
            }}
            value={
              listing.location.streetAddress.length > 30
                ? listing.location.streetAddress.slice(0, 30)
                : listing.location.streetAddress
            }
            onChange={e => {
              if (e.target.value.length < 30) {
                mergeCurrentListing({ location: { ...listing.location, streetAddress: e.target.value } });
              }
            }}
          />
          {/* <ApplyFont
            fontName={theme?.font || defaultSettings?.font}
            text={`${listing.location.streetAddress} ${listing.location.city}`}
          /> */}
        </div>
      </div>
      <div className="main-area" style={{ height: '870px' }}>
        <div className="floorplan" style={{ width: '100%' }}>
          <div className="floorplan-img">
            <Image
              url={listing.floorplanPicture}
              onChange={(newImgUrl, mergeCurrentListing) => {
                mergeCurrentListing({ floorplanPicture: newImgUrl });
              }}
              buttonTitle="Add Floor Plan IMG"
              width={'100%'}
              height={770}
              margin={20}
              style={{ backgroundSize: 'cover' }}
            />
            <span className="upload-floorplan-pdf">
              <FilePicker onSelect={onFileSelect} dataUrl={false} accept="application/pdf">
                <Scale.Back>
                  <Button primary size="tiny" loading={floorPlanLoading}>
                    <Icon name="file pdf" /> Upload Floor Plan PDF
                  </Button>
                </Scale.Back>
              </FilePicker>
            </span>
          </div>
        </div>
      </div>

      <div className="footer" style={{ height: '150px' }}>
        <div className="grid-row">
          <div className="grid-col-1">
            <div className="logo-container">
              <img
                className="logo"
                src={footerURL || defaultSettings?.horizontalLogo || PlaceholderImage}
                alt="Logo5"
              />
            </div>
          </div>
          <div
            className="grid-col-2"
            style={{
              backgroundColor: theme?.backgroundColor || defaultSettings?.primaryColor,
            }}
          >
            <div className="content">
              <span
                className="download-app"
                style={{
                  fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.light,
                }}
              >
                <input
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.light,
                  }}
                  value={`${listing.details.downloadText}`}
                  onChange={e =>
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        downloadText: e.target.value,
                      },
                    })
                  }
                />{' '}
              </span>
              <ApplyFont fontName={theme?.font || defaultSettings?.font}>
                <div className="website">
                  <input
                    style={{
                      backgroundColor: 'transparent',
                      width: '100%',
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.light,
                    }}
                    value={`${listing.details.downloadUrl}`}
                    onChange={e =>
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          downloadUrl: e.target.value,
                        },
                      })
                    }
                  />
                  {/* <EditableInput
                    self={self}
                    className="url"
                    fieldName="emailAddress"
                    placeholder="company@domain.com"
                    style={{
                      fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.light,
                    }}
                  /> */}
                </div>
              </ApplyFont>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '10px',
            fontSize: '7px',
            fontFamily: theme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
          }}
        >
          <UniversalLegalText />
        </div>
      </div>
    </div>
  );
};

class VantageWestBrochure2 extends Component {
  constructor(props) {
    super(props);

    let {
      currentListing,
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16 = logoPlaceholder,
      address,
    } = props;

    const { photos, description, location } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;
    if (!image6) image6 = photos[5] || PlaceHolderImage6;
    if (!image7) image7 = photos[6] || PlaceHolderImage7;
    if (!image8) image8 = photos[7] || PlaceHolderImage1;
    if (!image9) image9 = photos[8] || PlaceHolderImage2;
    if (!image10) image10 = photos[9] || PlaceHolderImage3;
    if (!image11) image11 = photos[10] || PlaceHolderImage4;
    if (!image12) image12 = photos[11] || PlaceHolderImage5;
    if (!image13) image13 = photos[12] || PlaceHolderImage6;
    if (!image14) image14 = photos[13] || PlaceHolderImage7;
    if (!image15) image15 = photos[14] || PlaceHolderImage1;
    if (!image16) image16 = photos[15] || PlaceHolderImage2;

    if (!address) address = `${location.streetAddress}, ${location.city}`;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16,
      address,
      description,
    };
  }

  customMergeListing = obj => {
    // this.setState({...this.state,listing:{...this.state.listing,...obj}},()=>{
    //   const newListing = _.omit({...this.state.listing,...obj}, true);
    // this.props.mergeCurrentListing(newListing);
    // })
    const newListing = _.omit({ ...this.props.currentListing, ...obj }, true);
    this.props.mergeCurrentListing(newListing);
  };

  render() {
    const { appliedTheme, defaultSettings, staticBrokerFonts, currentBroker } = this.props;
    const {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16,
      address,
    } = this.state;

    VantageWestBrochure2._cached = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16,
      address,
      appliedTheme,
      defaultSettings,
      staticBrokerFonts,
      currentBroker,
    };

    return (
      <>
        <VantageWestBrochureTemplate className="vw-brochure-2-wrapper">
          <Page1
            self={this}
            listing={this.props.currentListing}
            theme={appliedTheme}
            defaultSettings={defaultSettings}
            currentBroker={currentBroker}
            mergeCurrentListing={this.customMergeListing}
            defaultURL={this.props.defaultURL}
            footerURL={this.props.footerURL}
          />
          <br className="not-in-pdf" />
          <Page2
            self={this}
            listing={this.props.currentListing}
            theme={appliedTheme}
            defaultSettings={defaultSettings}
            currentBroker={currentBroker}
            mergeCurrentListing={this.customMergeListing}
            defaultURL={this.props.defaultURL}
            footerURL={this.props.footerURL}
          />
          <br className="not-in-pdf" />
          <Page3
            self={this}
            listing={this.props.currentListing}
            mergeCurrentListing={this.customMergeListing}
            defaultURL={this.props.defaultURL}
            footerURL={this.props.footerURL}
          />
          <br className="not-in-pdf" />
          <Page4
            self={this}
            listing={this.props.currentListing}
            theme={appliedTheme}
            defaultSettings={defaultSettings}
            mergeCurrentListing={this.customMergeListing}
            currentBroker={currentBroker}
            defaultURL={this.props.defaultURL}
            footerURL={this.props.footerURL}
          />
        </VantageWestBrochureTemplate>
        {/* <button
      style={{
        width:'100px',
        height:'40px',
        position:'fixed',
        minWidth:'0',
        minHeight:'0',
        right:'-200px',
        top:'0px',
        zIndex:10000,
        backgroundColor:'#00ff00'
      }}
      onClick={() => {

  }}>Save</button> */}
      </>
    );
  }
}

VantageWestBrochure2._name = TEMPLATE_NAME;
VantageWestBrochure2._width = TEMPLATE_WIDTH;
VantageWestBrochure2._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: VantageWestBrochure2, width: TEMPLATE_WIDTH, height: 1056 });

const mapStateToProps = state => {
  const {
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return { currentListing, appliedTheme: theme.appliedTheme, defaultSettings: theme.defaultSettings, currentBroker };
};

export default connect(mapStateToProps, { mergeCurrentListing })(VantageWestBrochure2);
