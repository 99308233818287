import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/ph-stacked-green-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate10.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_10';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate10 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;

    const { photos } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1 } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');

    FacebookTemplate10._cached = { headline, image1 };

    return (
      <div className={`facebook-10-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        <div className="logo">
          <img src={Logo} base64="true" alt="logo" width="100%" height="100%" />
        </div>
      </div>
    );
  }
}

FacebookTemplate10._name = TEMPLATE_NAME;
FacebookTemplate10._width = TEMPLATE_WIDTH;
FacebookTemplate10._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate10, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate10);
