import LogRocket from 'logrocket';

const isProduction =
  process.env.NODE_ENV === 'production' && window?.location?.host !== 'realvault-staging.covault.app';

if (isProduction) {
  LogRocket.init('5qiblv/realvault');
}

console.log('log rocket is being setup');
