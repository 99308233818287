import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Icon } from 'semantic-ui-react';
import { SYSTEM_ADMIN } from 'src/constants/permissions';
import Search from './search';
import DefaultLayout from '../layouts/default-layout';
import BlankListing from 'src/components/BlankListings/blank-listings';
import { getSubscriptionStatus } from 'src/redux/actions/stripeActions';
import { continueNextFunctionality } from 'src/utils/stripe-utils';
import { useTitle } from 'react-use';

const Dashboard = props => {
  useTitle('Dashboard - Realvault');

  useCheckSubscription(props);
  useCheckNoBroker(props);

  return (
    <DefaultLayout>
      <Container text>
        <div className="background white">
          <div className="center-title mt-4">
            <h1>Search MLS Listings</h1>
            <br />
          </div>
          {props.users?.currentUser?.broker_id ? (
            <Search />
          ) : (
            <div className="text center-title">
              <h1>Currently not assigned to a Brokerage.</h1>
              {props.users?.currentUser.user_role_id === SYSTEM_ADMIN ? (
                <p className="intro">
                  Click Settings Button <Icon name="settings" />
                  to assign yourself to a Brokerage. This will allow you to experience the application as an agent
                  would.
                </p>
              ) : (
                <p className="intro">Contact for info@covaultinc.com for further assistance.</p>
              )}
            </div>
          )}
        </div>
      </Container>

      <BlankListing />
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  const { users, brokers } = state;
  return { users, brokers };
};

export default connect(mapStateToProps, {
  getSubscriptionStatus,
})(Dashboard);

function useCheckNoBroker(props) {
  const history = useHistory();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (!props.users?.currentUser?.broker_id && !redirecting) {
      setRedirecting(true);
      history.replace('/onboarding/pricing');
    }
  }, [props.users?.currentUser?.broker_id]);
}

function useCheckSubscription(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.brokers?.currentBroker?.id && props.users?.currentUser?.id) {
      // check if the manual entry is enabled, or has subscription?
      // Show popup based on that
      localStorage.setItem('templateId', props.brokers?.currentBroker.template_code);
      continueNextFunctionality({
        dispatch,
        currentBroker: props.brokers?.currentBroker,
        currentUser: props.users?.currentUser,
        history,
      })
        .then(response => {
          console.debug(response, 'response of continue next functionality');
        })
        .catch(err => {
          console.error(err, 'ERR => while checking the subscription');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.brokers?.currentBroker?.id, props.users?.currentUser?.id]);
}
