import './setupLogRocket';

import React from 'react';

import { createRoot } from 'react-dom/client';

import './styles/index.scss';
import App from './App';
import { ToastProvider } from 'react-toast-notifications';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import configureStore from './redux/store';
import ContactAdminModal from './components/ContactAdminModal/contact-admin-modal';
import ChoosePlanPopup from './components/ChoosePlanPopup/choose-plan-popup';
import AddUserLimitReachedModal from './components/AddUserLimitReachedModal/add-user-limit-reached';
import MessageWrapper from './components/MessageWrapper/MessageWrapper';

import * as Analytics from './utils/analytics';

Analytics.init();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

export const store = configureStore();
root.render(
  <Provider store={store}>
    <ToastProvider autoDismiss autoDismissTimeout={6000} className="toast-message">
      <MessageWrapper />
      <ContactAdminModal />
      <ChoosePlanPopup />
      <AddUserLimitReachedModal />
      <App />
    </ToastProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
