import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import { Button, Icon, Modal, Form, Header } from 'semantic-ui-react';
import { USER_PERMISSIONS, USER_STATUS } from 'src/constants/permissions';
import { createRequestHeader } from 'src/services/request-headers';
import { USER_ROLES } from 'src/constants/permissions';
import { addItemInSubscription } from 'src/redux/actions/stripeActions';
import { continueNextFunctionality, getTotalStripeUsers } from 'src/utils/stripe-utils';
import { getBrokerTotalUsers, getSubscriptionStatus } from 'src/redux/actions/stripeActions';

import './UserModal.scss';

const propTypes = {
  edit: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.string),
  editPassword: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  broker: PropTypes.object,
};

const defaultProps = { edit: false, user: {}, editPassword: false };

const UserModal = props => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    password: '',
    user_role_id: props.user.user_role_id,
    user_status_id: props.user.user_status_id,
    requested_broker: props.user.requested_broker,
    broker_id: props.user.broker_id || props.broker,
    phone_number: props.user.phone_number,
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [formError, setFormError] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);

  const brokerages = props.brokers?.brokers?.map(({ name, id }) => ({ key: id, value: id, text: name }));

  const smallestPlan = props.products?.[0]?.plan?.[0];

  const stripeMaxAllowedUserCount = getTotalStripeUsers(props.currentSubscription?.items?.data);

  const onEditUpdateUser = () => {
    return axios
      .post(`${process.env.REACT_APP_API}/user`, formValues, createRequestHeader())
      .then(res => {
        addToast('Successfully Created a New User', { appearance: 'success', autoDismiss: true });
        props.getBrokerTotalUsers(props.user.broker_id || props.broker);
        props.getSubscriptionStatus({ user_id: props.users?.currentUser?.id });
        setLoading(false);
        setDisabled(false);
        setOpenModal(false);
        resetForm();
        props.getUsers();
      })
      .catch(error => {
        addToast(error.response ? error.response.data.error : 'Error', {
          appearance: 'error',
          autoDismiss: true,
        });
        setLoading(false);
        setDisabled(false);
      });
  };

  const handleValidations = async () => {
    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.user_role_id ||
      !formValues.user_status_id ||
      !formValues.broker_id ||
      !formValues.password ||
      formValues.password.length < 8
    ) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);

      if (!props.edit) {
        if (
          (!props.brokers?.currentBroker.manual_billing ||
            props.brokers?.currentBroker.manual_billing.toString() !== '1') &&
          props.brokers?.currentBroker.stripe_subscription_id
        ) {
          try {
            if (props.brokerageUserCount < stripeMaxAllowedUserCount) {
              onEditUpdateUser();
            } else {
              // charge the user for $9.99 and then create the user
              dispatch(
                addItemInSubscription({
                  user_id: props.users?.currentUser?.id,
                  price_id: smallestPlan.id,
                })
              )
                .then(response => {
                  onEditUpdateUser();
                })
                .catch(err => {
                  console.log('ERR => while charging the user');
                });
            }
          } catch (err) {
            console.log('ERR => while ---');
          }
        } else {
          onEditUpdateUser();
        }
      } else {
        onEditUpdateUser();
      }
    }
  };

  const handleEditValidations = async () => {
    if (props.editPassword && (!formValues.password || formValues.password.length < 8)) {
      setFormError(true);
    } else if (
      !formValues.user_role_id ||
      !formValues.user_status_id ||
      formValues.user_status_id === 1 ||
      !formValues.broker_id
    ) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      return axios
        .put(`${process.env.REACT_APP_API}/user/${props.user.id}`, formValues, createRequestHeader())
        .then(res => {
          addToast(`Successfully Updated ${props.user.first_name} ${props.user.last_name}`, {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
          setOpenModal(false);
          props.getUsers();
        })
        .catch(error => {
          addToast(error.response ? error.response.data.error : 'Error', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
        });
    }
  };

  const passwordError = () => {
    if (!formValues.password && formError) {
      return { content: 'This is a required field' };
    } else if (formValues.password.length < 8 && formError) {
      return { content: 'Password must be 8 characters long' };
    } else {
      return false;
    }
  };

  const resetForm = () => {
    setFormValues({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      user_role_id: '',
      user_status_id: '',
      requested_broker: '',
      broker_id: '',
      phone_number: '',
    });
  };

  const showPassword = props.editPassword || props.add;
  const permissionOptions = props.broker
    ? USER_PERMISSIONS.filter(permission => permission.value !== USER_ROLES.SYSTEM_ADMIN)
    : USER_PERMISSIONS;
  return (
    <Modal
      dimmer={true}
      size="large"
      trigger={
        <Button
          color={props.edit ? '' : 'green'}
          size="tiny"
          className={props.edit ? 'mt-5 w-full' : ''}
          onClick={async event => {
            event.preventDefault();
            // CHECK do continue
            if (!props.edit) {
              setAddUserLoading(true);
              const doContinue = await continueNextFunctionality({
                currentBroker: props.brokers?.currentBroker,
                currentUser: props.users?.currentUser,
                dispatch,
                includeUserCount: true,
              });
              setAddUserLoading(false);

              if (doContinue) {
                setOpenModal(true);
              }
            } else {
              setOpenModal(true);
            }
          }}
          floated="right"
          primary={!props.edit}
          loading={addUserLoading}
        >
          {props.edit ? (
            <span>
              <Icon name="pencil" />
              {props.editPassword ? 'Reset Password' : 'Edit'}
            </span>
          ) : (
            <span>Add User</span>
          )}
        </Button>
      }
      open={open}
      centered={false}
    >
      <Modal.Header>
        {props.edit ? `Edit ${props.user?.first_name} ${props.user?.last_name}` : `Create New User`}
      </Modal.Header>
      <Modal.Content className="user-modal-container">
        {!props.edit && props.brokers?.currentBroker.stripe_subscription_id && (
          <React.Fragment>
            <div className="text-center">
              <Header className="license-used-header" as="h1">
                {props.brokerageUserCount}/{stripeMaxAllowedUserCount} Licenses Used
              </Header>
              {props.brokerageUserCount === stripeMaxAllowedUserCount && (
                <p className="info-text">Each additional users is C$9.99, and will be added to your next bill.</p>
              )}
            </div>
          </React.Fragment>
        )}
        <Form>
          {!props.editPassword && (
            <>
              <Form.Input
                error={!formValues.first_name && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="First Name"
                onChange={e => setFormValues({ ...formValues, first_name: e.target.value })}
                value={formValues.first_name}
                label="First Name"
                disabled={props.edit}
              />
              <Form.Input
                error={!formValues.last_name && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="Last Name"
                onChange={e => setFormValues({ ...formValues, last_name: e.target.value })}
                value={formValues.last_name}
                label="Last Name"
                disabled={props.edit}
              />
              <Form.Input
                fluid
                required={false}
                placeholder="Phone Number"
                onChange={e => setFormValues({ ...formValues, phone_number: e.target.value })}
                value={formValues.phone_number}
                label="Phone Number"
                disabled={props.edit}
              />
              <Form.Input
                error={!formValues.email && formError ? { content: 'This is a required field' } : false}
                required={true}
                fluid
                placeholder="Email"
                onChange={e => setFormValues({ ...formValues, email: e.target.value })}
                value={formValues.email}
                label="Email"
                disabled={props.edit}
              />
              {props.edit && !props.broker && (
                <Form.Input
                  fluid
                  onChange={e => setFormValues({ ...formValues, requested_broker: e.target.value })}
                  value={formValues.requested_broker}
                  label="Requested Brokerage"
                  disabled
                />
              )}
              {!props.broker && (
                <Form.Select
                  error={!formValues.broker_id && formError ? { content: 'This is a required field' } : false}
                  required={true}
                  fluid
                  label="Brokerage"
                  options={brokerages}
                  value={formValues.broker_id}
                  placeholder="Select Brokerage"
                  onChange={(e, { value }) => setFormValues({ ...formValues, broker_id: value })}
                />
              )}
              <Form.Select
                error={!formValues.user_role_id && formError ? { content: 'This is a required field' } : false}
                required={true}
                fluid
                label="Role"
                options={permissionOptions}
                value={formValues.user_role_id}
                placeholder="Select Role"
                onChange={(e, { value }) => setFormValues({ ...formValues, user_role_id: value })}
              />
              <Form.Select
                error={
                  (!formValues.user_status_id || formValues.user_status_id === 1) && formError
                    ? { content: 'This is a required field' }
                    : false
                }
                required={true}
                fluid
                label="Status"
                options={USER_STATUS.filter(status => status.value !== 1)}
                value={formValues.user_status_id}
                placeholder="Select Status"
                onChange={(e, { value }) => setFormValues({ ...formValues, user_status_id: value })}
              />
            </>
          )}
          {showPassword && (
            <Form.Input
              error={passwordError()}
              fluid
              type="password"
              required={true}
              placeholder="Password"
              onChange={e => setFormValues({ ...formValues, password: e.target.value })}
              value={formValues.password}
              label="Password"
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          size="tiny"
          onClick={event => {
            event.preventDefault();
            setOpenModal(false);
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        {!props.edit ? (
          <Button
            color="green"
            size="tiny"
            onClick={async event => {
              event.preventDefault();
              handleValidations();
            }}
          >
            <span>
              <Icon name="checkmark" />
              Create User
            </span>
          </Button>
        ) : (
          <Button
            color="green"
            size="tiny"
            loading={loading}
            disabled={disabled}
            onClick={async event => {
              event.preventDefault();
              if (!props.editPassword) {
                delete formValues.password;
              }
              handleEditValidations();
            }}
          >
            <Icon name="checkmark" />
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

UserModal.propTypes = propTypes;
UserModal.defaultProps = defaultProps;

const mapStateToProps = state => {
  const {
    users,
    brokers,
    stripe: { products, currentSubscription, brokerageUserCount },
  } = state;
  return { users, brokers, products, currentSubscription, brokerageUserCount };
};

export default connect(mapStateToProps, {
  addItemInSubscription,
  getBrokerTotalUsers,
  getSubscriptionStatus,
})(UserModal);
