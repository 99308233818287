import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FacebookProvider, Initialize } from 'react-facebook';
import axios from 'axios';
import { Button, Icon } from 'semantic-ui-react';
import { createRequestHeader } from 'src/services/request-headers';
import templateRenderer from 'src/services/template-renderer';
import { continueNextFunctionality } from 'src/utils/stripe-utils';
import { postEventDetails } from 'src/utils/login-utils';
import Swal from 'sweetalert2';
import { POST_TO_FACEBOOK_CLICKED_EVENT, track } from 'src/utils/analytics';

import 'src/styles/sweetalert2.scss';

const PostFacebook = ({
  searchedListing,
  currentListing,
  brokers,
  templateKey,
  forListing = true,
  currentUser,
  dispatch,
  defaultURL,
  messageInterface,
}) => {
  const [posting, setPosting] = useState(false);
  const { currentBroker } = brokers;

  const postFacebookHandler = cb => {
    let shareUrl = getDefaultShareUrl(currentBroker, currentListing, defaultURL);

    return Swal.fire({
      title: 'Facebook Link URL',
      text: 'Choose the URL you would like users to be redirect to when they click on your post.',
      type: 'input',
      input: 'url',
      inputValue: shareUrl,
      confirmButtonText: 'Create Post',
      showCancelButton: true,
      dangerMode: false,
      customClass: { container: 'realvault-swal' },
      reverseButtons: true,
    }).then(({ isConfirmed, value }) => {
      if (isConfirmed) {
        cb(value);
      }
    });
  };

  const postToFacebook = async (api, url) => {
    setPosting(true);
    const doContinue = await continueNextFunctionality({
      dispatch,
      currentBroker,
      currentUser,
    });
    setPosting(false);

    if (doContinue) {
      setPosting(true);

      const { html, width, height, props } = await templateRenderer.render(
        templateKey,
        { currentListing, defaultURL },
        { useCache: true }
      );

      const rdata = {
        html,
        width,
        height,
      };

      if (forListing) {
        rdata.mlsId = (searchedListing && searchedListing.mlsId) || undefined;
      } else {
        rdata.agentId = undefined;
      }

      const responseData = await axios.post(`${process.env.REACT_APP_API}/share`, rdata, createRequestHeader()).then(
        res => {
          console.log('res.data', res);
          return res.data;
        },
        err => {
          setPosting(false);
          console.error(err);
        }
      );
      let shareUrl = decodeURIComponent(responseData);

      if (props.hasOwnProperty('_shareUrlQuery')) {
        shareUrl += props._shareUrlQuery;
      }

      shareUrl += `&facebook_url=${url}&link=google.com`;
      postEventDetails({
        type: 'facebook-share',
        time: new Date().toISOString,
        email: currentUser.email,
        broker: currentUser.broker_id,
        template: templateKey,
      });

      setPosting(false);

      api.ui({
        method: 'share',
        href: shareUrl,
      });
    }
  };
  return (
    <FacebookProvider appId="123858805047915">
      <Initialize>
        {({ isReady, api }) => {
          return (
            <Button
              loading={posting}
              disabled={posting || messageInterface.isVisible}
              onClick={() => {
                track(POST_TO_FACEBOOK_CLICKED_EVENT, { templateKey });
                postFacebookHandler(url => {
                  postToFacebook(api, url);
                });
              }}
            >
              <Icon name="facebook" />
              Post to Facebook
            </Button>
          );
        }}
      </Initialize>
    </FacebookProvider>
  );
};

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    brokers,
    users: { currentUser },
    messageInterface,
  } = state;
  return { searchedListing, currentListing, brokers, currentUser, messageInterface };
};

export default connect(mapStateToProps)(PostFacebook);

function getDefaultShareUrl(currentBroker, currentListing, defaultUrl) {
  let url;

  if (currentBroker?.feed_type?.trim() === 'CRE') {
    url = currentListing?.brokerage_url;
  } else if (currentBroker?.feed_type?.trim() === 'WLS') {
    // For Whistler Share URL
    const baseURL = 'https://www.whistlerrealestate.ca';
    const { location } = currentListing;
    url = location?.streetAddress?.split(' ')?.join('-')?.toLowerCase();
    url = `${baseURL}/property/${url}`;
  } else if (currentBroker?.feed_type?.trim() === 'BRG') {
    // pemberton holmes
    url = `https://www.pembertonholmes.com/listing/mls/viva/${currentListing?.mlsId}?g=1`;
  } else if (currentBroker?.feed_type?.trim() === 'KVC') {
    // macdonald reality
    const subUrl = currentListing?.etc?.memberPageUrl.includes('https:///')
      ? currentListing?.etc?.memberPageUrl.replace('https:///', '')
      : null;
    if (subUrl) {
      url = `https://www.macrealty.com/${subUrl}`;
    } else {
      url = currentListing?.etc?.memberPageUrl;
    }
  } else if (currentBroker?.feed_type?.trim() === 'VIVA') {
    url = 'https://realtor.ca';
  } else {
    url = defaultUrl;
  }
  return url;
}
