import React, { useEffect, useState } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logout from 'src/components/authorization/Logout';
import BrokerageLogo from 'src/components/BrokerageLogo/brokerage-logo';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { OldTemplates } from '../constants/oldTemplates';
import { useSelector } from 'react-redux';

const ADMIN_INITIAL_PATH = '/account/information';

const DefaultLayout = props => {
  const history = useHistory();
  const [expandMenu, setExpandMenu] = useState(true);
  const [logoImg, setLogoImg] = useState(null);
  let token = localStorage.getItem('jwt');
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));
  let [templateId, settemplateId] = useState(localStorage.getItem('templateId'));
  let decodedToken;
  if (!token) {
    token = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
    decodedToken = token;
  } else {
    decodedToken = jwt.decode(token);
  }
  const localBasicDetails = decodedToken;
  useEffect(() => {
    if (currentBroker && currentBroker.template_code) {
      let tempid = currentBroker.template_code;
      settemplateId(tempid);
      if (!OldTemplates.includes(tempid)) {
        async function getLogo() {
          let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
          return resp?.data?.logo;
        }

        getLogo().then(res => {
          if (res) {
            let imgs = [...res];
            imgs.forEach(v => {
              if (v.selected === true) {
                setLogoImg(v.url);
              }
            });
          }
        });
      }
    }
  }, [currentBroker]);
  return (
    <div className="App">
      <Grid columns={2} divided padded className="app-container">
        <Grid.Row className="app-row">
          <i
            className={`fa fa-bars fa-2x menu-icon ${expandMenu ? 'menu-icon-expand' : 'menu-icon-collapse'}`}
            onClick={e => {
              e.stopPropagation();
              setExpandMenu(prev => !prev);
            }}
          ></i>
          {expandMenu ? (
            <>
              <Grid.Column className="sidebar">
                <div className="top-logo ok">
                  <BrokerageLogo
                    imageUrl={
                      OldTemplates.includes(templateId) ? props.brokers?.currentBroker?.logo?.url : logoImg || logoImg
                    }
                  />
                </div>

                <div className="nav-links">
                  <>
                    <NavLink to="/dashboard" activeClassName="selected" data-test-id="dashboard-link">
                      <Button>DASHBOARD</Button>
                    </NavLink>
                    <NavLink to="/library" activeClassName="selected">
                      <Button>MARKETING HUB</Button>
                    </NavLink>
                  </>
                </div>
                <div className="bottom">
                  <div className="w-full p-5 flex flex-col justify-center items-center">
                    <Button
                      className="mb-2 w-4/5 ui button"
                      color="black"
                      onClick={() => {
                        history.replace(ADMIN_INITIAL_PATH);
                      }}
                    >
                      Admin Panel
                    </Button>
                    <Logout />
                  </div>
                </div>
              </Grid.Column>
            </>
          ) : null}
          <Grid.Column className="main-container" style={{ width: expandMenu ? 'calc(100% - 300px)' : '100%' }}>
            {props.children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { users, brokers } = state;
  return { users, brokers };
};

export default connect(mapStateToProps, {})(DefaultLayout);
