import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './PembertonBrochure1.scss';
import { register } from 'src/services/template-renderer';
import Floorplan from 'src/templates/brochures/fragments/pemberton/floorplan';
import ListingInfo from 'src/templates/brochures/fragments/pemberton/listing-info';
import ListingFeature from 'src/templates/brochures/fragments/pemberton/listing-feature';
import _ from 'src/lodash';
import { mergeCurrentListing } from 'src/redux/actions/actions';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_FOLDED_BROCHURE';
const TEMPLATE_WIDTH = 1224;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, mergeCurrentListing }) => {
  return (
    <div className={`scaled template pem-template-1 ${theme} page-1 ui-only-outline`}>
      <Floorplan mergeCurrentListing={mergeCurrentListing} listing={listing} theme={theme} dark />
      <ListingInfo mergeCurrentListing={mergeCurrentListing} listing={listing} theme={theme} />
    </div>
  );
};

const Page2 = ({ listing, theme, mergeCurrentListing }) => {
  return (
    <div className={`scaled template pem-template-1 ${theme} page-2 ui-only-outline`}>
      <ListingFeature mergeCurrentListing={mergeCurrentListing} listing={listing} theme={theme} />
    </div>
  );
};

const PembertonBrochure1 = ({ searchedListing, currentListing, themeClassName, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  const theme = themeClassName || localStorage.getItem('themeClassName');
  return (
    <>
      <Page1 mergeCurrentListing={customMergeListing} listing={currentListing} theme={theme} />
      <br className="not-in-pdf" />
      <Page2 mergeCurrentListing={customMergeListing} listing={currentListing} theme={theme} />
    </>
  );
};

PembertonBrochure1._name = TEMPLATE_NAME;
PembertonBrochure1._width = TEMPLATE_WIDTH;
PembertonBrochure1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: PembertonBrochure1,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(PembertonBrochure1);
