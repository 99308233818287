import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './PembertonBrochure5.scss';
import { register } from 'src/services/template-renderer';
import WindowPoster from 'src/templates/brochures/fragments/pemberton/window-poster';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
const TEMPLATE_NAME = 'PEMBERTON_HOLMES_WINDOW_SHEET_1_AGENT';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const PembertonBrochure5 = ({ searchedListing, currentListing, themeClassName, mergeCurrentListing }) => {
  const theme = themeClassName || localStorage.getItem('themeClassName');
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <div className={`template pem-template-5 ${theme} ui-only-outline`}>
      <WindowPoster theme={theme} mergeCurrentListing={customMergeListing} listing={currentListing} />
    </div>
  );
};

PembertonBrochure5._name = TEMPLATE_NAME;
PembertonBrochure5._width = TEMPLATE_WIDTH;
PembertonBrochure5._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: PembertonBrochure5,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(PembertonBrochure5);
