import React, { Component } from 'react';
import Logo from 'src/images/pemberton/ph-stacked-green-logo.png';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate12.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_25';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate25 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;
    

    if (!image1) image1 = PlaceHolderImage1;

    this.state = {
      headline,
      image1,
    };
  }

  render() {

    const { headline, image1, themeClassName } = this.state;
    const { currentListing } = this.props;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate25._cached = { headline, image1 };

    return (
      <div className={`facebook-12-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
        />
        <div className="content-wrapper">
          <div>
            <EditableTextarea self={this} className="headline" fieldName="headline" placeholder="Headline" />
            <hr style={{ width: '70%', margin: '0 auto', backgroundColor: theme === 'black' ? '#000' : '#112F23' }} />
          </div>
          <div className="logo" style={{ position: 'absolute', bottom: '15%', width: '100%' }}>
            <img src={Logo} style={{ margin: '0 auto' }} width="45%" height="45%" base64="true" alt="logo" />
          </div>
        </div>
      </div>
    );
  }
}

FacebookTemplate25._name = TEMPLATE_NAME;
FacebookTemplate25._width = TEMPLATE_WIDTH;
FacebookTemplate25._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate25, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate25);
