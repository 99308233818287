import React, { useState, useEffect } from 'react';
import { Container, Table, Loader, Icon, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import AdminLayout from 'src/layouts/admin-layout';
import { getThemeByBroker } from 'src/redux/actions/theme-creator';
import { useToasts } from 'react-toast-notifications';
import { deleteTheme } from 'src/redux/actions/theme-creator';
import Swal from 'sweetalert2';
import { useTitle } from 'react-use';

const backgroundCellStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ThemeViewer = () => {
  useTitle('Theme Viewer - Realvault');

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const { replace } = useHistory();

  const { currentBroker, allThemes } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allThemes: state.theme.allThemes,
  }));

  useEffect(() => {
    setLoading(true);
    dispatch(getThemeByBroker(currentBroker.id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onThemeDeleted = id => {
    return Swal.fire({
      title: 'Delete Theme!',
      text: 'Do you want to delete this theme?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E74942',
      customClass: { container: 'realvault-swal' },
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        dispatch(
          deleteTheme({
            id,
            broker: currentBroker.id.toString(),
          })
        )
          .then(response => {
            replace('/list-themes');
            setLoading(false);
            dispatch(getThemeByBroker(currentBroker.id));
            addToast('Theme Deleted Successfully!', {
              appearance: 'success',
              autoDismiss: true,
            });
          })
          .catch(err => {
            setLoading(false);
            console.log('ERR => while creating theme', err.message);
          });
        return false;
      }
      return false;
    });
  };

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex items-center justify-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      pageTitle="Additional Themes"
      rightContent={
        <Link to="/themes/create">
          <Button color="green">Add New Theme</Button>
        </Link>
      }
    >
      <Container>
        <div className="white">
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Theme Name</Table.HeaderCell>
                <Table.HeaderCell>Font Type</Table.HeaderCell>
                <Table.HeaderCell>Background Color</Table.HeaderCell>
                <Table.HeaderCell>Foreground Color</Table.HeaderCell>
                <Table.HeaderCell>Created On</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {allThemes.length > 0 && !loading && (
              <Table.Body>
                {allThemes.map(theme => (
                  <Table.Row key={theme.id}>
                    <Table.Cell>{theme.name}</Table.Cell>
                    <Table.Cell
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {theme.font}
                    </Table.Cell>
                    <Table.Cell>
                      <div style={backgroundCellStyles}>
                        <div
                          style={{
                            backgroundColor: theme.backgroundColor,
                            height: 16,
                            width: 16,
                          }}
                        ></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={backgroundCellStyles}>
                        <div
                          style={{
                            backgroundColor: theme.foregroundColor,
                            height: 16,
                            width: 16,
                          }}
                        ></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(theme.createdTime ? theme.createdTime * 1000 : Date.now()).toLocaleDateString()}
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex">
                        <div className="items-center justify-center ">
                          <Link
                            to={{
                              pathname: `/themes/edit/${theme.id}`,
                              state: {
                                theme,
                              },
                            }}
                          >
                            <Icon name="edit outline" size={24} />
                          </Link>
                        </div>
                        <div
                          className="items-center justify-center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => onThemeDeleted(theme.id)}
                        >
                          <Icon name="trash alternate" size={24} />
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>

          {allThemes.length === 0 && !loading && (
            <div className="flex items-center justify-center">
              <div>
                <p>No theme added yet. Create your first one</p>
                <div className="mt-4 text-center">
                  <Link to="/system-admin#1" className="ui button">
                    Create Theme
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </AdminLayout>
  );
};

export default ThemeViewer;
