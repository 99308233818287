import React from 'react';
import { Form, Image } from 'semantic-ui-react';
import '../../pages/OnboardingBroker/default-branding.scss';

const UploadBrandImages = ({ imgs }) => {
  return (
    <Form>
      <Form.Field className="mt-4">
        <div className="file-wrap" style={{ marginTop: '15px' }}>
          {imgs.map((img, i) => {
            return (
              <div className="img-wrap-preview">
                <Image
                  style={i === 0 ? { marginLeft: 0 } : {}}
                  id={img.id}
                  key={i}
                  className="uploaded-image"
                  src={img.url}
                  size="small"
                  bordered
                />
              </div>
            );
          })}
        </div>
      </Form.Field>
    </Form>
  );
};

export default UploadBrandImages;
