import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './scale.scss';

const scaleBack = function (field) {
  if (!field) return;

  let parentScale = 1;
  let curr = field.parentNode;

  while (curr && curr.getAttribute) {
    const scale = curr.getAttribute('x-data-scale');
    if (scale !== null) {
      parentScale = scale;
      break;
    }

    curr = curr.parentNode;
  }

  field.style.transform = `scale(${1 / parentScale})`;
};

const ScaleBack = ({ className = '', style = {}, children }) => {
  const devRef = useRef();
  setTimeout(() => scaleBack(devRef.current), 100);
  return (
    <div ref={devRef} className={`scale-back ${className}`} style={style}>
      {children}
    </div>
  );
};

const Scale = ({ width, height, targetWidth, targetHeight, scale = 1, children, style, pages = 1 }) => {
  if (targetWidth) {
    scale = targetWidth / width;
  } else if (targetHeight) {
    scale = targetHeight / height;
  }

  return (
    <div
      style={{ width: `${width * scale}px`, height: `${height * scale * pages}px`, ...style, overflow: 'hidden' }}
      x-data-scale={scale}
      className="scale-wrapper no-scale-in-pdf td-preview"
    >
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          minWidth: `${width}px`,
          minHeight: `${height}px`,
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }}
        className="scale-wrapper-sub"
      >
        {children}
      </div>
    </div>
  );
};

Scale.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  scale: PropTypes.number,
  children: PropTypes.any.isRequired,
};

Scale.Back = ScaleBack;

export default Scale;
