import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Stacked_Wordmark_Black.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate4.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_4';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate4 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;

    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      address,
      listingDetails,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = this.state;
    WhistlerSocialTemplate4._cached = { headline, image1, address, listingDetails };

    return (
      <WhistlerSocialTemplate className="whistler-social-4-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper overlay-light">
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerSocialTemplate4._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="headline"
            fieldName="headline"
            placeholder="Headline"
            rows={1}
          />
          <hr />
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerSocialTemplate4._cached.adjustedHeightAdr = v)}
            initialHeight={adjustedHeightAdr}
            className="address"
            fieldName="address"
            placeholder="#123 - 456 W Georgia St Vancouver"
            rows={1}
          />
          <EditableInput self={this} className="listingDetails" fieldName="listingDetails" placeholder="Bed | Bath" />
          <div className="logo">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate4._name = TEMPLATE_NAME;
WhistlerSocialTemplate4._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate4, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate4);
