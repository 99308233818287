import React, { useState } from 'react';
import { Container, Input, Dropdown, Form, Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { GooglePicker } from 'react-color';

import AdminLayout from 'src/layouts/admin-layout';
import { updateTheme } from 'src/redux/actions/theme-creator';
import FontPreview from 'src/components/FontPreview';
import { useTitle } from 'react-use';

const EditTheme = () => {
  useTitle('Edit Theme - Realvault');

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { replace } = useHistory();
  const { addToast } = useToasts();
  const { currentBroker, allFonts } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allFonts: state.theme.allFonts,
  }));
  const [themeData, setThemeData] = useState({
    name: state?.theme?.name || '',
    font: state?.theme?.font || '',
    backgroundColor: state?.theme?.backgroundColor || '',
    foregroundColor: state?.theme?.foregroundColor || '',
  });

  const [loading, setLoading] = useState(false);

  const FONT_OPTIONS = allFonts?.map(font => ({
    ...font,
    key: font.family,
    text: font.family,
    value: font.family,
  }));

  const changeFormHandler = (key, value) => {
    setThemeData(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onThemeCreated = () => {
    setLoading(true);
    dispatch(
      updateTheme({
        ...themeData,
        id: state.theme.id,
        broker: currentBroker.id.toString(),
      })
    )
      .then(response => {
        setLoading(false);
        setThemeData({
          name: '',
          font: '',
          backgroundColor: '',
          foregroundColor: '',
        });
        addToast('Theme Updated Successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        replace('/list-themes');
      })
      .catch(err => {
        setLoading(false);
        setThemeData({
          name: '',
          font: '',
          backgroundColor: '',
          foregroundColor: '',
        });
        console.log('ERR => while creating theme', err.message);
      });
  };

  return (
    <AdminLayout pageTitle="Update Theme">
      <Container>
        <div className="white mt-10">
          <Form onSubmit={onThemeCreated}>
            <Form.Field>
              <label>Theme name</label>
              <Input
                fluid
                placeholder="Enter name of the theme"
                value={themeData.name}
                onChange={event => changeFormHandler('name', event.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Font Type</label>
              <Dropdown
                fluid
                placeholder="Choose a font type"
                search
                selection
                options={FONT_OPTIONS}
                className="tiny"
                value={themeData.font}
                onChange={(event, data) => changeFormHandler('font', data.value)}
              />
            </Form.Field>
            <div className="mb-8">
              <FontPreview name={themeData.font} />
            </div>
            <div className="mb-8 color-picker-grid">
              <Form.Field>
                <label>Background Color</label>
                <GooglePicker
                  color={themeData.backgroundColor}
                  onChangeComplete={color => changeFormHandler('backgroundColor', color.hex)}
                />
              </Form.Field>
              <Form.Field>
                <label>Foreground Color</label>
                <GooglePicker
                  color={themeData.foregroundColor}
                  onChangeComplete={color => changeFormHandler('foregroundColor', color.hex)}
                  style={{ maxWidth: 300 }}
                />
              </Form.Field>
            </div>
            <Button type="submit" primary loading={loading}>
              Update Theme
            </Button>
          </Form>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default EditTheme;
