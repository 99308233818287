import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultLayout from 'src/layouts/default-layout';
import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';

import * as AllAdTemplates from 'src/templates/ad-templates';
import BaseAdvertisePage from './BaseAdvertisePage';
import { ADVERTISING_TEMPLATE_SELECTED, track } from 'src/utils/analytics';

const baseURL = process.env.REACT_APP_API;

const AdvertisingTemplate = ({ templateId, templates = [] }) => {
  const template = templates.find(st => st.id === templateId);
  const componentId = template?.componentId;
  const Component = AllAdTemplates[componentId];

  const pages = template?.pages;

  const scale = Component._scale;

  return (
    <BaseAdvertisePage
      defaultURL={template.banner_default_URL}
      footerURL={template.footer_default_URL}
      Component={Component}
      scale={scale}
      pages={pages}
    />
  );
};

const AdvertiseRoute = ({ brokerId }) => {
  const { id: templateId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const publisherId = 1;
    let url = `${baseURL}/publishers/${publisherId}/templates`;
    axios
      .get(url, createRequestHeader())
      .then(({ data }) => {
        console.log('template data from API', data);
        setTemplates(data);
        setLoading(false);

        const template = data.find(st => st.id === templateId);
        track(ADVERTISING_TEMPLATE_SELECTED, { template });
      })
      .catch(error => {
        console.error('error loading templates from API', error);
        setLoading(false);
      });
  }, [templateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DefaultLayout>
      <AdvertisingTemplate templateId={templateId} templates={templates} />
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers, templates: brokers.broucherTemplates, brokerId: brokers.currentBroker.id };
};

export default connect(mapStateToProps)(AdvertiseRoute);
