import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/sm-white-ph-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';
import HOME from 'src/images/icons/home-white.png';
import BATH from 'src/images/icons/bath-white.png';
import BED from 'src/images/icons/bed-white.png';
import './FacebookTemplate15.scss';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_15';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class Facebook15 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, image3, image4, address, bed, bath, sqft } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;

    if (!address) address = `${streetAddress}, ${city}`;

    if (!bed) bed = qtyBedrooms;
    if (!bath) bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    if (!sqft) sqft = `${squareFeet} SQFT`;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      address,
      bed,
      bath,
      sqft,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { image1, image2, image3, image4, address, bed, bath, sqft } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    Facebook15._cached = { image1, image2, image3, image4, address, bed, bath, sqft };

    return (
      <div className={`facebook-15-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableImage
          self={this}
          fieldName="image2"
          className="image2"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={1}
        />
        <EditableImage
          self={this}
          fieldName="image3"
          className="image3"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={this}
          fieldName="image4"
          className="image4"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={3}
        />

        <div className="content-wrapper-1"></div>
        <div className="content-wrapper-2"></div>
        <EditableInput
          self={this}
          className="address"
          fieldName="address"
          placeholder="#123 - 456 W Georgia St, Vancouver"
        />

        <img base64="true" className="cv-icon sqft-icon" src={HOME} alt="home-icon" />
        <EditableInput self={this} className="sqft-input" fieldName="sqft" placeholder="XXX SQFT" />
        <img base64="true" className="cv-icon bath-icon" src={BATH} alt="bath-icon" />
        <EditableInput self={this} className="bath-input" fieldName="bath" placeholder="X" />
        <img base64="true" className="cv-icon bed-icon" src={BED} alt="bed-icon" />
        <EditableInput self={this} className="bed-input" fieldName="bed" placeholder="X" />
        <div className="logo">
          <img src={Logo} width="80%" height="80%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

Facebook15._name = TEMPLATE_NAME;
Facebook15._width = TEMPLATE_WIDTH;
Facebook15._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: Facebook15, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(Facebook15);
