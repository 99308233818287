export const formatType = Object.freeze({
  AREA: ' sq ft',
  CURRENCY: '$',
});

// Accepts 'value' and optional 'format' for area (sq ft) or currency ($)
const FormatNaValue = (value, format) => {
  if (value === 'n/a' || !format) {
    return value;
  } else if (value === undefined) {
    return 'n/a';
  } else {
    switch (format) {
      case formatType.AREA:
        return value + formatType.AREA;
      case formatType.CURRENCY:
        return formatType.CURRENCY + value;
      default:
        return value;
    }
  }
};

export default FormatNaValue;
