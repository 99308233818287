import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import { register } from 'src/services/template-renderer';
import Logo from 'src/images/whistler/Stacked_Wordmark_Black.png';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate14.scss';
import EditableTextarea from 'src/components/editable-textarea';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_14';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate14 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;

    const { photos } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1 } = this.state;
    WhistlerSocialTemplate14._cached = { headline, image1 };

    return (
      <WhistlerSocialTemplate className="whistler-social-14-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper">
          <EditableTextarea self={this} className="headline" fieldName="headline" placeholder="Headline" rows={1} />
        </div>
        <div className="details-container">
          <div className="logo">
            <img src={Logo} width="80%" height="80%" base64="true" alt="logo" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate14._name = TEMPLATE_NAME;
WhistlerSocialTemplate14._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate14._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate14, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate14);
