import React from 'react';
import { connect } from 'react-redux';
import '../brochures/reset.css';
import './UniversalPembertonBrochure6.scss';
import { register } from 'src/services/template-renderer';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import UniversalWindowPoster from '../brochures/fragments/universal/universal-window-poster';
import PlaceholderImage from 'src/images/banner.png';

const TEMPLATE_NAME = 'WINDOW_SHEET_2_AGENTS_CO-LISTING';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const UniversalPembertonBrochure6 = ({
  searchedListing,
  currentListing,
  themeClassName,
  mergeCurrentListing,
  theme,
  ...rest
}) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  

  return (
    <div className="universalPemberton6">
      <ApplyFont
        fontName={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
      <div className={`template pem-template-6 ${theme} ui-only-outline`}>
        <UniversalWindowPoster
          headerLogo={rest.defaultURL || PlaceholderImage}
          footerLogo={rest.footerURL || PlaceholderImage}
          mergeCurrentListing={customMergeListing}
          listing={currentListing}
          twoAgents={true}
          theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
          font={
            rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
          }
        />
      </div>
    </div>
  );
};

UniversalPembertonBrochure6._name = TEMPLATE_NAME;
UniversalPembertonBrochure6._width = TEMPLATE_WIDTH;
UniversalPembertonBrochure6._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: UniversalPembertonBrochure6,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return {
    searchedListing,
    currentListing,
    appliedTheme: theme.appliedTheme,
    currentBroker,
    defaultSettings: theme.defaultSettings,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalPembertonBrochure6);
