import React from 'react';
import './universal-listing-feature.scss';
import Image from 'src/components/image';
import { default as formatValue, formatType } from 'src/utils/format-na-value';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

const placeholderPhotos = [];
placeholderPhotos[5] = PlaceHolderImage5;
placeholderPhotos[6] = PlaceHolderImage6;
placeholderPhotos[7] = PlaceHolderImage7;
placeholderPhotos[8] = PlaceHolderImage1;
placeholderPhotos[9] = PlaceHolderImage2;
placeholderPhotos[10] = PlaceHolderImage3;
placeholderPhotos[11] = PlaceHolderImage4;
placeholderPhotos[12] = PlaceHolderImage5;
placeholderPhotos[13] = PlaceHolderImage6;

const UniversalListingFeature = ({ listing, mergeCurrentListing, theme, font }) => {
  const photos = listing.mlsId !== 'demo123' ? listing.photos : placeholderPhotos;

  const baths =
    listing.details.qtyHalfBathrooms === 'n/a'
      ? `${formatValue(listing.details.qtyFullBathrooms)}/0`
      : `${formatValue(listing.details.qtyFullBathrooms)}/${formatValue(listing.details.qtyHalfBathrooms)}`;
  return (
    <div className="universal-listing-feature">
      <div>
        <Image imgKey="5" url={photos[5]} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="6" url={photos[6]} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="7" url={photos[7]} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="8" url={photos[8]} width={379} height={261} margin={'8px 0'} />
      </div>
      <div
        className="features no-hover"
        style={{
          fontFamily: font,
        }}
      >
        <div className="title">Features:</div>
        <div className="feature-list">
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Price:</span>
            <span style={{ display: 'flex' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.price.priceDisplay.length > 11
                    ? listing.price.priceDisplay.slice(0, 11)
                    : listing.price.priceDisplay
                }
                onChange={e => {
                  if (e.target.value.length < 11) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {listing.isMLSVisible && (
            <div>
              <span className="feature-name">MLS® #:</span>
              <span data-test-id="mls-id">{listing.mlsId}</span>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Bedrooms:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={formatValue(listing.details.qtyBedrooms)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      qtyBedrooms: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Baths (Full/Half):</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={`${listing.details.qtyFullBathrooms}/${listing.details.qtyHalfBathrooms}`}
                onChange={e => {
                  const { value } = e.target;
                  let half = 0;
                  let full = 0;
                  if (value.includes('/')) {
                    full = value.split('/')?.[0];
                    half = value.split('/')?.[1];
                  } else {
                    full = e.target.value;
                    half = 0;
                  }
                  mergeCurrentListing({
                    // price: {
                    //   ...listing.price,
                    //   priceDisplay: e.target.value,
                    // },
                    details: {
                      ...listing.details,
                      qtyHalfBathrooms: half,
                      qtyFullBathrooms: full,
                    },
                  });
                }}
              />
              {/* {baths} */}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Year Built:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.yearBuilt}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      yearBuilt: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Gross Taxes:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.features?.taxes?.length > 6 ? listing.features?.taxes.slice(0, 6) : listing.features?.taxes
                }
                onChange={e => {
                  mergeCurrentListing({
                    features: {
                      ...listing.features,
                      taxes: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Total Floor Area:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={formatValue(listing.details.squareFeet)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      squareFeet: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Lot Size:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.lotSizeDimensions}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      lotSizeDimensions: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Total Lot Size:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.lotSizeTotal}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      lotSizeTotal: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
        </div>

        <div className="feature-inc">
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Sub-area:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.neighborhood}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      neighborhood: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Maint. Fee:</span>
            <span>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.strataFee}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      strataFee: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div>
            <span style={{ float: 'left' }} className="feature-name">
              Maint. Fee Incl:
            </span>
            <span style={{ display: 'inline-block', width: '250px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={formatValue(listing.details.strataFeeIncl)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      strataFeeIncl: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
        </div>

        {/*<div className="edit-btn-hover not-in-pdf">
          <EditTextModal activeTab={0} triggerTitle="Edit Features" />
        </div>*/}
      </div>
      <div>
        <Image imgKey="9" url={photos[9]} width={379} height={261} margin={'8px 0'} />
      </div>
      <div>
        <Image imgKey="10" url={photos[10]} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="11" url={photos[11]} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="12" url={photos[12]} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="13" url={photos[13]} width={282} height={192} />
      </div>
    </div>
  );
};

export default UniversalListingFeature;
