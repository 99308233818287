import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Icon, Loader, Button } from 'semantic-ui-react';
import axios from 'axios';
import SearchResult from 'src/components/search-result';
import { clearSearchedListing } from 'src/redux/actions/actions';
import { getDefaultSettings } from 'src/redux/actions/theme-creator';
import PropTypes from 'prop-types';
import './search-listing.scss';
import { MLS, CREA_MLS_WITH_LISTING_OFFICE_KEY } from 'src/constants/API';
import { VIVA_MLS_WITH_LISTING_OFFICE_KEY } from 'src/constants/API';
import { BROKER_CODES } from 'src/constants/permissions';
import { createRequestHeader } from 'src/services/request-headers';
import { transformDataViva, transformDataMLS } from 'src/utils/crea-mapping';
import { LISTING_SEARCHED_EVENT, track } from 'src/utils/analytics';

const MIN_CHAR_SEARCH = 3;

const ERR_MSG = {
  NO_INPUT: `Please enter a street name longer than ${MIN_CHAR_SEARCH} characters`,
  NO_RESULTS: 'No listings found...',
  UNEXPECTED: 'Unexpected error. Try again...',
};

class SearchListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: null,
      hideResults: true,
      searchResultArray: [],
      hideError: true,
      errorMsg: null,
      entering: false,
      loading: false,
    };
  }

  handleSearch = async () => {
    const { clearSearchedListing } = this.props;
    const { currentBroker } = this.props.brokers;
    this.setState({ hideResults: true, entering: false, loading: true });

    // load the default theme
    this.props.getDefaultSettings(this.props.brokers.currentBroker.id);

    if (this.state.searchInput === null || this.state.searchInput.length < MIN_CHAR_SEARCH) {
      this.setState({ hideError: false, errorMsg: ERR_MSG.NO_INPUT });
      return;
    }

    track(LISTING_SEARCHED_EVENT, { searchInput: this.state.searchInput });

    if (currentBroker.feed_type?.trim() !== 'VIVA' && currentBroker.feed_type?.trim() !== 'CRE') {
      await axios
        .get(`${process.env.REACT_APP_API}${MLS(this.state.searchInput)}`, createRequestHeader())
        .then(response => {
          let listingArray = [];

          response.data.forEach((listing, index) => {
            listing.location.streetAddress2 = listing.location.streetAddress;
            listing.emailLegend = 'E';
            listing.websiteLegend = 'W';
            listing.agents.emailLegend = 'E';
            listing.agents.websiteLegend = 'W';
            listing.details.bedroomsText = 'Bedrooms: ';
            listing.details.bathroomsText = 'Bathrooms: ';
            listing.details.year = 'Year Built: ';
            listing.details.heading = 'NEIGHBOURHOOD HIGHLIGHTS';
            listing.details.features = 'FEATURES';

            listing.details.grossTaxLegend = 'Gross Taxes: ';
            listing.details.totalFloorArea = 'Total Floor Area: ';
            listing.details.totalLotSize = 'Total Lot Size: ';
            listing.details.downloadText = 'CONTACT';
            listing.details.downloadUrl = '(Phone Number)';

            listingArray.push(listing);
          });

          if (listingArray.length > 0) {
            this.setState({ hideResults: false, searchResultArray: listingArray, loading: false });
          } else {
            /*clearSearchedListing();*/
            this.setState({ hideError: false, errorMsg: ERR_MSG.NO_RESULTS, loading: false });
          }
        })
        .catch(error => {
          console.log('error', error);
          clearSearchedListing();
          this.setState({ hideError: false, errorMsg: ERR_MSG.UNEXPECTED, loading: false });
        });
    } else if (currentBroker.feed_type?.trim() === 'VIVA') {
      await axios
        .get(`${process.env.REACT_APP_API}${MLS(this.state.searchInput)}`, createRequestHeader())
        .then(response => {
          const listingArray = [];
          response.data.forEach(listing => {
            const transformedListing = transformDataViva(listing);
            transformedListing.details.bedroomsText = 'Bedrooms: ';
            transformedListing.details.bathroomsText = 'Bathrooms: ';
            transformedListing.details.year = 'Year Built: ';
            transformedListing.details.heading = 'NEIGHBOURHOOD HIGHLIGHTS';
            transformedListing.details.features = 'FEATURES';
            transformedListing.details.downloadText = 'CONTACT';
            transformedListing.details.totalFloorArea = 'Total Floor Area: ';
            transformedListing.details.totalLotSize = 'Total Lot Size: ';
            transformedListing.details.grossTaxLegend = 'Gross Taxes: ';
            listingArray.push(transformedListing);
          });
          if (listingArray.length > 0) {
            this.setState({ hideResults: false, searchResultArray: listingArray, loading: false });
          } else {
            this.setState({ hideError: false, errorMsg: ERR_MSG.NO_RESULTS, loading: false });
          }
        })
        .catch(error => {
          console.log(error, 'error');
          clearSearchedListing();
          this.setState({ hideError: false, errorMsg: ERR_MSG.UNEXPECTED, loading: false });
        });
    } else if (currentBroker.feed_type?.trim() === 'CRE') {
      await axios
        .get(`${process.env.REACT_APP_API}${MLS(this.state.searchInput)}`, createRequestHeader())
        .then(response => {
          const listingArray = [];
          response.data.forEach(listing => {
            const transformedListing = transformDataMLS(listing);
            transformedListing.details.bedroomsText = 'Bedrooms: ';
            transformedListing.details.bathroomsText = 'Bathrooms: ';
            transformedListing.details.year = 'Year Built: ';
            transformedListing.details.heading = 'NEIGHBOURHOOD HIGHLIGHTS';
            transformedListing.details.features = 'FEATURES';
            transformedListing.details.downloadText = 'CONTACT';
            transformedListing.details.totalFloorArea = 'Total Floor Area: ';
            transformedListing.details.totalLotSize = 'Total Lot Size: ';
            transformedListing.details.grossTaxLegend = 'Gross Taxes: ';
            listingArray.push(transformedListing);
          });
          if (listingArray.length > 0) {
            this.setState({ hideResults: false, searchResultArray: listingArray, loading: false });
          } else {
            this.setState({ hideError: false, errorMsg: ERR_MSG.NO_RESULTS, loading: false });
          }
        })
        .catch(error => {
          console.log(error, 'error');
          clearSearchedListing();
          this.setState({ hideError: false, errorMsg: ERR_MSG.UNEXPECTED, loading: false });
        });
    } else {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      // call the CREA MLS search api and get the result
      let listingType = currentBroker.feed_type?.trim();
      let url = `${baseURL}${VIVA_MLS_WITH_LISTING_OFFICE_KEY(
        currentBroker?.crea_listing_office_key?.trim() || 0,
        this.state.searchInput
      )}`;
      if (listingType === 'CRE') {
        url = `${baseURL}${CREA_MLS_WITH_LISTING_OFFICE_KEY(
          currentBroker?.crea_listing_office_key?.trim() || 0,
          this.state.searchInput
        )}`;
      }
      axios
        .get(url, createRequestHeader())
        .then(response => {
          const listingArray = [];
          if (response.data?.length) {
            response.data.forEach(listing => {
              const _listing = listing;
              _listing.location.streetAddress2 = listing.location.streetAddress;
              _listing.details.downloadText = 'CONTACT';
              _listing.emailLegend = 'E';
              _listing.websiteLegend = 'W';
              _listing.agents.emailLegend = 'E';
              _listing.agents.websiteLegend = 'W';
              _listing.details.bedroomsText = 'Bedrooms: ';
              _listing.details.bathroomsText = 'Bathrooms: ';
              _listing.details.year = 'Year Built: ';
              _listing.details.totalFloorArea = 'Total Floor Area: ';
              _listing.details.totalLotSize = 'Total Lot Size: ';
              _listing.details.heading = 'NEIGHBOURHOOD HIGHLIGHTS';
              _listing.details.features = 'FEATURES';
              _listing.details.grossTaxLegend = 'Gross Taxes: ';

              if (currentBroker.id.toString() === '10') {
                _listing.ListOfficeURL = 'vantagewestrealty.com';
                _listing.ListAgentDesignation = 'Sales Consultant, Partner';
              }
              listingArray.push(transformDataViva(_listing));
            });
          }
          if (listingArray.length > 0) {
            // here
            this.setState({ hideResults: false, searchResultArray: listingArray, loading: false });
          } else {
            clearSearchedListing();
            this.setState({ hideError: false, errorMsg: ERR_MSG.NO_RESULTS, loading: false });
          }
        })
        .catch(error => {
          clearSearchedListing();
          this.setState({ hideError: false, errorMsg: ERR_MSG.UNEXPECTED, loading: false });
        });
    }
  };

  handleChange({ target: { value } }) {
    const newState = { searchInput: value, entering: true, hideResults: true, hideError: true, errorMsg: null };
    newState.entering = value.length > 0;
    this.setState(newState);
  }

  render() {
    const { nextTo } = this.props;
    const { currentBroker } = this.props.brokers;

    return (
      <>
        <div className="ui action input search-mls">
          <Input
            placeholder={
              currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY
                ? 'Enter MLS ID or Street Address'
                : 'Enter Street Address'
            }
            className="search-input"
            onChange={e => this.handleChange(e)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleSearch();
              }
            }}
            data-test-id="search-mls-input"
          />
          <Button className="search-button" onClick={this.handleSearch}>
            <Icon name="search" />
          </Button>
        </div>
        <div className="search-results-container" hidden={this.state.hideResults}>
          <ul>
            {this.state.searchResultArray.map((listing, index) => {
              return (
                listing && (
                  <li key={index}>
                    <SearchResult searchResult={listing} linkTo={nextTo} />
                  </li>
                )
              );
            })}
          </ul>
        </div>

        <div hidden={this.state.hideError} className="search-error">
          <Icon name="exclamation triangle" />
          {this.state.errorMsg}
        </div>

        <div hidden={!this.state.loading} className="search-entering">
          <Loader style={{ position: 'relative', top: '15px', display: 'block' }} />
        </div>
      </>
    );
  }
}

SearchListing.propTypes = {
  nextTo: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers };
};

export default connect(mapStateToProps, { clearSearchedListing, getDefaultSettings })(SearchListing);
