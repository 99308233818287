import React from 'react';
import PropTypes from 'prop-types';
import RevaultLogo from 'src/components/Icons/RevaultLogo';

import './public-layout.scss';

const PublicLayout = props => {
  return (
    <div className="landing-page-background public-layout" style={props.containerStyle}>
      {/* Navbar */}
      <div className="bg-white flex flex-col navbar-container">
        <div className="navbar">
          <div className="container max-w-screen-xl mx-auto flex items-center h-full">
            <div className="flex flex-1 justify-between items-center px-6 xl:px-0">
              <span className="logo hidden md:block">
                <RevaultLogo width={256} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="background white main-content">
        <div className="padding--md">{props.children}</div>
      </div>
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PublicLayout;
