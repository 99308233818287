import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Container, Segment, Button, Header, Menu, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'src/lodash';
import DownloadPDF from 'src/components/download-pdf';
import ApplyTheme from 'src/components/ApplyTheme';
import AITextGenerationButton from 'src/components/AITextGenerationButton';
import SentToPublisher from 'src/components/SendToPublisher/SendToPublisher';
import { SEND_TO_PUBLISHER_EVENT, DOWNLOAD_ADVERTISING_PDF_CLICKED, track } from 'src/utils/analytics';

import './template-actions.scss';

const TemplateLayout = ({
  defaultURL,
  footerURL,
  templateKey,
  className,
  children,
  currentListing,
  messageInterface,
}) => {
  const history = useHistory();
  const { theme } = useSelector(state => ({
    theme: state.theme,
  }));

  const dropdownOptions = [
    {
      key: 1,
      content: (
        <DownloadPDF
          templateKey={templateKey}
          data={{ currentListing, ...theme }}
          defaultURL={defaultURL}
          footerURL={footerURL}
          filename={`brochure_${currentListing.mlsId}`}
          trackEvent={DOWNLOAD_ADVERTISING_PDF_CLICKED}
        />
      ),
      selected: false, // prevent default selection styling on dropdown.
      active: false, // prevent active class from being added.
    },
    {
      key: 2,
      content: (
        <SentToPublisher
          templateKey={templateKey}
          data={{ currentListing }}
          defaultURL={defaultURL}
          footerURL={footerURL}
          filename={`brochure_${currentListing.mlsId}`}
          currentListing={currentListing}
        />
      ),
      active: false,
    },
  ];

  return (
    <Container className={className} style={{ width: '1250px' }}>
      <Segment basic>
        <div className="mt-1 nowrap">
          <Header>{_.titleCase(templateKey)}</Header>
        </div>
        <div className="d-flex justify-content-between">
          <div className="template-actions mt-1 nowrap">
            <AITextGenerationButton />
            <ApplyTheme />
          </div>
          <div className="template-actions mt-1 nowrap">
            <Menu compact>
              <Dropdown
                text="Share"
                color="standard"
                options={dropdownOptions}
                simple
                item
                disabled={messageInterface.isVisible}
              />
            </Menu>
            <Button color="black" onClick={history.goBack} disabled={messageInterface.isVisible}>
              Back To Templates
            </Button>
          </div>
        </div>
      </Segment>
      {children}
    </Container>
  );
};

TemplateLayout.propTypes = {
  templateKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  currentListing: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps)(TemplateLayout);
