import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { enableBatching } from 'redux-batched-actions';
import { rootReducer } from './reducers/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  if (process.env.NODE_ENV !== 'production') {
    return createStore(enableBatching(rootReducer), {}, composeEnhancers(applyMiddleware(thunk, logger)));
  }
  return createStore(enableBatching(rootReducer), {}, applyMiddleware(thunk));
}
