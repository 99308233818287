import React, { Component } from 'react';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './MacdonaldFacebookTemplate7.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_7';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class MacdonaldFacebookTemplate7 extends Component {
  constructor(props) {
    super(props);

    let { title, content, clients, adjustedHeight } = props;

    this.state = {
      title,
      content,
      clients,
      adjustedHeight,
    };
  }

  render() {
    const { title, content, clients, adjustedHeight } = this.state;
    const _shareUrlQuery = `&t=${title || ''}&d=${content || ''}`;
    MacdonaldFacebookTemplate7._cached = { title, content, clients, _shareUrlQuery };

    return (
      <div className="mac-facebook-7-wrapper">
        <EditableInput self={this} className="title" fieldName="title" placeholder="Testimonial" />
        <hr />
        <div className="content-wrapper">
          <div className="quot1">“</div>
          <EditableTextarea
            self={this}
            onHeightChange={v => (MacdonaldFacebookTemplate7._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="content"
            fieldName="content"
            placeholder="Lorem ipsum dolor sit amet, adipiscing elit. Nullam lobortis sem vitae nisl scelerisque, nec aliquet leo pharetra. Cras rutrum est et velit eleifend ac at nisi."
          />
          <div className="quot2">”</div>
          <EditableInput self={this} className="clients" fieldName="clients" placeholder="Client Name(s)" />
        </div>
        <div className="logo">
          <img src={Logo} width="90%" height="90%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate7._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate7._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

export default MacdonaldFacebookTemplate7;
