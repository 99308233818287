import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure4 from 'src/templates/brochures/macdonald/MacdonaldBrochure4';

class MacdonaldBrochurePage4 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure4._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure4._width} height={MacdonaldBrochure4._height} pages={2} scale={1.2}>
          <MacdonaldBrochure4 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage4);
