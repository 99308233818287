import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import MacdonaldFacebookTemplate2 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate2';

class MacdonaldFacebook2 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout
        postFacebook={true}
        templateKey={MacdonaldFacebookTemplate2._name}
        type={type}
        listing={currentListing}
      >
        <Scale
          width={MacdonaldFacebookTemplate2._width}
          height={MacdonaldFacebookTemplate2._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <MacdonaldFacebookTemplate2 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebook2);
