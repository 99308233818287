import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import Logo from 'src/images/macdonald/macdonald-realty-logo.png';
import AwardLogo from 'src/images/macdonald/Macdonald-Sales-Award.png';
import EditableImage from 'src/components/editable-image';
import { register } from 'src/services/template-renderer';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/insert_photo_here.png';

import './MacdonaldFacebookTemplate17.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_17';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class MacdonaldFacebookTemplate17 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;

    const { photos } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1 } = this.state;
    MacdonaldFacebookTemplate17._cached = { headline, image1 };
    return (
      <div className="mac-facebook-17-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.TOP}
          pictures={[currentListing.photos]}
          imgKey={0}
        />
        <div className="content-wrapper">
          <div className="top-logo">
            <img src={Logo} width="75%" style={{ margin: '0 auto' }} height="75%" base64="true" alt="logo" />
          </div>
          <Header as="h1" className="award-message">
            Sales Achievement Award
            <br />
            2023
          </Header>
          <div className="bottom-logo">
            <img src={AwardLogo} width="60%" style={{ margin: '0 auto' }} height="60%" base64="true" alt="logo" />
          </div>
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate17._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate17._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate17._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate17, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebookTemplate17);
