import React, { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import PreviewItem from 'src/components/preview-item';
import Resizable from 'src/components/resizable';
import ApplyTheme from 'src/components/ApplyTheme';
import { createRequestHeader } from 'src/services/request-headers';
import * as AllAdTemplates from 'src/templates/ad-templates';
import { EmptyListingDashboard } from '../../pages/EmptyListingDashboard';
import axios from 'axios';
import { ADVERTISING_TAB_LOADED, PUBLISHER_CLICKED_EVENT, track } from 'src/utils/analytics';

export function AdvertiseTemplatePanel({ cached, mlsId, brokers }) {
  const [templates, setTemplates] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [selectedPublisherId, setSelectedPublisherId] = useState(null);
  const [loading, setLoading] = useState(true);

  const currentBroker = brokers.currentBroker;

  // load templates
  useEffect(() => {
    if (!selectedPublisherId) return;

    const { id: brokerId } = brokers.currentBroker;
    const baseURL = process.env.REACT_APP_API;
    setLoading(true);

    // TODO: refactor this later to a service?
    const url = `${baseURL}/publishers/${selectedPublisherId}/templates`;
    axios
      .get(url, createRequestHeader())
      .then(({ data }) => {
        console.log('template data from API', data);
        const templates = data.filter(template => template.category === 'advertising');
        setTemplates(templates);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('error loading templates from API', error);
      });
  }, [selectedPublisherId]);

  // load publishers
  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/publishers`;
    setLoading(true);
    axios
      .get(url, createRequestHeader())
      .then(({ data }) => {
        console.log('publisher data from API', data);
        setPublishers(data.publishers);
        setSelectedPublisherId(data.publishers[0].id);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('error loading publishers from API', error);
      });
  }, []);

  useEffect(() => {
    if (!currentBroker) return;
    track(ADVERTISING_TAB_LOADED, { currentBroker });
  }, [currentBroker]);

  return (
    <Resizable {...cached} className="preview-list-container">
      {({ widthCol6, widthCol4, widthCol3 }) => {
        EmptyListingDashboard._cached = { widthCol6, widthCol4, widthCol3 };
        const targetHeight = 450 * (widthCol3 / 500);

        return (
          <>
            <div style={{ textAlign: 'center', marginBottom: 50 }}>
              <Header as="h1">Local Print Publications</Header>
              {currentBroker.ad_tab_message && currentBroker.ad_tab_message.trim() !== '' && (
                <p style={{ marginBottom: 10, textAlign: 'center' }}>{currentBroker.ad_tab_message}</p>
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
            >
              <ApplyTheme />
            </div>

            <>
              <PublishersBar
                publishers={publishers}
                selectedPublisherId={selectedPublisherId}
                onTabClick={publisherId => {
                  setSelectedPublisherId(publisherId);

                  const publisher = publishers.find(p => p.id === publisherId);
                  track(PUBLISHER_CLICKED_EVENT, { publisher });
                }}
              />
              <Grid>
                <Grid.Row columns={2} className="mb-50p">
                  {loading && <div>Loading...</div>}
                  {templates.length === 0 && <p>Coming soon...</p>}
                  {templates.map((template, index) => (
                    <Grid.Column key={`templates-${index}`}>
                      <PreviewItem
                        title={template.title}
                        price={template.price}
                        retailPrice={template.retailPrice}
                        linkTo={`/listing/${mlsId}/advertise/${template.id}`}
                        targetHeight={targetHeight}
                        Component={AllAdTemplates[template.componentId]}
                        pages={template.pages}
                        defaultURL={template.default_url}
                        data={{ headline: template.title }}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
            </>
          </>
        );
      }}
    </Resizable>
  );
}

function PublishersBar({ publishers, onTabClick, selectedPublisherId }) {
  return (
    <div
      className="publishers-bar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #CACACA',
        borderRadius: 5,
        padding: 10,
        paddingRight: 20,
        marginBottom: 48,
        alignItems: 'center',
      }}
    >
      {publishers.map((publisher, index) => {
        const selected = publisher.id === selectedPublisherId;
        return (
          <div
            key={`publisher-${index}`}
            className="publisher"
            style={{
              cursor: selected ? 'default' : 'pointer',
              filter: 'grayscale(100%)',
              opacity: selected ? 1 : 0.5,
              padding: '0 10px',
            }}
            onClick={() => onTabClick(publisher.id)}
          >
            <img src={publisher.logo} alt={publisher.name} />
          </div>
        );
      })}
    </div>
  );
}

export default AdvertiseTemplatePanel;
