import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';

import './MacdonaldFacebookTemplate9.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_9';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class MacdonaldFacebookTemplate9 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, image2, image3, image4, address, detail } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    const bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    const sqft = `${squareFeet} SQFT`;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;

    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms}   |   Bath: ${bath}   |   ${sqft}`;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      image4,
      address,
      detail,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, image2, image3, image4, address, detail } = this.state;
    MacdonaldFacebookTemplate9._cached = { headline, image1, image2, image3, image4, address, detail };

    return (
      <div className="mac-facebook-9-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableImage
          self={this}
          fieldName="image2"
          className="image2"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={1}
        />
        <EditableImage
          self={this}
          fieldName="image3"
          className="image3"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={this}
          fieldName="image4"
          className="image4"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={3}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        <hr />
        <EditableInput
          self={this}
          className="address"
          fieldName="address"
          placeholder="#123 - 959 W Georgia St Vancouver"
        />
        <EditableInput self={this} className="detail" fieldName="detail" placeholder="Bed: X | Bath: X | XXX SQFT" />
        <div className="logo">
          <img src={Logo} width="90%" height="90%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate9._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate9._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate9._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate9, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebookTemplate9);
