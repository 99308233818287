import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import PembertonBrochure4 from 'src/templates/brochures/pemberton/PembertonBrochure4';

class Brochure4 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={PembertonBrochure4._name} currentListing={currentListing}>
        <Scale width={PembertonBrochure4._width} height={PembertonBrochure4._height} pages={2} scale={1.2}>
          <PembertonBrochure4 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(Brochure4);
