import React from 'react';
import { Button, Icon, Modal, Form, Grid } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import VisaCard from '../Icons/VisaCard';
import AmexCard from '../Icons/AmexCard';
import MasterCard from '../Icons/MasterCard';
import DiscoverCard from '../Icons/DiscoverCard';

import './update-billing-modal.scss';

const UpdateBillingModal = ({ open, setOpen, loading, setLoading, customer, onSubmit, mode = 'add' }) => {
  const { addToast } = useToasts();

  // load the stripe
  const stripe = useStripe();
  const elements = useElements();

  const options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const onCardGenerationError = message => {
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    setLoading(true);
    const {
      error,
      token: { id: token_id },
    } = await stripe.createToken(cardElement, {
      name: customer?.name,
      currency: 'USD',
      address_city: customer?.address?.city,
      address_country: customer?.address?.country,
      address_line1: customer?.address?.line1,
      address_line2: '',
      address_state: customer?.address?.state,
      address_zip: customer?.address?.postal_code,
    });
    if (error) {
      onCardGenerationError(error.message);
      return;
    }
    onSubmit(token_id);
  };

  return (
    <Modal centered open={open} onClose={() => setOpen(false)} size="small">
      <Modal.Header>Add Payment Information</Modal.Header>
      <Modal.Content>
        <div className="update-billing-modal">
          <div className="credit-card-heading">
            <div className="heading">
              <div className="radio-outer">
                <div className="radio-inner" />
              </div>
              <h3 className="pay-by-card-title">Pay by Card</h3>
            </div>
            <div className="credit-card-icons-container">
              <VisaCard height={32} width={32} />
              <MasterCard height={32} width={32} />
              <AmexCard height={32} width={32} />
              <DiscoverCard height={32} width={32} />
            </div>
          </div>

          <Form>
            <Form.Field className="form-field" required>
              <label>Card Number (Credit or Debit)</label>
              <div className="input-container">
                <CardNumberElement options={options} />
                <i className="fa fa-credit-card card-icon"></i>
              </div>
            </Form.Field>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field className="form-field" required>
                    <label>Expiration Date</label>
                    <div className="input-container">
                      <CardExpiryElement options={options} />
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field className="form-field" required>
                    <label>CVC / CVN</label>
                    <div className="input-container">
                      <CardCvcElement options={options} />
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="black" size="tiny" onClick={() => setOpen(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          color="green"
          size="tiny"
          disabled={loading}
          loading={loading}
          onClick={async event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <Icon name="checkmark" />
          {mode === 'add' ? 'Add' : 'Update'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateBillingModal;
