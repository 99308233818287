import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Tab, Dimmer, Loader, Button } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import _ from 'src/lodash';
import PreviewItem from 'src/components/preview-item';
import Resizable from 'src/components/resizable';
import { BROKER_CODES } from 'src/constants/permissions';
import axios from 'axios';
import { MLS_ID } from 'src/constants/API';
import { createRequestHeader } from 'src/services/request-headers';
import DefaultLayout from 'src/layouts/default-layout';
import ApplyTheme from 'src/components/ApplyTheme';

import { setBothListing, clearBothListing } from 'src/redux/actions/actions';
import PembertonBrochure1 from 'src/templates/brochures/pemberton/PembertonBrochure1';
import PembertonBrochure2 from 'src/templates/brochures/pemberton/PembertonBrochure2';
import PembertonBrochure3 from 'src/templates/brochures/pemberton/PembertonBrochure3';
import PembertonBrochure4 from 'src/templates/brochures/pemberton/PembertonBrochure4';
import PembertonBrochure5 from 'src/templates/brochures/pemberton/PembertonBrochure5';
import PembertonBrochure6 from 'src/templates/brochures/pemberton/PembertonBrochure6';

import MacdonaldBrochure1 from 'src/templates/brochures/macdonald/MacdonaldBrochure1';
import MacdonaldBrochure2 from 'src/templates/brochures/macdonald/MacdonaldBrochure2';
import MacdonaldBrochure3 from 'src/templates/brochures/macdonald/MacdonaldBrochure3';
import MacdonaldBrochure4 from 'src/templates/brochures/macdonald/MacdonaldBrochure4';
import MacdonaldBrochure5 from 'src/templates/brochures/macdonald/MacdonaldBrochure5';
import MacdonaldBrochure6 from 'src/templates/brochures/macdonald/MacdonaldBrochure6';
import MacdonaldBrochure7 from 'src/templates/brochures/macdonald/MacdonaldBrochure7';
import MacdonaldBrochure8 from 'src/templates/brochures/macdonald/MacdonaldBrochure8';

import FacebookTemplate12 from 'src/templates/facebook/pemberton/FacebookTemplate12';
import FacebookTemplate2 from 'src/templates/facebook/pemberton/FacebookTemplate2';
import FacebookTemplate3 from 'src/templates/facebook/pemberton/FacebookTemplate3';
import FacebookTemplate4 from 'src/templates/facebook/pemberton/FacebookTemplate4';
import FacebookTemplate5 from 'src/templates/facebook/pemberton/FacebookTemplate5';
import FacebookTemplate6 from 'src/templates/facebook/pemberton/FacebookTemplate6';
import FacebookTemplate8 from 'src/templates/facebook/pemberton/FacebookTemplate8';
import FacebookTemplate9 from 'src/templates/facebook/pemberton/FacebookTemplate9';
import FacebookTemplate10 from 'src/templates/facebook/pemberton/FacebookTemplate10';
import FacebookTemplate11 from 'src/templates/facebook/pemberton/FacebookTemplate11';
import FacebookTemplate13 from 'src/templates/facebook/pemberton/FacebookTemplate13';
import FacebookTemplate15 from 'src/templates/facebook/pemberton/FacebookTemplate15';
import FacebookTemplate18 from 'src/templates/facebook/pemberton/FacebookTemplate18';
import FacebookTemplate19 from 'src/templates/facebook/pemberton/FacebookTemplate19';
import FacebookTemplate20 from 'src/templates/facebook/pemberton/FacebookTemplate20';
import FacebookTemplate21 from 'src/templates/facebook/pemberton/FacebookTemplate21';
import FacebookTemplate22 from 'src/templates/facebook/pemberton/FacebookTemplate22';
import FacebookTemplate23 from 'src/templates/facebook/pemberton/FacebookTemplate23';
import FacebookTemplate7 from 'src/templates/facebook/pemberton/FacebookTemplate7';
import FacebookTemplate17 from 'src/templates/facebook/pemberton/FacebookTemplate17';
import FacebookTemplate24 from 'src/templates/facebook/pemberton/FacebookTemplate24';
import FacebookTemplate25 from 'src/templates/facebook/pemberton/FacebookTemplate25';
import FacebookTemplate26 from 'src/templates/facebook/pemberton/FacebookTemplate26';
import facebookTemplate27 from 'src/templates/facebook/pemberton/facebookTemplate27';
import 'src/components/preview-list.scss';

import Instagram1 from 'src/templates/instagram/macdonald/instagram1';
import Instagram2 from 'src/templates/instagram/macdonald/instagram2';
import Instagram3 from 'src/templates/instagram/macdonald/instagram3';
import Instagram4 from 'src/templates/instagram/macdonald/instagram4';

import MacdonaldFacebookTemplate1 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate1';
import MacdonaldFacebookTemplate2 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate2';
import MacdonaldFacebookTemplate3 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate3';
import MacdonaldFacebookTemplate4 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate4';
import MacdonaldFacebookTemplate5 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate5';
import MacdonaldFacebookTemplate6 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate6';
import MacdonaldFacebookTemplate8 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate8';
import MacdonaldFacebookTemplate9 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate9';
import MacdonaldFacebookTemplate10 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate10';
import MacdonaldFacebookTemplate11 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate11';
import MacdonaldFacebookTemplate13 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate13';
import MacdonaldFacebookTemplate14 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate14';
import MacdonaldFacebookTemplate15 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate15';
import MacdonaldFacebookTemplate7 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate7';
import MacdonaldFacebookTemplate17 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate17';
import MacdonaldFacebookTemplate18 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate18';
import AllWhistlerSocialTemplatesContainer from 'src/templates/facebook/whistler/AllWhistlerSocialTemplatesContainer';
import AllWhistlerFacebookTemplatesContainer from 'src/templates/facebook/whistler/AllWhistlerFacebookTemplatesContainer';
import AllWhistleBrochuresContainer from 'src/templates/brochures/whistler/AllWhistleBrochuresContainer';

import SocialTemplatePanel from 'src/components/SocialTemplatePanel';
import BrochureTemplatePanel from 'src/components/BrochureTemplatePanel';
import AdvertiseTemplatePanel from 'src/components/AdvertiseTemplatePanel';
import { BROCHURES_TAB_LOADED, SOCIAL_MEDIA_TAB_LOADED, track } from 'src/utils/analytics';

class ListingDashboard extends Component {
  state = {
    headline: 'Add Text',
    type: 'add-text',
    isLoading: false,
    themeClassName: localStorage.getItem('themeClassName') || 'green',
    initialMount: false,
  };

  componentDidMount() {
    document.title = 'Listing Dashboard - Realvault';

    localStorage.setItem('themeClassName', this.state.themeClassName);
    if (this.props.match.params.mlsId && !this.props.currentListing) {
      this.handleFetchListing(this.props.match.params.mlsId);
    }
    this.setState({ activeTab: localStorage.getItem('activeTab') });
    this.setState({ initialMount: true });
  }

  handleFetchListing = mlsId => {
    this.setState({ isLoading: true });
    return axios
      .get(`${process.env.REACT_APP_API}${MLS_ID(mlsId)}`, createRequestHeader())
      .then(({ data }) => {
        if (data.length === 0) this.props.clearBothListing();
        this.props.setBothListing(data);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        this.props.clearBothListing();
        this.setState({ isLoading: false });
      });
  };

  handleTabChange = (event, tab) => {
    this.props.history.replace(`/listing/${this.props.match.params.mlsId}#${tab.activeIndex}`);
  };

  handleThemeChange = theme => {
    localStorage.setItem('themeClassName', theme);
    this.setState({ themeClassName: theme });
  };

  render() {
    const { type } = this.state;
    const { currentListing, brokers, match } = this.props;
    if (match.params.mlsId && !currentListing) return <Redirect to={`/listing/${match.params.mlsId}#0`} />;
    if (!match.params.mlsId && !currentListing) return <Redirect to="/" />;

    const mlsId = currentListing.ID;
    const headline = _.startCase(this.state.headline);

    const cached = ListingDashboard._cached || {};

    const panes = () =>
      [
        {
          menuItem: { name: 'SOCIAL MEDIA', key: '1' },
          render: () => <Tab.Pane>{renderTemplates()}</Tab.Pane>,
        },
        {
          menuItem: {
            name: 'BROCHURES',
            key: '0',
          },
          render: () => <Tab.Pane>{renderBrochures()}</Tab.Pane>,
        },
        brokers.currentBroker?.show_ad_tab
          ? {
              menuItem: {
                name: 'ADVERTISE',
                key: '2',
              },
              render: () => <Tab.Pane>{renderAdvertise()}</Tab.Pane>,
            }
          : null,
      ].filter(Boolean);

    const renderAdvertise = () => <AdvertiseTemplatePanel mlsId={mlsId} cached={cached} brokers={brokers} />;
    const renderBrochures = () => (
      <Resizable {...cached} className="preview-list-container">
        {({ widthCol6, widthCol4, widthCol3 }) => {
          ListingDashboard._cached = { widthCol6, widthCol4, widthCol3 };

          return (
            <>
              <TrackBrochureLoaded currentListing={currentListing} />
              <Header as="h1" className="preview-list-header">
                BROCHURE TEMPLATES
              </Header>
              <div
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                }}
              >
                <ApplyTheme />
              </div>
              {brokers.currentBroker?.template_code === BROKER_CODES.PEMBERTON && (
                <>
                  <div style={{ textAlign: 'center', padding: '20px', marginBottom: '30px' }}>
                    <Button.Group size="large">
                      <Button
                        active={this.state.themeClassName === 'green'}
                        onClick={() => this.handleThemeChange('green')}
                      >
                        Green Theme
                      </Button>
                      <Button
                        active={this.state.themeClassName === 'black'}
                        onClick={() => this.handleThemeChange('black')}
                      >
                        Black Theme
                      </Button>
                    </Button.Group>
                  </div>
                  <Grid>
                    <Grid.Row columns={2} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Folded Brochure"
                          linkTo={`${mlsId}/brochure/1`}
                          targetWidth={widthCol4}
                          Component={PembertonBrochure1}
                          pages={2}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Folded Brochure with Two Page Floorplan"
                          linkTo={`${mlsId}/brochure/2`}
                          targetWidth={widthCol4}
                          Component={PembertonBrochure2}
                          pages={2}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Feature Sheet"
                          linkTo={`${mlsId}/brochure/3`}
                          targetWidth={widthCol3 / 2}
                          Component={PembertonBrochure3}
                          pages={2}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Feature Sheet"
                          linkTo={`${mlsId}/brochure/4`}
                          targetWidth={widthCol3 / 2}
                          Component={PembertonBrochure4}
                          pages={2}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Window Sheet 1 Agent"
                          linkTo={`${mlsId}/brochure/5`}
                          targetWidth={widthCol4}
                          Component={PembertonBrochure5}
                          pages={1}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Window Sheet 2 Agents Co-Listing"
                          linkTo={`${mlsId}/brochure/6`}
                          targetWidth={widthCol4}
                          Component={PembertonBrochure6}
                          pages={1}
                          data={{ themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
              {brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY && (
                <Grid>
                  <Grid.Row columns={2} className="mb-50p">
                    <Grid.Column>
                      <PreviewItem
                        title="Folded Brochure"
                        linkTo={`${mlsId}/brochure/1`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure1}
                        pages={2}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <PreviewItem
                        title="Folded Brochure with Custom Agent Banner"
                        linkTo={`${mlsId}/brochure/2`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure2}
                        pages={2}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} className="mb-50p">
                    <Grid.Column>
                      <PreviewItem
                        title="Feature Sheet"
                        linkTo={`${mlsId}/brochure/3`}
                        targetWidth={widthCol3 / 2}
                        Component={MacdonaldBrochure3}
                        pages={2}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <PreviewItem
                        title="Feature Sheet with Custom Agent Banner"
                        linkTo={`${mlsId}/brochure/4`}
                        targetWidth={widthCol3 / 2}
                        Component={MacdonaldBrochure4}
                        pages={2}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} className="mb-50p">
                    <Grid.Column>
                      <PreviewItem
                        title="Window Sheet 1 Agent"
                        linkTo={`${mlsId}/brochure/5`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure5}
                        pages={1}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <PreviewItem
                        title="Window Sheet 2 Agents Co-Listing"
                        linkTo={`${mlsId}/brochure/6`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure6}
                        pages={1}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2} className="mb-50p">
                    <Grid.Column>
                      <PreviewItem
                        title="Horizontal Window Sheet 3 Agent"
                        linkTo={`${mlsId}/brochure/7`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure7}
                        pages={1}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <PreviewItem
                        title="Horizontal Window Sheet 4 Agents Co-Listing "
                        linkTo={`${mlsId}/brochure/8`}
                        targetWidth={widthCol4}
                        Component={MacdonaldBrochure8}
                        pages={1}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
              {brokers.currentBroker?.template_code === BROKER_CODES.WHISTLER && (
                <AllWhistleBrochuresContainer mlsId={mlsId} targetWidth={widthCol4} />
              )}
              {/* All New templates will be displayed here */}
              {[BROKER_CODES.WHISTLER, BROKER_CODES.MACDONALD_REALTY, BROKER_CODES.PEMBERTON].includes(
                brokers.currentBroker?.template_code
              ) && <div className="empty-spacer" />}
              {/* Universal brochure templates */}

              <BrochureTemplatePanel
                mlsId={mlsId}
                template_code={brokers.currentBroker?.template_code}
                targetWidth={widthCol4}
                isTemplatesAreUsedForHardcodedBroker={[
                  BROKER_CODES.WHISTLER,
                  BROKER_CODES.MACDONALD_REALTY,
                  BROKER_CODES.PEMBERTON,
                ].includes(brokers.currentBroker?.template_code)}
              />
            </>
          );
        }}
      </Resizable>
    );

    const renderTemplates = () => (
      <Resizable {...cached} className="preview-list-container">
        {({ widthCol6, widthCol4, widthCol3 }) => {
          ListingDashboard._cached = { widthCol6, widthCol4, widthCol3 };
          return (
            <>
              <TrackSocialMediaLoaded currentListing={currentListing} />
              <Header as="h1" className="preview-list-header">
                SOCIAL MEDIA
              </Header>
              <div
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                }}
              >
                <ApplyTheme />
              </div>
              {/* {!Object.values(BROKER_CODES).includes(brokers.currentBroker.template_code) && (
                <>
                  <Header as="h2" className="preview-list-header">
                    Social Media Templates have not been created for this Brokerage.
                  </Header>
                  <Header as="h3" className="preview-list-header">
                    Please contact info@covaultinc.com for assistance.
                  </Header>
                </>
              )} */}
              {brokers.currentBroker?.template_code === BROKER_CODES.PEMBERTON && (
                <>
                  <div style={{ textAlign: 'center', padding: '20px', marginBottom: '30px' }}>
                    <Button.Group size="large">
                      <Button
                        active={this.state.themeClassName === 'green'}
                        onClick={() => this.handleThemeChange('green')}
                      >
                        Green Theme
                      </Button>
                      <Button
                        active={this.state.themeClassName === 'black'}
                        onClick={() => this.handleThemeChange('black')}
                      >
                        Black Theme
                      </Button>
                    </Button.Group>
                  </div>
                  <Grid>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 1"
                          linkTo={`${mlsId}/facebook/${type}/19`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate19}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 2"
                          linkTo={`${mlsId}/facebook/${type}/20`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate20}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 3"
                          linkTo={`${mlsId}/facebook/${type}/23`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate23}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 4"
                          linkTo={`${mlsId}/facebook/${type}/22`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate22}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 5"
                          linkTo={`${mlsId}/facebook/${type}/21`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate21}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 6"
                          linkTo={`${mlsId}/facebook/${type}/10`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate10}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 7"
                          linkTo={`${mlsId}/facebook/${type}/4`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate4}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 8"
                          linkTo={`${mlsId}/facebook/${type}/18`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate18}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 9"
                          linkTo={`${mlsId}/facebook/${type}/3`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate3}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      {/* <Grid.Column>
                        <PreviewItem
                          title="Template 10"
                          linkTo={`${mlsId}/facebook/${type}/2`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate2}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column> */}
                      <Grid.Column>
                        <PreviewItem
                          title="Template 10"
                          linkTo={`${mlsId}/facebook/${type}/5`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate5}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 11"
                          linkTo={`${mlsId}/facebook/${type}/12`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate12}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 12"
                          linkTo={`${mlsId}/facebook/${type}/15`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate15}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 13"
                          linkTo={`${mlsId}/facebook/${type}/8`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate8}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 14"
                          linkTo={`${mlsId}/facebook/${type}/9`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate9}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 15"
                          linkTo={`${mlsId}/facebook/${type}/11`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate11}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 16"
                          linkTo={`${mlsId}/facebook/${type}/13`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate13}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 17"
                          linkTo={`${mlsId}/facebook/${type}/6`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate6}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 18"
                          linkTo={`/listing/${mlsId}/facebook/${type}/27`}
                          targetWidth={widthCol4}
                          Component={facebookTemplate27}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {/* <Grid>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 19"
                          linkTo={`${mlsId}/facebook/${type}/13`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate13}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 20"
                          linkTo={`${mlsId}/facebook/${type}/8`}
                          targetWidth={widthCol4}
                          Component={FacebookTemplate8}
                          data={{ headline, themeClassName: this.state.themeClassName }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}

                  <>
                    <Header as="h1" className="preview-list-header">
                      PROMO
                    </Header>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <PreviewItem
                            title="Template 1"
                            linkTo={`${mlsId}/facebook/promo/7`}
                            targetWidth={widthCol4}
                            Component={FacebookTemplate7}
                            data={{ themeClassName: this.state.themeClassName }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <PreviewItem
                            title="Template 2"
                            linkTo={`${mlsId}/facebook/promo/17`}
                            targetWidth={widthCol4}
                            Component={FacebookTemplate17}
                            data={{ themeClassName: this.state.themeClassName }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <PreviewItem
                            title="Template 3"
                            linkTo={`${mlsId}/facebook/new-blog/24`}
                            targetWidth={widthCol4}
                            Component={FacebookTemplate24}
                            data={{ headline: 'New Blog', themeClassName: this.state.themeClassName }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <PreviewItem
                            title="Template 4"
                            linkTo={`${mlsId}/facebook/new-blog/25`}
                            targetWidth={widthCol4}
                            Component={FacebookTemplate25}
                            data={{ headline: 'New Blog', themeClassName: this.state.themeClassName }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <PreviewItem
                            title="Template 5"
                            linkTo={`${mlsId}/facebook/new-blog/26`}
                            targetWidth={widthCol4}
                            Component={FacebookTemplate26}
                            data={{ headline: 'New Blog', themeClassName: this.state.themeClassName }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </>
                </>
              )}

              {brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY && (
                <>
                  <Grid>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 1"
                          linkTo={`${mlsId}/facebook/${type}/1`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate1}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 2"
                          linkTo={`${mlsId}/facebook/${type}/2`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate2}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 3"
                          linkTo={`${mlsId}/facebook/${type}/3`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate3}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 4"
                          linkTo={`${mlsId}/facebook/${type}/4`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate4}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 5"
                          linkTo={`${mlsId}/facebook/${type}/5`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate5}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 6"
                          linkTo={`${mlsId}/facebook/${type}/6`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate6}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 7"
                          linkTo={`${mlsId}/facebook/${type}/8`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate8}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 8"
                          linkTo={`${mlsId}/facebook/${type}/9`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate9}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 9"
                          linkTo={`${mlsId}/facebook/${type}/10`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate10}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 10"
                          linkTo={`${mlsId}/facebook/${type}/11`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate11}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 11"
                          linkTo={`${mlsId}/facebook/${type}/13`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate13}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 12"
                          linkTo={`${mlsId}/facebook/${type}/14`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate14}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 13"
                          linkTo={`${mlsId}/facebook/${type}/15`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate15}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Header as="h1" className="preview-list-header">
                    INSTAGRAM STORIES
                  </Header>
                  <Grid>
                    <Grid.Row columns={4} className="mb-50p">
                      <Grid.Column>
                        <PreviewItem
                          title="Template 1"
                          linkTo={`${mlsId}/instagram/${type}/1`}
                          targetWidth={widthCol6}
                          Component={Instagram1}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 2"
                          linkTo={`${mlsId}/instagram/${type}/2`}
                          targetWidth={widthCol6}
                          Component={Instagram2}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 3"
                          linkTo={`${mlsId}/instagram/${type}/3`}
                          targetWidth={widthCol6}
                          Component={Instagram3}
                          data={{ headline }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 4"
                          linkTo={`${mlsId}/instagram/${type}/4`}
                          targetWidth={widthCol6}
                          Component={Instagram4}
                          data={{ headline }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Header as="h1" className="preview-list-header">
                    PROMO
                  </Header>
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 1"
                          linkTo={`${mlsId}/facebook/${type}/7`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate7}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 2"
                          linkTo={`${mlsId}/facebook/${type}/17`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate17}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PreviewItem
                          title="Template 3"
                          linkTo={`${mlsId}/facebook/${type}/18`}
                          targetWidth={widthCol4}
                          Component={MacdonaldFacebookTemplate18}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}

              {brokers.currentBroker?.template_code === BROKER_CODES.WHISTLER && (
                <div>
                  <AllWhistlerSocialTemplatesContainer mlsId={mlsId} type={type} targetWidth={widthCol4} />
                  <AllWhistlerFacebookTemplatesContainer mlsId={mlsId} type={type} targetWidth={widthCol3} />
                </div>
              )}

              {/* All New templates will be displayed here */}
              {[BROKER_CODES.WHISTLER, BROKER_CODES.MACDONALD_REALTY, BROKER_CODES.PEMBERTON].includes(
                brokers.currentBroker?.template_code
              ) && <div className="empty-spacer" />}

              {/* Render the universal templates here */}
              <SocialTemplatePanel
                mlsId={mlsId}
                type={type}
                targetWidth={widthCol4}
                isTemplatesAreUsedForHardcodedBroker={[
                  BROKER_CODES.WHISTLER,
                  BROKER_CODES.MACDONALD_REALTY,
                  BROKER_CODES.PEMBERTON,
                ].includes(brokers.currentBroker?.template_code)}
              />
            </>
          );
        }}
      </Resizable>
    );
    let tempTab = this.state.activeTab;
    if (tempTab) {
      tempTab = Number(tempTab?.replace('#', ''));
    }
    const activeTab = this.props.location?.hash?.substring(1)
      ? this.props.location.hash.substring(1)
      : tempTab
      ? tempTab
      : 0;

    if (this.state.loading) {
      return (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      );
    }
    return (
      <DefaultLayout>
        <div className="padding--md">
          <Tab panes={panes(this.props)} activeIndex={activeTab} onTabChange={this.handleTabChange}></Tab>
        </div>
      </DefaultLayout>
    );
  }
}

// HACK: workaround the old component to use hooks instead of class
function TrackBrochureLoaded({ currentListing }) {
  useEffect(() => {
    track(BROCHURES_TAB_LOADED, { currentListing });
  }, []);

  return null;
}

// HACK: workaround the old component to use hooks instead of class
function TrackSocialMediaLoaded({ currentListing }) {
  useEffect(() => {
    track(SOCIAL_MEDIA_TAB_LOADED, { currentListing });
  }, []);

  return null;
}

const mapStateToProps = state => {
  const { searchedListing, currentListing, brokers, users } = state;
  return { searchedListing, currentListing, brokers, users };
};

export default connect(mapStateToProps, { setBothListing, clearBothListing })(withRouter(ListingDashboard));
