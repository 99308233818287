import React, { useState, useEffect, useCallback } from 'react';
import { Table, Checkbox, Container, Loader, Input } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';

import { patchCurrentBroker } from 'src/redux/actions/brokerActions';
import 'src/components/preview-list.scss';
import './manage-advertising-templates.scss';

import AdminLayout from 'src/layouts/admin-layout';
import debounce from 'lodash/debounce';

// const BASE_URL = process.env.REACT_APP_API;

const ManageAdvertisingTemplates = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { currentBroker } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
  }));
  const showAdTab = currentBroker.show_ad_tab;

  function handleShowAdTab(ev) {
    dispatch(patchCurrentBroker(currentBroker.id, { show_ad_tab: !showAdTab })).then(handleUpdateCurrentBroker);
  }

  const handleChangeAdTabMessage = useCallback(
    newValue => {
      dispatch(patchCurrentBroker(currentBroker.id, { ad_tab_message: newValue })).then(debouncedUpdateCurrentBroker);
    },
    [currentBroker.id]
  );

  function handleUpdateCurrentBroker(result) {
    console.log('update is here', result);
    if (result instanceof Error) {
      return addToast('Failed to update ad tab message', { appearance: 'error' });
    }
    addToast('Successfully updated ad tab setting', { appearance: 'success' });
  }

  const debouncedUpdateCurrentBroker = debounce(handleUpdateCurrentBroker, 500);

  console.log('currentBroker', currentBroker);

  return (
    <AdminLayout pageTitle="Manage Advertising Templates">
      <Container style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
        <Checkbox label="Show Ad Tab" checked={showAdTab} onChange={handleShowAdTab} />
        <Input
          style={{ marginTop: 10 }}
          label="Custom Message"
          value={currentBroker.ad_tab_message}
          onChange={ev => handleChangeAdTabMessage(ev.target.value)}
        />
      </Container>
    </AdminLayout>
  );
};

export default ManageAdvertisingTemplates;
