import React, { useState, useEffect } from 'react';
import { Container, Table, Loader, Tab } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import AdminLayout from 'src/layouts/admin-layout';

import './billing-history.scss';
import { getCustomerInvoiceList, getCustomerInvoicePendingItems } from 'src/redux/actions/stripeActions';
import { useTitle } from 'react-use';

const BillingHistory = props => {
  useTitle('Billing History - Realvault');

  const dispatch = useDispatch();
  const { invoiceHistory } = useSelector(state => state.stripe);
  const { currentUser, pendingItems } = useSelector(state => ({
    currentUser: state.users.currentUser,
    pendingItems: state.stripe.pendingItems,
  }));

  const [loading, setLoading] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
  });

  useEffect(() => {
    // make the api call here to filter
    setLoading(true);
    dispatch(
      getCustomerInvoiceList({
        user_id: currentUser?.id,
        start_date: filters.dateFrom ? new Date(filters.dateFrom).toISOString() : '',
        end_date: filters.dateTo ? new Date(filters.dateTo).toISOString() : '',
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log('ERR => while fetching invoice list');
      });

    dispatch(
      getCustomerInvoicePendingItems({
        user_id: currentUser?.id,
        start_date: filters.dateFrom ? new Date(filters.dateFrom).toISOString() : '',
        end_date: filters.dateTo ? new Date(filters.dateTo).toISOString() : '',
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log('ERR => while fetching invoice list');
      });
  }, [currentUser, dispatch, filters.dateFrom, filters.dateTo]);

  const panes = [
    {
      menuItem: 'Active Invoice',
      render: () => (
        <Tab.Pane>
          <div className="table-container">
            {loading ? (
              <div className="flex justify-center items-center">
                <Loader active={loading} />
              </div>
            ) : (
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Invoice Number</Table.HeaderCell>
                    <Table.HeaderCell>Invoice Date</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {invoiceHistory.length > 0 ? (
                  <Table.Body>
                    {invoiceHistory.map(history => (
                      <Table.Row key={history.id}>
                        <Table.Cell>{history.id}</Table.Cell>
                        <Table.Cell>{new Date(history.period_start * 1000).toDateString()}</Table.Cell>
                        <Table.Cell>${(history.total / 100).toFixed(2)}</Table.Cell>
                        <Table.Cell className="status-cell">{history.status}</Table.Cell>
                        <Table.Cell>
                          <a target="_blank" href={history.invoice_pdf} className="download_invoice_link">
                            Download Invoice
                          </a>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                ) : (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan="5">
                        <p className="my-4 text-center">No invoice data found!</p>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                )}
              </Table>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Pending Invoice Items',
      render: () => (
        <Tab.Pane>
          {loading ? (
            <div className="flex justify-center items-center">
              <Loader active={loading} />
            </div>
          ) : (
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Invoice Date</Table.HeaderCell>
                  <Table.HeaderCell>Quantity</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {pendingItems.length > 0 ? (
                <Table.Body>
                  {pendingItems.map(history => (
                    <Table.Row key={history.id}>
                      <Table.Cell>{`${new Date(history.period.start * 1000).toDateString()} - ${new Date(
                        history.period.end * 1000
                      ).toDateString()}`}</Table.Cell>
                      <Table.Cell textAlign="center">{history.quantity}</Table.Cell>
                      <Table.Cell>{history.description}</Table.Cell>
                      <Table.Cell>
                        {history.amount < 0 ? '-' : ''} ${(Math.abs(history.amount) / 100).toFixed(2)}
                      </Table.Cell>
                      <Table.Cell className="status-cell">{history.amount < 0 ? 'Credit' : 'Debit'}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="5">
                      <p className="my-4 text-center">No pending invoice data found!</p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <AdminLayout pageTitle="Billing History">
      <Container>
        <div className="billing-history-container">
          <div className="date-filters-container">
            <p className="mr-4">Search Date Range:</p>
            <input
              type="date"
              className="mr-4 date-input"
              value={filters.dateFrom}
              max={filters.dateTo}
              onChange={event => {
                const { value } = event.target;
                setFilters(prev => ({
                  ...prev,
                  dateFrom: value,
                }));
              }}
            />
            <p className="mr-4">To:</p>
            <input
              type="date"
              className="date-input"
              value={filters.dateTo}
              min={filters.dateFrom}
              onChange={event => {
                const { value } = event.target;
                setFilters(prev => ({
                  ...prev,
                  dateTo: value,
                }));
              }}
            />
          </div>
          <Tab
            panes={panes}
            activeIndex={activeTabIndex}
            onTabChange={(e, { activeIndex }) => setActiveTabIndex(activeIndex)}
          />
        </div>
      </Container>
    </AdminLayout>
  );
};

export default BillingHistory;
