import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import InstagramTemplate1 from 'src/templates/instagram/macdonald/instagram1';

class Instagram1 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout templateKey={InstagramTemplate1._name} type={type} listing={currentListing}>
        <Scale
          width={InstagramTemplate1._width}
          height={InstagramTemplate1._height}
          scale={0.5}
          style={{ outline: 'grey 1px solid', margin: 'auto' }}
        >
          <InstagramTemplate1 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(Instagram1);
