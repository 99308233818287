import React from 'react';
import './horizontal-window-poster.scss';
import AgentBranding from 'src/components/agent-branding';
import Image from 'src/components/image';
import Scale from 'src/components/scale';
import Logo from 'src/images/macdonald/macdonald-realty-logo.png';
import ListingAreaType from 'src/components/listing-area-type';
import EditTextModal from 'src/components/edit-text-modal';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import UniversalLegalText from 'src/templates/brochures/fragments/common/universal-legal-text';

const HorizontalWindowPoster = ({
  listing,
  twoAgents = false,
  mergeCurrentListing,
  marginRightTitle = ' ',
  customStyleForAgentBrandingBroacher5 = false,
}) => {
  return (
    <div
      className="mac-horizontal-window-poster no-hover"
      style={{
        position: 'relative',
      }}
    >
      <div className="logo-fixed">
        <img src={Logo} width="130" height="106" base64="true" alt="logo" />
      </div>
      <div
        className="listing-intro"
        style={{
          paddingTop: '20px',
          marginRight: marginRightTitle,
        }}
      >
        <ListingAreaType
          mergeCurrentListing={mergeCurrentListing}
          listing={listing}
          onlyTitle={true}
          hideAddress={false}
          font={'LuxiaR, Verdana, Tahoma'}
        />
      </div>
      <div className="photo-layout">
        <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={350} height={260} />
        <Image imgKey="1" url={listing.photos[1] || PlaceHolderImage2} width={350} height={260} />
      </div>
      <div className="info-photo-layout">
        <div className="listing-desc no-hover">
          <div className="address">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontSize: 17,
                fontFamily: "'UbuntuM', Verdana, Tahoma",
                color: 'var(--mac-font-color)',
                lineHeight: '1em',
                width: '100%',
              }}
              value={
                listing.location.streetAddress.length > 30
                  ? listing.location.streetAddress.slice(0, 30)
                  : listing.location.streetAddress
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="content-layout">
            <div className="description">
              <textarea
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  height: '99px',
                  overflow: 'hidden',
                  resize: 'none',
                  wordBreak: 'break-word',
                  fontFamily: "'UbuntuL', Verdana, Tahoma",
                  lineHeight: '15px',
                  fontSize: '9px',
                }}
                value={listing.description.length >= 624 ? listing.description.slice(0, 624) : listing.description}
                onChange={e => {
                  mergeCurrentListing({
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="details">
              <div className="item" style={{ display: 'flex', alignItems: 'baseline' }}>
                <b
                  style={{
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    marginRight: '8px',
                  }}
                >
                  BEDS:
                </b>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    color: '#3d3d3d',
                  }}
                  value={listing.details.qtyBedrooms}
                  onChange={e => {
                    if (e.target.value.length < 3) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyBedrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="item" style={{ display: 'flex', alignItems: 'baseline' }}>
                <b
                  style={{
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    marginRight: '3px',
                  }}
                >
                  BATHS:
                </b>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    color: '#3d3d3d',
                  }}
                  value={listing.details.qtyFullBathrooms}
                  onChange={e => {
                    if (e.target.value.length < 3) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyFullBathrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="item" style={{ display: 'flex', alignItems: 'baseline' }}>
                <b
                  style={{
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    marginRight: '8px',
                  }}
                >
                  SQ FT:
                </b>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    color: '#3d3d3d',
                  }}
                  value={listing.details.squareFeet}
                  onChange={e => {
                    if (e.target.value.length < 9) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          squareFeet: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="item" style={{ display: 'flex', alignItems: 'baseline' }}>
                <b style={{ marginRight: '12px', fontFamily: "'UbuntuM', Verdana, Tahoma" }}>PRICE:</b> $
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                    color: '#3d3d3d',
                  }}
                  value={
                    listing.price.priceDisplay.length > 11
                      ? listing.price.priceDisplay.slice(0, 11)
                      : listing.price.priceDisplay
                  }
                  onChange={e => {
                    if (e.target.value.length < 11) {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/*<div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={0} triggerTitle="Edit Details" />
            </Scale.Back>
          </div>*/}
        </div>
      </div>
      {twoAgents ? (
        <div className="two-agents">
          <AgentBranding
            mergeCurrentListing={mergeCurrentListing}
            agents={listing.agents}
            showLogo={false}
            customMarginWindows={true}
            activeTab={1}
          />
          <AgentBranding
            coAgent={true}
            listing={listing.agents}
            mergeCurrentListing={mergeCurrentListing}
            agents={{
              agentImageUrl: listing.agents.coAgentImageUrl,
              agentName: listing.agents.coAgentName,
              agentTitle: listing.agents.coAgentTitle,
              agentPhone: listing.agents.coAgentPhone,
              agentEmail: listing.agents.coAgentEmail,
              agentWebsite: listing.agents.coAgentWebsite,
              agentLogo: listing.agents.coAgentLogo,
            }}
            showLogo={false}
            activeTab={2}
          />
        </div>
      ) : (
        <AgentBranding
          customStyleForAgentBrandingBroacher5={customStyleForAgentBrandingBroacher5}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
          showLogo={false}
          customJustifyContent
        />
      )}
      <div
        className="legal"
        style={{
          position: 'absolute',
          fontFamily: "'UbuntuR', Verdana, Tahoma",
          marginTop: '0px',
          bottom: '-18px',
        }}
      >
        <UniversalLegalText />
      </div>
    </div>
  );
};

export default HorizontalWindowPoster;
