import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';

import './WhistlerFacebookTemplate4.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_FACEBOOK_4';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class WhistlerFacebookTemplate4 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, image2, image3, address } = props;

    const { photos, location } = currentListing;

    const { streetAddress, city } = location;

    const largePhotos = photos;

    if (!image1) image1 = largePhotos[0] || PlaceHolderImage1;
    if (!image2) image2 = largePhotos[1] || PlaceHolderImage2;
    if (!image3) image3 = largePhotos[2] || PlaceHolderImage3;

    if (!address) address = `${streetAddress}, ${city}`;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      address,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, image2, image3, address } = this.state;
    WhistlerFacebookTemplate4._cached = { headline, image1, image2, image3, address };

    return (
      <WhistlerSocialTemplate className="whistler-facebook-4-wrapper">
        <div className="multi-photo-layout">
          <div className="two-photo-container">
            <EditableImage
              self={this}
              fieldName="image2"
              className="photo"
              size="cover"
              position={EditableImage.CENTER}
              pictures={currentListing.pictures}
              imgKey={1}
            />
            <EditableImage
              self={this}
              fieldName="image3"
              className="photo"
              size="cover"
              position={EditableImage.CENTER}
              pictures={currentListing.pictures}
              imgKey={2}
            />
          </div>
          <EditableImage
            self={this}
            fieldName="image1"
            className="image1"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={0}
          />
        </div>
        <div className="details-container">
          <div className="border"></div>
          <div className="content">
            <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
            <EditableInput
              self={this}
              className="address"
              fieldName="address"
              placeholder="#123 - 656 W Georgia St Vancouver"
            />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerFacebookTemplate4._name = TEMPLATE_NAME;
WhistlerFacebookTemplate4._width = TEMPLATE_WIDTH;
WhistlerFacebookTemplate4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerFacebookTemplate4, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerFacebookTemplate4);
