export const LOGIN = '/auth/login';
export const REFRESH_TOKEN = '/refresh';
export const LOGOUT = '/auth/logout';

export const REGISTER_USER = '/user/register';
export const USER = userId => (userId ? `/user/${userId}` : '/user');
export const USERS = '/user?status=1';

export const BROKER = brokerId => (brokerId ? `/broker/${brokerId}` : '/broker');

export const MLS = searchTerm => `/mls/${searchTerm}`;
export const MLS_ID = searchTerm => `/mls/${searchTerm}/listingID?`;

export const CREA_MLS = searchTerm => `/crea/${searchTerm}`;
export const CREA_MLS_WITH_LISTING_OFFICE_KEY = (listingKey, searchTerm) => `/creasearch/${listingKey}/${searchTerm}`;

export const VIVA_MLS = searchTerm => `/viva/${searchTerm}`;
export const VIVA_MLS_WITH_LISTING_OFFICE_KEY = (listingKey, searchTerm) => `/vivasearch/${listingKey}/${searchTerm}`;

export const PDF = '/pdf';
