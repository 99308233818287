import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure6 from 'src/templates/brochures/macdonald/MacdonaldBrochure6';

class MacdonaldBrochurePage6 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure6._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure6._width} height={MacdonaldBrochure6._height} pages={1} scale={1.5}>
          <MacdonaldBrochure6 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage6);
