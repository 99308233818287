import React, { Component } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import Routes from './routes/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createRequestHeader } from './services/request-headers';
import { setCurrentBroker } from 'src/redux/actions/brokerActions';
import { setCurrentUser } from 'src/redux/actions/userActions';
import 'react-image-lightbox/style.css';
import { identify, LOGIN_EVENT, track, updateGroup } from './utils/analytics';

class App extends Component {
  state = {
    loading: false,
  };

  async componentDidMount() {
    const refresh_token = localStorage.getItem('refresh_token');
    if (refresh_token) {
      try {
        const response = await this.getNewTokens(refresh_token);
        localStorage.setItem('jwt', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);

        const decodedToken = jwt.decode(response.data.access_token);
        if (decodedToken.user_id) {
          this.setState({ loading: true });
          await this.handleFetchUser(decodedToken.user_id);
        } else {
          localStorage.removeItem('jwt');
          localStorage.removeItem('refresh_token');
        }
      } catch (error) {
        localStorage.removeItem('jwt');
        localStorage.removeItem('refresh_token');
      }
    }
  }

  getNewTokens(refresh_token) {
    return axios.post(`${process.env.REACT_APP_API}/auth/refresh`, { refresh_token });
  }

  handleFetchUser = id => {
    this.setState({ loading: true });
    return axios
      .get(`${process.env.REACT_APP_API}/user/${id}`, createRequestHeader())
      .then(res => {
        this.props.setCurrentUser(res.data);

        const user = res.data;
        console.log('Logrocket user identified', user);
        identify(user.id, {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,

          broker_id: user.broker_id,
        });
        track(LOGIN_EVENT);

        if (res.data.broker_id) {
          this.handleFetchBroker(res.data.broker_id);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        localStorage.removeItem('jwt');
      });
  };

  handleFetchBroker = id => {
    return axios
      .get(`${process.env.REACT_APP_API}/broker/${id}`, createRequestHeader())
      .then(res => {
        this.setState({ loading: false });
        this.props.setCurrentBroker(res.data);

        updateGroup(id, res.data);
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  render() {
    return <Routes />;
  }
}

const mapStateToProps = state => {
  const { users } = state;
  return { users };
};

const mapDispatchToProps = dispatch => bindActionCreators({ setCurrentUser, setCurrentBroker }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
