import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlaceholderImage from 'src/images/banner.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { staticBrokerFonts } from 'src/utils/generic-utils';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';

import './FacebookTemplate2.scss';

const TEMPLATE_NAME = 'Facebook_Template_2';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class VantageWestFacebookTemplate2 extends Component {
  constructor(props) {
    super(props);

    let {
      currentListing,
      headline,
      image1,
      image2,
      image3,
      address,
      listingDetails,
      adjustedHeight,
      adjustedHeightAdr,
      defaultURL,
    } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;

    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      address,
      listingDetails,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing, defaultSettings, appliedTheme, currentBroker } = this.props;
    const { headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = this.state;
    VantageWestFacebookTemplate2._cached = {
      headline,
      image1,
      address,
      listingDetails,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="vw-social-2-wrapper">
        <div className="main-section">
          <div className="grid-section">
            <div className="grid-left">
              <div className="image-container-1">
                <EditableImage
                  self={this}
                  fieldName="image1"
                  className="image"
                  size="cover"
                  position={EditableImage.CENTER}
                  pictures={currentListing.pictures}
                  imgKey={0}
                />
              </div>
              <div className="image-container-2">
                <EditableImage
                  self={this}
                  fieldName="image2"
                  className="image"
                  size="cover"
                  position={EditableImage.CENTER}
                  pictures={currentListing.pictures}
                  imgKey={1}
                />
              </div>
            </div>
            <div className="grid-right">
              <EditableImage
                self={this}
                fieldName="image3"
                className="image"
                size="cover"
                position={EditableImage.CENTER}
                pictures={currentListing.pictures}
                imgKey={2}
              />
            </div>
          </div>
          <div className="text-section">
            <div
              className="text-grid"
              style={{
                borderLeftColor: this.props.appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
              }}
            >
              <ApplyFont fontName={this.props.appliedTheme?.font || defaultSettings?.font}>
                <EditableInput
                  self={this}
                  className="headline"
                  fieldName="headline"
                  placeholder="Text Here"
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.medium,
                    color: this.props.appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
                  }}
                />
                {/* <p>123 x 456 Government St. Victoria</p> */}
                <EditableTextarea
                  self={this}
                  onHeightChange={v => (VantageWestFacebookTemplate2._cached.adjustedHeightAdr = v)}
                  initialHeight={adjustedHeightAdr}
                  className="address"
                  fieldName="address"
                  placeholder="#123 - 456 W Georgia St Vancouver"
                  rows={1}
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.regular,
                  }}
                />
              </ApplyFont>
            </div>
            <img
              src={this.props.defaultURL || defaultSettings?.horizontalLogo || PlaceholderImage}
              className="logo-image"
              alt="brand-logo"
            />
          </div>
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

VantageWestFacebookTemplate2._name = TEMPLATE_NAME;
VantageWestFacebookTemplate2._width = TEMPLATE_WIDTH;
VantageWestFacebookTemplate2._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: VantageWestFacebookTemplate2, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps)(VantageWestFacebookTemplate2);
