import React from 'react';
import './universal-listing-info.scss';

import Image from 'src/components/image';
import Scale from 'src/components/scale';
import ListingAreaType from 'src/components/listing-area-type';
import UniversalAgentBranding from 'src/templates/brochures/fragments/universal/universal-agent-branding';
import EditTextModal from 'src/components/edit-text-modal';
import BackgroundImage from 'src/components/background-image';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';

import EditableImageV2 from 'src/components/editable-image-v2';

const UniversalListingInfo = ({
  headerLogo,
  footerLogo,
  listing,
  moreImages = false,
  hasAgentBanner = false,
  dark = false,
  layout = 'default',
  twoAgents = false,
  mergeCurrentListing,
  theme,
  font,
}) => {
  const themeName = theme || localStorage.getItem('themeClassName');
  let SecondImages = null;

  if (moreImages) {
    SecondImages = (
      <div className="photo-layout-2">
        <div>
          <Image imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={175} height={108} />
        </div>
        <div>
          <Image imgKey="4" id="four" url={listing.photos[4] || PlaceHolderImage4} width={175} height={108} />
        </div>
        <div>
          <Image imgKey="5" url={listing.photos[5] || PlaceHolderImage5} width={175} height={108} />
        </div>
      </div>
    );
  } else {
    SecondImages = (
      <div className="photo-layout-2">
        <div>
          <Image id="five" imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={266} height={160} />
        </div>
        <div>
          <Image imgKey="4" url={listing.photos[4] || PlaceHolderImage4} width={266} height={160} />
        </div>
      </div>
    );
  }

  return (
    <div className="listing-info">
      {/* group for equal spacing */}
      <div>
        <div
          className="listing-info--header"
          style={{
            backgroundColor: theme,
            marginBottom: 8,
          }}
        >
          <EditableImageV2
            fieldName="logo"
            className="logo"
            alt="Brand Logo"
            size="contain"
            style={{ width: 95, height: 65, position: 'relative' }}
            position={EditableImageV2.CENTER}
            buttonSize={'tiny'}
            buttonText={''}
            src={listing.pictures[101] || headerLogo}
            onUpdate={url => {
              const pictures = [...listing.pictures];
              pictures[101] = url;
              mergeCurrentListing({
                pictures,
              });
            }}
          />
          <div className="listing-intro">
            <ListingAreaType font={font} theme={theme} mergeCurrentListing={mergeCurrentListing} listing={listing} />
          </div>
        </div>
        <div className={`photo-layout-1 ${layout}`} style={{ marginBottom: 8 }}>
          <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={357} height={225} />
          <div className="stacked">
            <Image imgKey="1" url={listing.photos[1] || PlaceHolderImage2} width={175} height={108} />
            <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage3} width={175} height={109} />
          </div>
        </div>
        {SecondImages}
      </div>
      <div className="" style={{ width: '100%' }}>
        <div className="listing-desc">
          <div className="address" style={{ width: 'auto', flex: 1 }}>
            <div>
              <input
                className="mobile-p"
                style={{
                  width: '100%',
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  fontFamily: font,
                }}
                value={
                  listing.location.streetAddress.length > 30
                    ? listing.location.streetAddress.slice(0, 30)
                    : listing.location.streetAddress
                }
                onChange={e => {
                  if (e.target.value.length < 30) {
                    mergeCurrentListing({
                      location: {
                        ...listing.location,
                        streetAddress: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="price" style={{ fontFamily: font }}>
            <div>
              <span style={{ marginRight: 8 }}>LIST PRICE</span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  textAlign: 'left',
                  fontFamily: font,
                  width: '75px',
                }}
                value={listing.price.priceDisplay}
                onChange={e => {
                  mergeCurrentListing({
                    price: {
                      ...listing.price,
                      priceDisplay: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="description">
          <textarea
            className="mobile-p"
            style={{
              width: '100%',
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              height: '105px',
              resize: 'none',
              fontFamily: font,
              wordBreak: 'break-word',
              lineHeight: '14px',
            }}
            value={listing.description.length > 802 ? listing.description.slice(0, 802) : listing.description}
            onChange={e => {
              mergeCurrentListing({
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
      {hasAgentBanner ? (
        <div className="agent-banner-container">
          <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
          {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
            <span className="empty-placeholder not-in-pdf"></span>
          )}
          <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <div style={{ position: 'relative', left: '-27%' }}>
                <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
              </div>
            </Scale.Back>
          </div>
        </div>
      ) : twoAgents ? (
        <div className="two-agents">
          <UniversalAgentBranding
            theme={theme}
            font={font}
            mergeCurrentListing={mergeCurrentListing}
            agents={listing.agents}
            showLogo={false}
            activeTab={1}
          />
          <UniversalAgentBranding
            theme={theme}
            font={font}
            mergeCurrentListing={mergeCurrentListing}
            agents={{
              agentImageUrl: listing.agents.coAgentImageUrl,
              agentName: listing.agents.coAgentName,
              agentTitle: listing.agents.coAgentTitle,
              agentPhone: listing.agents.coAgentPhone,
              agentEmail: listing.agents.coAgentEmail,
              agentWebsite: listing.agents.coAgentWebsite,
              agentLogo: listing.agents.coAgentLogo,
            }}
            showLogo={false}
            activeTab={2}
          />
        </div>
      ) : (
        <UniversalAgentBranding
          theme={theme}
          font={font}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
          showLogo={false}
        />
      )}
    </div>
  );
};

export default UniversalListingInfo;
