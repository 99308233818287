import React from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Container, Segment, Button, Header, Menu, Dropdown } from 'semantic-ui-react';
import _ from 'src/lodash';
import PostFacebook from 'src/components/social-post-facebook';
import SocialDownload from 'src/components/social-download';
import ApplyTheme from 'src/components/ApplyTheme';
import AITextGenerationButton from 'src/components/AITextGenerationButton';
import './template-actions.scss';
import { connect } from 'react-redux';

const SocialLayout = ({
  defaultURL,
  templateKey,
  className,
  children,
  listing,
  postFacebook = false,
  match,
  messageInterface,
}) => {
  let header = '';
  const history = useHistory();

  // if (listing) {
  //   header = `${_.titleCase(templateKey)}`;
  // } else {
  //   header = `${_.titleCase(templateKey)}`;
  // }

  const dropdownOptions = [
    {
      key: 1,
      content: <SocialDownload defaultURL={defaultURL} templateKey={templateKey} />,
      selected: false, // prevent default selection styling on dropdown.
      active: false, // prevent active class from being added.
    },
  ];

  if (postFacebook) {
    dropdownOptions.push({
      key: 2,
      content: <PostFacebook defaultURL={defaultURL} templateKey={templateKey} />,
      active: false,
    });
  }

  return (
    <Container className={className} style={{ width: '1250px' }}>
      <Segment basic>
        <div className="mt-1 nowrap">
          <Header>{header}</Header>
        </div>
        <div className="d-flex justify-content-between">
          <div className="template-actions mt-1 nowrap">
            <AITextGenerationButton isSocial/>
            <ApplyTheme />
          </div>
          <div className="template-actions mt-1 nowrap">
            <Menu compact>
              <Dropdown
                text="Share"
                color="standard"
                options={dropdownOptions}
                simple
                item
                disabled={messageInterface.isVisible}
              />
            </Menu>
            <Button color="black" onClick={history.goBack} disabled={messageInterface.isVisible}>
              Back To Templates
            </Button>
          </div>
        </div>
      </Segment>
      {children}
    </Container>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default withRouter(connect(mapStateToProps)(SocialLayout));
