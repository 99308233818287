import {
  SET_CURRENT_BROKER,
  CLEAR_CURRENT_BROKER,
  STORE_BROKERS,
  SET_SOCIAL_TEMPLATES,
  SET_BROUCHERS_TEMPLATES,
  SET_ALL_BROKER_PRINT_TEMPLATES,
  SET_ALL_BROKER_SOCIAL_TEMPLATES,
  SET_PRINT_TEMPLATES_BY_BROKER_ID,
  SET_SOCIAL_TEMPLATES_BY_BROKER_ID,
} from '../../constants/action-types';

const initialState = {
  currentBroker: {},
  brokers: [],
  broucherTemplates: [],
  socialTemplates: [],
  allBrokerPrintTemplates: [],
  allBrokerSocialTemplates: [],
  allowedBrokerPrintTemplates: [],
  allowedBrokerSocialTemplates: [],
};

export const brokerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_BROKER: {
      const { broker } = action.payload;
      return {
        ...state,
        currentBroker: broker,
      };
    }
    case CLEAR_CURRENT_BROKER: {
      return {
        ...state,
        currentBroker: {},
      };
    }
    case STORE_BROKERS: {
      const { brokers } = action.payload;
      return {
        ...state,
        brokers,
      };
    }
    case SET_BROUCHERS_TEMPLATES: {
      const { broucherTemplates } = action.payload;
      return {
        ...state,
        broucherTemplates,
      };
    }
    case SET_SOCIAL_TEMPLATES: {
      const { socialTemplates } = action.payload;
      return {
        ...state,
        socialTemplates,
      };
    }
    case SET_ALL_BROKER_PRINT_TEMPLATES:
      return {
        ...state,
        allBrokerPrintTemplates: action.payload.allBrokerPrintTemplates,
      };
    case SET_ALL_BROKER_SOCIAL_TEMPLATES:
      return {
        ...state,
        allBrokerSocialTemplates: action.payload.allBrokerSocialTemplates,
      };
    case SET_PRINT_TEMPLATES_BY_BROKER_ID:
      return {
        ...state,
        allowedBrokerPrintTemplates: action.payload.allowedBrokerPrintTemplates,
      };
    case SET_SOCIAL_TEMPLATES_BY_BROKER_ID:
      return {
        ...state,
        allowedBrokerSocialTemplates: action.payload.allowedBrokerSocialTemplates,
      };
    default:
      return state;
  }
};
