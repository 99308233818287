import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import once from 'lodash/once';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import snakeCase from 'lodash/snakeCase';
import trim from 'lodash/trim';
import debounce from 'lodash/debounce';

const titleCase = v => startCase(lowerCase(v));

export default {
  cloneDeep,
  isArray,
  isObject,
  isString,
  isEmpty,
  map,
  omit,
  once,
  reduce,
  startCase,
  lowerCase,
  snakeCase,
  titleCase,
  trim,
  debounce,
};
