import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBothListing } from '../redux/actions/actions';
import PropTypes from 'prop-types';
import './search-result.scss';
import { LISTING_RESULT_SELECTED_EVENT, track } from 'src/utils/analytics';

const SearchResult = ({ searchResult, linkTo, setBothListing }) => {
  const handleSelectListing = () => {
    track(LISTING_RESULT_SELECTED_EVENT, { searchResult: searchResult });
    setBothListing(searchResult);
  };

  const renderAddress = () => {
    const city = searchResult.location.city === 'n/a' ? '' : `, ${searchResult.location.city}`;
    const prov = searchResult.location.stateCode === 'n/a' ? '' : `, ${searchResult.location.stateCode}`;
    return `${searchResult.location.streetAddress}${city}${prov}`;
  };

  return (
    <Link to={`${linkTo}/${searchResult.ID}`}>
      <Button className="select-listing-btn" onClick={handleSelectListing}>
        <div className="search-result">
          <Icon name="map pin" />
          {searchResult.isExact && (
            <Icon size="large" name="check circle outline" className="exact-match" title="Exact Search Match" />
          )}
          {renderAddress()}
          <br />
          <div className="desc">
            <Icon name="edit outline" />
            {searchResult.description}
          </div>
        </div>
      </Button>
    </Link>
  );
};

SearchResult.propTypes = {
  searchResult: PropTypes.shape({
    description: PropTypes.string.isRequired,
    location: PropTypes.shape({
      streetAddress: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      stateCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
  linkTo: PropTypes.string.isRequired,
};
const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps, { setBothListing })(SearchResult);
