import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import AdvertisingLayout from 'src/layouts/advertising-layout';

const BaseAdvertisePage = ({ Component, scale, pages, defaultURL, footerURL, currentListing }) => {
  return (
    <AdvertisingLayout
      defaultURL={defaultURL}
      footerURL={footerURL}
      templateKey={Component._name}
      currentListing={currentListing}
    >
      <Scale width={Component._width} height={Component._height} pages={pages} scale={scale}>
        <Component defaultURL={defaultURL} footerURL={footerURL} />
      </Scale>
    </AdvertisingLayout>
  );
};

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(BaseAdvertisePage);
