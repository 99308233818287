import React from 'react';
import './universal-half-listing-feature.scss';
import Image from 'src/components/image';
import { default as formatValue, formatType } from 'src/utils/format-na-value';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';

import EditableImageV2 from 'src/components/editable-image-v2';

const UniversalHalfListingFeature = ({ listing, mergeCurrentListing, theme, font, headerLogo, footerLogo }) => {
  const baths =
    listing.details.qtyHalfBathrooms === 'n/a'
      ? `${formatValue(listing.details.qtyFullBathrooms)}/0`
      : `${formatValue(listing.details.qtyFullBathrooms)}/${formatValue(listing.details.qtyHalfBathrooms)}`;
  return (
    <div className="half-listing-feature">
      <div className="half-listing-feature--header" style={{ backgroundColor: theme }}>
        <EditableImageV2
          fieldName="logo"
          className="logo"
          alt="Brand Logo"
          size="contain"
          style={{ width: 95, height: 65, position: 'relative', marginTop: -10 }}
          position={EditableImageV2.CENTER}
          buttonSize={'tiny'}
          buttonText={''}
          src={ listing.pictures[100] || headerLogo}
          onUpdate={url => {
            const pictures = [...listing.pictures];
            pictures[100] = url;
            mergeCurrentListing({
              pictures,
            });
          }}
        />
      </div>
      <div className="flex">
        <Image
          imgKey="5"
          url={listing.photos[5] || PlaceHolderImage1}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <Image imgKey="6" url={listing.photos[6] || PlaceHolderImage2} width={266} height={200} margin={'8px 0 0'} />
      </div>
      <div className="flex">
        <Image
          imgKey="7"
          url={listing.photos[7] || PlaceHolderImage3}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <div className="features no-hover">
          <div
            className="title"
            style={{
              fontFamily: font,
            }}
          >
            Features:
          </div>
          <div
            className="feature-list "
            style={{
              fontFamily: font,
            }}
          >
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Price:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={
                    listing.price.priceDisplay.length > 11
                      ? listing.price.priceDisplay.slice(0, 11)
                      : listing.price.priceDisplay
                  }
                  onChange={e => {
                    if (e.target.value.length < 11) {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">Sub-area:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={listing.details.neighborhood}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">MLS® #:</span>
              <span data-test-id="mls-id" style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={listing.mlsId}
                  onChange={e => {
                    mergeCurrentListing({
                      mlsId: e.target.value,
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">Bedrooms:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={formatValue(listing.details.qtyBedrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">Baths (Full/Half):</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '10px',
                    fontFamily: font,
                  }}
                  value={formatValue(listing.details.qtyFullBathrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }}
                />
                /{' '}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '10px',
                    marginLeft: '5px',
                    fontFamily: font,
                  }}
                  value={formatValue(listing.details.qtyHalfBathrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyHalfBathrooms: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">Year Built:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={listing.details.yearBuilt}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        yearBuilt: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex', fontFamily: font }}>
              <span className="feature-name">Gross Taxes:</span>
              <span style={{ display: 'flex' }}>
                {formatType.CURRENCY}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily: font,
                  }}
                  value={
                    formatValue(listing.details?.grossTax)?.length > 6
                      ? formatValue(listing.details?.grossTax).slice(0, 6)
                      : formatValue(listing.details?.grossTax)
                  }
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        grossTax: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            {/* <div className="edit-btn-hover not-in-pdf">
              <EditTextModal activeTab={0} triggerTitle="Edit Features" />
            </div>*/}
          </div>
        </div>
      </div>
      <div className="flex">
        <Image
          imgKey="8"
          url={listing.photos[8] || PlaceHolderImage4}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <Image imgKey="9" url={listing.photos[9] || PlaceHolderImage5} width={266} height={200} margin={'8px 0'} />
      </div>
    </div>
  );
};

export default UniversalHalfListingFeature;
