import { USER_ROLES } from 'src/constants/permissions';

export const accountSettingsSidebarData = [
  {
    title: 'Account Settings',
    path: '/account',
    redirect: '/account/information',
    allowRender: () => true,
    subNavs: [
      {
        title: 'Account Information',
        path: '/account/information',
      },
    ],
  },
  {
    title: 'Manage Brand',
    path: '/themes/default',
    allowRender: ({ currentUser }) => currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN,
  },
  {
    title: 'Manage Users',
    path: '/manage-users',
    allowRender: ({ currentUser }) =>
      [USER_ROLES.BROKER_ADMIN, USER_ROLES.SYSTEM_ADMIN].includes(currentUser?.user_role_id),
  },
  {
    title: 'Feed Settings',
    path: '/feed-settings',
    allowRender: ({ currentUser, currentBroker }) =>
      currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN &&
      (currentBroker?.feed_type?.trim()?.toLowerCase() === 'cre' ||
        currentBroker?.feed_type?.trim()?.toLowerCase() === 'viva'),
  },
  {
    title: 'Manage Templates',
    path: '/templates',
    redirect: '/templates/social',
    allowRender: ({ currentUser }) => currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN,
    subNavs: [
      {
        title: 'Social Media',
        path: '/templates/social',
      },
      {
        title: 'Print',
        path: '/templates/print',
      },
      {
        title: 'Advertising',
        path: '/templates/advertising',
      },
    ],
  },
  {
    title: 'Manage Themes',
    path: '/themes',
    redirect: '/themes/default',
    allowRender: ({ currentUser }) => currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN,
    subNavs: [
      {
        title: 'Additional Themes',
        path: '/themes/additional',
      },
    ],
  },
  {
    title: 'Billing',
    path: '/billing',
    redirect: '/billing/information',
    allowRender: ({ currentUser, currentBroker }) =>
      (currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN ||
        currentUser?.user_role_id === USER_ROLES.SYSTEM_ADMIN) &&
      (!currentBroker.manual_billing || currentBroker.manual_billing.toString() !== '1') &&
      currentBroker.stripe_subscription_id,
    subNavs: [
      {
        title: 'Billing Information',
        path: '/billing/information',
      },
      {
        title: 'Billing History',
        path: '/billing/history',
      },
      {
        title: 'Current Plan',
        path: '/billing/plan',
      },
    ],
  },
];
