import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Header, Form, Grid, Loader, Button } from 'semantic-ui-react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import COUNTRIES from 'src/utils/countries.json';

import './payment.scss';
import { PLAN_TITLES } from './select-pricing';
import { createStripeSubscription, makeUserActiveInactive, changeManualBilling } from 'src/redux/actions/stripeActions';
import { loginUser } from 'src/redux/actions/userActions';
import { setAllTemplatesToBrokerId } from 'src/redux/actions/brokerActions';
import { couponValiadate } from 'src/redux/actions/stripeActions';
import axios from 'axios';
import config from '../../utils/config';
import { refreshUser } from 'src/redux/actions/userActions';
import { useTitle } from 'react-use';
import { ONBOARDING_PAYMENT_SUCCESS_EVENT, track } from 'src/utils/analytics';

export const STRIPE_GST_PERCENT = 5;

const Payment = ({ selectedPlan, basicDetails, paymentDetails, setPaymentDetails, isUserAlreadyOnboarded }) => {
  useTitle('Payment - Realvault');

  const [formErrors, setFormErrors] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState({
    isApplied: false,
    code: '',
    fieldTouched: false,
  });

  useEffect(() => {
    setPromoCode(prev => ({
      ...prev,
      code: '',
      fieldTouched: false,
    }));
  }, [promoCode.isApplied]);

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    brokerOnboarding: { basicDetailsResponse },
    user,
  } = useSelector(state => ({
    brokerOnboarding: state.stripe.brokerOnboarding,
    user: state.users.currentUser,
  }));

  // load the stripe
  const stripe = useStripe();
  const elements = useElements();

  // payment amounts
  const stripePlanAmount = parseFloat(((selectedPlan?.amount || 999) / 100).toFixed(2));
  const totalTaxAmount = parseFloat(((stripePlanAmount * STRIPE_GST_PERCENT) / 100).toFixed(2));
  const totalSubscriptionAmount = stripePlanAmount + totalTaxAmount;

  let defultImageBaseUrl = '';
  if (process.env.REACT_APP_TEMPLATE_API === 'https://dev-api.covault.app') {
    defultImageBaseUrl = 'https://covault-dev-bastion.s3.ca-central-1.amazonaws.com';
  } else if (process.env.REACT_APP_TEMPLATE_API === 'https://staging-api.covault.app') {
    defultImageBaseUrl = 'https://realvault-app-staging.s3.ca-central-1.amazonaws.com';
  } else {
    defultImageBaseUrl = 'https://realvault-app.s3.ca-central-1.amazonaws.com';
  }

  const [defaultUrl, setdefaultUrl] = useState(defultImageBaseUrl + config.defaultImgUrl);
  const options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const checkFormValidation = () => {
    const errors = {};
    const { street, city, province, postal_code, country, ...fieldsToValidate } = paymentDetails;
    let validateFields;
    if (paymentDetails.address_type === 'same') {
      validateFields = fieldsToValidate;
    } else {
      validateFields = paymentDetails;
    }
    // const { street, city, province, postal_code, country, ...fieldsToValidate } = paymentDetails;
    Object.entries(validateFields).forEach(([key, value]) => {
      errors[key] = {
        valid: !!value,
        message: 'This is a required field',
      };
    });

    setFormErrors(errors);
    const hasErrors = Object.values(errors).some(field => field.valid === false);
    return !hasErrors;
  };

  useEffect(() => {
    async function getLogo() {
      const localBasicDetails = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
      let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
      return resp?.data?.logo;
    }

    getLogo().then(res => {
      if (res) {
        let newArr = [...res];
        newArr.forEach(v => {
          if (v.selected === true) {
            console.log('v.url', v.url);
            setdefaultUrl(v.url);
          }
        });
      }
    });
  }, []);

  const onSubscriptionSuccess = () => {
    track(ONBOARDING_PAYMENT_SUCCESS_EVENT, { basicDetails });

    if (basicDetails?.password) {
      addToast('Subscription Success! Logging into the system now!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    setLoading(false);
    // enable all the templates for the broker

    dispatch(
      setAllTemplatesToBrokerId(basicDetailsResponse?.broker_id, {
        default_URL: defaultUrl,
      })
    );

    const alreadyLoggedIn = user?.email;
    const action = alreadyLoggedIn
      ? refreshUser()
      : loginUser({
          email: basicDetails?.email,
          password: basicDetails?.password,
        });

    // login the user directly
    dispatch(action)
      .then(res => {
        localStorage.removeItem('broker_onboarding_step');
        history.push('/dashboard');
        console.log('Login Now response', res);
      })
      .catch(err => {
        console.error('ERR => while logging in', err);
      });
  };

  const onSubscriptionFailure = message => {
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
    });
    setLoading(false);
  };

  const onSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    setLoading(true);

    const billing_details = {
      address: {
        line1: paymentDetails?.street,
        line2: '',
        city: paymentDetails?.city,
        country: paymentDetails?.country,
        state: paymentDetails?.province,
        postal_code: paymentDetails?.postal_code,
      },
      email: basicDetails?.email,
      name: paymentDetails?.name_on_card,
      phone: basicDetails?.phone_number,
    };

    // Use your card Element with other Stripe.js APIs

    try {
      const { error, token } = await stripe.createToken(cardElement, {
        name: paymentDetails?.name_on_card,
        currency: 'USD',
        address_city: paymentDetails?.city,
        address_country: paymentDetails?.country,
        address_line1: paymentDetails?.street,
        address_line2: '',
        address_state: paymentDetails?.province,
        address_zip: paymentDetails?.postal_code,
      });
      const token_id = token ? token?.id : '';

      if (error) {
        onSubscriptionFailure(error.message);
        return;
      }

      dispatch(
        createStripeSubscription({
          user_id: isUserAlreadyOnboarded
            ? user.id
            : basicDetailsResponse
            ? basicDetailsResponse.user_id
            : JSON.parse(localStorage.getItem('broker_onboarding_basic_details')).user_id,
          card_token: token_id,
          price_id: selectedPlan
            ? selectedPlan.id
            : JSON.parse(localStorage.getItem('broker_onboarding_selected_plan')).id,
          address: billing_details.address,
        })
      )
        .then(response => {
          if (response.status === 'OK') {
            if (response.payment_status === 'requires_action') {
              setLoading(false);
              console.log(response, 'Response from the createStripeSubscription api here');

              // call confirm the payment
              stripe
                .confirmCardPayment(response.client_secret, {
                  payment_method: {
                    card: cardElement,
                    billing_details,
                  },
                })
                .then(response => {
                  console.log(response, 'response from the confirmCardPayment api');
                  if (response.error) {
                    onSubscriptionFailure(response.error.message);
                    return;
                  }

                  if (response.paymentIntent) {
                    dispatch(
                      makeUserActiveInactive({
                        user_id: basicDetailsResponse?.user_id,
                        user_status_id: '2', // BROKER_ADMIN
                      })
                    ).then(response => {
                      console.log(response, 'Confirm Payment response');
                      onSubscriptionSuccess();
                    });
                  }
                });
            } else {
              onSubscriptionSuccess();
            }
          } else if (response.status === 'fail') {
            onSubscriptionFailure(response.msg);
          }
        })
        .catch(err => {
          setLoading(false);
          console.error('Err => while creating stripe subscription', err);
        });
    } catch (e) {
      console.error('Error => while creating stripe subscription', e);
      onSubscriptionFailure('Invalid Card Details');
      setLoading(false);
    }
  };

  const onApplyPromoCode = () => {
    // verify the promo code is valid or not!
    setLoading(true);
    dispatch(
      couponValiadate({
        coup_code: promoCode.code,
      })
    )
      .then(response => {
        console.log(response, 'response of verify coupon code');
        if (response.status === 'fail') {
          addToast(response.message || 'Cannot use this coupon code!', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
          return;
        } else if (response.status === 'ok') {
          if (response.data.limit_left > 0) {
            let localData = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
            dispatch(
              changeManualBilling({
                broker_id: localData?.broker_id,
                user_id: localData?.user_id,
                coup_code: promoCode.code,
                manual_billing: '1',
              })
            )
              .then(resp => {
                setLoading(false);
                console.log(resp, 'Response of change manual billing');
                onSubscriptionSuccess();
              })
              .catch(err => {
                setLoading(false);
                console.log('ERR => while changing manual billing', err);
              });
          } else {
            addToast('Coupon code limit exceeds!', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoading(false);
          }
        }
      })
      .catch(err => {
        setLoading(false);
        console.log('ERR => while verifying coupon code');
      });
  };

  return (
    <div className="payment-onboarding">
      <div className="w-full md:w-4/5 mx-auto">
        <div className="mx-auto">
          <div className="md:flex bg-white shadow-md rounded-sm">
            <div className="w-full md:w-2/3 p-6 md:p-8 lg:p-12 left-sidebar">
              <Header className="payment-header" as="h3">
                Payment Details
              </Header>

              <div className="form-container">
                <Form>
                  <Button
                    onClick={() => setPromoCode(prev => ({ ...prev, isApplied: !prev.isApplied }))}
                    style={{
                      marginBottom: '1rem',
                      backgroundColor: promoCode.isApplied ? undefined : '#9BD315',
                      color: promoCode.isApplied ? undefined : '#fff',
                    }}
                  >
                    {promoCode.isApplied ? 'Back To Payment Info' : 'Use Promo Code'}
                  </Button>

                  {!promoCode.isApplied ? (
                    <React.Fragment>
                      <Form.Input
                        error={
                          !paymentDetails.name_on_card && formErrors?.name_on_card?.valid === false
                            ? { content: formErrors?.name_on_card?.message }
                            : false
                        }
                        fluid
                        required={true}
                        placeholder="Name On Card"
                        onChange={e => setPaymentDetails({ ...paymentDetails, name_on_card: e.target.value })}
                        value={paymentDetails.name_on_card}
                        label="Name on card"
                      />
                      <Form.Field className="form-field" required>
                        <label>Card Number (Credit or Debit)</label>
                        <div className="input-container">
                          <CardNumberElement options={options} />
                          <i className="fa fa-credit-card card-icon"></i>
                        </div>
                      </Form.Field>
                      <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Form.Field className="form-field" required>
                            <label>Expiration Date</label>
                            <div className="input-container">
                              <CardExpiryElement options={options} />
                            </div>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Form.Field className="form-field" required>
                            <label>CVC / CVN</label>
                            <div className="input-container">
                              <CardCvcElement options={options} />
                            </div>
                          </Form.Field>
                        </Grid.Column>
                      </Grid>
                      <div className="mt-8">
                        <Form.Input
                          error={
                            !paymentDetails.street && formErrors?.street?.valid === false
                              ? { content: formErrors?.street?.message }
                              : false
                          }
                          fluid
                          required={true}
                          placeholder="Street"
                          onChange={e => setPaymentDetails({ ...paymentDetails, street: e.target.value })}
                          value={paymentDetails.street}
                          label="Street"
                        />
                      </div>
                      <div className="mt-6">
                        <Grid>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Form.Input
                              required
                              error={
                                !paymentDetails.city && formErrors?.city?.valid === false
                                  ? { content: formErrors?.city?.message }
                                  : false
                              }
                              fluid
                              placeholder="City"
                              onChange={e => setPaymentDetails({ ...paymentDetails, city: e.target.value })}
                              value={paymentDetails.city}
                              label="City"
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Form.Input
                              required
                              error={
                                !paymentDetails.postal_code && formErrors?.postal_code?.valid === false
                                  ? { content: formErrors?.postal_code?.message }
                                  : false
                              }
                              fluid
                              placeholder="Postal Code"
                              onChange={e => setPaymentDetails({ ...paymentDetails, postal_code: e.target.value })}
                              value={paymentDetails.postal_code}
                              label="Postal Code"
                            />
                          </Grid.Column>
                        </Grid>
                      </div>
                      <div>
                        <Grid>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Form.Dropdown
                              required
                              error={
                                !paymentDetails.country && formErrors?.country?.valid === false
                                  ? { content: formErrors?.country?.message }
                                  : false
                              }
                              placeholder="Select Country"
                              fluid
                              search
                              selection
                              options={COUNTRIES.map(country => ({
                                key: country.iso2,
                                value: country.iso2,
                                text: country.name,
                              }))}
                              value={paymentDetails.country}
                              label="Select Country"
                              onChange={(e, data) => {
                                const country = COUNTRIES.find(country => country.iso2 === data.value);
                                setProvinces(
                                  country?.states.map(state => ({
                                    key: state.id,
                                    value: state.state_code,
                                    text: state.name,
                                  })) || []
                                );
                                setPaymentDetails({ ...paymentDetails, country: data.value });
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Form.Dropdown
                              required
                              error={
                                !paymentDetails.province && formErrors?.province?.valid === false
                                  ? { content: formErrors?.province?.message }
                                  : false
                              }
                              placeholder="Province"
                              fluid
                              search
                              selection
                              options={provinces}
                              value={paymentDetails.province}
                              label="Province"
                              onChange={(e, data) => setPaymentDetails({ ...paymentDetails, province: data.value })}
                            />
                          </Grid.Column>
                        </Grid>
                      </div>
                      <div className="text-center mt-16">
                        <OutlinedButton
                          onClick={() => {
                            const isValid = checkFormValidation();
                            if (isValid) {
                              onSubmit();
                            }
                          }}
                          style={{
                            border: '1px solid #233349',
                            backgroundColor: '#233349',
                            color: 'white',
                            textTransform: 'uppercase',
                          }}
                        >
                          {loading ? (
                            <div className="flex justify-center items-center">
                              <Loader active size="mini" inline inverted />
                            </div>
                          ) : (
                            'Pay Now'
                          )}
                        </OutlinedButton>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="mt-8">
                      <Form.Input
                        error={
                          promoCode.fieldTouched && !promoCode.code ? { content: 'Promo Code is required!' } : false
                        }
                        fluid
                        required={true}
                        placeholder="Enter Promo Code"
                        onChange={e => {
                          const { value } = e.target;
                          setPromoCode(prev => ({
                            ...prev,
                            code: value,
                          }));
                        }}
                        onBlur={() =>
                          setPromoCode(prev => ({
                            ...prev,
                            fieldTouched: true,
                          }))
                        }
                        value={promoCode.code}
                        label="Promo Code"
                      />
                      <div className="text-center">
                        <OutlinedButton
                          onClick={onApplyPromoCode}
                          style={{
                            border: '1px solid #233349',
                            backgroundColor: '#233349',
                            color: 'white',
                            textTransform: 'uppercase',
                          }}
                        >
                          {loading ? (
                            <div className="flex justify-center items-center">
                              <Loader active size="mini" inline inverted />
                            </div>
                          ) : (
                            'Apply and Continue'
                          )}
                        </OutlinedButton>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            </div>
            <div className="bg-red-600 w-full md:w-1/3 p-6 p-6 md:p-8 lg:p-12 right-sidebar">
              <Header className="subscription-header" as="h3">
                Subscription
              </Header>

              {!promoCode.isApplied ? (
                <div className="subscription-details-container text-sm">
                  <div className="mb-8">
                    <div className="flex justify-between items-center">
                      <span>{selectedPlan?.plan_title || PLAN_TITLES[0]} Plan</span>
                      <span>${stripePlanAmount}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>{STRIPE_GST_PERCENT}% GST:</span>
                      <span>${totalTaxAmount}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center font-bold">
                    <span>TOTAL (monthly):</span>
                    <span>${totalSubscriptionAmount}</span>
                  </div>
                  <div className="mt-12 text-right text-xs">All payments in CDN dollars.</div>
                </div>
              ) : (
                <div className="subscription-details-container text-sm">Promo code applied!</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
