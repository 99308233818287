import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Loader, Modal, Form, Radio, Icon } from 'semantic-ui-react';
import { getThemeByBroker } from 'src/redux/actions/theme-creator';
import { SET_APPLY_THEME } from 'src/redux/reducers/theme-creator';
import './apply-theme.scss';

const AvailableThemes = ({ messageInterface }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const dispatch = useDispatch();
  const { currentBroker, allThemes } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allThemes: state.theme.allThemes,
  }));

  const fetchAllThemesForBroker = () => {
    dispatch(getThemeByBroker(currentBroker.id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (modalVisible) {
      setLoading(true);
      fetchAllThemesForBroker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const onApplyTheme = () => {
    dispatch({
      type: SET_APPLY_THEME,
      payload: selectedTheme,
    });
    setModalVisible(false);
  };

  const onDiscardTheme = () => {
    setSelectedTheme(null);
    dispatch({
      type: SET_APPLY_THEME,
      payload: null,
    });
    setModalVisible(false);
  };

  const onThemeChange = (e, { value }) => {
    setSelectedTheme(allThemes.find(theme => theme.id === value));
  };

  const renderThemeData = () => {
    if (allThemes?.length === 0) {
      return (
        <div className="flex justify-center items-center">
          <div>
            <p>No theme added yet. Create your first one</p>
            <div className="text-center mt-4">
              <Link to="/system-admin#1" className="ui button">
                Create Theme
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Form>
        {allThemes.map(theme => (
          <Form.Field key={theme.id}>
            <div className="d-flex align-items-center color-container">
              <div
                className="color-div"
                style={{
                  backgroundColor: theme.backgroundColor,
                }}
              ></div>
              <Radio
                label={theme.name}
                name="themeData"
                value={theme.id}
                checked={selectedTheme?.id === theme.id}
                onChange={onThemeChange}
              />
            </div>
          </Form.Field>
        ))}
        <div className="text-left mt-4">
          <Link to="/system-admin#1">+ Add New Theme</Link>
        </div>
      </Form>
    );
  };

  return (
    <>
      <Button
        className="color-white"
        color="blue"
        onClick={() => setModalVisible(true)}
        disabled={messageInterface.isVisible}
      >
        Change Theme
      </Button>

      <Modal dimmer="inverted" open={modalVisible} onClose={() => {}}>
        <Modal.Header>Select a theme to apply</Modal.Header>
        <Modal.Content>
          {loading ? (
            <div className="flex justify-center items-center loading-container">
              <Loader active={loading} />
            </div>
          ) : (
            renderThemeData()
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={onDiscardTheme}>
            <Icon name="remove" />
            Discard
          </Button>
          <Button color="green" onClick={onApplyTheme}>
            <Icon name="check" />
            Apply Theme
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps)(AvailableThemes);
