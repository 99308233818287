import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import ListingGuard from 'src/components/listing-guard';
import { BROKER_CODES } from 'src/constants/permissions';
import Instagram1 from './instagram1';
import Instagram2 from './instagram2';
import Instagram3 from './instagram3';
import Instagram4 from './instagram4';
import PageNotFound from 'src/pages/PageNotFound';
import DefaultLayout from 'src/layouts/default-layout';
import { useTitle } from 'react-use';
import { SOCIAL_MEDIA_TEMPLATE_SELECTED, track } from 'src/utils/analytics';

const InstagramRoute = () => {
  useTitle('Instagram Template - Realvault');
  const { id: templateId, mlsId } = useParams();
  useEffect(() => {
    track(SOCIAL_MEDIA_TEMPLATE_SELECTED, { templateId, mlsId });
  }, [templateId]);

  return (
    <DefaultLayout>
      <ListingGuard>
        {({ searchedListing, type, templateId, brokers }) => {
          if (!searchedListing) return <Redirect to={`/search/${templateId || ''}`} />;

          if (brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY) {
            switch (templateId) {
              case '1':
                return <Instagram1 type={type} />;
              case '2':
                return <Instagram2 type={type} />;
              case '3':
                return <Instagram3 type={type} />;
              case '4':
                return <Instagram4 type={type} />;
              default:
                return <Redirect to="/social" />;
            }
          } else {
            return <PageNotFound />;
          }
        }}
      </ListingGuard>
    </DefaultLayout>
  );
};

export default InstagramRoute;
