import React from 'react';
import { connect } from 'react-redux';
import '../brochures/reset.css';
import './UniversalPembertonBrochure3.scss';
import { register } from 'src/services/template-renderer';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import UniversalListingInfo from '../brochures/fragments/universal/universal-listing-info';
import UniversalFloorplan from '../brochures/fragments/universal/universal-floorplan';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import PlaceholderImage from 'src/images/banner.png';

const TEMPLATE_NAME = 'FEATURE_SHEET_WITH_DOUBLE_HEADER';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL  }) => {
  return (
    <div className={`scaled template pem-template-3 ${theme} page-2 ui-only-outline`}>
      <UniversalListingInfo
        headerLogo={defaultURL || PlaceholderImage}
        footerLogo={footerURL || PlaceholderImage}
        font={font}
        mergeCurrentListing={mergeCurrentListing}
        moreImages={true}
        listing={listing}
        layout="large"
        theme={theme}
      />
    </div>
  );
};

const Page2 = ({ listing, theme, mergeCurrentListing, font, defaultURL, footerURL }) => {
  return (
    <div className={`scaled template pem-template-3 ${theme} page-1 ui-only-outline`}>
      <div>
        <UniversalFloorplan
          headerLogo={defaultURL || PlaceholderImage}
          footerLogo={footerURL || PlaceholderImage}
          mergeCurrentListing={mergeCurrentListing}
          listing={listing}
          dark
          theme={theme}
          font={font}
        />
      </div>
    </div>
  );
};

const UniversalPembertonBrochure3 = ({
  searchedListing,
  currentListing,
  themeClassName,
  mergeCurrentListing,
  theme,
  ...rest
}) => {
  const customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  const backgroundColor = rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor;
  const fontName =
    rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular;

  return (
    <div>
      <ApplyFont fontName={fontName} />
      <Page1
        theme={backgroundColor}
        font={fontName}
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
      />
      <br className="not-in-pdf" />
      <Page2
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={backgroundColor}
        font={fontName}
      />
    </div>
  );
};

UniversalPembertonBrochure3._name = TEMPLATE_NAME;
UniversalPembertonBrochure3._width = TEMPLATE_WIDTH;
UniversalPembertonBrochure3._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: UniversalPembertonBrochure3,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return {
    searchedListing,
    currentListing,
    appliedTheme: theme.appliedTheme,
    currentBroker,
    defaultSettings: theme.defaultSettings,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalPembertonBrochure3);
