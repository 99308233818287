import React from 'react';
import { Container } from 'semantic-ui-react';

import './page-title.scss';

const PageTitle = ({ title, rightContent, hideContainer = false }) => {
  if (hideContainer) {
    return (
      <div className="page-title">
        <div>{title}</div>
        {rightContent}
      </div>
    );
  }
  return (
    <Container>
      <div className="page-title">
        <div>{title}</div>
        {rightContent}
      </div>
    </Container>
  );
};

export default PageTitle;
