import { batchActions } from 'redux-batched-actions';

import {
  SET_SEARCHED_LISTING,
  CLEAR_SEARCHED_LISTING,
  SET_CURRENT_LISTING,
  MERGE_CURRENT_LISTING,
  CLEAR_CURRENT_LISTING,
  ADD_UPLOADED_IMAGE,
  REMOVE_UPLOADED_IMAGE,
  CLEAR_UPLOADED_IMAGES,
} from '../../constants/action-types';

export const setSearchedListing = listing => ({
  type: SET_SEARCHED_LISTING,
  payload: {
    listing,
  },
});

export const clearSearchedListing = () => ({
  type: CLEAR_SEARCHED_LISTING,
});

export const setCurrentListing = listing => ({
  type: SET_CURRENT_LISTING,
  payload: {
    listing,
  },
});

export const mergeCurrentListing = partialListing => ({
  type: MERGE_CURRENT_LISTING,
  payload: {
    partialListing,
  },
});

export const clearCurrentListing = () => ({
  type: CLEAR_CURRENT_LISTING,
});

export const setBothListing = listing => {
  return (dispatch, getState) => {
    dispatch(batchActions([setSearchedListing(listing), setCurrentListing(listing)]));
  };
};

export const clearBothListing = listing => {
  return (dispatch, getState) => {
    dispatch(batchActions([clearSearchedListing(listing), clearCurrentListing(listing)]));
  };
};

export const addUploadedImage = imgUrl => ({
  type: ADD_UPLOADED_IMAGE,
  payload: {
    imgUrl,
  },
});

export const removeUploadedImage = imgUrl => ({
  type: REMOVE_UPLOADED_IMAGE,
  payload: {
    imgUrl,
  },
});

export const clearUploadedImages = () => ({
  type: CLEAR_UPLOADED_IMAGES,
});
