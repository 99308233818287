import React from 'react';
import './window-poster.scss';
import AgentBranding from 'src/components/agent-branding';
import Image from 'src/components/image';
import Scale from 'src/components/scale';

import Logo from 'src/images/pemberton/ph-stacked-white-logo.png';

import ListingAreaType from 'src/components/listing-area-type';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';

const WindowPoster = ({ listing, twoAgents = false, mergeCurrentListing, theme = null }) => {
  const themeName = theme || localStorage.getItem('themeClassName');
  return (
    <div className="window-poster">
      <div className="listing-info">
        <div className="listing-info--header">
          <div className="logo">
            <img src={Logo} width="80" base64="true" alt="logo" />
          </div>
          <div className="listing-intro">
            <ListingAreaType theme={theme} listing={listing} mergeCurrentListing={mergeCurrentListing} />
          </div>
        </div>
      </div>
      <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={540} height={320} />
      <div className="info-photo-layout ">
        <div className="listing-desc no-hover">
          <div className="address">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                color: themeName == 'green' ? '#112F23' : '#000',
                fontFamily: 'OvoR',
              }}
              value={
                listing.location.streetAddress.length > 30
                  ? listing.location.streetAddress.slice(0, 30)
                  : listing.location.streetAddress
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>

          <div className="description">
            <textarea
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                height: '130px',
                overflow: 'hidden',
                resize: 'none',
                wordBreak: 'break-word',
                lineHeight: '14px',
              }}
              value={listing.description.length > 645 ? listing.description.slice(0, 645) : listing.description}
              onChange={e => {
                mergeCurrentListing({
                  description: e.target.value,
                });
              }}
            />
          </div>

          <div className="details">
            <div className="item" style={{ display: 'flex', alignItems: 'baseline' }}>
              BEDS/BATHS:
              <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '15px',
                  }}
                  value={listing.details.qtyBedrooms}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }}
                />{' '}
                /{' '}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '15px',
                  }}
                  value={listing.details.qtyFullBathrooms}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="item" style={{ display: 'flex', alignItems: 'baseline', marginRight: '25px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>SQ FT:</span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '50px',
                }}
                value={listing.details.squareFeet}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      squareFeet: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <span style={{ whiteSpace: 'nowrap' }}>PRICE: $</span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.price.priceDisplay.length > 11
                    ? listing.price.priceDisplay.slice(0, 11)
                    : listing.price.priceDisplay
                }
                onChange={e => {
                  if (e.target.value.length < 30) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={0} triggerTitle="Edit Details" />
            </Scale.Back>
          </div>*/}
        </div>
        <div className="stacked">
          <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage2} width={175} height={103} />
          <Image imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={175} height={103} />
        </div>
      </div>
      {twoAgents ? (
        <div className="two-agents">
          <AgentBranding
            mergeCurrentListing={mergeCurrentListing}
            agents={listing.agents}
            showLogo={false}
            activeTab={1}
            customInfoPadding={true}
            customJustifyContent={true}
            theme={themeName === 'green' ? '#112F23' : '#000'}
            font="OvoR"
          />
          <AgentBranding
            mergeCurrentListing={mergeCurrentListing}
            theme={themeName === 'green' ? '#112F23' : '#000'}
            customInfoPadding={true}
            customJustifyContent={true}
            font="OvoR"
            agents={{
              agentImageUrl: listing.agents.coAgentImageUrl,
              agentName: listing.agents.coAgentName,
              agentTitle: listing.agents.coAgentTitle,
              agentPhone: listing.agents.coAgentPhone,
              agentEmail: listing.agents.coAgentEmail,
              agentWebsite: listing.agents.coAgentWebsite,
              agentLogo: listing.agents.coAgentLogo,
            }}
            listing={listing.agents}
            coAgent={true}
            showLogo={false}
            activeTab={2}
          />
        </div>
      ) : (
        <AgentBranding
          theme={themeName === 'green' ? '#112F23' : '#000'}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
          showLogo={false}
          font="OvoR"
          customInfoPadding={true}
          customJustifyContent={true}
        />
      )}
      <div className="legal">
        Pemberton Holmes. This communication is not intended to cause or induce breach of an existing agency agreement.
      </div>
    </div>
  );
};

export default WindowPoster;
