import React, { Component } from 'react';

class Resizable extends Component {
  constructor(props) {
    super(props);

    const { widthCol6 = 200, widthCol4 = 300, widthCol3 = 500 } = props;

    this.divRef = React.createRef();

    this.state = {
      widthCol6,
      widthCol4,
      widthCol3,
    };
  }
  componentDidMount() {
    this.resize(200);
    window.addEventListener('resize', this.handleDocumentResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDocumentResize);
  }

  handleDocumentResize = () => {
    this.resize();
  };

  resize = timeout => {
    if (!this.divRef.current) return;

    setTimeout(() => {
      try {
        const computedDiv = window.getComputedStyle(this.divRef.current);
        const divWidth = parseInt(computedDiv.getPropertyValue('width'), 10);
        this.setState({
          widthCol6: divWidth / 6,
          widthCol4: divWidth / 4,
          widthCol3: divWidth / 3,
        });
      } catch (err) {
        console.error(err);
      }
    }, timeout);
  };

  render() {
    const { children, className } = this.props;
    const { widthCol6, widthCol4, widthCol3 } = this.state;

    return (
      <div ref={this.divRef} className={className}>
        {children({ widthCol6, widthCol4, widthCol3 })}
      </div>
    );
  }
}

export default Resizable;
