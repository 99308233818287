import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from 'src/services/template-renderer';
import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import EditableInput from 'src/components/editable-input';
import PlaceholderImage from 'src/images/banner.png';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';

import './SocialTemplate1.scss';

const TEMPLATE_NAME = 'Revault_Social_Template_1';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class SocialTemplate1 extends Component {
  constructor(props) {
    super(props);

    let {
      currentListing,
      headline,
      image1,
      image2,
      image3,
      image4,
      address,
      adjustedHeight,
      adjustedHeightAdr,
      listingDetails,
      defaultURL,
    } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      image4,
      address,
      adjustedHeight,
      adjustedHeightAdr,
      listingDetails,
      defaultURL,
    };
  }

  render() {
    const { currentListing, appliedTheme, defaultSettings, currentBroker } = this.props;
    const { headline, image1, image2, image3, image4, listingDetails, address, adjustedHeight, adjustedHeightAdr } =
      this.state;

    SocialTemplate1._cached = {
      headline,
      image1,
      image2,
      image3,
      image4,
      listingDetails,
      address,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="vw-social-template-1-wrapper">
        <div className="header-image-container">
          <EditableImage
            self={this}
            fieldName="image1"
            className="image1"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={0}
          />
        </div>
        <img
          src={this.state.defaultURL || defaultSettings?.squareLogo || PlaceholderImage}
          className="logo-img"
          alt="brand-logo22"
        />
        <div className="sub-main">
          <div
            className="card-div"
            style={{
              backgroundColor: `${appliedTheme?.backgroundColor || defaultSettings?.primaryColor}`,
              fontFamily: appliedTheme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
              color: appliedTheme?.foregroundColor || defaultSettings?.secondaryColor,
            }}
          >
            <div
              className="inputs-container"
              style={{
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',

                backgroundColor: `${appliedTheme?.backgroundColor}` || `${defaultSettings?.primaryColor}`,
              }}
            >
              <ApplyFont fontName={appliedTheme?.font || defaultSettings?.font}>
                <EditableTextarea
                  self={this}
                  onHeightChange={v => (SocialTemplate1._cached.adjustedHeightAdr = v)}
                  initialHeight={adjustedHeightAdr}
                  className="address"
                  fieldName="address"
                  placeholder="#123 - 456 W Georgia St Vancouver"
                  rows={1}
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.medium,
                  }}
                />
                <EditableInput
                  self={this}
                  className="listingDetails"
                  fieldName="listingDetails"
                  placeholder="Bed | Bath"
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.regular,
                  }}
                />
              </ApplyFont>
            </div>
          </div>
          <div className="grid-image-container">
            <div className="grid">
              <EditableImage
                self={this}
                fieldName="image2"
                className="grid-image"
                size="cover"
                position={EditableImage.CENTER}
                pictures={currentListing.pictures}
                imgKey={1}
              />
            </div>
            <div className="grid">
              <EditableImage
                self={this}
                fieldName="image3"
                className="grid-image"
                size="cover"
                position={EditableImage.CENTER}
                pictures={currentListing.pictures}
                imgKey={2}
              />
            </div>
            <div className="grid">
              <EditableImage
                self={this}
                fieldName="image4"
                className="grid-image"
                size="cover"
                position={EditableImage.CENTER}
                pictures={currentListing.pictures}
                imgKey={3}
              />
            </div>
          </div>
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

SocialTemplate1._name = TEMPLATE_NAME;
SocialTemplate1._width = TEMPLATE_WIDTH;
SocialTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: SocialTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps)(SocialTemplate1);
