import axios from 'axios';
import jwt from 'jsonwebtoken';
import { createRequestHeader } from 'src/services/request-headers';
import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  STORE_USERS,
  UPDATE_CURRENT_USER_BROKER_ID,
} from '../../constants/action-types';
import { fetchCurrentBroker } from './brokerActions';

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: {
    user,
  },
});

export const updateCurrentUserBroker = data => ({
  type: UPDATE_CURRENT_USER_BROKER_ID,
  payload: { broker_id: data.broker_id },
});

export const clearCurrentUser = user => ({
  type: CLEAR_CURRENT_USER,
  payload: null,
});

export const storeUsers = users => ({
  type: STORE_USERS,
  payload: { users },
});

export const fetchCurrentUser = userId => dispatch => {
  return axios
    .get(`${process.env.REACT_APP_API}/user/${userId}`, createRequestHeader())
    .then(res => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: {
          user: res.data,
        },
      });
      return res;
    })
    .catch(error => error);
};

export const loginUser = loginInfo => dispatch => {
  return axios
    .post(`${process.env.REACT_APP_API}/auth/login`, loginInfo)
    .then(async res => {
      const decodedToken = jwt.decode(res.data.access_token);
      localStorage.setItem('jwt', res.data.access_token);
      localStorage.setItem('refresh_token', res.data.refresh_token);
      await dispatch(fetchCurrentUser(decodedToken.user_id));
      await dispatch(fetchCurrentBroker(decodedToken.broker_id));
      return res;
    })
    .catch(error => error);
};

export const refreshUser = () => dispatch => {
  const refresh_token = localStorage.getItem('refresh_token');
  return axios
    .post(`${process.env.REACT_APP_API}/auth/refresh`, { refresh_token })
    .then(async response => {
      const decodedToken = jwt.decode(response.data.access_token);
      localStorage.setItem('jwt', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);

      await dispatch(fetchCurrentUser(decodedToken.user_id));
      await dispatch(fetchCurrentBroker(decodedToken.broker_id));
      return response;
    })
    .catch(error => error);
};

export const isUserEmailExists = email => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/isUserEmailExists`,
      {
        email,
      },
      createRequestHeader()
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error;
    });
};
export const getAllUsers = brokerId => dispatch => {
  return axios
    .get(`${process.env.REACT_APP_API}/user?broker_id=${brokerId}`, createRequestHeader())
    .then(res => {
      dispatch({
        type: STORE_USERS,
        payload: { users: res.data },
      });
      return res.data;
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteUserById = userId => dispatch => {
  return axios.delete(`${process.env.REACT_APP_API}/user/${userId}`, createRequestHeader());
};
