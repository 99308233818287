import { combineReducers } from 'redux';
import { searchedListingReducer } from './searchedListingReducer';
import { currentListingReducer } from './currentListingReducer';
import { userReducer } from './userReducer';
import { brokerReducer } from './brokerReducer';
import { uploadedImagesReducer } from './uploadedImagesReducer';
import { documentReducer } from './documentReducer';
import { themeCreatorReducer } from './theme-creator';
import { stripeReducer } from './stripeReducer';
import { appPermissionReducer } from './appPermissionReducer';
import { templateStyleReducer } from './templateStyleReducer';
import { messageReducer } from './messageReducer';

export const rootReducer = combineReducers({
  searchedListing: searchedListingReducer,
  currentListing: currentListingReducer,
  users: userReducer,
  uploadedImages: uploadedImagesReducer,
  brokers: brokerReducer,
  documents: documentReducer,
  theme: themeCreatorReducer,
  stripe: stripeReducer,
  appPermissions: appPermissionReducer,
  templateStyle: templateStyleReducer,
  messageInterface: messageReducer,
});
