import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Stacked_Wordmark_Black.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerFacebookTemplate7.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_FACEBOOK_7';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class WhistlerFacebookTemplate7 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, detail, adjustedHeight, adjustedHeightAdr } = props;

    const { photos, location, details } = currentListing;
    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms } = details;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms || 0}   |   Bath: ${qtyFullBathrooms || 0}`;

    this.state = {
      headline,
      image1,
      address,
      detail,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, detail, adjustedHeight, adjustedHeightAdr } = this.state;
    WhistlerFacebookTemplate7._cached = { headline, image1, address, detail };

    return (
      <WhistlerSocialTemplate className="whistler-facebook-7-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
          rows={1}
        />
        <div className="overlay-dark"></div>
        <div className="content-wrapper">
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerFacebookTemplate7._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="headline"
            fieldName="headline"
            placeholder="Headline"
            rows={1}
          />
          <hr />
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerFacebookTemplate7._cached.adjustedHeightAdr = v)}
            initialHeight={adjustedHeightAdr}
            className="address"
            fieldName="address"
            placeholder="#123 - 656 W Georgia St Vancouver"
          />
          <EditableInput
            self={this}
            className="listingDetails"
            fieldName="detail"
            placeholder="#123 - 456 W Georgia St Vancouver"
          />
          <div className="logo">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerFacebookTemplate7._name = TEMPLATE_NAME;
WhistlerFacebookTemplate7._width = TEMPLATE_WIDTH;
WhistlerFacebookTemplate7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerFacebookTemplate7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerFacebookTemplate7);
