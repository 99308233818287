import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate11.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_11';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate11 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, agentDetails } = props;

    const { photos, location, agents } = currentListing;

    const { streetAddress, city } = location;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!agentDetails) agentDetails = `${agents.agentName}  |  ${agents.agentPhone}`;

    this.state = {
      headline,
      image1,
      address,
      agentDetails,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, agentDetails } = this.state;
    WhistlerSocialTemplate11._cached = { headline, image1, address, agentDetails };

    return (
      <WhistlerSocialTemplate className="whistler-social-11-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="template-background-image"
          size="cover"
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper overlay-dark">
          <div className="border"></div>
          <div className="content">
            <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
            <EditableInput
              self={this}
              className="address"
              fieldName="address"
              placeholder="#123 - 456 W Georgia St Vancouver"
            />
            <EditableInput
              self={this}
              className="agent-details"
              fieldName="agentDetails"
              placeholder="Agent Name | Agent Phone"
            />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate11._name = TEMPLATE_NAME;
WhistlerSocialTemplate11._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate11._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate11, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate11);
