import React from 'react';

import './outlined-inverse-button.scss';

const OutlinedInverseButton = ({ onClick, className, style = {}, children }) => {
  return (
    <div className={`outlined-inverse-btn ${className}`} onClick={onClick} style={style}>
      {children}
    </div>
  );
};

export default OutlinedInverseButton;
