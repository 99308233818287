import React from 'react';
import { Provider } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { store } from 'src/index';

const _templates = {};

export const register = (key, template) => {
  _templates[key] = template;
  return template;
};

export const get = key => {
  return _templates[key] || null;
};

export const render = async (key, data = {}, options = {}) => {
  const { Component, height, width, targetWidth, targetHeight } = get(key);
  const extra = options.useCache ? Component._cached || {} : {};
  const props = { ...data, ...extra };

  const html = renderToStaticMarkup(
    <Provider store={store}>
      <Component {...props} broker={{ template_code: data.template_code }} />
    </Provider>
  );

  return {
    html,
    height,
    width,
    props,
    targetWidth,
    targetHeight,
  };
};

export default { register, get, render };
