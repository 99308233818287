import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure4.scss';
import { register } from 'src/services/template-renderer';
import Floorplan from 'src/templates/brochures/fragments/macdonald/floorplan';
import ListingInfo from 'src/templates/brochures/fragments/macdonald/listing-info';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';

const TEMPLATE_NAME = 'MACDONALD_REALTY_FEATURE_SHEET_WITH_CUSTOM_AGENT_BANNER';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, mergeCurrentListing }) => {
  return (
    <div className="scaled template-dark template mac-template-4 page-2 ui-only-outline">
      <ListingInfo
        customImageHeight={true}
        mergeCurrentListing={mergeCurrentListing}
        moreImages={true}
        dark={true}
        layout="large"
        listing={listing}
        hasAgentBanner={true}
      />
    </div>
  );
};

const Page2 = ({ listing, mergeCurrentListing }) => {
  return (
    <div className="scaled template-dark template mac-template-3 page-1 ui-only-outline">
      <Floorplan mergeCurrentListing={mergeCurrentListing} dark={true} listing={listing} showAgentInfo={false} />
    </div>
  );
};

const MacdonaldBrochure4 = ({ searchedListing, currentListing, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <>
      <Page1 mergeCurrentListing={customMergeListing} listing={currentListing} />
      <br className="not-in-pdf" />
      <Page2 mergeCurrentListing={customMergeListing} listing={currentListing} />
    </>
  );
};

MacdonaldBrochure4._name = TEMPLATE_NAME;
MacdonaldBrochure4._width = TEMPLATE_WIDTH;
MacdonaldBrochure4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure4,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure4);
