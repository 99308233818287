import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import { Modal, Button, Form, Header, Image, Icon } from 'semantic-ui-react';
import FontPicker from 'font-picker-react';
import { useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2';

import FontPreview from 'src/components/FontPreview';
import './add-settings-modal.scss';
import { setDefaultSettings, getAllFonts } from 'src/redux/actions/theme-creator';
import { OldTemplates } from '../../constants/oldTemplates';

const AddSettingsModal = ({
  prevImgArray,
  setimgPreviewArray,
  setBtnLoading,
  open,
  close,
  initialValues,
  onChangeSettings,
  imgPreviewArray,
  setLogo,
  onRemoveLogo,
  btnLoading,
}) => {
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const { currentBroker, allFonts } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allFonts: state.theme.allFonts,
  }));
  let templateId = localStorage.getItem('templateId');
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(getAllFonts());
  }, [dispatch]);

  const [values, setValues] = useState({
    primaryColor: initialValues?.primaryColor || '#fffff',
    secondaryColor: initialValues?.secondaryColor || '#ffffff',
    font: initialValues?.font,
  });

  const FONT_OPTIONS = allFonts?.map(font => ({
    ...font,
    key: font.family,
    text: font.family,
    value: font.family,
  }));

  const changeFormHandler = (key, value) => {
    setTouched(true);
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onApply = async () => {
    await onChangeSettings2();
    await onChangeSettings();
  };
  const onChangeSettings2 = async () => {
    try {
      setValues(prev => ({
        ...prev,
      }));

      dispatch(
        setDefaultSettings(currentBroker.id, {
          ...values,
        })
      )
        .then(res => {
          setBtnLoading(false);
        })
        .catch(err => {
          setBtnLoading(false);
          addToast(err?.response?.data?.error, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log('ERR => while changing the default setting', err);
        });
    } catch (err) {
      setBtnLoading(false);
      addToast(err?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const onClose = () => {
    if (!touched) {
      close();
      return;
    }
    return Swal.fire({
      title: 'Save Theme Settings',
      text: 'Save Your changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E74942',
      customClass: { container: 'realvault-swal' },
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) {
        /*onRemoveLogo(imgPreviewArray.length-1,true)*/
        setimgPreviewArray(prevImgArray);
        close();
        return false;
      }
      return false;
    });
  };

  return (
    <Form onSubmit={onApply}>
      <Modal size="small" open={open} onClose={onClose} className="add-settings-modal">
        <Modal.Header>Add / Update Settings</Modal.Header>
        <Modal.Content>
          <div className="mb">
            <Form.Field>
              <Header as="h5">Select Your Color</Header>
              <div className={'color-picker-wrap'}>
                <SketchPicker
                  disableAlpha={false}
                  color={values.primaryColor}
                  onChangeComplete={color => changeFormHandler('primaryColor', color.hex)}
                  className="mr-8"
                />
                <div className="preview-color" style={{ background: values.primaryColor }}></div>
              </div>
            </Form.Field>
          </div>
          <Form.Field className="mt">
            <Header as="h5">Font Type</Header>
            <FontPicker
              apiKey="AIzaSyC2Q2CfcyWZAe4dABQncovnxXETju6ZPwE"
              placeholder="Choose a font type"
              activeFontFamily={values.font}
              onChange={font => changeFormHandler('font', font.family)}
            />
          </Form.Field>
          <div className="mt mb-8">
            <FontPreview name={values.font} />
          </div>

          {!OldTemplates.includes(templateId) ? (
            <Form.Field className="mt">
              <Header as="h5" className="upload-heading">
                Upload Logos
              </Header>
              <div className="file-wrap">
                <div className="flex justify-between items-center upload-img-field">
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    multiple
                    onChange={e => {
                      setTouched(true);
                      setLogo('brand', e);
                    }}
                  />
                  <i className="bx bx-plus"></i>
                </div>

                {imgPreviewArray.map((img, i) => {
                  return (
                    <div className="img-wrap-preview">
                      <Image id={img.id} key={i} className="uploaded-image" src={img.url} size="small" bordered />
                      <button
                        onClick={() => {
                          setTouched(true);
                          onRemoveLogo(i);
                        }}
                        className="remove-img"
                        type="button"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </div>
                  );
                })}
              </div>
            </Form.Field>
          ) : (
            ''
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={close}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={onApply} loading={btnLoading}>
            <Icon name="check" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    </Form>
  );
};

export default AddSettingsModal;
