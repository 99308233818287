import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './MacdonaldFacebookTemplate16.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_16';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class MacdonaldFacebookTemplate16 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1 } = props;

    const { photos } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    this.state = {
      headline,
      image1,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1 } = this.state;
    MacdonaldFacebookTemplate16._cached = { headline, image1 };

    return (
      <div className="mac-facebook-16-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableTextarea self={this} className="headline" fieldName="headline" placeholder="headline" />
        <hr />
        <div className="logo">
          <img src={Logo} width="90%" height="90%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate16._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate16._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate16._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate16, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebookTemplate16);
