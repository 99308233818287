import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import UpdatePhotoModal from 'src/components/update-photo-modal';
import BackgroundImage from 'src/components/background-image';
import Scale from 'src/components/scale';
import './editable-image.scss';

const EditableImage = ({
  self,
  fieldName,
  style = {},
  className = '',
  size = 'cover',
  opacity = 1,
  pictures = [],
  position = EditableImage.CENTER,
  imgKey,
  buttonSize = 'medium',
  buttonText = 'Change Photo',
}) => {
  // Photos are used in PDF downloads, Pictures are for reference
  const { photos } = self.props.currentListing || [];

  return (
    <div className={`editable-image ${className}`} style={style}>
      <BackgroundImage url={self.state[fieldName]} className="image-content" size={size} opacity={opacity} />
      <div className="upload not-in-pdf">
        <div className="upload-wrapper" />
        <UpdatePhotoModal
          photos={pictures}
          updatePhoto={url => {
            photos[imgKey] = url;
            self.setState({ [fieldName]: url });
          }}
          trigger={handleClick => (
            <Scale.Back className={`${position} upload-button-wrapper`}>
              <Button primary size={buttonSize} onClick={handleClick}>
                <Icon name="edit" />
                {buttonText}
              </Button>
            </Scale.Back>
          )}
        />
      </div>
    </div>
  );
};

EditableImage.TOP = 'top';
EditableImage.CENTER = 'center';
EditableImage.TOP_LEFT = 'top-left';
EditableImage.LEFT = 'left';

export default EditableImage;
