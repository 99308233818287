import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import PembertonBrochure5 from 'src/templates/brochures/pemberton/PembertonBrochure5';

class Brochure5 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={PembertonBrochure5._name} currentListing={currentListing}>
        <Scale width={PembertonBrochure5._width} height={PembertonBrochure5._height} pages={1} scale={1.5}>
          <PembertonBrochure5 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(Brochure5);
