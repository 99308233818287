import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure8 from 'src/templates/brochures/macdonald/MacdonaldBrochure8';

class MacdonaldBrochurePage8 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure8._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure8._width} height={MacdonaldBrochure8._height} pages={1} scale={1.5}>
          <MacdonaldBrochure8 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage8);
