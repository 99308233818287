import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure7.scss';
import { register } from 'src/services/template-renderer';
import HorizontalWindowPoster from 'src/templates/brochures/fragments/macdonald/horizontal-window-poster';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
const TEMPLATE_NAME = 'MACDONALD_REALTY_HORIZONTAL_WINDOW_SHEET_AGENT';
const TEMPLATE_WIDTH = 792;
const TEMPLATE_HEIGHT = 612;
const TARGET_TEMPLATE_WIDTH = 1056;
const TARGET_TEMPLATE_HEIGHT = 816;

const MacdonaldBrochure7 = ({ searchedListing, currentListing, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <div
      className="template mac-template-7 ui-only-outline"
      style={{
        paddingTop: '0px',
      }}
    >
      <HorizontalWindowPoster
        customStyleForAgentBrandingBroacher5={true}
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        twoAgents={false}
      />
    </div>
  );
};

MacdonaldBrochure7._name = TEMPLATE_NAME;
MacdonaldBrochure7._width = TEMPLATE_WIDTH;
MacdonaldBrochure7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure7,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure7);
