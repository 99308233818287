import React from 'react';
import Scale from 'src/components/scale';
import { connect } from 'react-redux';
import EditTextModal from 'src/components/edit-text-modal';
import './universal-agent-branding.scss';

import pembertonLogo from 'src/images/pemberton/ph-stacked-green-logo.png';
import LeadingCompany from 'src/images/macdonald/leadingCompany.png';
import WRCLogo from 'src/images/whistler/Stacked_Wordmark_Black.png';
import { BROKER_CODES } from 'src/constants/permissions';

import EditableImageV2 from 'src/components/editable-image-v2';

const UniversalAgentBranding = ({
  coAgent,
  listing,
  agents,
  showLogo = true,
  activeTab = 1,
  brokers,
  theme,
  mergeCurrentListing,
  footerLogo = null,
  brandLogo = null,
  font = '',
  agentPhotoCustomMargin = false,
  customWidthForCoAgent = false,
  agentNameCustomPadding = false,
  customStyleForAgentBrandingBroacher7 = false,
  customStyleForAgentBrandingBroacher5 = false,
  customTitlemargin = false,
  customTop = ' ',
  infopadding,
  customInfoPadding = false,
  customTopAgent = false,
  customHeightwindow = false,
  customJustifyContent = false,
  customHeightMain = false,
  alternateStyle = false,
}) => {
  const themeName = theme || localStorage.getItem('themeClassName');
  let themeLogo = brandLogo;
  let fontClassName = '';
  let fontFamilyName = '';
 
  return (
    <div
      className={`${fontClassName} universal-agent-branding no-hover ${showLogo ? '' : 'no-logo'} ${
        alternateStyle ? 'alternateStyle' : ''
      }`}
      style={{ top: customTopAgent ? '10px' : '', height: customHeightMain ? '110px' : '' }}
    >
      <div
        className="agent-photo"
        style={{
          font: '',
          backgroundImage: 'url(' + agents.agentImageUrl + ')',
          marginTop: agentPhotoCustomMargin ? '7px' : '0',
          top: customTop,
          height: customHeightwindow ? '110px' : '100px',
        }}
      >
        {(agents.agentImageUrl === undefined || agents.agentImageUrl.length < 5) && (
          <span className="empty-placeholder"></span>
        )}
        <div className="edit-btn-hover not-in-pdf">
          <Scale.Back>
            <EditTextModal activeTab={activeTab} triggerTitle="Edit Agent" />
          </Scale.Back>
        </div>
      </div>
      <div
        className={`agent-info ${infopadding}`}
        style={{
          width: customWidthForCoAgent ? '63%' : '',
          paddingTop: customInfoPadding ? '0' : '',
          justifyContent: customJustifyContent ? 'flex-start' : 'flex-end',
        }}
      >
        <div
          className={`agent-name ${alternateStyle ? 'alternateStyle' : ''}`}
          style={{ paddingTop: agentNameCustomPadding ? '4px' : '' }}
        >
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              color: theme || '#0054a5',
              fontFamily: font || "'UbuntuM', Verdana, Tahoma",
              width: '100%',
            }}
            value={coAgent ? listing?.coAgentName : agents.agentName}
            onChange={e => {
              if (coAgent) {
                if (e.target.value.length < 24) {
                  mergeCurrentListing({
                    agents: {
                      ...listing,
                      coAgentName: e.target.value,
                    },
                  });
                }
              } else {
                if (e.target.value.length < 24) {
                  mergeCurrentListing({
                    agents: {
                      ...agents,
                      agentName: e.target.value,
                    },
                  });
                }
              }
            }}
          />
        </div>
        <div
          className="agent-title"
          style={{ margin: (customStyleForAgentBrandingBroacher5 ? '0px 0' : '') || (customTitlemargin ? '1px' : '') }}
        >
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              fontFamily: font || "'UbuntuL', Verdana, Tahoma",
              width: '100%',
            }}
            maxLength={39}
            value={coAgent ? listing?.coAgentTitle?.slice(0, 39) : agents.agentTitle?.slice(0, 39)}
            onChange={e => {
              if (coAgent) {
                if (e.target.value.length < 40) {
                  mergeCurrentListing({
                    agents: {
                      ...listing,
                      coAgentTitle: e.target.value,
                    },
                  });
                }
              } else {
                if (e.target.value.length < 40) {
                  mergeCurrentListing({
                    agents: {
                      ...agents,
                      agentTitle: e.target.value,
                    },
                  });
                }
              }
            }}
          />
        </div>
        <div
          className={`agent-phone`}
          style={{ margin: (customStyleForAgentBrandingBroacher5 ? '0px 0' : '') || (customTitlemargin ? '1px' : '') }}
        >
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              fontFamily: font || "'UbuntuL', Verdana, Tahoma",
            }}
            value={coAgent ? listing?.coAgentPhone?.replace(/-/g, '.') : agents.agentPhone?.replace(/-/g, '.')}
            onChange={e => {
              if (coAgent) {
                if (e.target.value.length < 15) {
                  mergeCurrentListing({
                    agents: {
                      ...listing,
                      coAgentPhone: e.target.value,
                    },
                  });
                }
              } else {
                if (e.target.value.length < 15) {
                  mergeCurrentListing({
                    agents: {
                      ...agents,
                      agentPhone: e.target.value,
                    },
                  });
                }
              }
            }}
          />
        </div>
        <div className="agent-contact">
          <div style={{ display: 'flex', alignItems: 'baseline', marginottom: '2px' }}>
            <span
              style={{
                fontFamily: font || "'UbuntuM', Verdana, Tahoma",
              }}
            >
              E
            </span>
            <input
              type="email"
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font || "'UbuntuL', Verdana, Tahoma",
                width: '100%',
              }}
              value={coAgent ? listing?.coAgentEmail : agents.agentEmail}
              onChange={e => {
                if (coAgent) {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...listing,
                        coAgentEmail: e.target.value,
                      },
                    });
                  }
                } else {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...agents,
                        agentEmail: e.target.value,
                      },
                    });
                  }
                }
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span
              style={{
                fontFamily: font || "'UbuntuM', Verdana, Tahoma",
              }}
            >
              W
            </span>
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font || "'UbuntuL', Verdana, Tahoma",
                width: '100%',
              }}
              value={
                coAgent
                  ? listing?.coAgentWebsite?.replace(/https:\/\/|http:\/\//, '')
                  : agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '')
              }
              onChange={e => {
                if (coAgent) {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...listing,
                        coAgentWebsite: e.target.value,
                      },
                    });
                  }
                } else {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...agents,
                        agentWebsite: e.target.value,
                      },
                    });
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      {showLogo && (
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: 190,
          }}
        >
          <EditableImageV2
            fieldName="logo"
            alt="Agent branding"
            size="contain"
            style={{ width: 135, height: 95, position: 'relative' }}
            position={EditableImageV2.CENTER}
            buttonSize={'tiny'}
            buttonText={''}
            src={agents.pictures?.[103] || footerLogo || themeLogo}
            onUpdate={url => {
              const pictures = [];
              pictures[103] = url;
              mergeCurrentListing({
                agents: {
                  ...agents,
                  pictures,
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers };
};

export default connect(mapStateToProps)(UniversalAgentBranding);
