import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { USER_ROLES } from 'src/constants/permissions';
import logo from 'src/images/logo/revault-icon-blue.png';
import { uploadBrokerageBanner } from 'src/redux/actions/brokerActions';
import { saveUploadedBrandLogo } from 'src/redux/actions/theme-creator';
import { fetchCurrentBroker } from 'src/redux/actions/brokerActions';
import { removeBrokerageBanner } from 'src/redux/actions/brokerActions';
import CropperModal from './cropper-modal';
import './brokerage-logo.scss';

const BrokerageLogo = ({ imageUrl = '' }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [mainImg, setMainImg] = useState(null);
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));

  const [updateLogoLoading, setUpdateLogoLoading] = useState(false);
  const [removeLogoLoading, setRemoveLogoLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isCurrentUserAdmin = [USER_ROLES.SYSTEM_ADMIN.toString(), USER_ROLES.BROKER_ADMIN.toString()].includes(
    currentUser?.user_role_id?.toString()
  );

  const onApply = (file, dataURL) => {
    if (file) {
      setShowModal(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('broker_id', currentBroker?.id);
      formData.append('user_id', currentUser?.id);

      setUpdateLogoLoading(true);

      dispatch(uploadBrokerageBanner(formData))
        .then(resp => {
          const logoId = resp.data?.file_id;

          dispatch(
            saveUploadedBrandLogo({
              broker_id: currentBroker?.id,
              logo_id: logoId,
            })
          )
            .then(async res => {
              addToast('Brokerage logo updated successfully!', {
                appearance: 'success',
                autoDismiss: true,
              });
              // fetch the current broker
              await dispatch(fetchCurrentBroker(currentBroker?.id));

              setUpdateLogoLoading(false);
              setShowModal(false);
              setMainImg(dataURL);
            })
            .catch(err => {
              setUpdateLogoLoading(false);
              setShowModal(false);
              addToast('Failed to update brokerage logo!', {
                appearance: 'error',
                autoDismiss: true,
              });
              console.log('Err => while uploading brand logo', err);
            });
        })
        .catch(err => {
          setUpdateLogoLoading(false);
          addToast('Failed to update brokerage logo!', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  const onRemovePhoto = () => {
    setRemoveLogoLoading(true);
    dispatch(removeBrokerageBanner(currentBroker?.id))
      .then(response => {
        setRemoveLogoLoading(false);
        setShowModal(false);
        addToast('Brokerage image removed successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(err => {
        setRemoveLogoLoading(false);
        setShowModal(false);
        console.log('ERR => while removing image', err);
        addToast('Failed to remove brokerage logo!', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  if (updateLogoLoading) {
    return (
      <div className="flex justify-center items-center">
        <Loader active={updateLogoLoading} inline size="small" />
      </div>
    );
  }

  if (!isCurrentUserAdmin) {
    return (
      <div className="brokerage-logo">
        <img src={imageUrl || logo} width={imageUrl ? '150' : '120'} alt="logo" className="inline-block" />
      </div>
    );
  }

  if (!isCurrentUserAdmin) {
    return (
      <div className="brokerage-logo">
        <img src={imageUrl || logo} alt="logo" className="inline-block brokerage-logo-image" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        className="cursor-pointer relative brokerage-logo"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <img src={mainImg || imageUrl || logo} alt="logo0" className="inline-block brokerage-logo-image" />
      </div>
      <CropperModal
        image={imageUrl || mainImg || logo}
        currentBroker={currentBroker}
        onApply={onApply}
        open={showModal}
        setOpen={setShowModal}
        loading={updateLogoLoading}
        removeLoading={removeLogoLoading}
        onRemovePhoto={onRemovePhoto}
      />
    </React.Fragment>
  );
};

export default BrokerageLogo;
