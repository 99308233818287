import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { clearCurrentUser } from 'src/redux/actions/userActions';
import { createRequestHeader } from 'src/services/request-headers';

/**
 * @constant Logout - Logic for authentication
 */

const Logout = props => {
  function afterLogout() {
    localStorage.removeItem('jwt');
    props.clearCurrentUser();
    window.location.href = '/';
  }

  return (
    <Button
      onClick={async event => {
        event.preventDefault();
        axios
          .post(`${process.env.REACT_APP_API}/auth/logout`, {}, createRequestHeader())
          .then(afterLogout)
          .catch(error => {
            // session might have already expired, so just clean up
            afterLogout();
            console.warn(error);
          });
      }}
    >
      LOG OUT
    </Button>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({ clearCurrentUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
