import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Icon, Modal, Header, Input, TextArea, Tab, Image, Label, Radio } from 'semantic-ui-react';
import FilePicker from 'src/components/file-picker';
import _ from 'src/lodash';
import './edit-text-modal.scss';

import { mergeCurrentListing } from 'src/redux/actions/actions';
import BackgroundImage from './background-image';
import { BROKER_CODES } from 'src/constants/permissions';
import { setBothListing } from '../redux/actions/actions';

// List of fields that would be editable in the modal
const WHITELIST_FIELDS = {
  agent: ['agentName', 'agentPhone', 'agentTitle', 'agentEmail', 'agentWebsite'],
  coAgent: ['coAgentName', 'coAgentPhone', 'coAgentTitle', 'coAgentEmail', 'coAgentWebsite'],
  location: ['streetAddress', 'city', 'county', 'stateCode', 'zipCode'],
  details: [
    'neighborhood',
    'propertyType',
    'qtyBedrooms',
    'qtyFullBathrooms',
    'qtyHalfBathrooms',
    'yearBuilt',
    'grossTax',
    'strataFee',
    'squareFeet',
    'lotSizeDimensions',
    'lotSizeTotal',
    'heading',
    'features',
  ],
  price: ['priceDisplay'],
};
const MCD_FIELDS = {
  features: ['maintenanceFee'],
};
const canadanizationMap = {
  county: 'neighbourhood',
  neighborhood: 'neighbourhood',
  stateCode: 'province',
  zipCode: 'postalCode',
};

class EditTextModal extends Component {
  constructor(props) {
    super(props);
    const initialState = this.getInitialState();
    let state = {
      isModalOpen: false,
      location: {
        city: '',
        countryCode: '',
        county: '',
        latitude: '',
        longitude: '',
        stateCode: 'BC',
        streetAddress: '',
        zipCode: '',
      },
      details: {
        apartmentNumber: 'n/a',
        architecturalStyle: 'n/a',
        belowGradeBeds: 'n/a',
        grossTax: 'n/a',
        lotSizeDimensions: 'n/a',
        lotSizeLength: 'n/a',
        lotSizeTotal: 'n/a',
        lowerFloorArea: 'n/a',
        mainFloorArea: 'n/a',
        neighborhood: 'n/a',
        heading: 'n/a',
        features: 'n/a',
        neighborhood_highlights: 'n/a',
        propertyType: 'n/a',
        qtyBedrooms: 'n/a',
        qtyFullBathrooms: 'n/a',
        qtyHalfBathrooms: '0',
        squareFeet: 'n/a',
        strataFee: 'n/a',
        strataFeeIncl: 'n/a',
        upperFloorArea: 'n/a',
        yearBuilt: 'n/a',
      },
      price: {
        price: 'n/a',
        priceDisplay: 'n/a',
      },
      agents: {
        agent_id: 'n/a',
        agent_name: 'n/a',
        agent_email: 'n/a',
        agent_phone: 'n/a',
        agent_license_number: 'n/a',
        co_agent_id: 'n/a',
        co_agent_name: 'n/a',
        co_agent_email: 'n/a',
        co_agent_phone: 'n/a',
        agentLogo: 'n/a',
        agentBanner: 'n/a',
        coAgentLogo: 'n/a',
        agentWebsite: 'n/a',
        agentImageUrl: 'n/a',
        coAgentWebsite: 'n/a',
        coAgentImageUrl: 'n/a',
      },
      description: 'n/a',
      subtitle: 'n/a',
      title: 'n/a',
      ...initialState,
    };

    this.state = state;
  }

  getInitialState = () => {
    const clonedListing = _.cloneDeep(this.props.currentListing);
    return clonedListing;
  };

  toggleModal = () => {
    const state = this.getInitialState();
    this.setState(prevState => ({ ...state, isModalOpen: !prevState.isModalOpen }));
  };

  onUpdate = () => {
    const newListing = _.omit(this.state, ['isModalOpen']);
    this.props.mergeCurrentListing(newListing);
    setBothListing({ ...newListing });
    this.setState({ isModalOpen: false });
  };

  onResetTemplateText = () => {
    const newListing = _.omit(this.props.searchedListing, ['isModalOpen']);
    this.props.mergeCurrentListing(newListing);
    this.setState({ isModalOpen: false });
  };

  // Accepts current listing fields, whitelist fields and prop name to update the state
  renderFields = (listingFields, whitelistFields, topField) => {
    return _.map(whitelistFields, (key, index) => {
      if (this.props.brokers.currentBroker.template_code === 'WRC') {
        if (key !== 'heading' && key !== 'features' && key !== 'qtyHalfBathrooms') {
          return (
            <div className="edit-field" key={index}>
              <Input
                label={_.startCase(key in canadanizationMap ? canadanizationMap[key] : key)}
                defaultValue={listingFields[key]}
                onChange={e => this.setState({ [topField]: { ...this.state[topField], [key]: e.target.value } })}
              />
            </div>
          );
        }
      } else {
        return (
          <div className="edit-field" key={index}>
            <Input
              label={_.startCase(key in canadanizationMap ? canadanizationMap[key] : key)}
              defaultValue={listingFields[key]}
              onChange={e => this.setState({ [topField]: { ...this.state[topField], [key]: e.target.value } })}
            />
          </div>
        );
      }
    });
  };

  render() {
    const { isModalOpen, location, details, price, description, title, subtitle, isMLSVisible } = this.state;
    const { brokers } = this.props;

    const editListingTab = (
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={7}>
            {this.props.brokers.currentBroker.template_code !== 'WRC' ? (
              <>
                <div className="edit-field">
                  <Input label="Header" defaultValue={title} onChange={e => this.setState({ title: e.target.value })} />
                </div>
                <div className="edit-field">
                  <Input
                    label="SubHeader"
                    defaultValue={subtitle}
                    onChange={e => this.setState({ subtitle: e.target.value })}
                  />
                </div>
              </>
            ) : (
              ''
            )}

            <Header>Location</Header>
            {this.renderFields(location, WHITELIST_FIELDS.location, 'location')}
          </Grid.Column>
          <Grid.Column width={9}>
            <Header>Description</Header>
            <TextArea
              defaultValue={description}
              className="desc-field"
              onChange={e => this.setState({ description: e.target.value })}
            />
            <Header>Show Listing #</Header>
            <Radio
              toggle
              label="Show"
              checked={isMLSVisible}
              onChange={() => this.setState(prevState => ({ isMLSVisible: !prevState.isMLSVisible }))}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header>Features</Header>
            <div className="listing-details">
              {this.renderFields(price, WHITELIST_FIELDS.price, 'price')}
              {this.renderFields(details, WHITELIST_FIELDS.details, 'details')}
              {brokers?.currentBroker?.template_code === 'MCD'
                ? this.renderFields(details, MCD_FIELDS.features, 'features')
                : ''}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    const editAgentTab = (agent, key) => {
      const imgUrlField = key + 'ImageUrl';

      return (
        <Grid key={key}>
          <Grid.Row columns={3} style={{ paddingBottom: 0 }}>
            <Grid.Column width={7}>
              <Header>Details</Header>
              {this.renderFields(agent, WHITELIST_FIELDS[key], 'agents')}
            </Grid.Column>
            <Grid.Column width={3} className="agent-photo-container">
              <Header>Photo</Header>
              <FilePicker
                onSelect={async dataUrl => {
                  this.setState({ agents: { ...agents, [imgUrlField]: dataUrl } });
                }}
                accept="image/*"
              >
                {agent[imgUrlField] === undefined || agent[imgUrlField].length < 5 ? (
                  <div className="agent-photo">
                    <span className="empty-placeholder"></span>
                  </div>
                ) : (
                  <Image src={agent[imgUrlField]} size="medium" className="agent-photo" />
                )}
                <Button primary size="tiny" className="change-photo-btn">
                  <Icon name="upload" /> Upload
                </Button>
              </FilePicker>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ paddingTop: 0 }}>
            <Grid.Column width={7}></Grid.Column>
            {brokers.currentBroker?.template_code !== BROKER_CODES.PEMBERTON && (
              <Grid.Column width={9} className="agent-banner-container">
                <Header>Banner</Header>
                <FilePicker
                  onSelect={async dataUrl => {
                    this.setState({ agents: { ...agents, agentBanner: dataUrl } });
                  }}
                  accept="image/*"
                >
                  {agents.agentBanner.length < 5 ? (
                    <div className="agent-banner">
                      <span className="empty-placeholder"></span>
                    </div>
                  ) : (
                    <BackgroundImage url={agents.agentBanner} width={525} height={150} size="contain" />
                  )}
                  <Label pointing style={{ display: 'flex', justifyContent: 'center' }}>
                    Not shown on all templates
                  </Label>
                  <Button primary size="tiny" className="change-banner-btn">
                    <Icon name="upload" /> Upload
                  </Button>
                </FilePicker>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      );
    };

    const { agents } = this.state;
    const { activeTab = 0, triggerTitle = 'EDIT LISTING' } = this.props;

    const agent = {
      agentName: agents.agentName,
      agentTitle: agents.agentTitle,
      agentPhone: agents.agentPhone,
      agentEmail: agents.agentEmail,
      agentWebsite: agents.agentWebsite,
      agentImageUrl: agents.agentImageUrl,
      agentLogo: agents.agentLogo,
    };

    const coAgent = {
      coAgentName: agents.coAgentName,
      coAgentTitle: agents.coAgentTitle,
      coAgentPhone: agents.coAgentPhone,
      coAgentEmail: agents.coAgentEmail,
      coAgentWebsite: agents.coAgentWebsite,
      coAgentImageUrl: agents.coAgentImageUrl,
      coAgentLogo: agents.coAgentLogo,
    };

    const panes = [
      {
        menuItem: {
          icon: 'file alternate outline',
          name: 'LISTING',
          key: '0',
        },
        render: () => editListingTab,
      },
      {
        menuItem: { icon: 'user outline', content: 'AGENT', name: 'Agent', key: '1' },
        render: () => editAgentTab(agent, 'agent'),
      },
      {
        menuItem: { icon: 'user outline', content: 'CO-AGENT', name: 'Co-Agent', key: '2' },
        render: () => editAgentTab(coAgent, 'coAgent'),
      },
    ];

    return (
      <Modal
        size="large"
        trigger={
          <Button color="black" size="tiny" onClick={this.toggleModal}>
            <Icon name="edit outline" />
            {triggerTitle}
          </Button>
        }
        open={isModalOpen}
        className="edit-modal"
        centered={false}
      >
        <Modal.Header>Edit Template Details</Modal.Header>
        <Modal.Content className="edit-modal-content">
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} defaultActiveIndex={activeTab}></Tab>
        </Modal.Content>
        <Modal.Actions>
          <Button basic size="tiny" onClick={this.onResetTemplateText} style={{ float: 'left' }}>
            <Icon name="redo" /> Reset to original
          </Button>
          <Button size="tiny" color="black" onClick={this.toggleModal}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" size="tiny" onClick={this.onUpdate}>
            <Icon name="checkmark" /> Update All
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { searchedListing, currentListing, brokers } = state;
  return { searchedListing, currentListing, brokers };
};

export default connect(mapStateToProps, { mergeCurrentListing })(EditTextModal);
