import React, { useState } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';
import './DocumentDeleteModal.scss';

function DocumentDeleteModal({ isOpen, onClose, document }) {
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    onClose(false);
  };

  const deleteDocument = async documentId => {
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API}/document/${documentId}`,
        createRequestHeader({ 'content-type': 'multipart/form-data' })
      );
      onClose(true);
    } catch (error) {
      onClose(false, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} closeOnDimmerClick={false} size="tiny">
      <Modal.Header>Delete Document</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="modal-message">
            Are you sure you want to delete <span className="document-name">{document.name}</span>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => handleModalClose(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button loading={loading} icon="check" color="green" onClick={() => deleteDocument(document.id)}>
          <Icon name="check" /> Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DocumentDeleteModal;
