import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './MacdonaldFacebookTemplate1.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_1';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class MacdonaldFacebookTemplate1 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address } = props;
    const { photos, location } = currentListing;

    const { streetAddress, city } = location;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;

    this.state = {
      headline,
      image1,
      address,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address } = this.state;
    MacdonaldFacebookTemplate1._cached = { headline, image1, address };

    return (
      <div className="mac-facebook-1-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Add Text" />
        <EditableTextarea
          self={this}
          className="address"
          fieldName="address"
          placeholder="#123 - 456 W Georgia St Vancouver"
        />
        <div className="logo">
          <img src={Logo} width="80%" height="80%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate1._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate1._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebookTemplate1);
