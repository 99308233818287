export const isFileLarge = (actualFileSize, expectedFileSizeInMB) => {
  return actualFileSize < +expectedFileSizeInMB * 1024 * 1024;
};

export const staticBrokerFonts = {
  2: {
    light: 'Primary',
    regular: 'Primary',
    medium: 'Primary',
    bold: 'Primary',
  },
  3: {
    light: 'UbuntuL',
    regular: 'UbuntuR',
    medium: 'UbuntuM',
    bold: 'UbuntuB',
  },
  4: {
    light: 'Social-Light',
    regular: 'Social-Reg',
    medium: 'Social-Bold',
    bold: 'Social-Bold',
  },
};

export const copyToClipboard = text => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const getCardIcon = cardType => {
  const _cardType = cardType?.toLowerCase();
  const ICONS = {
    amex: 'fa fa-cc-amex',
    discover: 'fa fa-cc-discover',
    visa: 'fa fa-cc-visa',
    mastercard: 'fa fa-cc-mastercard',
    other: 'fa fa-credit-card',
  };
  return ICONS[_cardType] || ICONS['other'];
};

export const formatStripeAmount = amount => {
  if (!amount) return '';

  const amountInNumber = parseInt(amount);
  const actualAmount = amountInNumber / 100;

  const integer = parseInt(actualAmount);
  let mantissa = (actualAmount % integer).toFixed(2).toString();

  const splitMantissa = mantissa.split('.');

  if (splitMantissa.length === 2) {
    mantissa = splitMantissa[1];
  }

  return {
    integer,
    mantissa,
  };
};

export function validateEmail(email) {
  if (!email) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function generateTemplateCode(templateName) {
  if (!templateName) return '';

  const names = templateName.split(' ');
  if (names.length === 1) {
    return names[0].slice(0, 3).toUpperCase();
  } else {
    const [first, second] = names;
    return `${first[0]}${first[1]}${second[0]}`.toUpperCase();
  }
}
