import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import MacdonaldFacebookTemplate18 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate18';

class MacdonaldFacebook18 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout
        postFacebook={true}
        templateKey={MacdonaldFacebookTemplate18._name}
        type={type}
        listing={currentListing}
      >
        <Scale
          width={MacdonaldFacebookTemplate18._width}
          height={MacdonaldFacebookTemplate18._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <MacdonaldFacebookTemplate18 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebook18);
