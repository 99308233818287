import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from 'src/utils/generic-utils';

import './InviteUser.scss';
import { updateCurrentBroker } from 'src/redux/actions/brokerActions';
import { continueNextFunctionality } from 'src/utils/stripe-utils';

const InviteUser = () => {
  const dispatch = useDispatch();
  const { currentBroker, currentUser } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    currentUser: state.users.currentUser,
  }));
  const [loading, setLoading] = useState(false);
  const [generatedInviteHash, setGeneratedInviteHash] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    if (!currentBroker?.invite_hash) {
      const hashedBrokerInvite = btoa(`${currentBroker?.name}-${currentBroker?.id}`);
      setGeneratedInviteHash(hashedBrokerInvite);
      dispatch(
        updateCurrentBroker(currentBroker?.id, {
          colour: currentBroker?.colour,
          crea_listing_office_key: currentBroker?.crea_listing_office_key || '',
          feed_type: currentBroker?.feed_type?.trim(),
          invite_hash: hashedBrokerInvite,
          logo: currentBroker?.logo,
          name: currentBroker?.name,
          template_code: currentBroker?.template_code || '',
          feed_listing_access_token: '',
        })
      )
        .then(response => {
          console.log(response, 'response of the api');
        })
        .catch(err => {
          console.log('ERR => whileupdating user', err);
        });
    }
  }, [currentBroker, dispatch]);

  const generateInviteLink = async () => {
    setLoading(true);
    const doContinue = await continueNextFunctionality({
      dispatch,
      currentBroker,
      currentUser,
      includeUserCount: true,
    });

    if (doContinue) {
      setLoading(true);
      let hashedBrokerInvite = currentBroker?.invite_hash || generatedInviteHash;
      const baseURL = window.location.origin;
      const inviteLink = `${baseURL}/register?broker=${hashedBrokerInvite}`;

      setTimeout(() => {
        copyToClipboard(inviteLink);
        setLoading(false);
        addToast('Text copied to clipboard!', {
          appearance: 'success',
          autoDismiss: true,
        });
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="invite-user">
      <Button color="blue" loading={loading} size="tiny" onClick={generateInviteLink} floated="right">
        {loading && (
          <div className="loading-alert-popup">
            <span>Copying text to clipboard...</span>
          </div>
        )}
        <span>Copy Invite Link</span>
      </Button>
    </div>
  );
};

export default InviteUser;
