import _ from 'src/lodash';

const isJSON = value => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

//
// Iterate the values in data to be set to session storage,
// and exclude data url to prevent storage from hitting its limit
//
const excludeDataUrl = str => {
  if (str === undefined || str.startsWith('data:')) return '';
  return str;
};

let verifyArray, verifyObject, verifyData;

verifyArray = arr => {
  if (arr.length === 0) return arr;

  if (_.isString(arr[0])) return arr.map(excludeDataUrl);
  return arr.map(verifyData);
};

verifyObject = data => {
  return _.reduce(
    data,
    (ret, val, key) => {
      ret[key] = verifyData(val);
      return ret;
    },
    {}
  );
};

verifyData = data => {
  if (_.isString(data)) return excludeDataUrl(data);
  if (_.isArray(data)) return verifyArray(data);
  if (_.isObject(data)) return verifyObject(data);
  return data;
};
//
// End of data verification
//

export const setStorage = (key, value) => {
  const verifiedValue = verifyData(value);
  const json = JSON.stringify(verifiedValue);
  sessionStorage.setItem(key, json);
  return value;
};

export const getStorage = (key, defaultValue = null) => {
  const value = sessionStorage.getItem(key);

  if (isJSON(value)) {
    const v = JSON.parse(value);
    if (v === undefined) {
      setStorage(key, defaultValue);
      return defaultValue;
    }
    return v;
  } else {
    setStorage(key, defaultValue);
    return defaultValue;
  }
};

export default { setStorage, getStorage };
