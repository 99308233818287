import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/small-brand-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';

import './FacebookTemplate14.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_14';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate14 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, address1, address2 } = props;

    const { pictures, location } = currentListing;

    const { streetAddress, city } = location;

    if (!image1) image1 = pictures[0];
    if (!image2) image2 = pictures[1];

    if (!address1) address1 = streetAddress;
    if (!address2) address2 = city;

    this.state = {
      image1,
      image2,
      address1,
      address2,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { image1, image2, address1, address2 } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate14._cached = { image1, image2, address1, address2 };

    return (
      <div className={`facebook-14-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
        />
        <EditableImage
          self={this}
          fieldName="image2"
          className="image2"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
        />
        <div className="content-wrapper"></div>
        <EditableInput self={this} className="address1" fieldName="address1" placeholder="#123 - 456 W Georgia St" />
        <EditableInput self={this} className="address2" fieldName="address2" placeholder="Vancouver" />
        <div className="logo">
          <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

FacebookTemplate14._name = TEMPLATE_NAME;
FacebookTemplate14._width = TEMPLATE_WIDTH;
FacebookTemplate14._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate14, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate14);
