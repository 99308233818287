import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form, Header, Divider, Icon } from 'semantic-ui-react';
import PublicLayout from '../../layouts/public-layout';

import './continue-with.scss';
import { useTitle } from 'react-use';

const propTypes = { redirectAfterLogin: PropTypes.bool, forgotPassword: PropTypes.func };
const defaultProps = { redirectAfterLogin: false };

const ContinueWith = ({ onContinueWithEmail }) => {
  useTitle('Continue With - Realvault');

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);

  if (redirectLogin) {
    localStorage.setItem('broker_onboarding_step', 1);
    return <Redirect to="/login" />;
  } else {
    return (
      <div
        className="background white main-content"
        style={{ width: '45%', margin: '0 auto', marginTop: 'var(--navbarHeight)', padding: 50 }}
      >
        <Header as="h1" className="text-center uppercase">
          Register
        </Header>
        <div className="background white">
          <Form>
            <div className="">
              <Button
                onClick={event => {
                  event.preventDefault();
                  setLoading(true);
                  setDisabled(true);

                  onContinueWithEmail();
                }}
                loading={loading}
                icon
                labelPosition="left"
                className="btn btn-primary btn-block fluid"
              >
                <Icon name="mail" />
                Continue with Email
              </Button>
            </div>
            <Divider horizontal>OR</Divider>
            <div className="mt-12">
              <Button
                loading={loading}
                icon
                labelPosition="left"
                className="fluid"
                onClick={event => {
                  event.preventDefault();
                  setLoading(true);
                  setDisabled(true);

                  console.info('redirecting to google....');
                  window.location.href = `${process.env.REACT_APP_API}/auth/google`;
                }}
              >
                <Icon name="google" />
                Sign up with Google
              </Button>
            </div>
          </Form>
        </div>
        <Header as="h4">
          Already have an account?{' '}
          <Button onClick={() => setRedirectLogin(true)} className="link-button">
            Login
          </Button>
        </Header>
      </div>
    );
  }
};

export default ContinueWith;
