export const TOGGLE_ACTION_BUTTON_MESSAGE = 'TOGGLE_ACTION_BUTTON_MESSAGE';

const initialState = {
  isVisible: false,
  isLoading: false,
  message: { title: null, note: null },
  data: null,
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACTION_BUTTON_MESSAGE:
      return {
        ...state,
        isVisible: action.payload.isVisible,
        isLoading: action.payload.isLoading,
        message: { title: action.payload.message.title, note: action.payload.message.note },
        data: action.payload.data,
      };
    default:
      return state;
  }
};
