import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure6.scss';
import { register } from 'src/services/template-renderer';
import WindowPoster from 'src/templates/brochures/fragments/macdonald/window-poster';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';

const TEMPLATE_NAME = 'MACDONALD_REALTY_WINDOW_SHEET_2_AGENTS_CO-LISTING';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 816;
const TARGET_TEMPLATE_HEIGHT = 1056;

const MacdonaldBrochure6 = ({ searchedListing, currentListing, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <div className="template mac-template-6 ui-only-outline">
      <WindowPoster mergeCurrentListing={customMergeListing} listing={currentListing} twoAgents={true} />
    </div>
  );
};

MacdonaldBrochure6._name = TEMPLATE_NAME;
MacdonaldBrochure6._width = TEMPLATE_WIDTH;
MacdonaldBrochure6._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure6,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure6);
