import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import qs from 'qs';
import PublicLayout from '../../layouts/public-layout';
import { withRouter, Redirect } from 'react-router-dom';
import { setCurrentBroker } from 'src/redux/actions/brokerActions';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Message, Header, Divider } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { setCurrentUser } from 'src/redux/actions/userActions';
import { handleFetchUser } from 'src/utils/login-utils';
import { continueNextFunctionality } from 'src/utils/stripe-utils';
import { useTitle } from 'react-use';

/**
 * @constant Register - Logic for for register a user/requesting access
 */

const propTypes = {};
const Register = props => {
  useTitle('Register - Realvault');

  let params;
  const dispatch = useDispatch();
  if (props.location.search) {
    params = qs.parse(props.location.search.slice(1));
  }

  let parsedBroker = (params && params.broker) || '';

  try {
    parsedBroker = atob(params && params.broker) || '';
  } catch (err) {
    // do nothing
  }

  const { addToast } = useToasts();
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    requested_broker: parsedBroker.split('-')?.[0],
    password: '',
    phone_number: '',
  });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [formError, setFormError] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);

  const [currentBroker, setCurrentBroker] = useState(null);

  const brokerId = parsedBroker.split('-')?.[1];
  const currentUser = {
    user_role_id: 2,
  };

  useEffect(() => {
    if (brokerId) {
      axios
        .get(`${process.env.REACT_APP_API}/getBrokerForInvitedUser/${brokerId}`)
        .then(({ data }) => {
          setCurrentBroker(data);
        })
        .catch(err => {
          console.error('ERR => while getting broker info');
          throw err;
        });
    }
  }, [brokerId]);

  const handleValidations = async () => {
    if (!parsedBroker) {
      addToast('Invite Code not found! Contact your Brokerage admin / system admin to get one.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.requested_broker ||
      !formValues.password ||
      formValues.password.length < 8 ||
      !acceptedTerms
    ) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      const doContinue = await continueNextFunctionality({
        dispatch,
        currentBroker,
        currentUser,
        includeUserCount: true,
      });
      setLoading(false);
      setDisabled(false);

      if (doContinue) {
        setLoading(true);
        setDisabled(true);
        return axios
          .post(`${process.env.REACT_APP_API}/user/register`, {
            ...formValues,
            invite_user_hash: params?.broker,
          })
          .then(async res => {
            if (res.data.loggedIn === true) {
              const decodedToken = jwt.decode(res.data.meta.access_token);
              localStorage.setItem('jwt', res.data.meta.access_token);
              localStorage.setItem('refresh_token', res.data.meta.refresh_token);
              await handleFetchUser(decodedToken.user_id, props.setCurrentUser, props.setCurrentBroker);
              props.history.push('/dashboard');
            } else {
              addToast('Successfully requested access to RealVault.', {
                appearance: 'success',
                autoDismiss: true,
              });
              setLoading(false);
              setDisabled(false);
              setSuccess(true);
            }
          })
          .catch(error => {
            addToast(error?.response?.data?.error, { appearance: 'error', autoDismiss: true });
            setLoading(false);
            setDisabled(false);
            setSuccess(false);
          });
      }
    }
  };
  const passwordError = () => {
    if (!formValues.password && formError) {
      return { content: 'This is a required field' };
    } else if (formValues.password.length < 8 && formError) {
      return { content: 'Password must be 8 characters long' };
    } else {
      return false;
    }
  };

  if (redirectLogin) {
    return <Redirect to="/login" />;
  } else {
    return (
      <PublicLayout
        containerStyle={{
          minHeight: 'calc(100vh + 72px + 120px + 30px)',
        }}
      >
        <div>
          <Header as="h1" className="text-center uppercase">
            Register
          </Header>
          <div className="background white">
            {success && (
              <Message
                success
                header="Your user registration was successful"
                content={`A Covault representative will review your request and grant access. Questions? Contact info@covaultinc.com for further assistance.`}
              />
            )}
            <Form>
              <Form.Input
                error={!formValues.first_name && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="First Name"
                onChange={e => setFormValues({ ...formValues, first_name: e.target.value })}
                value={formValues.first_name}
                label="First Name"
              />
              <Form.Input
                error={!formValues.last_name && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="Last Name"
                onChange={e => setFormValues({ ...formValues, last_name: e.target.value })}
                value={formValues.last_name}
                label="Last Name"
              />
              <Form.Input
                fluid
                required={false}
                placeholder="Phone Number"
                onChange={e => setFormValues({ ...formValues, phone_number: e.target.value })}
                value={formValues.phone_number}
                label="Phone Number"
              />
              <Form.Input
                error={!formValues.email && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="Email"
                onChange={e => setFormValues({ ...formValues, email: e.target.value })}
                value={formValues.email}
                label="Email"
              />
              <Form.Input
                error={!formValues.requested_broker && formError ? { content: 'This is a required field' } : false}
                fluid
                required={true}
                placeholder="Brokerage"
                onChange={e => setFormValues({ ...formValues, requested_broker: e.target.value })}
                value={formValues.requested_broker}
                label="What brokerage do you work for?"
                disabled={params && params.broker}
              />
              <Form.Input
                error={passwordError()}
                fluid
                type="password"
                required={true}
                placeholder="Password"
                onChange={e => setFormValues({ ...formValues, password: e.target.value })}
                value={formValues.password}
                label="Password"
              />

              <Form.Checkbox
                error={!acceptedTerms && formError ? { content: 'This is a required field', pointing: 'left' } : false}
                value={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                label={
                  <label>
                    I agree to the{' '}
                    <a
                      href="https://realvault.ca/assets/images/Covault-Terms-Of-Service.pdf"
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Terms of Service
                    </a>{' '}
                    &{' '}
                    <a
                      href="https://realvault.ca/assets/images/Covault-Privacy-Policy-2021.pdf"
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                }
              />
              <Button
                primary
                loading={loading}
                disabled={disabled}
                size="tiny"
                onClick={async event => {
                  handleValidations();
                  event.preventDefault();
                }}
              >
                Register
              </Button>
            </Form>
          </div>
          <Header as="h4">
            Already a user?{' '}
            <Button onClick={() => setRedirectLogin(true)} className="link-button">
              Login
            </Button>
          </Header>
        </div>
      </PublicLayout>
    );
  }
};

Register.propTypes = propTypes;
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({ setCurrentUser, setCurrentBroker }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
