import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Dimmer, Loader, Grid } from 'semantic-ui-react';
import { setBroucherTemplates } from 'src/redux/actions/brokerActions';
import { createRequestHeader } from 'src/services/request-headers';
import * as AllBrochureTemplates from 'src/templates/brochures';
import PreviewItem from '../preview-item';
import './index.scss';

const headline = 'Add Text';

class BrochureTemplatePanel extends Component {
  state = {
    brochureTemplates: [],
    loading: false,
    // templateMap: {
    //   PMB: ['VantageWestBrochure1', 'VantageWestBrochure2'],
    //   MCD: ['VantageWestBrochure1', 'VantageWestBrochure2'],
    //   WRC: ['VantageWestBrochure1'],
    //   VNW: ['VantageWestBrochure1', 'VantageWestBrochure2'],
    //   // VIVA: ['VantageWestBrochure1', 'VantageWestBrochure2'],
    // },
  };
  // BRG for pemberton
  // KVC for macdonald
  componentDidMount() {
    const { id: brokerId } = this.props.brokers.currentBroker;
    const baseURL = process.env.REACT_APP_TEMPLATE_API;
    this.setState({ loading: true });
    let url = `${baseURL}/print-templates/broker/${brokerId}`;
    axios
      .get(url, createRequestHeader())
      .then(({ data }) => {
        this.props.setBroucherTemplates(data);
        console.log("data", data)
        this.setState({ brochureTemplates: data, loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  render() {
    console.log("this.state.brochureTemplates", this.state.brochureTemplates)
    const { mlsId, targetWidth } = this.props;
    if (this.state.loading) {
      return (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      );
    }

    return (
      <div>
        <div className="brochure-templates-container">
          {this.state.brochureTemplates.map(
            (template, index) =>
              AllBrochureTemplates[template.componentId] && (
                // (this.state.templateMap[this.props.template_code] === undefined ||
                // this.state.templateMap[this.props.template_code].includes(template.componentId)) ? (
                <Grid.Column key={template.id}>
                  <PreviewItem
                    title={template.title || `Vantage West Brochure ${index + 1}`}
                    linkTo={`/listing/${mlsId}/brochure/${template.name}`}
                    targetWidth={targetWidth}
                    Component={AllBrochureTemplates[template.componentId]}
                    data={{ headline }}
                    pages={template.pages}
                    headerURL={template.banner_default_URL}
                    footerURL={template.footer_default_URL}
                  />
                </Grid.Column>
              )
            // ) : null
          )}
        </div>

        {!this.props.isTemplatesAreUsedForHardcodedBroker &&
          this.state.brochureTemplates.filter(template => AllBrochureTemplates[template.componentId]).length === 0 && (
            <p className="text-center">No template found!</p>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brokers: state.brokers,
  currentListing: state.currentListing,
  searchedListing: state.searchedListing,
});

export default connect(mapStateToProps, {
  setBroucherTemplates,
})(BrochureTemplatePanel);
