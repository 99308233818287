import { SEARCHED_LISTING, SET_SEARCHED_LISTING, CLEAR_SEARCHED_LISTING } from '../../constants/action-types';
import { setStorage, getStorage } from './browser-storage';

export const searchedListingReducer = (state = getStorage(SEARCHED_LISTING), action) => {
  switch (action.type) {
    case SET_SEARCHED_LISTING: {
      const { listing } = action.payload;
      return setStorage(SEARCHED_LISTING, listing);
    }
    case CLEAR_SEARCHED_LISTING: {
      return setStorage(SEARCHED_LISTING, null);
    }
    default:
      return state;
  }
};
