import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from 'src/services/template-renderer';
import Scale from 'src/components/scale';
import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import EditableImage from 'src/components/editable-image';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import EditTextModal from 'src/components/edit-text-modal';
import EditableInput from 'src/components/editable-input';
import PlaceholderImage from 'src/images/banner.png';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';

import './BoulevardAdTemplate1.scss';

const TEMPLATE_NAME = 'Boulevard_Template_1';
const TEMPLATE_WIDTH = 1050;
const TEMPLATE_HEIGHT = 1425;

class BoulevardAdTemplate1 extends Component {
  constructor(props) {
    super(props);

    let {
      currentListing,
      headline,
      image1,
      image2,
      image3,
      image100,
      address,
      adjustedHeight,
      adjustedHeightAdr,
      listingDetails,
      defaultURL,
    } = props;

    const { photos, location, details, price, agents } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms } = details;
    const { priceDisplay } = price;
    const bath = qtyFullBathrooms || 0;
    const agentName = agents.agentName || 'Sally Smith';

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image100) image100 = this.props.currentBroker?.logo?.url || PlaceholderImage;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `${qtyBedrooms} Bedroom |  ${bath} Bathroom |  Price ${priceDisplay} `;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      image100,
      address,
      adjustedHeight,
      adjustedHeightAdr,
      listingDetails,
      defaultURL,
    };
  }

  render() {
    const { currentListing, appliedTheme, defaultSettings, currentBroker, mergeCurrentListing } = this.props;
    const { headline, image1, image2, image3, image100, listingDetails, address, adjustedHeight, adjustedHeightAdr } =
      this.state;

    BoulevardAdTemplate1._cached = {
      headline,
      image1,
      image2,
      image3,
      image100,
      listingDetails,
      address,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="boulevard-template-1-wrapper">
        <div
          className="header"
          style={{
            backgroundColor: this.props.appliedTheme?.backgroundColor
              ? `${this.props.appliedTheme?.backgroundColor}`
              : `${defaultSettings?.primaryColor}`,
          }}
        />
        <div className="header-image-container">
          <EditableImage
            self={this}
            fieldName="image1"
            className="image1"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={0}
          />
        </div>
        <div className="grid-image-container">
          <div className="grid left">
            <EditableImage
              self={this}
              fieldName="image2"
              className="grid-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={currentListing.pictures}
              imgKey={1}
            />
          </div>
          <div className="grid right">
            <EditableImage
              self={this}
              fieldName="image3"
              className="grid-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={currentListing.pictures}
              imgKey={2}
            />
          </div>
        </div>
        <div className="sub-main">
          <div className="card-div">
            <div
              className="inputs-container"
              style={{
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <ApplyFont fontName={appliedTheme?.font || defaultSettings?.font}>
                <EditableInput
                  self={this}
                  onHeightChange={v => (BoulevardAdTemplate1._cached.adjustedHeightAdr = v)}
                  initialHeight={adjustedHeightAdr}
                  className="address"
                  fieldName="address"
                  placeholder="#123 - 456 W Georgia St Vancouver"
                  rows={1}
                  inputStyle={{
                    fontWeight: 700,
                  }}
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.medium,
                    color: this.props.appliedTheme?.backgroundColor
                      ? `${this.props.appliedTheme?.backgroundColor}`
                      : `${defaultSettings?.primaryColor}`,
                  }}
                />
                <EditableInput
                  self={this}
                  className="listingDetails"
                  fieldName="listingDetails"
                  placeholder="Bed | Bath | Price"
                  style={{
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.regular,
                    color: '00000',
                  }}
                />
              </ApplyFont>
            </div>
          </div>
          <div className="agent-container">
            <div
              className="agent-photo"
              style={{
                font: '',
                backgroundImage: 'url(' + currentListing.agents.agentImageUrl + ')',
              }}
            >
              {(currentListing.agents.agentImageUrl === undefined ||
                currentListing.agents.agentImageUrl.length < 5) && <span className="empty-placeholder"></span>}
              <div className="edit-btn-hover not-in-pdf">
                <Scale.Back>
                  <EditTextModal activeTab={1} triggerTitle="Edit Agent" />
                </Scale.Back>
              </div>
            </div>

            <div className="agent-info">
              <div className="agent-name">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.regular,
                    color: this.props.appliedTheme?.backgroundColor
                      ? `${this.props.appliedTheme?.backgroundColor}`
                      : `${defaultSettings?.primaryColor}`,
                    width: '100%',
                  }}
                  value={currentListing.agents.agentName}
                  onChange={e => {
                    if (e.target.value.length < 90) {
                      mergeCurrentListing({
                        ...currentListing,
                        agents: {
                          ...currentListing.agents,
                          agentName: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="agent-title">
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    fontFamily:
                      this.props.appliedTheme?.font ||
                      defaultSettings?.font ||
                      staticBrokerFonts[currentBroker?.id]?.regular,
                    width: '100%',
                  }}
                  maxLength={39}
                  value={currentListing.agents.agentTitle?.slice(0, 39)}
                  onChange={e => {
                    if (e.target.value.length < 90) {
                      mergeCurrentListing({
                        ...currentListing,
                        agents: {
                          ...currentListing.agents,
                          agentTitle: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="agent-contact">
                <div className="agent-phone">
                  <input
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      fontFamily:
                        this.props.appliedTheme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.regular,
                    }}
                    value={currentListing.agents.agentPhone?.replace(/-/g, '.')}
                    onChange={e => {
                      if (e.target.value.length < 90) {
                        mergeCurrentListing({
                          ...currentListing,
                          agents: {
                            ...currentListing.agents,
                            agentPhone: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      fontFamily:
                        this.props.appliedTheme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.regular,
                      width: '100%',
                    }}
                    value={currentListing.agents.agentEmail}
                    onChange={e => {
                      if (e.target.value.length < 90) {
                        mergeCurrentListing({
                          ...currentListing,
                          agents: {
                            ...currentListing.agents,
                            agentEmail: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <input
                    className="agent-website"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      fontFamily:
                        this.props.appliedTheme?.font ||
                        defaultSettings?.font ||
                        staticBrokerFonts[currentBroker?.id]?.regular,
                      width: '100%',
                      backgroundColor: this.props.appliedTheme?.backgroundColor
                        ? `${this.props.appliedTheme?.backgroundColor}`
                        : `${defaultSettings?.primaryColor}`,
                    }}
                    value={currentListing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '')}
                    onChange={e => {
                      if (e.target.value.length < 90) {
                        mergeCurrentListing({
                          ...currentListing,
                          agents: {
                            ...currentListing.agents,
                            agentWebsite: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="brand-logos">
              <div style={{ width: 189, height: 189, marginTop: 40, position: 'relative' }}>
                <EditableImage
                  self={this}
                  style={{ height: '100%', width: '100%' }}
                  fieldName="image100"
                  size="contain"
                  position={EditableImage.CENTER}
                  pictures={currentListing.pictures}
                  imgKey={100}
                />
              </div>
            </div>
          </div>
          <div
            className="footer"
            style={{
              backgroundColor: this.props.appliedTheme?.backgroundColor
                ? `${this.props.appliedTheme?.backgroundColor}`
                : `${defaultSettings?.primaryColor}`,
            }}
          />
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

BoulevardAdTemplate1._name = TEMPLATE_NAME;
BoulevardAdTemplate1._width = TEMPLATE_WIDTH;
BoulevardAdTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: BoulevardAdTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(BoulevardAdTemplate1);
