import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';
import { DEFAULT_HEADERS } from 'src/utils/headers';
import {
  SET_CURRENT_BROKER,
  CLEAR_CURRENT_BROKER,
  STORE_BROKERS,
  SET_BROUCHERS_TEMPLATES,
  SET_SOCIAL_TEMPLATES,
  SET_ALL_BROKER_PRINT_TEMPLATES,
  SET_ALL_BROKER_SOCIAL_TEMPLATES,
  SET_PRINT_TEMPLATES_BY_BROKER_ID,
  SET_SOCIAL_TEMPLATES_BY_BROKER_ID,
} from '../../constants/action-types';

export const setCurrentBroker = broker => ({
  type: SET_CURRENT_BROKER,
  payload: {
    broker,
  },
});

export const clearCurrentBroker = broker => ({
  type: CLEAR_CURRENT_BROKER,
  payload: null,
});

export const storeBrokers = brokers => ({
  type: STORE_BROKERS,
  payload: { brokers },
});

export const setSocialTemplates = socialTemplates => ({
  type: SET_SOCIAL_TEMPLATES,
  payload: {
    socialTemplates,
  },
});

export const setBroucherTemplates = broucherTemplates => ({
  type: SET_BROUCHERS_TEMPLATES,
  payload: {
    broucherTemplates,
  },
});

export const getAllPrintTemplates = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/print-templates`, null, DEFAULT_HEADERS);
      resolve(data);
      dispatch({
        type: SET_ALL_BROKER_PRINT_TEMPLATES,
        payload: {
          allBrokerPrintTemplates: data,
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllSocialTemplates = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/social-templates`, null, DEFAULT_HEADERS);
      resolve(data);
      dispatch({
        type: SET_ALL_BROKER_SOCIAL_TEMPLATES,
        payload: {
          allBrokerSocialTemplates: data,
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPrintTemplatesByBrokerId = brokerId => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/print-templates/broker/${brokerId}`, null, DEFAULT_HEADERS);
      resolve(data);
      dispatch({
        type: SET_PRINT_TEMPLATES_BY_BROKER_ID,
        payload: {
          allowedBrokerPrintTemplates: data,
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSocialTemplatesByBrokerId = brokerId => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/social-templates/broker/${brokerId}`, null, DEFAULT_HEADERS);
      resolve(data);
      dispatch({
        type: SET_SOCIAL_TEMPLATES_BY_BROKER_ID,
        payload: {
          allowedBrokerSocialTemplates: data,
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setBrokerPrintTemplates = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/print-templates/broker/${brokerId}`, templateData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addBrokerPrintTemplate = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(
        `${baseURL}/print-templates/broker/${brokerId}/add`,
        templateData,
        DEFAULT_HEADERS
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeBrokerPrintTemplate = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(
        `${baseURL}/print-templates/broker/${brokerId}/remove`,
        templateData,
        DEFAULT_HEADERS
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setBrokerSocialTemplates = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(
        `${baseURL}/social-templates/broker/${brokerId}`,
        templateData,
        DEFAULT_HEADERS
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addBrokerSocialTemplate = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(
        `${baseURL}/social-templates/broker/${brokerId}/add`,
        templateData,
        DEFAULT_HEADERS
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeBrokerSocialTemplate = (brokerId, templateData) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(
        `${baseURL}/social-templates/broker/${brokerId}/remove`,
        templateData,
        DEFAULT_HEADERS
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addUpdateCREASearchOfficeKey = (brokerId, creaKeyData) => () => {
  const baseURL = process.env.REACT_APP_API;
  return axios.put(`${baseURL}/broker/${brokerId}/updateCreaSearchOfficeKey`, creaKeyData, createRequestHeader());
};

export const fetchCurrentBroker = brokerId => dispatch => {
  return axios
    .get(`${process.env.REACT_APP_API}/broker/${brokerId}`, createRequestHeader())
    .then(({ data }) => {
      dispatch({
        type: SET_CURRENT_BROKER,
        payload: {
          broker: data,
        },
      });
    })
    .catch(error => error);
};

export const updateCurrentBroker = (brokerId, userData) => dispatch => {
  return axios
    .put(`${process.env.REACT_APP_API}/broker/${brokerId}`, userData, createRequestHeader())
    .then(res => {
      dispatch({
        type: SET_CURRENT_BROKER,
        payload: {
          broker: res.data,
        },
      });
      return res.data;
    })
    .catch(error => {
      throw error;
    });
};

export const patchCurrentBroker = (brokerId, userData) => dispatch => {
  return axios
    .patch(`${process.env.REACT_APP_API}/broker/${brokerId}`, userData, createRequestHeader())
    .then(res => {
      dispatch({
        type: SET_CURRENT_BROKER,
        payload: {
          broker: res.data,
        },
      });
      return res.data;
    })
    .catch(error => {
      return error;
    });
};

export const uploadBrokerageBanner = apiData => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_API}/uploadBrokerageBanner`,
    apiData,
    createRequestHeader({ 'content-type': 'multipart/form-data' })
  );
};

export const removeBrokerageBanner = brokerId => dispatch => {
  return axios
    .delete(`${process.env.REACT_APP_API}/removeBrokerageBanner/${brokerId}`)
    .then(({ data }) => {
      dispatch(fetchCurrentBroker(brokerId));
      return data;
    })
    .catch(error => {
      throw error;
    });
};

export const setAllTemplatesToBrokerId = (brokerId, defaultUrl) => dispatch => {
  return axios
    .post(`${process.env.REACT_APP_TEMPLATE_API}/setAllTemplates/${brokerId}`, defaultUrl)
    .then(({ data }) => {
      dispatch(fetchCurrentBroker(brokerId));
      return data;
    })
    .catch(error => {
      throw error;
    });
};
