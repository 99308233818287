import React from 'react';
import './listing-info.scss';
import Logo from 'src/images/macdonald/macdonald-cmyk-color-logo.png';
import LogoDark from 'src/images/macdonald/macdonald-cmyk-bw-logo.png';
import Image from 'src/components/image';
import Scale from 'src/components/scale';
import ListingAreaType from 'src/components/listing-area-type';
import AgentBranding from 'src/components/agent-branding';
import EditTextModal from 'src/components/edit-text-modal';
import BackgroundImage from 'src/components/background-image';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';

const ListingInfo = ({
  listing,
  moreImages = false,
  hasAgentBanner = false,
  dark = false,
  layout = 'default',
  mergeCurrentListing,
  customImageHeight = false,
  defaultSettings = null,
  agentPhotoCustomMargin = false,
}) => {
  let SecondImages = null;
  if (moreImages) {
    SecondImages = (
      <div className="photo-layout-2" style={{ marginTop: '-2.5px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Image imgKey="3" url={listing.photos[3] || PlaceHolderImage4} width={175} height={108} />
        </div>
        <div>
          <Image
            imgKey="4"
            id="four"
            style={{ margin: '0px 7.15px 0 6px' }}
            url={listing.photos[4] || PlaceHolderImage5}
            width={175}
            height={108}
          />
        </div>
        <div>
          <Image imgKey="5" url={listing.photos[5] || PlaceHolderImage6} width={175} height={108} />
        </div>
      </div>
    );
  } else {
    SecondImages = (
      <div className="photo-layout-2" style={{ marginTop: '2px' }}>
        <div>
          <Image id="five" imgKey="3" url={listing.photos[3] || PlaceHolderImage4} width={266} height={160} />
        </div>
        <div>
          <Image imgKey="4" url={listing.photos[4] || PlaceHolderImage5} width={266} height={160} />
        </div>
      </div>
    );
  }

  return (
    <div className="mac-listing-info">
      <div className="broker-branding">
        <div className="logo">
          <img src={dark ? LogoDark : Logo} width="99" base64="true" alt="logo" />
        </div>
        <div className="listing-intro">
          <ListingAreaType
            dark={dark}
            font={"'LuxiaR',Verdana,Tahoma"}
            mergeCurrentListing={mergeCurrentListing}
            listing={listing}
          />
        </div>
      </div>
      <div className={`photo-layout-1 ${layout}`}>
        <Image
          imgKey="0"
          url={listing.photos[0] || PlaceHolderImage1}
          width={357}
          style={{ width: '357px', maxWidth: '357px', marginRight: '7.15px' }}
          height={customImageHeight ? 238 : 225}
        />
        <div className="stacked">
          <Image
            imgKey="1"
            url={listing.photos[1] || PlaceHolderImage2}
            width={175}
            height={customImageHeight ? 122 : 108.5}
            style={{ height: customImageHeight ? 122 : 108.5, minHeight: customImageHeight ? 117 : 108.5 }}
          />
          <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage3} width={175} height={109} />
        </div>
      </div>
      {SecondImages}
      <div className="listing-desc-container no-hover" style={{ width: '100%', height: '140px' }}>
        <div className="listing-desc" style={{ alignItems: 'baseline' }}>
          <span className="address" style={{ flex: '1' }}>
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                color: dark ? '#3d3d3d' : '#0054a5',
                fontFamily: 'UbuntuM, Verdana, Tahoma',
              }}
              value={
                listing.location.streetAddress.length > 30
                  ? listing.location.streetAddress.slice(0, 30)
                  : listing.location.streetAddress
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }
              }}
            />
          </span>
          <span
            className="price"
            style={{
              fontWeight: 'bold',
              fontFamily: "'UbuntuM', Verdana, Tahoma",
            }}
          >
            LIST PRICE $
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '75px',
                fontWeight: 'bold',
                fontFamily: 'UbuntuM, Verdana, Tahoma',
              }}
              value={listing.price.price.length > 11 ? listing.price.price.slice(0, 11) : listing.price.price}
              onChange={e => {
                if (e.target.value.length < 11) {
                  mergeCurrentListing({
                    price: {
                      ...listing.price,
                      price: e.target.value,
                    },
                  });
                }
              }}
            />
          </span>
        </div>
        <div className="description">
          <textarea
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
              overflow: 'hidden',
              resize: 'none',
              fontFamily: "'UbuntuR', Verdana, Tahoma",
              wordBreak: 'break-word',
              lineHeight: '14px',
            }}
            rows={9}
            value={listing.description.length > 790 ? listing.description.slice(0, 790) : listing.description}
            onChange={e => {
              mergeCurrentListing({
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
      {hasAgentBanner ? (
        <div className="agent-banner-container">
          <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
          {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
            <span className="empty-placeholder not-in-pdf"></span>
          )}
          <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <div style={{ position: 'relative', left: '-27%' }}>
                <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
              </div>
            </Scale.Back>
          </div>
        </div>
      ) : (
        <AgentBranding
          agentPhotoCustomMargin={agentPhotoCustomMargin}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
        />
      )}
    </div>
  );
};

export default ListingInfo;
