import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';
import HOME from 'src/images/icons/home.png';
import BATH from 'src/images/icons/bath.png';
import BED from 'src/images/icons/bed.png';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';

import './instagram4.scss';

const TEMPLATE_NAME = 'INSTAGRAM_STORY_4';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1920;

class InstagramTemplate4 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, image2, address, bed, bath, sqft } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;

    if (!address) address = `${streetAddress}, ${city}`;

    if (!bed) bed = qtyBedrooms;
    if (!bath) bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    if (!sqft) sqft = `${squareFeet} SQFT`;

    this.state = {
      headline,
      image1,
      image2,
      address,
      bed,
      bath,
      sqft,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, image2, address, bed, bath, sqft } = this.state;
    InstagramTemplate4._cached = { headline, image1, image2, address, bed, bath, sqft };

    return (
      <div className="instagram-4-wrapper">
        <div className="headline-wrapper">
          <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        </div>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableImage
          self={this}
          fieldName="image2"
          className="image2"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={1}
        />
        <EditableTextarea
          self={this}
          className="address"
          fieldName="address"
          placeholder="#123 - 456 W Georgia St Vancouver"
        />
        <img base64="true" className="cv-ig-icon sqft-icon" src={HOME} alt="home-icon" />
        <EditableInput self={this} className="sqft-input" fieldName="sqft" placeholder="XXX SQFT" />
        <img base64="true" className="cv-ig-icon bath-icon" src={BATH} alt="bath-icon" />
        <EditableInput self={this} className="bath-input" fieldName="bath" placeholder="X" />
        <img base64="true" className="cv-ig-icon bed-icon" src={BED} alt="bed-icon" />
        <EditableInput self={this} className="bed-input" fieldName="bed" placeholder="X" />
        <div className="logo">
          <img src={Logo} width="70%" height="70%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

InstagramTemplate4._name = TEMPLATE_NAME;
InstagramTemplate4._width = TEMPLATE_WIDTH;
InstagramTemplate4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: InstagramTemplate4, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(InstagramTemplate4);
