import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import SearchListing from 'src/components/search-listing';

const getNextRoute = nextKey => {
  switch (nextKey) {
    case 'add-text':
      return '/social/add-text';
    default:
      return '/listing';
  }
};

const Search = () => {
  const { nextRoute } = useParams();
  const nextTo = getNextRoute(nextRoute);

  return (
    <Container>
      <SearchListing nextTo={nextTo} />
    </Container>
  );
};

export default Search;
