import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo-fullcolor.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';
import _ from 'src/lodash';
import { formatPhoneNumber } from 'src/lib/string';

import './MacdonaldFacebookTemplate12.scss';

const TEMPLATE_NAME = 'MACDONALD_REALTY_12';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

const DEFAULT = {
  mainhead: 'Curious about the current real estate market?',
  title: 'Contact me for more info.',
};

class MacdonaldFacebookTemplate12 extends Component {
  constructor(props) {
    super(props);

    let { users, mainhead = DEFAULT.mainhead, image1, title = DEFAULT.title, detail } = props;

    if (!_.isEmpty(users.currentUser)) {
      const { imageUrl, firstName, lastName, phone } = users.currentUser;
      if (!image1) image1 = _.isEmpty(imageUrl) ? 'https://via.placeholder.com/1080' : imageUrl;
      if (!detail) detail = `${firstName} ${lastName}  |  ${formatPhoneNumber(phone.phoneNumber)}`;
    }

    this.state = {
      mainhead,
      image1,
      title,
      detail,
    };
  }

  render() {
    const { mainhead, image1, title, detail } = this.state;
    const _shareUrlQuery = `&t=${title || ''}&d=${detail || ''}`;
    MacdonaldFacebookTemplate12._cached = { mainhead, image1, title, detail, _shareUrlQuery };

    return (
      <div className="mac-facebook-12-wrapper">
        <EditableImage self={this} fieldName="image1" className="image1" size="cover" position={EditableImage.TOP} />
        <div className="content-wrapper"></div>
        <EditableTextarea self={this} className="mainhead" fieldName="mainhead" placeholder={DEFAULT.mainhead} />
        <hr />
        <EditableInput self={this} className="title" fieldName="title" placeholder={DEFAULT.title} />
        <EditableInput self={this} className="detail" fieldName="detail" placeholder="Agent Name   |   (123) 456-789" />
        <div className="logo">
          <img src={Logo} width="80%" height="80%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

MacdonaldFacebookTemplate12._name = TEMPLATE_NAME;
MacdonaldFacebookTemplate12._width = TEMPLATE_WIDTH;
MacdonaldFacebookTemplate12._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: MacdonaldFacebookTemplate12, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { users = {} } = state;
  return { users };
};

export default connect(mapStateToProps)(MacdonaldFacebookTemplate12);
