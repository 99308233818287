export const CURRENT_USER = 'CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const STORE_USERS = 'STORE_USERS';
export const UPDATE_CURRENT_USER_BROKER_ID = 'UPDATE_CURRENT_USER_BROKER_ID';

export const CURRENT_BROKER = 'CURRENT_BROKER';
export const SET_CURRENT_BROKER = 'SET_CURRENT_BROKER';
export const CLEAR_CURRENT_BROKER = 'CLEAR_CURRENT_BROKER';
export const STORE_BROKERS = 'STORE_BROKERS';

export const SEARCHED_LISTING = 'SEARCHED_LISTING';
export const SET_SEARCHED_LISTING = 'SET_SEARCHED_LISTING';
export const CLEAR_SEARCHED_LISTING = 'CLEAR_SEARCHED_LISTING';

export const CURRENT_LISTING = 'CURRENT_LISTING';
export const SET_CURRENT_LISTING = 'SET_CURRENT_LISTING';
export const MERGE_CURRENT_LISTING = 'MERGE_CURRENT_LISTING';
export const CLEAR_CURRENT_LISTING = 'CLEAR_CURRENT_LISTING';

export const UPLOADED_IMAGES = 'UPLOADED_IMAGES';
export const ADD_UPLOADED_IMAGE = 'ADD_UPLOADED_IMAGE';
export const REMOVE_UPLOADED_IMAGE = 'REMOVE_UPLOADED_IMAGE';
export const CLEAR_UPLOADED_IMAGES = 'CLEAR_UPLOADED_IMAGES';

export const CURRENT_DOCUMENT = 'CURRENT_DOCUMENT';
export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
export const CLEAR_CURRENT_DOCUMENT = 'CLEAR_CURRENT_DOCUMENT';
export const STORE_DOCUMENTS = 'STORE_DOCUMENTS';

export const SET_BROUCHERS_TEMPLATES = 'SET_BROUCHERS_TEMPLATES';
export const SET_SOCIAL_TEMPLATES = 'SET_SOCIAL_TEMPLATES';

export const SET_ALL_BROKER_PRINT_TEMPLATES = 'SET_ALL_BROKER_PRINT_TEMPLATES';
export const SET_ALL_BROKER_SOCIAL_TEMPLATES = 'SET_ALL_BROKER_SOCIAL_TEMPLATES';

export const SET_PRINT_TEMPLATES_BY_BROKER_ID = 'SET_PRINT_TEMPLATES_BY_BROKER_ID';
export const SET_SOCIAL_TEMPLATES_BY_BROKER_ID = 'SET_SOCIAL_TEMPLATES_BY_BROKER_ID';

export const SET_TEXT_SIZE = "SET_TEXT_SIZE";
export const SET_INPUT_TEXT_SIZE = 'SET_INPUT_TEXT_SIZE';
export const RESET_TEXT_SIZE = 'RESET_TEXT_SIZE';
