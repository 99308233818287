import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Stacked_Wordmark_white.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate13.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_13';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate13 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, bath, bed, sqft, adjustedHeight, adjustedHeightAdr } = props;

    const { photos, location, details } = currentListing;
    const { streetAddress, city } = location;

    const { qtyBedrooms, qtyFullBathrooms, squareFeet } = details;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!bath) bath = `Bath: ${qtyFullBathrooms || 0}`;
    if (!bed) bed = `Bed: ${qtyBedrooms || 0}`;
    if (!sqft) sqft = `SqFt: ${squareFeet}`;

    this.state = {
      headline,
      image1,
      address,
      bath,
      bed,
      sqft,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, bath, bed, sqft, adjustedHeight, adjustedHeightAdr } = this.state;
    WhistlerSocialTemplate13._cached = { headline, image1, address, bath, bed, sqft };

    return (
      <WhistlerSocialTemplate className="whistler-social-13-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.LEFT}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="overlay-light"></div>
        <div className="content-wrapper">
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerSocialTemplate13._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="headline"
            fieldName="headline"
            placeholder="Headline"
            rows={1}
          />
          <hr />
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerSocialTemplate13._cached.adjustedHeightAdr = v)}
            initialHeight={adjustedHeightAdr}
            className="address"
            fieldName="address"
            placeholder="#123 - 656 W Georgia St Vancouver"
            rows={1}
          />
          <div className="listing-details-wrapper">
            <EditableInput self={this} className="listingDetails" fieldName="bed" placeholder="Bed" />
            <EditableInput
              self={this}
              className="listingDetails"
              fieldName="bath"
              placeholder="#123 - 456 W Georgia St Vancouver"
            />
            <EditableInput
              self={this}
              className="listingDetails"
              fieldName="sqft"
              placeholder="#123 - 456 W Georgia St Vancouver"
            />
          </div>

          <div className="logo">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate13._name = TEMPLATE_NAME;
WhistlerSocialTemplate13._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate13._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate13, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate13);
