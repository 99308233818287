import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal } from 'semantic-ui-react';
import Spinner from '../Spinner/spinner';
import { SUB_TITLE } from 'src/pages/OnboardingBroker/select-pricing';
import { PLAN_TITLES } from 'src/pages/OnboardingBroker/select-pricing';
import { fetchCurrentBroker } from 'src/redux/actions/brokerActions';
import {
  getStripeProducts,
  upgradeStripeSubscription,
  downgradeStripeSubscription,
  renewStripeSubscription,
  getSubscriptionStatus,
} from 'src/redux/actions/stripeActions';
import { CLOSE_CHOOSE_PLAN_MODAL } from 'src/redux/reducers/appPermissionReducer';
import { formatStripeAmount } from 'src/utils/generic-utils';

import './choose-plan-popup.scss';

const ChoosePlanPopup = props => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState('');
  const [fetchProductsLoading, setFetchProductsLoading] = useState(false);

  const { currentUser, currentBroker, products, openChoosePlanModal, currentSubscription } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
    products: state.stripe.products,
    openChoosePlanModal: state.appPermissions.openChoosePlanModal,
    currentSubscription: state.stripe.currentSubscription,
  }));

  useEffect(() => {
    if (openChoosePlanModal) {
      // get the stripe products to show
      dispatch(getStripeProducts())
        .then(() => {
          console.log('Fetched the current plan');
        })
        .catch(err => {
          console.log('Something went wrong while fetching stripe products', err);
        });

      // get the stripe current subscription
      setFetchProductsLoading(true);
      dispatch(
        getSubscriptionStatus({
          user_id: currentUser?.id,
        })
      )
        .then(() => {
          setFetchProductsLoading(false);
        })
        .catch(err => {
          setFetchProductsLoading(false);
          console.log('Something went wrong while fetching current subscription', err);
        });
    }
  }, [currentUser, dispatch, openChoosePlanModal]);

  const onUpgradeSubscription = planId => {
    setLoading(planId);
    dispatch(
      upgradeStripeSubscription({
        user_id: currentUser?.id,
        price_id: planId,
      })
    )
      .then(response => {
        if (response.status !== 'fail') {
          addToast('Subscription plan upgraded successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(fetchCurrentBroker(currentBroker?.id));
          dispatch({
            type: CLOSE_CHOOSE_PLAN_MODAL,
          });
        } else {
          addToast(response?.msg, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        setLoading('');
      })
      .catch(err => {
        console.log(err, 'ERR => while upgrading subscription');
        setLoading('');
      });
  };

  const onDowngradeSubscription = (planId, oldPlanId) => {
    setLoading(planId);
    dispatch(
      downgradeStripeSubscription({
        user_id: currentUser?.id,
        price_id: planId,
        // from_price_id: oldPlanId,
      })
    )
      .then(response => {
        if (response.status !== 'fail') {
          addToast('Subscription plan downgraded successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(fetchCurrentBroker(currentBroker?.id));
          dispatch({
            type: CLOSE_CHOOSE_PLAN_MODAL,
          });
        } else {
          addToast(response?.msg, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        setLoading('');
      })
      .catch(err => {
        console.log(err, 'ERR => while downgrading subscription');
        setLoading('');
      });
  };

  const onRenewSubscription = planId => {
    setLoading(planId);
    dispatch(
      renewStripeSubscription({
        user_id: currentUser?.id,
        price_id: planId,
      })
    )
      .then(response => {
        if (response.status !== 'fail') {
          addToast('Subscription plan renewed successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(fetchCurrentBroker(currentBroker?.id));
          dispatch({
            type: CLOSE_CHOOSE_PLAN_MODAL,
          });
        } else {
          addToast(response?.msg, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        setLoading('');
      })
      .catch(err => {
        console.log(err, 'ERR => while renewing subscription');
        setLoading('');
      });
  };

  const onChangeSubscription = planId => {
    if (currentSubscription?.status === 'active') {
      const selectedPlan = products[0]?.plan?.find(plan => plan.id === planId);
      const currentPlan = products?.[0]?.plan?.find(plan => plan.id === currentBroker.stripe_plan_id);

      if (selectedPlan.amount < currentPlan.amount) {
        onDowngradeSubscription(planId, currentPlan?.id);
      } else {
        onUpgradeSubscription(planId);
      }
    } else {
      // renew the subscription,
      onRenewSubscription(planId);
    }
  };

  const isInactiveSubscription =
    currentSubscription?.status !== 'active' &&
    currentSubscription?.cancel_at &&
    currentSubscription?.cancel_at_period_end === true;

  return (
    <Modal
      centered
      open={openChoosePlanModal}
      size="small"
      onClose={() =>
        dispatch({
          type: CLOSE_CHOOSE_PLAN_MODAL,
        })
      }
    >
      <div className="choose-plan-container">
        <Modal.Content className="modal-content">
          <div className="content">
            {fetchProductsLoading ? (
              <div className="flex justify-center items-center h-full w-full">
                <Spinner />
              </div>
            ) : (
              products.length > 0 &&
              products[0]?.plan?.slice(0, 3)?.map((plan, index) => (
                <React.Fragment key={plan.id}>
                  <div className="plan-box">
                    <div className="content-header header-1">
                      <h1>{PLAN_TITLES[index]}</h1>
                    </div>

                    <div className="main-content">
                      <div className="pricing-section">
                        <div>
                          <span className={`price price-${index + 1}`}>
                            ${formatStripeAmount(plan.amount)?.integer}
                            <sup className="text-2xl">.{formatStripeAmount(plan.amount)?.mantissa}</sup>
                          </span>
                        </div>
                        <div className="pricing-users">{SUB_TITLE[index]}</div>
                      </div>

                      {/* Plan Features */}
                      <div className="plan-features">
                        <ul>
                          <li>2 brochure templates</li>
                          <li>10 social media templates</li>
                          <li>cloud storage</li>
                        </ul>
                      </div>

                      {/* Switch Plan Button */}
                      {loading === plan.id ? (
                        <Spinner />
                      ) : (
                        <React.Fragment>
                          {plan.id === currentBroker?.stripe_plan_id && !isInactiveSubscription && (
                            <div className="action-btn-container">
                              <span className="btn current-plan">Current Plan</span>
                            </div>
                          )}

                          {plan.id === currentBroker?.stripe_plan_id && isInactiveSubscription && (
                            <div className="action-btn-container" onClick={() => onChangeSubscription(plan.id)}>
                              <span className={`btn btn-${index + 1}`}>Renew Plan</span>
                            </div>
                          )}

                          {plan.id !== currentBroker?.stripe_plan_id && (
                            <div className="action-btn-container" onClick={() => onChangeSubscription(plan.id)}>
                              <span className={`btn btn-${index + 1}`}>Switch Plan</span>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
          <div className="footer">More than 50 users? Contact us today,info@covaultinc.com</div>
        </Modal.Content>
      </div>
    </Modal>
  );
};

export default ChoosePlanPopup;
