import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSocialMediaPage from 'src/pages/facebook/BaseSocialMediaPage';

class WhistlerSocialPage extends Component {
  render() {
    return <BaseSocialMediaPage props={this.props} Component={this.props.Component} />;
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialPage);
