import React, { Component } from 'react';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import FacebookTemplate17 from 'src/templates/facebook/pemberton/FacebookTemplate17';

class PembertonFacebook17 extends Component {
  render() {
    const { type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout postFacebook={true} templateKey={FacebookTemplate17._name} type={type}>
        <Scale
          width={FacebookTemplate17._width}
          height={FacebookTemplate17._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <FacebookTemplate17 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

export default PembertonFacebook17;
