import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { BROKER_CODES } from 'src/constants/permissions';
import './listing-area-type.scss';

const ListingAreaType = ({
  showTitle = true,
  listing,
  onlyTitle = false,
  brokers,
  mergeCurrentListing,
  fontColor = null,
  font = null,
  hideAddress = null,
  dark = false,
  theme,
}) => {
  const fontClassName = brokers.currentBroker?.template_code !== BROKER_CODES.PEMBERTON ? 'mac' : '';
  return (
    <div className={`${fontClassName} brochure-header`}>
      {!hideAddress && (
        <h1 style={{ maxWidth: '700px', width: '400px', overflow: 'visible' }}>
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              fontSize: '29px',
              fontFamily: font || "'OvoR',Verdana,Tahoma",
              color: dark
                ? '#3d3d3d'
                : theme == 'green' || theme == 'black' || theme == '#310606'
                ? '#ffffff'
                : brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY
                ? 'var(--mac-font-color)'
                : '#ffffff',
              lineHeight: '1em',
              width: '100%',
              textAlign: 'right',
              background: 'inherit',
            }}
            value={listing.title.length > 27 ? listing.title.slice(0, 27) : listing.title}
            onChange={e => {
              if (e.target.value.length < 27) {
                mergeCurrentListing({
                  ...listing,
                  title: e.target.value,
                });
              }
            }}
          />
          {/* {showTitle && listing.title} */}
        </h1>
      )}

      {!onlyTitle && (
        <h2>
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              fontSize: 'inherit',
              fontFamily: font || "'LuxiaR',Verdana,Tahoma",
              color: dark
                ? '#3d3d3d'
                : theme == 'green' || theme == 'black' || theme == '#310606'
                ? '#ffffff'
                : brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY
                ? 'var(--mac-font-color)'
                : '#ffffff',
              lineHeight: '1em',
              width: '100%',
              textAlign: 'right',
              background: 'inherit',
            }}
            value={listing.subtitle.length > 27 ? listing.subtitle.slice(0, 27) : listing.subtitle}
            onChange={e => {
              if (e.target.value.length < 27) {
                mergeCurrentListing({
                  ...listing,
                  subtitle: e.target.value,
                });
              }
            }}
          />
        </h2>
      )}
    </div>
  );
};

ListingAreaType.propTypes = {
  listing: PropTypes.shape({
    details: PropTypes.shape({
      neighborhood: PropTypes.string.isRequired,
      qtyBedrooms: PropTypes.string.isRequired,
      propertyType: PropTypes.string.isRequired,
    }),
  }),
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers };
};

export default connect(mapStateToProps)(ListingAreaType);
