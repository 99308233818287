import React, { useState } from 'react';
import { Icon, Table, Popup, Button, Modal } from 'semantic-ui-react';
import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';
import DocumentAddEditModal from 'src/components/documents/DocumentAddEditModal';
import DocumentDeleteModal from 'src/components/documents/DocumentDeleteModal';
import './DocumentList.scss';
import Spinner from '../Spinner/spinner';
import { useToasts } from 'react-toast-notifications';
import Lightbox from 'react-image-lightbox';
import { track, ADD_DOCUMENT_CLICKED } from 'src/utils/analytics';

const DocumentList = ({ documents, isAdmin, onNeedsRefresh }) => {
  const { addToast } = useToasts();
  const defaultDocument = { name: '', description: '', id: '' };
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(defaultDocument);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewModalData, setPreviewModalData] = useState({ url: null, header: '' });

  const handleModalClose = (successfulUpload, error) => {
    setModalOpen(false);
    setCurrentDocument(defaultDocument);

    if (successfulUpload) {
      onNeedsRefresh();
    }
  };

  const handleAddDocumentClick = () => {
    track(ADD_DOCUMENT_CLICKED, {});
    setModalOpen(true);
  };

  const handleDeleteClick = document => {
    setCurrentDocument(document);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = successfulDelete => {
    setDeleteModalOpen(false);
    setCurrentDocument(defaultDocument);

    if (successfulDelete) {
      onNeedsRefresh();
    }
  };

  const handleEditClick = document => {
    setCurrentDocument(document);
    setModalOpen(true);
  };

  const handleDownloadClick = async file => {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/document/${file.id}/download`,
      responseType: 'blob',
      ...createRequestHeader(),
    });

    const type = response.headers['content-type'];
    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file.name;
    link.click();
    link.remove();
  };

  const renderEmptyMessage = () => {
    if (isAdmin) {
      return (
        <p className="empty-message">
          Your brokerage currently has no documents. Click "Add Document" to begin uploading documents for all users in
          your brokerage.
        </p>
      );
    } else {
      return (
        <p className="empty-message">
          Your brokerage currently has no documents. Contact your admin to add documents to this page.
        </p>
      );
    }
  };

  const renderDocumentIcon = document => {
    const index = document.name.lastIndexOf('.');
    const fileType = document.name.substring(index);

    if (fileType === '.pdf') {
      return <Icon name="file pdf outline" />;
    } else if (['.png', '.jpg', '.jpeg'].includes(fileType)) {
      return <Icon name="file image outline" />;
    } else {
      return <Icon name="file outline" />;
    }
  };

  const availableDocumentRoom = () => {
    return documents && documents.length < 15;
  };

  console.log({ documents });

  const documentRows = documents.map(document => (
    <Table.Row
      key={document.id}
      onClick={async () => {
        if (document.file_id) {
          // const index = document.name.lastIndexOf('.');
          // const fileType = document.name.substring(index);
          // const res = await axios.post(`${process.env.REACT_APP_API}/getFileUrl`, { file_id: document.file_id });
          // if (fileType === '.pdf') {
          //   setPreviewModalData({ header: '', url: null });
          //   window.open(res.data.url, '_blank');
          // } else {
          //   setPreviewModal(true);
          //   setPreviewModalData({ header: document.name, url: res.data.url });
          //   console.log({ res });
          // }
        } else {
          addToast('File ID was not found', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }}
    >
      <Table.Cell>
        {renderDocumentIcon(document)}
        {document.name}
      </Table.Cell>
      <Table.Cell>{document.description}</Table.Cell>
      <Table.Cell textAlign="center" className="action-row">
        <Popup
          className="tooltip"
          content="Download"
          mouseEnterDelay={500}
          size="mini"
          on="hover"
          trigger={
            <Icon
              name="cloud download"
              link
              onClick={() => {
                handleDownloadClick(document);
              }}
            />
          }
        />
        {isAdmin && (
          <>
            <Popup
              className="tooltip"
              content="Edit"
              mouseEnterDelay={500}
              on="hover"
              size="mini"
              trigger={<Icon name="edit" link onClick={() => handleEditClick(document)} />}
            />
            <Popup
              className="tooltip"
              content="Delete"
              mouseEnterDelay={500}
              on="hover"
              size="mini"
              trigger={<Icon name="trash" link onClick={() => handleDeleteClick(document)} />}
            />
          </>
        )}
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <>
      <div>
        {isAdmin && (
          <Button
            color="blue"
            floated="right"
            className="add-button"
            disabled={!availableDocumentRoom()}
            onClick={handleAddDocumentClick}
          >
            Add Document
          </Button>
        )}
      </div>
      {!documents || !documents.length ? (
        renderEmptyMessage()
      ) : (
        <Table striped singleLine padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>Name</Table.HeaderCell>
              <Table.HeaderCell width={9}>Description</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{documentRows}</Table.Body>
        </Table>
      )}
      {previewModal && <Lightbox mainSrc={previewModalData.url} onCloseRequest={() => setPreviewModal(false)} />}
      {/* <Modal
      open={previewModal }
      closeOnDimmerClick={false}
    >
      <Modal.Header>{previewModalData.header}</Modal.Header>
      <Modal.Content>
        {
          previewModalData.url?<img src={previewModalData.url}/>:<Spinner/>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setPreviewModal(false)}>
          Cancel
        </Button>
      </Modal.Actions>
      </Modal>  */}
      <DocumentAddEditModal isOpen={modalOpen} onClose={handleModalClose} document={currentDocument} />
      <DocumentDeleteModal isOpen={deleteModalOpen} onClose={handleDeleteModalClose} document={currentDocument} />
    </>
  );
};

export default DocumentList;
