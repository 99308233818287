import React, { useState, useRef } from 'react';

const autoExpand = function (field) {
  if (!field) return;

  try {
    // Reset field height
    field.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(field);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    const adjustedHeight = height + 'px';
    field.style.height = adjustedHeight;

    return adjustedHeight;
  } catch (err) {
    console.error(err);
  }
};

const EditableTextarea = ({
  self,
  fieldName,
  placeholder = 'Type Text...',
  style = {},
  rows = null,
  className = '',
  initialHeight = null,
  maxLength = null,
  onHeightChange = () => {},
}) => {
  const textRef = useRef();
  const [statePlaceholder, setPlaceholder] = useState(placeholder);

  setTimeout(() => {
    const adjustedHeight = autoExpand(textRef.current);
    if (adjustedHeight) {
      onHeightChange(adjustedHeight);
    } else {
      onHeightChange(initialHeight);
    }
  }, 100);

  return (
    <div className={`${className}`} style={style}>
      <textarea
        ref={textRef}
        className={`editable inhert-style`}
        style={{ height: initialHeight, lineHeight: '1.3em' }}
        value={self.state[fieldName]}
        placeholder={statePlaceholder}
        rows={rows}
        onFocus={() => {
          setPlaceholder('');
        }}
        maxLength={maxLength}
        onChange={e => {
          self.setState({ [fieldName]: e.target.value });
          autoExpand(textRef.current);
        }}
      />
    </div>
  );
};

export default EditableTextarea;
