import React from 'react';
import './half-listing-feature.scss';
import Image from 'src/components/image';
import Logo from 'src/images/pemberton/ph-stacked-white-logo.png';

import { default as formatValue, formatType } from 'src/utils/format-na-value';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';

const HalfListingFeature = ({ listing, mergeCurrentListing, theme }) => {
  const baths =
    listing.details.qtyHalfBathrooms === 'n/a'
      ? `${formatValue(listing.details.qtyFullBathrooms)}/0`
      : `${formatValue(listing.details.qtyFullBathrooms)}/${formatValue(listing.details.qtyHalfBathrooms)}`;
  const theme_ = theme || localStorage.getItem('themeClassName');
  return (
    <div className="half-listing-feature">
      <div className="half-listing-feature--header">
        <div className="logo">
          <img src={Logo} width="80" base64="true" alt="logo" />
        </div>
      </div>
      <div className="flex">
        <Image
          imgKey="5"
          url={listing.photos[5] || PlaceHolderImage1}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <Image imgKey="6" url={listing.photos[6] || PlaceHolderImage2} width={266} height={200} margin={'8px 0 0'} />
      </div>
      <div className="flex">
        <Image
          imgKey="7"
          url={listing.photos[7] || PlaceHolderImage3}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <div className="features no-hover">
          <div className="title" style={{ fontFamily: 'OvoR', color: theme_ === 'black' ? '#000' : '#112F23' }}>
            Features:
          </div>
          <div className="feature-list ">
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Price:</span>
              <span style={{ display: 'flex' }}>
                $
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={
                    listing.price.priceDisplay.length > 11
                      ? listing.price.priceDisplay.slice(0, 11)
                      : listing.price.priceDisplay
                  }
                  onChange={e => {
                    if (e.target.value.length < 11) {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Sub-area:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={listing.details.neighborhood}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">MLS® #:</span>
              <span data-test-id="mls-id" style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={listing.mlsId}
                  onChange={e => {
                    mergeCurrentListing({
                      mlsId: e.target.value,
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Bedrooms:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={formatValue(listing.details.qtyBedrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Baths (Full/Half):</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '10px',
                  }}
                  value={formatValue(listing.details.qtyFullBathrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }}
                />
                /{' '}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '10px',
                    marginLeft: '5px',
                  }}
                  value={formatValue(listing.details.qtyHalfBathrooms)}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyHalfBathrooms: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Year Built:</span>
              <span style={{ display: 'flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={listing.details.yearBuilt}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        yearBuilt: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span className="feature-name">Gross Taxes:</span>
              <span style={{ display: 'flex' }}>
                {formatType.CURRENCY}
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={
                    formatValue(listing.details?.grossTax)?.length > 6
                      ? formatValue(listing.details?.grossTax).slice(0, 6)
                      : formatValue(listing.details?.grossTax)
                  }
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        grossTax: e.target.value,
                      },
                    });
                  }}
                />
              </span>
            </div>
            {/* <div className="edit-btn-hover not-in-pdf">
              <EditTextModal activeTab={0} triggerTitle="Edit Features" />
            </div>*/}
          </div>
        </div>
      </div>
      <div className="flex">
        <Image
          imgKey="8"
          url={listing.photos[8] || PlaceHolderImage4}
          width={266}
          height={200}
          margin={'8px 8px 0 0'}
        />
        <Image imgKey="9" url={listing.photos[9] || PlaceHolderImage5} width={266} height={200} margin={'8px 0'} />
      </div>
    </div>
  );
};

export default HalfListingFeature;
