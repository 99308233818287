import React, { useState, useEffect } from 'react';
import { Icon, Modal, Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';

import './add-user-limit-reached.scss';
import { CLOSE_MAX_USER_LIMIT_REACHED_MODAL } from 'src/redux/reducers/appPermissionReducer';
import { getStripeProducts, addItemInSubscription } from 'src/redux/actions/stripeActions';
import AddUserIcon from '../Icons/AddUserIcon';

const AddUserLimitReachedModal = () => {
  const dispatch = useDispatch();
  const { openMaxUserLimitReachedModal, products, currentUser } = useSelector(state => ({
    openMaxUserLimitReachedModal: state.appPermissions.openMaxUserLimitReachedModal,
    products: state.stripe.products,
    currentUser: state.users.currentUser,
  }));
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const smallestPlan = products?.[0]?.plan?.[0];

  useEffect(() => {
    if (openMaxUserLimitReachedModal) {
      dispatch(getStripeProducts())
        .then(() => {
          console.log('Fetched the current plan');
        })
        .catch(err => {
          console.log('Something went wrong while fetching stripe products', err);
        });
    }
  }, [dispatch, openMaxUserLimitReachedModal]);

  const addExtendedUser = () => {
    // call the api to add 1 user to the subscription
    setLoading(true);
    setDisabled(true);
    dispatch(
      addItemInSubscription({
        user_id: currentUser?.id,
        price_id: smallestPlan.id,
      })
    )
      .then(resposne => {
        setLoading(false);
        setDisabled(false);
        addToast('Plan extended successfully, You can now Add / Invite a new user!', {
          appearance: 'success',
          autoDismiss: true,
        });

        dispatch({
          type: CLOSE_MAX_USER_LIMIT_REACHED_MODAL,
        });
      })
      .catch(err => {
        setLoading(false);
        setDisabled(false);
        addToast('Failed to extend the plan!', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const isLoggedIn = Object.keys(currentUser).length > 0;

  return (
    <Modal dimmer={true} open={openMaxUserLimitReachedModal} centered={false} size="small">
      <Modal.Header>
        <div className="flex">
          <AddUserIcon height={24} width={24} className="mr-4" style={{ color: 'orange' }} />
          {isLoggedIn ? <span>Add / Invite New User</span> : <span>Register New User</span>}
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="add-user-limit-reached-container">
          {isLoggedIn ? (
            <React.Fragment>
              <p>Congrats! Looks like your team is growing.</p>
              <p>
                You&apos;ve used all your licenses, but that&apos;s okay, you can always add more to your current plan.
              </p>
              <p>Each additional users is C$9.99, and will be added to your next bill.</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>Congrats! Looks like your team is growing.</p>
              <p>All the licenses under this brokerage is used, Contact your Brokerage Admin to purchase more</p>
            </React.Fragment>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          size="tiny"
          onClick={() =>
            dispatch({
              type: CLOSE_MAX_USER_LIMIT_REACHED_MODAL,
            })
          }
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          color="green"
          size="tiny"
          loading={loading}
          disabled={disabled}
          onClick={() => {
            if (isLoggedIn) {
              addExtendedUser();
            } else {
              dispatch({
                type: CLOSE_MAX_USER_LIMIT_REACHED_MODAL,
              });
            }
          }}
        >
          <Icon name="checkmark" />
          {isLoggedIn ? 'Yes, I agree' : 'Okay'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddUserLimitReachedModal;
