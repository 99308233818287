import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ListingGuard from 'src/components/listing-guard';
import PageNotFound from 'src/pages/PageNotFound';
import DefaultLayout from 'src/layouts/default-layout';
import { BROKER_CODES } from 'src/constants/permissions';
import Brochure1 from './pemberton/brochure1';
import Brochure2 from './pemberton/brochure2';
import Brochure3 from './pemberton/brochure3';
import Brochure4 from './pemberton/brochure4';
import Brochure5 from './pemberton/brochure5';
import Brochure6 from './pemberton/brochure6';

import MacdonaldBrochurePage1 from './macdonald/MacdonaldBrochurePage1';
import MacdonaldBrochurePage2 from './macdonald/MacdonaldBrochurePage2';
import MacdonaldBrochurePage3 from './macdonald/MacdonaldBrochurePage3';
import MacdonaldBrochurePage4 from './macdonald/MacdonaldBrochurePage4';
import MacdonaldBrochurePage5 from './macdonald/MacdonaldBrochurePage5';
import MacdonaldBrochurePage6 from './macdonald/MacdonaldBrochurePage6';
import MacdonaldBrochurePage7 from './macdonald/MacdonaldBrochurePage7';
import MacdonaldBrochurePage8 from './macdonald/MacdonaldBrochurePage8';
import WhistlerBrochure1 from 'src/templates/brochures/whistler/WhistlerBrochure1';
import WhistlerBrochure2 from 'src/templates/brochures/whistler/WhistlerBrochure2';
import WhistlerBrochure3 from 'src/templates/brochures/whistler/WhistlerBrochure3';
import WhistlerBrochure4 from 'src/templates/brochures/whistler/WhistlerBrochure4';
import WhistlerBrochure5 from 'src/templates/brochures/whistler/WhistlerBrochure5';
import WhistlerBrochure6 from 'src/templates/brochures/whistler/WhistlerBrochure6';
import WhistlerBrochure7 from 'src/templates/brochures/whistler/WhistlerBrochure7';
import WhistlerBrochure8 from 'src/templates/brochures/whistler/WhistlerBrochure8';
import WhistlerBrochurePage from './whistler/WhistlerBrochurePage';
import * as AllBrochureTemplates from 'src/templates/brochures';
import BaseBrochurePage from 'src/pages/brochure/BaseBrochurePage';
import { useTitle } from 'react-use';
import { BROCHURE_TEMPLATE_SELECTED, track } from 'src/utils/analytics';

const renderPemberton = (templateId, templates) => {
  switch (templateId) {
    case '1':
      return <Brochure1 />;
    case '2':
      return <Brochure2 />;
    case '3':
      return <Brochure3 />;
    case '4':
      return <Brochure4 />;
    case '5':
      return <Brochure5 />;
    case '6':
      return <Brochure6 />;
    default:
      const component = templates.find(st => st.name === templateId);
      const componentId = component?.componentId;
      const pages = component?.pages;
      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <BaseBrochurePage
          defaultURL={component.banner_default_URL}
          footerURL={component.footer_default_URL}
          Component={AllBrochureTemplates[componentId]}
          pages={pages}
        />
      );
  }
};

const renderMacdonald = (templateId, templates) => {
  switch (templateId) {
    case '1':
      return <MacdonaldBrochurePage1 />;
    case '2':
      return <MacdonaldBrochurePage2 />;
    case '3':
      return <MacdonaldBrochurePage3 />;
    case '4':
      return <MacdonaldBrochurePage4 />;
    case '5':
      return <MacdonaldBrochurePage5 />;
    case '6':
      return <MacdonaldBrochurePage6 />;
    case '7':
      return <MacdonaldBrochurePage7 />;
    case '8':
      return <MacdonaldBrochurePage8 />;
    default:
      const component = templates.find(st => st.name === templateId);
      const componentId = component?.componentId;
      const pages = component?.pages;
      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <BaseBrochurePage
          defaultURL={component.banner_default_URL}
          footerURL={component.footer_default_URL}
          Component={AllBrochureTemplates[componentId]}
          pages={pages}
        />
      );
  }
};

const renderWhistler = (templateId, templates) => {
  switch (templateId) {
    case '1':
      return <WhistlerBrochurePage Component={WhistlerBrochure1} pages={2} />;
    case '2':
      return <WhistlerBrochurePage Component={WhistlerBrochure2} pages={2} />;
    case '3':
      return <WhistlerBrochurePage Component={WhistlerBrochure3} pages={4} />;
    case '4':
      return <WhistlerBrochurePage Component={WhistlerBrochure4} pages={4} />;
    case '5':
      return <WhistlerBrochurePage Component={WhistlerBrochure5} pages={4} />;
    case '6':
      return <WhistlerBrochurePage Component={WhistlerBrochure6} pages={4} />;
    case '7':
      return <WhistlerBrochurePage Component={WhistlerBrochure7} pages={2} />;
    case '8':
      return <WhistlerBrochurePage Component={WhistlerBrochure8} pages={2} />;
    default:
      const component = templates.find(st => st.name === templateId);
      const componentId = component?.componentId;
      const pages = component?.pages;
      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <BaseBrochurePage
          defaultURL={component.banner_default_URL}
          footerURL={component.footer_default_URL}
          Component={AllBrochureTemplates[componentId]}
          pages={pages}
        />
      );
  }
};

const renderUniversalTemplate = (templateId, templates = []) => {
  const component = templates.find(st => st.name === templateId);
  const componentId = component?.componentId;

  const pages = component?.pages;
  return !componentId ? (
    <Redirect to="/dashboard" />
  ) : (
    <BaseBrochurePage
      defaultURL={component.banner_default_URL}
      footerURL={component.footer_default_URL}
      Component={AllBrochureTemplates[componentId]}
      pages={pages}
    />
  );
};

const BrochureRoute = props => {
  useTitle('Brochure - Realvault');

  const { id: templateId } = useParams();
  useEffect(() => {
    const templates = props.broucherTemplates;
    const template = templates.find(st => st.name === templateId);
    track(BROCHURE_TEMPLATE_SELECTED, { template });
  }, [templateId]);

  return (
    <DefaultLayout>
      {![BROKER_CODES.WHISTLER, BROKER_CODES.MACDONALD_REALTY, BROKER_CODES.PEMBERTON].includes(
        props.brokers.currentBroker?.template_code
      ) ? (
        renderUniversalTemplate(templateId, props.broucherTemplates)
      ) : (
        <ListingGuard>
          {({ searchedListing, templateId, brokers }) => {
            if (!searchedListing) return <Redirect to={`/dashboard`} />;
            if (brokers.currentBroker?.template_code === BROKER_CODES.PEMBERTON) {
              return renderPemberton(templateId, props.broucherTemplates);
            } else if (brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY) {
              return renderMacdonald(templateId, props.broucherTemplates);
            } else if (brokers.currentBroker?.template_code === BROKER_CODES.WHISTLER) {
              return renderWhistler(templateId, props.broucherTemplates);
            } else {
              return <PageNotFound />;
            }
          }}
        </ListingGuard>
      )}
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers, broucherTemplates: brokers.broucherTemplates };
};

export default connect(mapStateToProps)(BrochureRoute);
