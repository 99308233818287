import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import Logout from 'src/components/authorization/Logout';
import RevaultLogo from 'src/components/Icons/RevaultLogo';

import './onboarding-layout.scss';

const OnboardingLayout = ({ backgroundImage, user = null, children }) => {
  return (
    <div className="onboarding-layout bg-white flex flex-col">
      <div className="navbar">
        <div className="container max-w-screen-xl mx-auto flex items-center h-full">
          <div className="flex flex-1 justify-between items-center px-6 xl:px-0">
            <span className="logo hidden md:block">
              <RevaultLogo width={256} />
            </span>
            <ul className="flex flex-1 items-center justify-start md:mx-8 list-container">
              <li className="font-semibold mx-2 md:mx-4 lg:mx-8 text-black pb-1">
                <a href="http://realvault.ca/">Home</a>
              </li>
              <li className="font-semibold mx-2 md:mx-4 lg:mx-8 text-black pb-1">
                <NavLink to="/onboarding/pricing">Pricing</NavLink>
              </li>
            </ul>
            {user?.email ? (
              <Logout />
            ) : (
              <Link to="/login">
                <Button color="green">Login</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {backgroundImage ? (
        <div
          className="content-area background-attachment flex-1"
          style={{
            background: `url('${backgroundImage}') rgba(255, 255, 255, 0.5)`,
          }}
        >
          <div className="container max-w-screen-xl mx-auto px-6 xl:px-0">{children}</div>
        </div>
      ) : (
        <div className="content-area flex-1">
          <div className="container max-w-screen-xl mx-auto px-6 xl:px-0">{children}</div>
        </div>
      )}
    </div>
  );
};

export default OnboardingLayout;
