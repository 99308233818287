import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Wordmark_horizontal_white.png';
import EditableImage from 'src/components/editable-image';
import EditTextModal from 'src/components/edit-text-modal';
import { register } from 'src/services/template-renderer';
import BackgroundImage from 'src/components/background-image';
import { formatCurrencyNoDecimal } from 'src/utils/format-number';
import Scale from 'src/components/scale';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';

import './WhistlerBrochure1.scss';
import WhistlerBrochureTemplate from 'src/templates/base/WhistlerBrochureTemplate';

const TEMPLATE_NAME = '2PG - Custom Agent Banner';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-1 ui-only-outline no-hover`}>
      <div className="hero-image">
        <EditableImage
          self={self}
          fieldName="image1"
          className="hero-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={0}
        />
      </div>
      <div className="content-wrapper">
        <div className="address">
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
            }}
            value={
              listing.location.streetAddress.length > 40
                ? listing.location.streetAddress.slice(0, 40)
                : listing.location.streetAddress
            }
            onChange={e => {
              if (e.target.value.length < 40) {
                mergeCurrentListing({
                  location: {
                    ...listing.location,
                    streetAddress: e.target.value,
                  },
                });
              }
            }}
          />
        </div>
        <div className="desc-wrapper">
          <div className="description">
            <textarea
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                height: '130px',
                overflow: 'hidden',
                resize: 'none',
                wordBreak: 'break-word',
                lineHeight: '14px',
              }}
              value={listing.description.length > 800 ? listing.description.slice(0, 800) : listing.description}
              onChange={e => {
                mergeCurrentListing({
                  description: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="logo">
          <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
        </div>
      </div>
    </div>
  );
};

const Page2 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-2 ui-only-outline`}>
      <div className="image-container">
        <EditableImage
          self={self}
          fieldName="image2"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={1}
        />
        <EditableImage
          self={self}
          fieldName="image3"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={self}
          fieldName="image4"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={3}
        />
        <EditableImage
          self={self}
          fieldName="image5"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
        />
      </div>
      <div className="features-container no-hover">
        <div className="features-list">
          <div className="feature">
            Price:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={15}
                value={
                  formatCurrencyNoDecimal(listing.price.priceDisplay).length > 15
                    ? formatCurrencyNoDecimal(listing.price.priceDisplay).slice(0, 15)
                    : formatCurrencyNoDecimal(listing.price.priceDisplay)
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {listing.isMLSVisible && (
            <div className="feature">
              WLS Id:
              <span style={{ width: '150px', marginLeft: '6px' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '100%',
                  }}
                  maxLength={30}
                  value={listing.mlsId.length > 30 ? listing.mlsId.slice(0, 30) : listing.mlsId}
                  onChange={e => {
                    if (e.target.value.length <= 30) {
                      mergeCurrentListing({
                        mlsId: e.target.value,
                      });
                    }
                  }}
                />
              </span>
            </div>
          )}
          <div className="feature">
            Bedrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                value={
                  listing.details.qtyBedrooms.length > 15
                    ? listing.details.qtyBedrooms.slice(0, 15)
                    : listing.details.qtyBedrooms
                }
                maxLength={15}
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Bathrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={15}
                value={
                  listing.details.qtyFullBathrooms.length > 15
                    ? listing.details.qtyFullBathrooms.slice(0, 15)
                    : listing.details.qtyFullBathrooms
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Year Built:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={15}
                value={
                  listing.details.yearBuilt.length > 15
                    ? listing.details.yearBuilt.slice(0, 15)
                    : listing.details.yearBuilt
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        yearBuilt: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Sub-area:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={30}
                value={
                  listing.details.neighborhood.length > 30
                    ? listing.details.neighborhood.slice(0, 30)
                    : listing.details.neighborhood
                }
                onChange={e => {
                  if (e.target.value.length <= 30) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Maint. Fee:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '90%',
                  marginLeft: '3px',
                }}
                maxLength={15}
                value={
                  listing.details.strataFee.length > 15
                    ? listing.details.strataFee.slice(0, 15)
                    : listing.details.strataFee
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        strataFee: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Gross Taxes:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '90%',
                  marginLeft: '3px',
                }}
                maxLength={15}
                value={
                  listing.details?.grossTax?.length > 15
                    ? listing.details?.grossTax.slice(0, 15)
                    : listing.details?.grossTax
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        grossTax: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Floor Area:
            <span style={{ display: 'flex', whiteSpace: 'nowrap', width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '75%',
                  marginLeft: '3px',
                }}
                maxLength={15}
                value={
                  listing.details.squareFeet.length > 15
                    ? listing.details.squareFeet.slice(0, 15)
                    : listing.details.squareFeet
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        squareFeet: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Lot Size:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={15}
                value={
                  listing.details.lotSizeTotal.length > 15
                    ? listing.details.lotSizeTotal.slice(0, 15)
                    : listing.details.lotSizeTotal
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        lotSizeTotal: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
        </div>
        {/*<div className="edit-btn-hover not-in-pdf">
          <EditTextModal activeTab={0} triggerTitle="Edit Features" />
        </div>*/}
      </div>
      <div className="agent-banner-container">
        <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
        {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
          <span className="empty-placeholder not-in-pdf"></span>
        )}
        <div className="edit-btn-hover not-in-pdf">
          <Scale.Back>
            <div style={{ position: 'relative', left: '-27%' }}>
              <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
            </div>
          </Scale.Back>
        </div>
      </div>
    </div>
  );
};

class WhistlerBrochure1 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, image3, image4, image5 } = props;

    const { photos, description, location } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      location,
      description,
    };
  }

  render() {
    const { currentListing, mergeCurrentListing } = this.props;
    const { image1, image2, image3, image4, image5 } = this.state;
    WhistlerBrochure1._cached = { image1, image2, image3, image4, image5 };
    let customMergeListing = obj => {
      const newListing = _.omit({ ...currentListing, ...obj }, true);
      mergeCurrentListing(newListing);
    };
    return (
      <WhistlerBrochureTemplate className="whistler-brochure-1-wrapper">
        <Page1 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page2 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
      </WhistlerBrochureTemplate>
    );
  }
}

WhistlerBrochure1._name = TEMPLATE_NAME;
WhistlerBrochure1._width = TEMPLATE_WIDTH;
WhistlerBrochure1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerBrochure1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(WhistlerBrochure1);
