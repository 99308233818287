import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Header, Dropdown, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import FontPreview from 'src/components/FontPreview';
import PageTitle from 'src/components/PageTitle/page-title';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import { setDefaultSettings, getAllFonts, saveUploadedBrandLogo } from 'src/redux/actions/theme-creator';
import './default-branding.scss';
import FontPicker from 'font-picker-react';
import { useTitle } from 'react-use';
import { ONBOARDING_SKIP_SELECT_FONT, track } from 'src/utils/analytics';

const DefaultBranding = ({ onSubmit }) => {
  useTitle('Branding - Realvault');

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { allFonts } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allFonts: state.theme.allFonts,
  }));

  const FONT_OPTIONS = allFonts?.map(font => ({
    ...font,
    key: font.family,
    text: font.family,
    value: font.family,
  }));

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    font: '',
    squareLogo: '',
    horizontalLogo: '',
    verticalLogo: '',
    brandLogo: '',
  });

  useEffect(() => {
    dispatch(getAllFonts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFormHandler = (key, value) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeSettings = async event => {
    event.preventDefault();
    setLoading(true);
    const localBasicDetails = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));

    // UPLOAD
    try {
      // upload the square logo, horizontal logo and vertical logo
      dispatch(
        setDefaultSettings(localBasicDetails?.broker_id, {
          ...values,
        })
      )
        .then(res => {
          setLoading(false);
          // call the api to update the brand logo

          addToast('Settings changed successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });

          onSubmit();
        })
        .catch(err => {
          setLoading(false);
          addToast(err?.response?.data?.error, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log('ERR => while changing the default setting', err);
        });
    } catch (err) {
      setLoading(false);
      addToast(err?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="default-branding">
      <div className="w-full md:w-4/5 mx-auto">
        <div className="mx-auto">
          <PageTitle title="Almost there! Pick your font" hideContainer />
          <div className="bg-white shadow-md rounded-sm w-full p-6 md:p-8">
            <Form onSubmit={onChangeSettings}>
              <Form.Field className="pt-6">
                <Header as="h5">Font Type</Header>
                <FontPicker
                  apiKey="AIzaSyC2Q2CfcyWZAe4dABQncovnxXETju6ZPwE"
                  className="font-picker"
                  placeholder="Choose a font type"
                  activeFontFamily={values.font}
                  onChange={font => {
                    changeFormHandler('font', font.family);
                  }}
                />
              </Form.Field>
              <div className="mt mb-8">
                <FontPreview name={values.font} />
              </div>
              <div className="text-center mt-16">
                <OutlinedButton
                  onClick={onChangeSettings}
                  style={{
                    border: '1px solid #233349',
                    backgroundColor: '#233349',
                    color: 'white',
                    textTransform: 'uppercase',
                  }}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <Loader active size="mini" inline inverted />
                    </div>
                  ) : (
                    'Update Now'
                  )}
                </OutlinedButton>
              </div>
              <div className="mt-8 text-center">
                <div
                  onClick={ev => {
                    track(ONBOARDING_SKIP_SELECT_FONT, {});
                    onSubmit(ev);
                  }}
                  className="skip-link"
                >
                  <u>Skip</u>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultBranding;
