import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from 'src/services/template-renderer';
import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import PlaceholderImage from 'src/images/banner.png';
import { staticBrokerFonts } from 'src/utils/generic-utils';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './SocialTemplate2.scss';

const TEMPLATE_NAME = 'Revault_Social_Template_2';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class SocialTemplate2 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, adjustedHeight, adjustedHeightAdr, listingDetails, defaultURL } =
      props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      address,
      adjustedHeight,
      adjustedHeightAdr,
      listingDetails,
      defaultURL,
    };
  }

  render() {
    const { currentListing, appliedTheme, defaultSettings, currentBroker } = this.props;
    const { headline, image1, listingDetails, address, adjustedHeight, adjustedHeightAdr } = this.state;
    SocialTemplate2._cached = {
      headline,
      image1,
      listingDetails,
      address,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="vw-social-template-2-wrapper">
        <div className="header-image-container">
          <EditableImage
            self={this}
            fieldName="image1"
            className="image1"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={0}
          />
          <img
            src={this.state.defaultURL || defaultSettings?.squareLogo || PlaceholderImage}
            className="logo-img"
            alt="brand-logo111"
          />
        </div>
        <div className="sub-main">
          <div className="card-div">
            <ApplyFont fontName={appliedTheme?.font || defaultSettings?.font}>
              <EditableTextarea
                self={this}
                onHeightChange={v => (SocialTemplate2._cached.adjustedHeight = v)}
                initialHeight={adjustedHeight}
                className="headline"
                fieldName="headline"
                placeholder="Headline"
                rows={1}
                style={{
                  fontFamily:
                    appliedTheme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.medium,
                  color: appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
                }}
              />
              <EditableTextarea
                self={this}
                onHeightChange={v => (SocialTemplate2._cached.adjustedHeightAdr = v)}
                initialHeight={adjustedHeightAdr}
                className="address"
                fieldName="address"
                placeholder="#123 - 456 W Georgia St Vancouver"
                rows={1}
                style={{
                  fontFamily:
                    appliedTheme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                  color: defaultSettings?.secondary,
                }}
              />
            </ApplyFont>
          </div>
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

SocialTemplate2._name = TEMPLATE_NAME;
SocialTemplate2._width = TEMPLATE_WIDTH;
SocialTemplate2._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: SocialTemplate2, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps)(SocialTemplate2);
