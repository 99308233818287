import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  STORE_USERS,
  UPDATE_CURRENT_USER_BROKER_ID,
} from '../../constants/action-types';

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  users: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        currentUser: user,
      };
    }
    case CLEAR_CURRENT_USER: {
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
      };
    }
    case STORE_USERS: {
      const { users } = action.payload;
      return {
        ...state,
        users,
      };
    }
    case UPDATE_CURRENT_USER_BROKER_ID: {
      const { broker_id } = action.payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          broker_id,
        },
      };
    }
    default:
      return state;
  }
};
