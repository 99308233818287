import React, { useState, useEffect, useCallback } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import InviteUser from 'src/components/admin/InviteUser';
import RequestTable from 'src/components/admin/RequestTable';
import UserModal from 'src/components/admin/UserModal';
import UserTable from 'src/components/admin/UserTable';
import { USER_ROLES } from 'src/constants/permissions';
import AdminLayout from 'src/layouts/admin-layout';
import { storeUsers } from 'src/redux/actions/userActions';
import { createRequestHeader } from 'src/services/request-headers';

import { getSubscriptionStatus, getBrokerTotalUsers, getStripeProducts } from 'src/redux/actions/stripeActions';
import { getTotalStripeUsers } from 'src/utils/stripe-utils';

import './manage-users.scss';
import { useTitle } from 'react-use';

const ManageUsers = () => {
  useTitle('Manage Users - Realvault');

  const dispatch = useDispatch();
  const { users, brokers, products, currentSubscription, brokerageUserCount } = useSelector(state => ({
    users: state.users,
    brokers: state.brokers,
    products: state.stripe.products,
    currentSubscription: state.stripe.currentSubscription,
    brokerageUserCount: state.stripe.brokerageUserCount,
  }));
  const [loading, setLoading] = useState(false);

  let currentBroker;
  if (users.currentUser.user_role_id === USER_ROLES.BROKER_ADMIN) {
    currentBroker = users.currentUser.broker_id;
  }

  const fetchUsers = useCallback(() => {
    setLoading(true);
    return axios
      .get(`${process.env.REACT_APP_API}/user`, createRequestHeader())
      .then(res => {
        setLoading(false);
        dispatch(storeUsers(res.data));
      })
      .catch(error => {
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchUsers();

    // get subscription status
    dispatch(
      getSubscriptionStatus({
        user_id: users.currentUser?.id,
      })
    )
      .then(data => {
        console.log(data, 'getSubscriptionStatus Response here');
      })
      .catch(err => {
        console.log('Something went wrong while fetching stripe products', err);
      });

    // get the total users in a brokerage
    dispatch(getBrokerTotalUsers(users?.currentUser?.broker_id))
      .then(data => {
        console.log('Fetched brokerage total users');
      })
      .catch(err => {
        console.log('ERR => while fetching brokers');
      });

    // get all stripe products
    dispatch(getStripeProducts())
      .then(data => {
        console.log('Fetched all stripe products');
      })
      .catch(err => {
        console.log('ERR => while fetching all stripe products');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center">
          <Loader active={loading} />
        </div>
      </AdminLayout>
    );
  }

  const currentPlan = products?.[0]?.plan?.find(plan => plan.id === brokers?.currentBroker?.stripe_plan_id);

  let extraPlan;

  if (currentSubscription?.items?.data?.length === 1) {
    // check for the count in the same object
    extraPlan = {
      ...currentSubscription?.items?.data[0],
      quantity: currentSubscription?.items?.data[0]?.quantity - 1,
    };
  } else {
    extraPlan = currentSubscription?.items?.data?.find(subscriptionItem => {
      return subscriptionItem.plan.id !== currentPlan?.id;
    });
  }

  const totalStripePlanUsers = getTotalStripeUsers(currentSubscription?.items?.data);

  return (
    <AdminLayout
      pageTitle="Manage Users"
      rightContent={
        <React.Fragment>
          <div className="flex">
            {[USER_ROLES.BROKER_ADMIN, USER_ROLES.SYSTEM_ADMIN].includes(users?.currentUser?.user_role_id) && (
              <InviteUser />
            )}
            <UserModal getUsers={fetchUsers} add broker={currentBroker} />
          </div>
        </React.Fragment>
      }
    >
      <Container className="manage-users">
        {/* Plan stats */}
        {(!brokers?.currentBroker?.manual_billing || brokers?.currentBroker?.manual_billing === '0') &&
          brokers?.currentBroker?.stripe_subscription_id && (
            <div className="plan-stats-container">
              <div className="stat-row">
                <span className="heading">Users in Plan: </span>
                <span>{currentPlan?.metadata?.max_user} Users</span>
                {currentSubscription && (
                  <>
                    {currentSubscription?.status === 'active' ? (
                      <span className="active-status">Active</span>
                    ) : (
                      <span className="cancelled-status">Cancelled</span>
                    )}
                  </>
                )}
              </div>
              <div className="stat-row">
                <span className="heading">User license remaining: </span>
                {currentSubscription && <span>{totalStripePlanUsers - brokerageUserCount} Users</span>}
              </div>
              <div className="stat-row">
                <span className="heading">Extra users added: </span>
                <span>{extraPlan?.quantity || 0} Users</span>
              </div>
            </div>
          )}
        {users.currentUser.user_role_id === USER_ROLES.SYSTEM_ADMIN && (
          <RequestTable users={users?.users} getUsers={fetchUsers} />
        )}
        <br />
        <UserTable users={users?.users} brokers={brokers?.brokers} user={users.currentUser} getUsers={fetchUsers} />
      </Container>
    </AdminLayout>
  );
};

export default ManageUsers;
