import React from 'react';
import './AllWhistlerSocialTemplatesContainer.scss';

import PreviewItem from 'src/components/preview-item';
import WhistlerSocialTemplate1 from './WhistlerSocialTemplate1';
import WhistlerSocialTemplate2 from './WhistlerSocialTemplate2';
import WhistlerSocialTemplate3 from './WhistlerSocialTemplate3';
import WhistlerSocialTemplate5 from './WhistlerSocialTemplate5';
import WhistlerSocialTemplate4 from './WhistlerSocialTemplate4';
import WhistlerSocialTemplate6 from './WhistlerSocialTemplate6';
import WhistlerSocialTemplate7 from './WhistlerSocialTemplate7';
import WhistlerSocialTemplate8 from './WhistlerSocialTemplate8';
import WhistlerSocialTemplate9 from './WhistlerSocialTemplate9';
import WhistlerSocialTemplate10 from './WhistlerSocialTemplate10';
import WhistlerSocialTemplate11 from './WhistlerSocialTemplate11';
import WhistlerSocialTemplate12 from './WhistlerSocialTemplate12';
import WhistlerSocialTemplate13 from './WhistlerSocialTemplate13';
import WhistlerSocialTemplate14 from './WhistlerSocialTemplate14';
import WhistlerSocialTemplate15 from './WhistlerSocialTemplate15';
import WhistlerSocialTemplate16 from './WhistlerSocialTemplate16';
import WhistlerSocialTemplate17 from './WhistlerSocialTemplate17';
import WhistlerSocialTemplate18 from './WhistlerSocialTemplate18';

const AllWhistlerSocialTemplatesContainer = ({
  mlsId,
  type,
  headline = 'Add Text',
  targetWidth,
  initialRouteParam = '',
}) => {
  return (
    <div className="whistler-social-templates-container">
      <PreviewItem
        title="Whistler 1"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w1`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate1}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 2"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w2`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate2}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 3"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w3`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate3}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 4"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w4`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate4}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 5"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w5`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate5}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 6"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w6`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate6}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 7"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w7`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate7}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 8"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w8`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate8}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 9"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w9`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate9}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 10"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w10`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate10}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 11"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w11`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate11}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 12"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w12`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate12}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 13"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w13`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate13}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 14"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w14`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate14}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 15"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w15`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate15}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 16"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w16`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate16}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 17"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w17`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate17}
        data={{ headline }}
      />
      <PreviewItem
        title="Whistler 18"
        linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/w18`}
        targetWidth={targetWidth}
        Component={WhistlerSocialTemplate18}
        data={{ headline }}
      />
    </div>
  );
};

export default AllWhistlerSocialTemplatesContainer;
