import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Modal, Button } from 'semantic-ui-react';
import WarningIcon from 'src/components/Icons/WarningIcon';
import { OPEN_CONTACT_ADMIN_MODAL } from 'src/redux/reducers/appPermissionReducer';

import { CLOSE_CONTACT_ADMIN_MODAL } from 'src/redux/reducers/appPermissionReducer';

import './contact-admin-modal.scss';

const ContactAdminModal = props => {
  const dispatch = useDispatch();
  const { openContactAdminModal } = useSelector(state => ({
    openContactAdminModal: state.appPermissions.openContactAdminModal,
  }));

  const closeModal = () => {
    dispatch({
      type: CLOSE_CONTACT_ADMIN_MODAL,
    });
  };

  return (
    <Modal dimmer={true} open={openContactAdminModal} centered size="small">
      <Modal.Header>
        <div className="flex">
          <WarningIcon height={26} width={26} className="mr-4" style={{ color: 'orange' }} />
          <span>No Active Subscription!</span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="contact-admin-modal-container">
          <p>Oops! Looks like you dont have any Active Subscription Plan!</p>
          <p>Please contact your Administrator to purchase a plan!</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" size="tiny" onClick={closeModal}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button color="green" size="tiny" onClick={closeModal}>
          <Icon name="checkmark" />
          Okay
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactAdminModal;
