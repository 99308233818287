// Format Sq Ft areas, e.g. Lot Size, Floor Area
export const formatArea = value => {
  if (value === 'n/a' || value === undefined || value === '') return 'n/a';
  else return new Intl.NumberFormat().format(value.split(' ')[0].replace(',', '')) + ' sq ft';
};

// Format dollar amounts, e.g. Taxes, Strata Fee
export const formatCurrency = value => {
  if (value === 'n/a' || value === undefined || value === '') return 'n/a';
  else {
    value = value.replace(/[$,]/g, '');
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }
};

// Format dollar amounts, e.g. Taxes, Strata Fee but without any decimal values
export const formatCurrencyNoDecimal = value => {
  if (value === 'n/a' || value === undefined) return 'n/a';
  else {
    value = value.toString().replace(/[$,]/g, '');
    const convertedVal = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
    if (convertedVal === '$NaN') {
      return '';
    }
    return convertedVal;
  }
};
