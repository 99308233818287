import React from 'react';
import CompanyTransparentLogo from 'src/images/logo/transparent_app_logo.png';

function RevaultLogo({ height = 'auto', width = 'auto' }) {
  return (
    <a href="http://realvault.ca/">
      <img
        alt="revault-logo"
        src={CompanyTransparentLogo}
        style={{
          height,
          width,
          objectFit: 'contain',
        }}
      />
    </a>
  );
}

export default RevaultLogo;
