import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/macdonald/macdonald-realty-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './instagram1.scss';

const TEMPLATE_NAME = 'INSTAGRAM_STORY_1';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1920;

class InstagramTemplate1 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, detail1, detail2 } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;

    const bed = qtyBedrooms;
    const bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    const sqft = squareFeet;

    if (!detail1) detail1 = `Beds: ${bed}  |  Bath: ${bath}`;
    if (!detail2) detail2 = `${sqft} SQFT`;

    this.state = {
      headline,
      image1,
      address,
      detail1,
      detail2,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, detail1, detail2 } = this.state;
    InstagramTemplate1._cached = { headline, image1, address, detail1, detail2 };

    return (
      <div className="instagram-1-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          opacity={0.55}
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        <hr />
        <EditableTextarea
          self={this}
          className="address"
          fieldName="address"
          placeholder="#123 - 456 W Georgia St Vancouver"
        />
        <EditableInput self={this} className="detail1" fieldName="detail1" placeholder="Bed: X | Bath: X" />
        <EditableInput self={this} className="detail2" fieldName="detail2" placeholder="XXX SQFT" />
        <div className="logo">
          <img src={Logo} width="325.7" height="246.3" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

InstagramTemplate1._name = TEMPLATE_NAME;
InstagramTemplate1._width = TEMPLATE_WIDTH;
InstagramTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: InstagramTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(InstagramTemplate1);
