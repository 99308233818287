import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Header, Form, Input, Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { addUpdateCREASearchOfficeKey } from 'src/redux/actions/brokerActions';
import AdminLayout from 'src/layouts/admin-layout';

import './FeedSettings.scss';
import { useTitle } from 'react-use';

const FeedSettings = () => {
  useTitle('Feed Settings - Realvault');
  const dispatch = useDispatch();
  const { currentBroker } = useSelector(state => state.brokers);
  const creaListingKey = currentBroker?.crea_listing_office_key;

  const [listingKey, setListingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (creaListingKey) {
      let newcreaListingKey = creaListingKey;
      if (creaListingKey.trim() === '') {
        newcreaListingKey = '';
      }
      setListingKey(newcreaListingKey);
    }
  }, [creaListingKey]);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    let newListingKey = listingKey;
    if (listingKey.trim() === '') {
      newListingKey = ' ';
    }
    dispatch(
      addUpdateCREASearchOfficeKey(currentBroker?.id, {
        crea_listing_office_key: newListingKey,
      })
    )
      .then(response => {
        setLoading(false);
        addToast(`CREA Listing ${creaListingKey ? 'updated' : 'added'} successfully!`, {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(error => {
        setLoading(false);
        addToast(error.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  return (
    <AdminLayout pageTitle="Feed Settings">
      <Container className="feed-settings">
        <div className="feed-settings-container white mt-10">
          <Form onSubmit={handleSubmit}>
            <Form.Field width={6}>
              <label>
                {currentBroker.feed_type.trim().toLowerCase() === 'cre' ? 'CREA' : 'VIVA'} Listing Office Key
              </label>
              <Input
                placeholder={`Enter ${
                  currentBroker.feed_type.trim().toLowerCase() === 'cre' ? 'CREA' : 'VIVA'
                } Listing Office Key...`}
                value={listingKey}
                onChange={event => setListingKey(event.target.value)}
              />
            </Form.Field>
            <Button type="submit" primary loading={loading}>
              {creaListingKey ? 'Update Key' : 'Add Key'}
            </Button>
          </Form>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default FeedSettings;
