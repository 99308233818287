import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate9.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_9';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate9 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address } = props;

    const { photos, location } = currentListing;
    const { streetAddress, city } = location;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;

    this.state = {
      headline,
      image1,
      address,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address } = this.state;
    WhistlerSocialTemplate9._cached = { headline, image1, address };

    return (
      <WhistlerSocialTemplate className="whistler-social-9-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="details-container">
          <div className="content">
            <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
            <EditableInput
              self={this}
              className="address"
              fieldName="address"
              placeholder="#123 - 456 W Georgia St Whistler"
            />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate9._name = TEMPLATE_NAME;
WhistlerSocialTemplate9._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate9._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate9, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate9);
