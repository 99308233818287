import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import FileDownload from 'js-file-download';
import templateRenderer from 'src/services/template-renderer';
import { createRequestHeader } from 'src/services/request-headers';
import { postEventDetails } from 'src/utils/login-utils';
import { DOWNLOAD_WITH_QR_CODE_CLICKED, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const GetQrCode = ({
  defaultURL,
  footerURL,
  templateKey,
  data,
  filename = 'template',
  messageInterface,
  toggleMessage,
}) => {
  const { addToast } = useToasts();
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));

  const handleButtonClick = event => {
    event.preventDefault();
    track(DOWNLOAD_WITH_QR_CODE_CLICKED, { templateKey, data, filename });
    generateOnlineBrochure();
    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Preparing your download with QR Code.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
    });
  };

  const generateOnlineBrochure = async () => {
    const { html, width, height, targetWidth, targetHeight } = await templateRenderer.render(
      templateKey,
      { ...data, defaultURL, footerURL },
      { useCache: false }
    );
    axios
      .post(
        '/api/generate-brochure-share-link',
        { html, width, height, targetWidth, targetHeight, filename },
        { ...createRequestHeader() }
      )
      .then(({ data: responseData }) => {
        downloadPDFwithQR(responseData.url);
      })
      .catch(error => {
        addToast('Failed to generate QR Code, Please try again', {
          appearance: 'error',
          autoDismiss: true,
        });
        toggleMessage({ isVisible: false, isLoading: false, message: { title: null, note: null } });
      });
  };

  const downloadPDFwithQR = async url => {
    const codeURL = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      url
    )}&size=150x150&margin=20`;

    const qrCodeOverlayAngledRibbon = `
      <style>
        .qr-frame {
          --frameColor: #233359;

          position: absolute;
          right: 0px; /* position on brochure */
          top: 0px; /* position on brochure */
          width: 250px;
          height: 250px;

          box-shadow: 0px 0px 0px 40px var(--frameColor) inset;
          background: white;

          display: flex;
          align-items: center;
          justify-content: center;

          z-index: 1000000;
        }
        .qr-code {
          width: 150px;
          height: 150px;
          display: inline-block;
          background-image: url("${codeURL}");
        }
        .qr-frame::before {
          --fontSize: 1rem; 
          --ribbonTips: 25px; 
          --topOffset: 10px;
          --ribbonColor: #4a65aa;

          position: absolute;
          top: 0px;
          right: 0px;

          content: attr(ribbon-text);
          text-align: center;
          font-size: var(--fontSize);
          font-weight: 500;
          font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
          padding: 8px;
          color: white;

          inset: 0 0 auto auto;
          transform-origin: 0 0; 
          transform: translate(29.3%) rotate(45deg);
          
          background: var(--ribbonColor);
          box-shadow: 0 0 0 100px var(--ribbonColor);
          clip-path: 
            polygon(
              0 0,
              100% 0,
              calc(100% + 35px) 35px,
              calc(-35px) 35px
            );
          }
      </style>
      
      <div class="qr-frame"  ribbon-text="Digital Brochure">
        <div class="qr-code"></div>
      </div>
    `;

    const response = await templateRenderer.render(
      templateKey,
      { ...data, ...currentBroker, defaultURL, footerURL },
      {
        useCache: true,
      }
    );
    const { html, width, height, targetWidth = null, targetHeight = null } = response;

    axios
      .post(
        `${process.env.REACT_APP_API}/pdf`,
        {
          html: `<div style="width:100%"><div style="width:20%">${qrCodeOverlayAngledRibbon}</div> ${html}</div>`,
          width,
          height,
          targetWidth,
          targetHeight,
          filename,
        },
        { responseType: 'blob', ...createRequestHeader() }
      )
      .then(res => {
        const contentDisposition = res.headers['content-disposition'];
        const filename = contentDisposition.substr(contentDisposition.indexOf('=') + 1);
        postEventDetails({
          type: 'download',
          time: new Date().toISOString,
          email: currentUser.email,
          broker: currentUser.broker_id,
          template: templateKey,
        });
        FileDownload(res.data, filename);
        toggleMessage({ isVisible: false, isLoading: false, message: { title: null, note: null } });
      })
      .catch(error => {
        addToast('Failed to download PDF, Please try again', {
          appearance: 'error',
          autoDismiss: true,
        });
        toggleMessage({ isVisible: false, isLoading: false, message: { title: null, note: null } });
      });
  };

  return (
    <>
      <Button size="tiny" color="olive" disabled={messageInterface.isVisible} onClick={handleButtonClick}>
        Get QR code
      </Button>
    </>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(GetQrCode);
