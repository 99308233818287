import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { MLS_ID } from 'src/constants/API';
import { createRequestHeader } from 'src/services/request-headers';

import { setBothListing, clearBothListing } from 'src/redux/actions/actions';

const ListingGuard = ({ searchedListing, setBothListing, clearBothListing, children, brokers }) => {
  const { id: templateId, type, mlsId } = useParams();

  const [loaded, setLoaded] = useState(!mlsId || (searchedListing && searchedListing.ID === mlsId));
  if (!loaded) {
    axios
      .get(`${process.env.REACT_APP_API}${MLS_ID(mlsId)}`, createRequestHeader())
      .then(({ data }) => {
        if (data.length === 0) clearBothListing();

        // if mlsid found, then use the first one
        setBothListing(data[0]);
        setLoaded(true);
      })
      .catch(error => {
        clearBothListing();
        setLoaded(true);
        console.error(error);
      });

    return null;
  }

  return children({ searchedListing, mlsId, templateId, type, brokers });
};

const mapStateToProps = state => {
  const { searchedListing, brokers } = state;
  return { searchedListing, brokers };
};

export default connect(mapStateToProps, { setBothListing, clearBothListing })(ListingGuard);
