export const SYSTEM_ADMIN = 1;
export const USER_ROLES = {
  SYSTEM_ADMIN: 1,
  BROKER_ADMIN: 2,
  STANDARD: 3,
};

export const USER_STATUS = [
  { key: 1, value: 1, text: 'Registered' },
  { key: 2, value: 2, text: 'Active' },
  { key: 3, value: 3, text: 'Suspended' },
  { key: 4, value: 4, text: 'Deleted' },
];

export const USER_PERMISSIONS = [
  { key: 5, value: 1, text: 'System Admin' },
  { key: 6, value: 2, text: 'Broker Admin' },
  { key: 7, value: 3, text: 'Standard User' },
];

export const PERMISSION_HASH = {
  1: 'System Admin',
  2: 'Broker Admin',
  3: 'Standard User',
};

export const STATUS_HASH = {
  1: 'Registered',
  2: 'Active',
  3: 'Suspended',
  4: 'Deleted',
};

export const BROKER_CODES = {
  PEMBERTON: 'PMB',
  MACDONALD_REALTY: 'MCD',
  WHISTLER: 'WRC',
  VANTAGE_WEST: 'VNW',
};
