import mixpanel from 'mixpanel-browser';
import LogRocket from 'logrocket';

// tracked events
export const SIGN_UP_EVENT = 'Sign Up';
export const LOGIN_EVENT = 'Login';
export const ONBOARDING_PRICING_PLAN_SELECTED_EVENT = 'Onboarding Pricing Plan Selected';
export const ONBOARDING_BASIC_DETAILS_SUBMITTED_EVENT = 'Onboarding Basic Details Submitted';
export const ONBOARDING_LOGOS_SELECTED_EVENT = 'Onboarding Logos Selected';
export const ONBOARDING_SKIP_SELECT_LOGO = 'Onboarding Skip Select Logo';
export const ONBOARDING_COLOR_SELECTED_EVENT = 'Onboarding Color Selected';
export const ONBOARDING_SKIP_SELECT_COLOR = 'Onboarding Skip Select Color';
export const ONBOARDING_FONT_SELECTED_EVENT = 'Onboarding Font Selected';
export const ONBOARDING_SKIP_SELECT_FONT = 'Onboarding Skip Select Font';
export const ONBOARDING_PAYMENT_SUCCESS_EVENT = 'Onboarding Payment Success';
export const LISTING_SEARCHED_EVENT = 'Listing Searched';
export const LISTING_RESULT_SELECTED_EVENT = 'Listing Result Selected';
export const ADVERTISING_TAB_LOADED = 'Advertising Tab Loaded';
export const ADVERTISING_TEMPLATE_SELECTED = 'Advertising Template Selected';
export const BROCHURE_TEMPLATE_SELECTED = 'Brochure Template Selected';
export const BROCHURES_TAB_LOADED = 'Brochures Tab Loaded';
export const SOCIAL_MEDIA_TAB_LOADED = 'Social Media Tab Loaded';
export const SOCIAL_MEDIA_TEMPLATE_SELECTED = 'Social Media Template Selected';
export const BROCHURE_SHARE_LINK_COPIED = 'Brochure Share Link Copied';
export const DOWNLOAD_BROCHURE_PDF_CLICKED = 'Download Brochure PDF Clicked';
export const DOWNLOAD_SOCIAL_PDF_CLICKED = 'Download Social PDF Clicked';
export const POST_TO_FACEBOOK_CLICKED_EVENT = 'Post to Facebook Clicked';
export const SEND_TO_PUBLISHER_EVENT = 'Send to Publisher Clicked';
export const PUBLISHER_CLICKED_EVENT = 'Publisher Clicked';
export const DOWNLOAD_ADVERTISING_PDF_CLICKED = 'Download Advertising PDF Clicked';
export const ADD_DOCUMENT_CLICKED = 'Add Document Clicked';
export const DOCUMENT_UPLOADED = 'Document Uploaded';
export const DOWNLOAD_WITH_QR_CODE_CLICKED = 'Download Brochure with QR Code Clicked';
export const GENERATE_AI_TEXT = 'Generate AI text Clicked';

export const init = () => {
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  mixpanel.init('046e5c46bbdc7d46af652c4628200561', { debug: true });
};

export const track = (event, properties) => {
  // convert nested properties to dot notation
  const flattenedProperties = flatten(properties);

  console.log('tracking event', event, properties, flattenedProperties);
  mixpanel.track(event, flattenedProperties);
};

export const identify = (userId, properties) => {
  LogRocket.identify(userId, properties);

  mixpanel.identify(userId);
  mixpanel.people.set({ ...properties, $email: properties.email, $name: properties.name });

  if (properties.broker_id) {
    mixpanel.set_group('broker_id', properties.broker_id);
  }
};

export const updateGroup = (groupId, properties) => {
  const flattenedProperties = flatten(properties);
  mixpanel.get_group('broker_id', groupId).set({ ...flattenedProperties, $name: properties.name });
};

// flattens nested object to use dot notation
function flatten(obj, prefix = '') {
  if (!obj) return null;

  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flatten(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
}
