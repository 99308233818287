import React from 'react';
import { Icon } from 'semantic-ui-react';
import { getCardIcon } from 'src/utils/generic-utils';
import Spinner from 'src/components/Spinner/spinner';

import './view-credit-card.scss';

const ViewCreditCard = ({
  card,
  defaultCard = false,
  removeCardLoading = false,
  removeCard = () => {},
  updateCard = () => {},
  defaultCardLoading = false,
  onMakeDefaultCard = () => {},
}) => {
  return (
    <div className="view-card-container lg:mr-4 xs:mb-4 lg:mb-0">
      <div className="card-number-container">
        <div className="card-number">
          <span className="card-img">
            <i className={getCardIcon(card.brand)}></i>
          </span>
          <span>**** **** **** {card.last4}</span>
        </div>
        {defaultCard && <div className="default-card">Default</div>}
        {!defaultCard && (
          <React.Fragment>
            {defaultCardLoading ? (
              <div className="w-16">
                <Spinner
                  style={{
                    height: 12,
                    width: 12,
                    marginTop: 2,
                  }}
                />
              </div>
            ) : (
              <div className="make-default-card" onClick={onMakeDefaultCard}>
                Set Default
              </div>
            )}
          </React.Fragment>
        )}
        <div className="update-btn" onClick={updateCard}>
          <Icon name="edit outline" className="text-gray-700" />
        </div>
        {!defaultCard && (
          <div className="remove-btn" onClick={removeCard}>
            {removeCardLoading ? (
              <Spinner
                style={{
                  height: 12,
                  width: 12,
                  marginTop: 2,
                }}
              />
            ) : (
              <Icon name="trash alternate outline" className="text-red-700" />
            )}
          </div>
        )}
      </div>
      <div className="spacer" />
      <div className="card-expiry">
        {card?.exp_month}/{card?.exp_year}
      </div>
    </div>
  );
};

export default ViewCreditCard;
