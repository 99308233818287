import React from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import { connect, useSelector, useDispatch } from 'react-redux';
import FileDownload from 'js-file-download';
import templateRenderer from 'src/services/template-renderer';
import PropTypes from 'prop-types';
import { createRequestHeader } from 'src/services/request-headers';
import { continueNextFunctionality } from 'src/utils/stripe-utils';
import './download-pdf.scss';
import { postEventDetails } from 'src/utils/login-utils';
import { DOWNLOAD_BROCHURE_PDF_CLICKED, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const DownloadPDF = ({
  footerURL,
  defaultURL,
  templateKey,
  data,
  filename = 'template',
  trackEvent = DOWNLOAD_BROCHURE_PDF_CLICKED,
  messageInterface,
  toggleMessage,
}) => {
  const dispatch = useDispatch();
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));

  const handleButtonClick = async event => {
    event.preventDefault();
    track(trackEvent, { templateKey, data, filename });
    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Preparing your download.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
      data: null,
    });

    const doContinue = await continueNextFunctionality({
      dispatch,
      currentBroker,
      currentUser,
    });

    if (doContinue) {
      const response = await templateRenderer.render(
        templateKey,
        { ...data, ...currentBroker, defaultURL, footerURL },
        {
          useCache: true,
        }
      );
      const { html, width, height, targetWidth = null, targetHeight = null } = response;

      axios
        .post(
          `${process.env.REACT_APP_API}/pdf`,
          {
            html: `<div style="width:100%">${html}</div>`,
            width,
            height,
            targetWidth,
            targetHeight,
            filename,
          },
          { responseType: 'blob', ...createRequestHeader() }
        )
        .then(res => {
          const contentDisposition = res.headers['content-disposition'];
          const filename = contentDisposition.substr(contentDisposition.indexOf('=') + 1);
          // const filename = 'downloaded brochure';
          postEventDetails({
            type: 'download',
            time: new Date().toISOString,
            email: currentUser.email,
            broker: currentUser.broker_id,
            template: templateKey,
          });
          FileDownload(res.data, filename);
          toggleMessage({
            isVisible: false,
            isLoading: false,
            message: { title: null, note: null },
            data: null,
          });
        })
        .catch(error => {
          console.error('Error downloading PDF', error);
          toggleMessage({
            isVisible: false,
            isLoading: false,
            message: { title: null, note: null },
            data: null,
          });
        });
    }
  };

  return (
    <Button primary size="tiny" disabled={messageInterface.isVisible} onClick={handleButtonClick}>
      Download
    </Button>
  );
};

DownloadPDF.propTypes = {
  templateKey: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  filename: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(DownloadPDF);
