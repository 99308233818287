import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Form, Grid, Loader } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import PageTitle from 'src/components/PageTitle/page-title';
import { validateEmail } from 'src/utils/generic-utils';

import { isUserEmailExists } from 'src/redux/actions/userActions';
import lodashFn from 'src/lodash';
import './collect-basic-details.scss';
import { useTitle } from 'react-use';

const CollectBasicDetails = ({ basicDetails, setBasicDetails, onSubmit, isUserAlreadyOnboarded }) => {
  useTitle('Basic Details - RealVault');

  const dispatch = useDispatch();
  const [formError, setFormError] = useState({});
  const [userEmailExists, setUserEmailExists] = useState(false);

  const checkFormValidation = () => {
    const errors = {};

    let fieldsToValidate = {};

    fieldsToValidate.first_name = basicDetails.first_name;
    fieldsToValidate.last_name = basicDetails.last_name;
    fieldsToValidate.company_name = basicDetails.company_name;
    fieldsToValidate.email = basicDetails.email;
    // fieldsToValidate.phone_number = basicDetails.phone_number;

    if (!isUserAlreadyOnboarded) {
      fieldsToValidate.password = basicDetails.password;
      fieldsToValidate.confirmPassword = basicDetails.confirmPassword;
    }

    Object.entries(fieldsToValidate).forEach(([key, value]) => {
      errors[key] = {
        valid: !!value,
        message: 'This is a required field',
      };

      // email validation
      if (key === 'email' && fieldsToValidate.email) {
        const isValidEmail = validateEmail(value);
        if (!isValidEmail) {
          errors[key] = {
            valid: false,
            message: 'Invalid email format!',
          };
        }
      }

      // password match validation
      if (!isUserAlreadyOnboarded) {
        if (key === 'password' || key === 'confirmPassword') {
          if (fieldsToValidate.password && fieldsToValidate.confirmPassword) {
            const isPasswordMatch = fieldsToValidate.password === fieldsToValidate.confirmPassword;
            if (!isPasswordMatch) {
              errors.confirmPassword = {
                valid: false,
                message: 'Password mismatch!',
              };
            }
          }
        }
      }
    });
    setFormError(errors);
    const hasErrors = Object.values(errors).some(field => field.valid === false);
    return !hasErrors;
  };

  const onUserWithEmailExists = email => {
    dispatch(isUserEmailExists(email))
      .then(responseData => {
        setUserEmailExists(responseData.exists);
      })
      .catch(err => {
        console.log('ERR => while checking is user email already exists', err);
      });
  };

  const handler = useCallback(
    lodashFn.debounce(email => {
      onUserWithEmailExists(email);
    }, 500),
    []
  );

  return (
    <div className="collect-basic-details">
      <div className="w-full md:w-4/5 lg:w-1/2 mx-auto">
        <div className="mx-auto">
          <PageTitle title={<div className="h-8" />} hideContainer />
          <div className="bg-white shadow-md rounded-sm w-full p-6 md:p-8 mb-8">
            <div className="mb-10">
              <h3 className="welcome-header text-base md:text-xl">
                Welcome! We just need a bit of info to get started
              </h3>
            </div>
            <Form>
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Input
                    error={
                      !basicDetails.first_name && formError?.first_name?.valid === false
                        ? { content: formError?.first_name?.message }
                        : false
                    }
                    fluid
                    required={true}
                    placeholder="First Name"
                    onChange={e => setBasicDetails({ ...basicDetails, first_name: e.target.value })}
                    value={basicDetails.first_name}
                    label="First Name"
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Input
                    error={
                      !basicDetails.last_name && formError?.last_name?.valid === false
                        ? { content: formError?.last_name?.message }
                        : false
                    }
                    fluid
                    required={true}
                    placeholder="Last Name"
                    onChange={e => setBasicDetails({ ...basicDetails, last_name: e.target.value })}
                    value={basicDetails.last_name}
                    label="Last Name"
                  />
                </Grid.Column>
              </Grid>
              <div className="my-8 md:my-4">
                <Form.Input
                  error={
                    !basicDetails.company_name && formError?.company_name?.valid === false
                      ? { content: formError?.company_name?.message }
                      : false
                  }
                  fluid
                  required={true}
                  placeholder="Brokerage Name"
                  onChange={e => setBasicDetails({ ...basicDetails, company_name: e.target.value })}
                  value={basicDetails.company_name}
                  label="Brokerage Name"
                />
              </div>
              {!isUserAlreadyOnboarded && (
                <div className="my-8 md:my-4">
                  <Form.Input
                    error={formError.email?.valid === false ? { content: formError?.email?.message } : false}
                    fluid
                    required={true}
                    type="email"
                    placeholder="Email"
                    onChange={e => {
                      const { value } = e.target;
                      const isValidEmail = validateEmail(value);
                      if (value) {
                        if (isValidEmail) {
                          setFormError(prev => ({
                            ...prev,
                            email: {
                              valid: true,
                              message: '',
                            },
                          }));
                          handler(e.target.value);
                        } else {
                          setFormError(prev => ({
                            ...prev,
                            email: {
                              valid: false,
                              message: 'Invalid email format!',
                            },
                          }));
                        }
                      }
                      setBasicDetails({ ...basicDetails, email: value });
                    }}
                    value={basicDetails.email}
                    label="Email"
                  />
                  {userEmailExists && (
                    <div className="text-red-600 text-sm font-semibold">
                      Email already exists! Choose a different one or{' '}
                      <Link
                        className="text-blue-600"
                        to={{
                          pathname: '/reset-password',
                        }}
                      >
                        Reset Password
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {!isUserAlreadyOnboarded && (
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Form.Input
                      type="password"
                      autoComplete="new-password"
                      error={
                        !basicDetails.password && formError.password?.valid === false
                          ? { content: formError?.password?.message }
                          : false
                      }
                      fluid
                      required={true}
                      placeholder="Password"
                      onChange={e => {
                        const { value } = e.target;
                        if (basicDetails.confirmPassword) {
                          if (basicDetails.confirmPassword === value) {
                            setFormError(prev => ({
                              ...prev,
                              confirmPassword: {
                                valid: true,
                                message: '',
                              },
                            }));
                          } else {
                            setFormError(prev => ({
                              ...prev,
                              confirmPassword: {
                                valid: false,
                                message: 'Password mismatch!',
                              },
                            }));
                          }
                        }
                        setBasicDetails({ ...basicDetails, password: value });
                      }}
                      value={basicDetails.password}
                      label="Create Password"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Form.Input
                      type="password"
                      error={
                        formError.confirmPassword?.valid === false
                          ? { content: formError.confirmPassword?.message }
                          : false
                      }
                      fluid
                      required={true}
                      placeholder="Confirm Password"
                      onChange={e => {
                        const { value } = e.target;
                        if (basicDetails.password === value) {
                          setFormError(prev => ({
                            ...prev,
                            confirmPassword: {
                              valid: true,
                              message: '',
                            },
                          }));
                        } else {
                          setFormError(prev => ({
                            ...prev,
                            confirmPassword: {
                              valid: false,
                              message: 'Password mismatch!',
                            },
                          }));
                        }
                        setBasicDetails({ ...basicDetails, confirmPassword: value });
                      }}
                      value={basicDetails.confirmPassword}
                      label="Confirm Password"
                    />
                  </Grid.Column>
                </Grid>
              )}
              <div className="my-10">
                <div className="flex items-center">
                  <Checkbox
                    className="mr-6"
                    type="checkbox"
                    onChange={(event, { checked }) =>
                      setBasicDetails(prev => ({
                        ...prev,
                        hasAgreedTerms: checked,
                      }))
                    }
                    checked={basicDetails.hasAgreedTerms}
                  />
                  <p>
                    I agree to the{' '}
                    <a
                      href="http://www.realvault.ca/assets/images/Covault-Terms-Of-Service.pdf"
                      target="_blank"
                      className="terms-link"
                    >
                      <u>Terms and Service</u>
                    </a>{' '}
                    &amp;{' '}
                    <a
                      href="http://www.realvault.ca/assets/images/Covault-Privacy-Policy-2021.pdf"
                      target="_blank"
                      className="terms-link"
                    >
                      <u>Privacy Policy</u>
                    </a>
                  </p>
                </div>
              </div>
              <div
                className="text-center"
                style={{
                  cursor: basicDetails.hasAgreedTerms ? 'pointer' : 'not-allowed',
                }}
              >
                <OutlinedButton
                  onClick={() => {
                    const isValid = checkFormValidation();
                    if (isValid) {
                      onSubmit();
                    }
                  }}
                  style={{
                    border: basicDetails.hasAgreedTerms ? '1px solid #233349' : '1px solid #ccc',
                    backgroundColor: basicDetails.hasAgreedTerms ? '#233349' : '#ccc',
                    color: 'white',
                    textTransform: 'uppercase',
                    pointerEvents: basicDetails.hasAgreedTerms ? 'auto' : 'none',
                  }}
                >
                  {basicDetails.loading ? (
                    <div className="flex justify-center items-center">
                      <Loader active size="mini" inline inverted />
                    </div>
                  ) : (
                    'Looks Good'
                  )}
                </OutlinedButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectBasicDetails;
