import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Header, Grid, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import PageTitle from 'src/components/PageTitle/page-title';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import { setDefaultSettings, getAllFonts } from 'src/redux/actions/theme-creator';
import '../../pages/OnboardingBroker/default-branding.scss';
import { useTitle } from 'react-use';
import { ONBOARDING_SKIP_SELECT_COLOR, track } from 'src/utils/analytics';

const SetColors = ({ onSubmit }) => {
  useTitle('Set Colors - Realvault');

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const {
    brokerOnboarding: { basicDetailsResponse },
  } = useSelector(state => state.stripe);

  const { currentBroker, allFonts } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allFonts: state.theme.allFonts,
  }));

  const FONT_OPTIONS = allFonts?.map(font => ({
    ...font,
    key: font.family,
    text: font.family,
    value: font.family,
  }));

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    primaryColor: '#000000',
    secondaryColor: '#ffffff',
    font: '',
    squareLogo: '',
    horizontalLogo: '',
    verticalLogo: '',
    brandLogo: '',
  });

  useEffect(() => {
    dispatch(getAllFonts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFormHandler = (key, value) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeSettings = async event => {
    event.preventDefault();
    setLoading(true);
    const localBasicDetails = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));

    // UPLOAD
    try {
      // upload the square logo, horizontal logo and vertical logo
      dispatch(
        setDefaultSettings(localBasicDetails?.broker_id, {
          ...values,
        })
      )
        .then(res => {
          setLoading(false);
          // call the api to update the brand logo

          addToast('Settings changed successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });

          onSubmit();
        })
        .catch(err => {
          setLoading(false);
          addToast(err?.response?.data?.error, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log('ERR => while changing the default setting', err);
        });
    } catch (err) {
      setLoading(false);
      addToast(err?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="default-branding">
      <div className="w-full md:w-4/5 mx-auto">
        <div className="mx-auto">
          <PageTitle title="Pick Your Colour" hideContainer />
          <div className="bg-white shadow-md rounded-sm w-full p-6 md:p-8">
            <Form onSubmit={onChangeSettings}>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Form.Field>
                    <Header as="h5" style={{ textAlign: 'center', marginBottom: '30px' }}>
                      Select Your Color
                    </Header>
                    {/* <GooglePicker
                                            disableAlpha={false}
                                            color={values.primaryColor}
                                            onChangeComplete={color => changeFormHandler('primaryColor', color.hex)}
                                            className="mr-8"
                                        />*/}
                    <div
                      className={'color-picker-wrap'}
                      style={{
                        maxWidth: '100%',
                        marginBottom: '0',
                        width: '75%',
                        margin: 'auto',
                      }}
                    >
                      <SketchPicker
                        disableAlpha={false}
                        color={values.primaryColor}
                        onChangeComplete={color => changeFormHandler('primaryColor', color.hex)}
                        className="mr-8"
                      />
                      <div className="preview-color" style={{ background: values.primaryColor }}></div>
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid>

              <div className="text-center mt-16">
                <OutlinedButton
                  onClick={onChangeSettings}
                  style={{
                    border: '1px solid #233349',
                    backgroundColor: '#233349',
                    color: 'white',
                    textTransform: 'uppercase',
                  }}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <Loader active size="mini" inline inverted />
                    </div>
                  ) : (
                    'Update Now'
                  )}
                </OutlinedButton>
              </div>
              <div className="mt-8 text-center">
                <div
                  onClick={ev => {
                    track(ONBOARDING_SKIP_SELECT_COLOR, { values });
                    onSubmit(ev);
                  }}
                  className="skip-link"
                >
                  <u>Skip</u>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetColors;
