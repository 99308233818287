import { SET_CURRENT_DOCUMENT, CLEAR_CURRENT_DOCUMENT, STORE_DOCUMENTS } from '../../constants/action-types';

const initialState = {
  currentDocument: {},
  documents: [],
};

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_DOCUMENT: {
      const { document } = action.payload;
      return {
        ...state,
        currentDocument: document,
      };
    }
    case CLEAR_CURRENT_DOCUMENT: {
      return {
        ...state,
        currentDocument: {},
      };
    }
    case STORE_DOCUMENTS: {
      const { documents } = action.payload;
      return {
        ...state,
        documents,
      };
    }
    default:
      return state;
  }
};
