import React from 'react';
import './AllWhistleBrochuresContainer.scss';

import PreviewItem from 'src/components/preview-item';
import WhistlerBrochure1 from './WhistlerBrochure1';
import WhistlerBrochure2 from './WhistlerBrochure2';
import WhistlerBrochure3 from './WhistlerBrochure3';
import WhistlerBrochure4 from './WhistlerBrochure4';
import WhistlerBrochure5 from './WhistlerBrochure5';
import WhistlerBrochure6 from './WhistlerBrochure6';
import WhistlerBrochure7 from './WhistlerBrochure7';
import WhistlerBrochure8 from './WhistlerBrochure8';

const AllWhistleBrochuresContainer = ({ mlsId, targetWidth, initialRouteParam = '' }) => {
  return (
    <div>
      <div className="whistler-brochures-container">
        <PreviewItem
          title="2PG - Custom Agent Banner"
          linkTo={`${initialRouteParam}${mlsId}/brochure/1`}
          targetWidth={targetWidth}
          pages={2}
          Component={WhistlerBrochure1}
        />
        <PreviewItem
          title="2PG – Agent Headshot"
          linkTo={`${initialRouteParam}${mlsId}/brochure/2`}
          targetWidth={targetWidth}
          pages={2}
          Component={WhistlerBrochure2}
        />
        <PreviewItem
          title="4PG Email – Custom Agent Banner"
          linkTo={`${initialRouteParam}${mlsId}/brochure/3`}
          targetWidth={targetWidth}
          pages={4}
          Component={WhistlerBrochure3}
        />
        <PreviewItem
          title="4PG Email – Agent Headshot"
          linkTo={`${initialRouteParam}${mlsId}/brochure/4`}
          targetWidth={targetWidth}
          pages={4}
          Component={WhistlerBrochure4}
        />
        <PreviewItem
          title="4PG Print – Custom Agent Banner"
          linkTo={`${initialRouteParam}${mlsId}/brochure/5`}
          targetWidth={targetWidth}
          pages={4}
          Component={WhistlerBrochure5}
        />
        <PreviewItem
          title="4PG Print – Agent Headshot"
          linkTo={`${initialRouteParam}${mlsId}/brochure/6`}
          targetWidth={targetWidth}
          pages={4}
          Component={WhistlerBrochure6}
        />
        <PreviewItem
          title="Luxury Portfolio Brochure"
          linkTo={`${initialRouteParam}${mlsId}/brochure/7`}
          targetWidth={targetWidth}
          pages={2}
          Component={WhistlerBrochure7}
        />
        <PreviewItem
          title="2PG – Agent & Co-Agent Headshot"
          linkTo={`${initialRouteParam}${mlsId}/brochure/8`}
          targetWidth={targetWidth}
          pages={2}
          Component={WhistlerBrochure8}
        />
      </div>
    </div>
  );
};

export default AllWhistleBrochuresContainer;
