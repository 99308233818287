import React from 'react';
import PropTypes from 'prop-types';

const rotationMeta = {
  1: null,
  3: {
    deg: 180,
    flip: false,
  },
  6: {
    deg: 90,
    flip: true,
  },
  8: {
    deg: 270,
    flip: true,
  },
};

const getOrientation = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let value = 1; // Non-rotated is the default
      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
        // Not a JPEG
        return resolve(value);
      }

      idx += 2;
      let maxBytes = scanner.byteLength;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx);
        idx += 2;
        switch (uint16) {
          case 0xffe1: // Start of EXIF
            const exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            value = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
        }
      }
      return resolve(value);
    };
    fileReader.readAsArrayBuffer(file);
  });
};

File.prototype.toDataURL = function () {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsDataURL(this);
  });
};

File.prototype.toOrientedDataURL = async function () {
  const initialDataURL = await this.toDataURL();
  const rotationValue = await getOrientation(this);
  const rotationSpec = rotationMeta[rotationValue];
  if (!rotationSpec) return initialDataURL;

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      var canvas = document.createElement('CANVAS');

      if (rotationSpec.flip) {
        canvas.width = image.height;
        canvas.height = image.width;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }

      canvas.style.position = 'absolute';

      var ctx = canvas.getContext('2d');

      ctx.translate(image.height, image.width / image.height);
      ctx.rotate((rotationSpec.deg * Math.PI) / 180);
      ctx.drawImage(image, 0, 0);

      const dataURL = canvas.toDataURL();
      resolve(dataURL);
    };
    image.src = initialDataURL;
  });
};

const FilePicker = ({ onSelect, children, multiple = false, dataUrl = true, accept = '*' }) => {
  return (
    <div
      style={{ display: 'inline-block', verticalAlign: 'top' }}
      onClick={() => {
        var input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', accept);
        if (multiple) input.setAttribute('multiple', 'true');
        input.addEventListener(
          'change',
          async function handleFiles() {
            if (multiple) {
              const files = Array.from(this.files);

              if (dataUrl) {
                const urls = [];
                for (let x = 0; x < files.length; x++) {
                  urls.push(await files[x].toOrientedDataURL());
                }
                onSelect(urls);
              } else {
                onSelect(files);
              }
            } else {
              onSelect(dataUrl ? await this.files[0].toOrientedDataURL() : this.files[0]);
            }
            input.remove();
          },
          false
        );

        input.click();
      }}
    >
      {children}
    </div>
  );
};

FilePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.any,
  accept: PropTypes.string,
};

export default FilePicker;
