import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { storeDocuments } from 'src/redux/actions/documentActions';
import { createRequestHeader } from 'src/services/request-headers';
import { USER_ROLES } from 'src/constants/permissions';
import DefaultLayout from 'src/layouts/default-layout';
import DocumentList from 'src/components/documents/DocumentList';
import { useTitle } from 'react-use';

const Library = props => {
  useTitle('Marketing Hub - Realvault');

  const [loading, setLoading] = useState(true);
  const isAdmin = [USER_ROLES.SYSTEM_ADMIN, USER_ROLES.BROKER_ADMIN].includes(props.users.currentUser.user_role_id);
  const { storeDocuments } = props;

  useEffect(() => {
    const fetchDocuments = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API}/document`, createRequestHeader());
      storeDocuments(result.data);
      setLoading(false);
    };

    fetchDocuments();
  }, []);

  const refreshDocuments = async () => {
    setLoading(true);
    const result = await axios.get(`${process.env.REACT_APP_API}/document`, createRequestHeader());
    storeDocuments(result.data);
    setLoading(false);
  };

  const panes = props => [
    {
      menuItem: {
        name: 'DOCUMENTS',
        key: 'documents',
      },
      render: () => (
        <Tab.Pane loading={loading}>
          <DocumentList documents={props.documents.documents} isAdmin={isAdmin} onNeedsRefresh={refreshDocuments} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <div className="padding--md">
        <Tab panes={panes(props)}></Tab>
      </div>
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  const { users, documents } = state;
  return { users, documents };
};
const mapDispatchToProps = dispatch => bindActionCreators({ storeDocuments }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Library);
