import React from 'react';
import './spinner.scss';

const Spinner = ({ style = {} }) => {
  return (
    <div className="lds-ring" style={style}>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
    </div>
  );
};

export default Spinner;
