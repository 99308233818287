import React from 'react';
import './listing-feature.scss';
import Image from 'src/components/image';
import { default as formatValue, formatType } from 'src/utils/format-na-value';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

const ListingFeature = ({ listing, mergeCurrentListing, theme }) => {
  const baths =
    listing.details.qtyHalfBathrooms === 'n/a'
      ? `${formatValue(listing.details.qtyFullBathrooms)}/0`
      : `${formatValue(listing.details.qtyFullBathrooms)}/${formatValue(listing.details.qtyHalfBathrooms)}`;
  const theme_ = theme || localStorage.getItem('themeClassName');

  return (
    <div className="listing-feature">
      <div>
        <Image imgKey="5" url={listing.photos[5] || PlaceHolderImage5} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="6" url={listing.photos[6] || PlaceHolderImage6} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="7" url={listing.photos[7] || PlaceHolderImage7} width={379} height={253} />
      </div>
      <div>
        <Image imgKey="8" url={listing.photos[8] || PlaceHolderImage1} width={379} height={261} margin={'8px 0'} />
      </div>
      <div className="features no-hover">
        <div className="title" style={{ fontFamily: 'OvoR', color: theme_ === 'black' ? '#000' : '#112F23' }}>
          Features:
        </div>
        <div className="feature-list">
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Price:</span>
            <span style={{ display: 'flex' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.price.priceDisplay.length > 11
                    ? listing.price.priceDisplay.slice(0, 11)
                    : listing.price.priceDisplay
                }
                onChange={e => {
                  if (e.target.value.length < 11) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {listing.isMLSVisible && (
            <div>
              <span className="feature-name">MLS® #:</span>
              <span data-test-id="mls-id">{listing.mlsId}</span>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Bedrooms:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={formatValue(listing.details.qtyBedrooms)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      qtyBedrooms: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Baths (Full/Half):</span>
            <span style={{ display: 'flex' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '10px',
                }}
                value={formatValue(listing.details.qtyFullBathrooms)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      qtyFullBathrooms: e.target.value,
                    },
                  });
                }}
              />
              /{' '}
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '10px',
                  marginLeft: '5px',
                }}
                value={formatValue(listing.details.qtyHalfBathrooms)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      qtyHalfBathrooms: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Year Built:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.yearBuilt}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      yearBuilt: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Gross Taxes:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.details?.grossTax?.length > 6
                    ? listing.details?.grossTax.slice(0, 6)
                    : listing.details?.grossTax
                }
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      grossTax: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Total Floor Area:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={formatValue(listing.details.squareFeet)}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      squareFeet: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Lot Size:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.lotSizeDimensions}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      lotSizeDimensions: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name">Total Lot Size:</span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.lotSizeTotal}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      lotSizeTotal: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
        </div>

        <div className="feature-inc">
          <div style={{ display: 'flex' }}>
            <span className="feature-name" style={{ width: '88px' }}>
              Sub-area:
            </span>
            <span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={listing.details.neighborhood}
                onChange={e => {
                  mergeCurrentListing({
                    details: {
                      ...listing.details,
                      neighborhood: e.target.value,
                    },
                  });
                }}
              />
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span className="feature-name" style={{ width: '88px' }}>
              Maint. Fee:
            </span>
            <span>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  formatValue(listing.details.strataFee)?.length > 9
                    ? formatValue(listing.details.strataFee).slice(0, 9)
                    : formatValue(listing.details.strataFee)
                }
                onChange={e => {
                  if (e.target.value.length < 10) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        strataFee: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div>
            <span style={{ float: 'left' }} className="feature-name" style={{ width: '88px' }}>
              Maint. Fee Incl:
            </span>
            <span style={{ display: 'inline-block', width: '250px' }}>
              <textarea
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  resize: 'none',
                  // height: '37px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  resize: 'none',
                }}
                value={
                  formatValue(listing.details.strataFeeIncl)?.length > 100
                    ? formatValue(listing.details.strataFeeIncl).slice(0, 100)
                    : formatValue(listing.details.strataFeeIncl)
                }
                rows={3}
                onChange={e => {
                  if (e.target.value.length < 100) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        strataFeeIncl: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
        </div>

        {/*<div className="edit-btn-hover not-in-pdf">
          <EditTextModal activeTab={0} triggerTitle="Edit Features" />
        </div>*/}
      </div>
      <div>
        <Image imgKey="9" url={listing.photos[9] || PlaceHolderImage2} width={379} height={261} margin={'8px 0'} />
      </div>
      <div>
        <Image imgKey="10" url={listing.photos[10] || PlaceHolderImage3} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="11" url={listing.photos[11] || PlaceHolderImage4} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="12" url={listing.photos[12] || PlaceHolderImage5} width={282} height={192} />
      </div>
      <div>
        <Image imgKey="13" url={listing.photos[13] || PlaceHolderImage6} width={282} height={192} />
      </div>
    </div>
  );
};

export default ListingFeature;
