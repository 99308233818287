import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import FileDownload from 'js-file-download';
import templateRenderer from 'src/services/template-renderer';
import _ from 'src/lodash';
import { PDF } from 'src/constants/API';
import { createRequestHeader } from 'src/services/request-headers';
import { continueNextFunctionality } from 'src/utils/stripe-utils';
import { DOWNLOAD_SOCIAL_PDF_CLICKED, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const SocialDownload = ({
  searchedListing,
  currentListing,
  templateKey,
  filename,
  dispatch,
  currentUser,
  currentBroker,
  defaultURL,
  messageInterface,
  toggleMessage,
}) => {
  if (!filename) filename = _.snakeCase(templateKey);
  const handleButtonClick = async event => {
    event.preventDefault();
    track(DOWNLOAD_SOCIAL_PDF_CLICKED, { templateKey, filename, currentListing });

    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Preparing your download.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
      data: null,
    });

    const doContinue = await continueNextFunctionality({
      dispatch,
      currentBroker,
      currentUser,
    });

    if (doContinue) {
      const { html, width, height } = await templateRenderer.render(
        templateKey,
        { currentListing, defaultURL },
        { useCache: true }
      );

      axios
        .post(
          '/api/pdf',
          { html, width, height, type: '.png', filename },
          { ...createRequestHeader(), responseType: 'blob' }
        )
        .then(res => {
          const contentDisposition = res.headers['content-disposition'];
          const filename = contentDisposition.substr(contentDisposition.indexOf('=') + 1);
          FileDownload(res.data, filename);
          toggleMessage({
            isVisible: false,
            isLoading: false,
            message: { title: null, note: null },
            data: null,
          });
        })
        .catch(error => {
          toggleMessage({
            isVisible: false,
            isLoading: false,
            message: { title: null, note: null },
            data: null,
          });
        });
    }
  };

  return (
    <Button primary disabled={messageInterface.isVisible} onClick={handleButtonClick}>
      Download
    </Button>
  );
};

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    users: { currentUser },
    brokers: { currentBroker },
    messageInterface,
  } = state;
  return { searchedListing, currentListing, currentUser, currentBroker, messageInterface };
};

export default connect(mapStateToProps, { toggleMessage })(SocialDownload);
