export const OPEN_CONTACT_ADMIN_MODAL = 'OPEN_CONTACT_ADMIN_MODAL';
export const CLOSE_CONTACT_ADMIN_MODAL = 'CLOSE_CONTACT_ADMIN_MODAL';
export const OPEN_CHOOSE_PLAN_MODAL = 'OPEN_CHOOSE_PLAN_MODAL';
export const CLOSE_CHOOSE_PLAN_MODAL = 'CLOSE_CHOOSE_PLAN_MODAL';
export const OPEN_MAX_USER_LIMIT_REACHED_MODAL = 'OPEN_MAX_USER_LIMIT_REACHED_MODAL';
export const CLOSE_MAX_USER_LIMIT_REACHED_MODAL = 'CLOSE_MAX_USER_LIMIT_REACHED_MODAL';

const initialState = {
  openContactAdminModal: false,
  openChoosePlanModal: false,
  openMaxUserLimitReachedModal: false,
};

export const appPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONTACT_ADMIN_MODAL:
      return {
        ...state,
        openContactAdminModal: true,
      };

    case CLOSE_CONTACT_ADMIN_MODAL:
      return {
        ...state,
        openContactAdminModal: false,
      };

    case OPEN_CHOOSE_PLAN_MODAL:
      return {
        ...state,
        openChoosePlanModal: true,
      };

    case CLOSE_CHOOSE_PLAN_MODAL:
      return {
        ...state,
        openChoosePlanModal: false,
      };

    case OPEN_MAX_USER_LIMIT_REACHED_MODAL:
      return {
        ...state,
        openMaxUserLimitReachedModal: true,
      };

    case CLOSE_MAX_USER_LIMIT_REACHED_MODAL:
      return {
        ...state,
        openMaxUserLimitReachedModal: false,
      };

    default:
      return state;
  }
};
