import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './WhistlerSocialTemplate15.scss';
import EditableTextarea from 'src/components/editable-textarea';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_15';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate15 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, client, testimonial, adjustedHeight } = props;

    const { photos } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    if (!client) client = '- Client(s)';
    if (!testimonial) testimonial = '"We had such a great experience with The Whistler Real Estate Company!"';

    this.state = {
      headline,
      image1,
      client,
      testimonial,
      adjustedHeight,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, client, testimonial, adjustedHeight } = this.state;
    WhistlerSocialTemplate15._cached = { headline, image1, client, testimonial };

    return (
      <WhistlerSocialTemplate className="whistler-social-15-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.LEFT}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="overlay-light"></div>
        <div className="content-wrapper">
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerSocialTemplate15._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="testimonial"
            fieldName="testimonial"
            placeholder="Testimonial Placeholder"
          />
          <EditableInput self={this} className="client" fieldName="client" placeholder="- Client(s)" />
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate15._name = TEMPLATE_NAME;
WhistlerSocialTemplate15._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate15._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate15, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate15);
