import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import templateRenderer from 'src/services/template-renderer';
import { createRequestHeader } from 'src/services/request-headers';
import './get-brochure-share-link.scss';
import { BROCHURE_SHARE_LINK_COPIED, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const GetBrochureShareLink = ({
  defaultURL,
  footerURL,
  templateKey,
  data,
  filename = 'template',
  messageInterface,
  toggleMessage,
}) => {
  const { addToast } = useToasts();

  const handleButtonClick = async event => {
    event.preventDefault();
    track(BROCHURE_SHARE_LINK_COPIED, { templateKey, data });
    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Generating brochure sharable link.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
      data: null,
    });

    const { html, width, height, targetWidth, targetHeight } = await templateRenderer.render(
      templateKey,
      { ...data, defaultURL, footerURL },
      {
        useCache: true,
      }
    );
    axios
      .post(
        '/api/generate-brochure-share-link',
        { html, width, height, targetWidth, targetHeight, filename },
        { ...createRequestHeader() }
      )
      .then(({ data: responseData }) => {
        toggleMessage({
          isVisible: true,
          isLoading: false,
          message: { title: 'Here is your link.', note: null },
          data: responseData.url,
        });
      })
      .catch(error => {
        addToast(error?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        toggleMessage({ isVisible: false, isLoading: false, message: { title: null, note: null }, data: null });
      });
  };

  return (
    <Button size="tiny" disabled={messageInterface.isVisible} onClick={handleButtonClick}>
      Get Sharable Link
    </Button>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(GetBrochureShareLink);
