import React from 'react';
import './universal-window-poster.scss';
import UniversalAgentBranding from 'src/templates/brochures/fragments/universal/universal-agent-branding';
import Image from 'src/components/image';
import ListingAreaType from 'src/components/listing-area-type';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';

import EditableImageV2 from 'src/components/editable-image-v2';

const UniversalWindowPoster = ({
  listing,
  twoAgents = false,
  mergeCurrentListing,
  theme,
  font,
  headerLogo,
  footerLogo,
}) => {
  return (
    <div className="universal-window-poster">
      <div className="listing-info">
        <div className="listing-info--header" style={{ backgroundColor: theme }}>
          <EditableImageV2
            fieldName="logo"
            className="logo"
            alt="Brand Logo"
            size="contain"
            style={{ width: 95, height: 65, position: 'relative' }}
            position={EditableImageV2.CENTER}
            buttonSize={'tiny'}
            buttonText={''}
            src={listing.pictures[101] || headerLogo}
            onUpdate={url => {
              const pictures = [...listing.pictures];
              pictures[101] = url;
              mergeCurrentListing({
                pictures,
              });
            }}
          />
          <div className="listing-intro">
            <ListingAreaType font={font} theme={theme} listing={listing} mergeCurrentListing={mergeCurrentListing} />
          </div>
        </div>
      </div>
      <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={540} height={320} />
      <div className="info-photo-layout ">
        <div className="listing-desc no-hover">
          <div className="address">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                fontFamily: font,
              }}
              value={
                listing.location.streetAddress.length > 30
                  ? listing.location.streetAddress.slice(0, 30)
                  : listing.location.streetAddress
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="description">
            <textarea
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                height: '130px',
                overflow: 'hidden',
                resize: 'none',
                fontFamily: font,
                wordBreak: 'break-word',
                lineHeight: '14px',
              }}
              value={listing.description.length > 570 ? listing.description.slice(0, 570) : listing.description}
              onChange={e => {
                mergeCurrentListing({
                  description: e.target.value,
                });
              }}
            />
          </div>
          <div className="details">
            <div className="item" style={{ display: 'flex', fontFamily: font }}>
              BEDS/BATHS:
              <div style={{ display: 'inline-flex' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '15px',
                    fontFamily: font,
                    textAlign: 'center',
                  }}
                  value={listing.details.qtyBedrooms}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }}
                />
                <span style={{ textAlign: 'center' }}>/</span>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '15px',
                    fontFamily: font,
                    textAlign: 'center',
                  }}
                  value={listing.details.qtyFullBathrooms}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="item"
              style={{ display: 'flex', fontFamily: font, marginLeft: 10, marginRight: 20, justifyContent: 'center' }}
            >
              <div style={{ display: 'inline-flex' }}>
                <span style={{ whiteSpace: 'nowrap' }}>SQ FT:</span>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '50px',
                    fontFamily: font,
                    marginLeft: 10,
                  }}
                  value={listing.details.squareFeet}
                  onChange={e => {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        squareFeet: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', paddingRight: 10 }}>
              <span style={{ whiteSpace: 'nowrap', fontFamily: font }}>PRICE: $</span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  fontFamily: font,
                }}
                value={
                  listing.price.priceDisplay.length > 11
                    ? listing.price.priceDisplay.slice(0, 11)
                    : listing.price.priceDisplay
                }
                onChange={e => {
                  if (e.target.value.length < 11) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={0} triggerTitle="Edit Details" />
            </Scale.Back>
          </div>*/}
        </div>
        <div className="stacked">
          <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage2} width={175} height={103} />
          <Image imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={175} height={103} />
        </div>
      </div>
      {twoAgents ? (
        <div className="two-agents">
          <UniversalAgentBranding
            theme={theme}
            font={font}
            mergeCurrentListing={mergeCurrentListing}
            agents={listing.agents}
            showLogo={false}
            activeTab={1}
          />
          <UniversalAgentBranding
            theme={theme}
            font={font}
            mergeCurrentListing={mergeCurrentListing}
            agents={{
              agentImageUrl: listing.agents.coAgentImageUrl,
              agentName: listing.agents.coAgentName,
              agentTitle: listing.agents.coAgentTitle,
              agentPhone: listing.agents.coAgentPhone,
              agentEmail: listing.agents.coAgentEmail,
              agentWebsite: listing.agents.coAgentWebsite,
              agentLogo: listing.agents.coAgentLogo,
            }}
            showLogo={false}
            activeTab={2}
          />
        </div>
      ) : (
        <UniversalAgentBranding
          theme={theme}
          font={font}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
          showLogo={false}
        />
      )}
    </div>
  );
};

export default UniversalWindowPoster;
