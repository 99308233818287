import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Stacked_Wordmark_white.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './WhistlerFacebookTemplate1.scss';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_FACEBOOK_1';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 630;

class WhistlerFacebookTemplate1 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;

    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      address,
      listingDetails,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = this.state;
    WhistlerFacebookTemplate1._cached = { headline, image1, address, listingDetails };

    return (
      <WhistlerSocialTemplate className="whistler-facebook-1-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="content-wrapper">
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerFacebookTemplate1._cached.adjustedHeight = v)}
            initialHeight={adjustedHeight}
            className="headline"
            fieldName="headline"
            placeholder="Headline"
            rows={1}
          />
          <hr />
          <EditableTextarea
            self={this}
            onHeightChange={v => (WhistlerFacebookTemplate1._cached.adjustedHeightAdr = v)}
            initialHeight={adjustedHeightAdr}
            className="address"
            fieldName="address"
            placeholder="#123 - 456 W Georgia St Vancouver"
          />
          <EditableInput
            self={this}
            className="listingDetails"
            fieldName="listingDetails"
            placeholder="#123 - 456 W Georgia St Vancouver"
          />
          <div className="logo">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerFacebookTemplate1._name = TEMPLATE_NAME;
WhistlerFacebookTemplate1._width = TEMPLATE_WIDTH;
WhistlerFacebookTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerFacebookTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerFacebookTemplate1);
