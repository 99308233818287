import React, { useRef, useState } from 'react';

const EditableInput = ({
  self,
  fieldName,
  placeholder = 'Type Text...',
  style = {},
  className = '',
  inputStyle = {},
}) => {
  const divRef = useRef();
  const textRef = useRef();
  const [statePlaceholder, setPlaceholder] = useState(placeholder);

  return (
    <div ref={divRef} className={`${className}`} style={style}>
      <input
        ref={textRef}
        className={`editable inhert-style`}
        value={self.state[fieldName]}
        placeholder={statePlaceholder}
        onFocus={() => {
          setPlaceholder('');
        }}
        style={inputStyle}
        onChange={e => {
          self.setState({ [fieldName]: e.target.value });
        }}
      />
    </div>
  );
};

export default EditableInput;
