import React, { useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { setCurrentBroker } from 'src/redux/actions/brokerActions';
import { useToasts } from 'react-toast-notifications';
import { Button, Form } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentUser } from 'src/redux/actions/userActions';

const ResetPassword = props => {
  const { addToast } = useToasts();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = event => {
    event.preventDefault();

    if (!email) {
      setShowError(true);
      return;
    }

    setLoading(true);
    setDisabled(true);
    axios
      .post(`${process.env.REACT_APP_API}/resetPassword`, { email })
      .then(res => {
        setShowConfirmation(true);
      })
      .catch(error => {
        addToast(error.response ? error.response.data.error : 'Error', {
          appearance: 'error',
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
        setShowError(false);
      });
  };

  return (
    <div>
      <div className="background white" style={{ width: '30vw' }}>
        <Form>
          {showConfirmation && (
            <p className="form-message">
              An email has been sent with a link to reset your password. Please check your email and follow the
              instructions.
            </p>
          )}
          <Form.Field>
            <label>Email</label>
            <Form.Input
              error={showError ? { content: 'This is a required field' } : false}
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </Form.Field>
          <Button primary loading={loading} disabled={disabled} size="tiny" onClick={e => sendEmail(e)}>
            Send Email
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({ setCurrentUser, setCurrentBroker }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
