import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import './WhistlerSocialTemplate17.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_17';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate17 extends Component {
  constructor(props) {
    super(props);

    let { content, clients, testimonial, adjustedHeight } = props;

    if (!clients) clients = '- Client Name(s)';
    if (!testimonial) testimonial = 'We had such a great experience with The Whistler Real Estate Company!';

    this.state = {
      content,
      clients,
      testimonial,
      adjustedHeight,
    };
  }

  render() {
    const { content, clients, testimonial, adjustedHeight } = this.state;

    WhistlerSocialTemplate17._cached = { content, clients, testimonial };

    return (
      <WhistlerSocialTemplate className="whistler-social-17-wrapper">
        <div className="details-container">
          <div className="testimonial">
            <div className="headline">Testimonial</div>
            <hr />
            <EditableTextarea
              self={this}
              onHeightChange={v => (WhistlerSocialTemplate17._cached.adjustedHeight = v)}
              initialHeight={adjustedHeight}
              className="content"
              fieldName="testimonial"
              placeholder="Placeholder Text"
            />
            <EditableInput self={this} className="client-name" fieldName="clients" placeholder="Client Name(s)" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate17._name = TEMPLATE_NAME;
WhistlerSocialTemplate17._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate17._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate17, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate17);
