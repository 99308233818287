import axios from 'axios';
import { DEFAULT_HEADERS } from 'src/utils/headers';
import { UPDATE_CURRENT_USER_BROKER_ID } from 'src/constants/action-types';
import {
  SET_BROKERAGE_USER_COUNT,
  SET_BROKER_CARDS,
  SET_BROKER_ONBOARDING_BASIC_DETAILS,
  SET_CURRENT_SUBSCRIPTION,
  SET_INVOICE_HISTORY,
  SET_INVOICE_ITEMS,
  SET_STRIPE_PRODUCTS,
} from '../reducers/stripeReducer';

export const getStripeProducts = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/stripe/products`, DEFAULT_HEADERS);

      const stripePlans = data.map(item => ({
        product: item.product,
        plan: item.plan.sort((a, b) => a.amount - b.amount),
      }));

      dispatch({
        type: SET_STRIPE_PRODUCTS,
        payload: stripePlans,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createBrokerBasicDetailsOnboarding = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseURL = process.env.REACT_APP_API;
      const { data } = await axios.post(`${baseURL}/brokerV2`, apiData, DEFAULT_HEADERS);
      localStorage.setItem(
        'broker_onboarding_basic_details',
        JSON.stringify({ ...data, first_name: apiData.first_name, last_name: apiData.last_name })
      );
      localStorage.setItem('broker_onboarding_step', 3);
      dispatch({
        type: SET_BROKER_ONBOARDING_BASIC_DETAILS,
        payload: data,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createStripeSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/createSubscription`, apiData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const renewStripeSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/renewSubscription`, apiData, DEFAULT_HEADERS);
      dispatch(getSubscriptionStatus({ user_id: apiData?.user_id }));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelStripeSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/cancelSubscription`, apiData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const makeUserActiveInactive = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/activeInactiveUser`, apiData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBrokerTotalUsers = broker_id => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/getBrokerUserCount/${broker_id}`, DEFAULT_HEADERS);
      dispatch({
        type: SET_BROKERAGE_USER_COUNT,
        payload: data.count,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBrokerTotalUsersAPI = broker_id => {
  // OLD deprecated PHP API
  const baseURL = process.env.REACT_APP_TEMPLATE_API;
  return axios.get(`${baseURL}/getBrokerUserCount/${broker_id}`, DEFAULT_HEADERS);
};

export const getBrokerCardList = user_id => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.get(`${baseURL}/getAllCards/${user_id}`, DEFAULT_HEADERS);

      dispatch({
        type: SET_BROKER_CARDS,
        payload: {
          customer: data.customer,
          cards: data.cards.data,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addStripeCard = (apiData, user_id) => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/createCard`, apiData, DEFAULT_HEADERS);

      dispatch(getBrokerCardList(user_id));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateStripeCard = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/updateCard`, apiData, DEFAULT_HEADERS);

      dispatch(getBrokerCardList(apiData.user_id));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeStripeCard = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/removeCard`, apiData, DEFAULT_HEADERS);

      dispatch(getBrokerCardList(apiData.user_id));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const makeDefaultStripeCard = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/setCardDefault`, apiData, DEFAULT_HEADERS);

      dispatch(getBrokerCardList(apiData.user_id));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCustomerInvoiceList = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/invoiceDetails`, apiData, DEFAULT_HEADERS);

      dispatch({
        type: SET_INVOICE_HISTORY,
        payload: data?.data || [],
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCustomerInvoicePendingItems = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/pendingInvoiceItems`, apiData, DEFAULT_HEADERS);

      dispatch({
        type: SET_INVOICE_ITEMS,
        payload: data?.data || [],
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const upgradeStripeSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/upgradeSubscription`, apiData, DEFAULT_HEADERS);
      resolve(data);
      dispatch(getSubscriptionStatus({ user_id: apiData?.user_id }));
    } catch (error) {
      reject(error);
    }
  });
};

export const downgradeStripeSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/downgradeSubscription`, apiData, DEFAULT_HEADERS);
      dispatch(getSubscriptionStatus({ user_id: apiData?.user_id }));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateCustomerAddress = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/updateBillingAddress`, apiData, DEFAULT_HEADERS);
      dispatch(getBrokerCardList(apiData.user_id));
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addItemInSubscription = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/addItemInSubscription`, apiData, DEFAULT_HEADERS);
      dispatch(
        getSubscriptionStatus({
          user_id: apiData?.user_id,
        })
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSubscriptionStatusAPI = apiData => {
  // OLD deprecated PHP API
  const baseURL = process.env.REACT_APP_TEMPLATE_API;
  return axios.post(`${baseURL}/getSubscriptionStatus`, apiData, DEFAULT_HEADERS);
};

export const getSubscriptionStatus = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/getSubscriptionStatus`, apiData, DEFAULT_HEADERS);
      dispatch({
        type: SET_CURRENT_SUBSCRIPTION,
        payload: data.values,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const changeManualBilling = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_API;
      const { data } = await axios.post(`${baseURL}/changeManualBilling`, apiData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const couponValiadate = apiData => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // OLD deprecated PHP API
      const baseURL = process.env.REACT_APP_TEMPLATE_API;
      const { data } = await axios.post(`${baseURL}/couponValiadate`, apiData, DEFAULT_HEADERS);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
