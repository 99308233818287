import merge from 'lodash/merge';
import {
  CURRENT_LISTING,
  SET_CURRENT_LISTING,
  MERGE_CURRENT_LISTING,
  CLEAR_CURRENT_LISTING,
} from '../../constants/action-types';
import { setStorage, getStorage } from './browser-storage';

export const currentListingReducer = (state = getStorage(CURRENT_LISTING), action) => {
  switch (action.type) {
    case SET_CURRENT_LISTING: {
      const { listing } = action.payload;
      return setStorage(CURRENT_LISTING, listing);
    }
    case MERGE_CURRENT_LISTING: {
      const { partialListing } = action.payload;
      return setStorage(CURRENT_LISTING, merge({}, state, partialListing));
    }
    case CLEAR_CURRENT_LISTING: {
      return setStorage(CURRENT_LISTING, null);
    }
    default:
      return state;
  }
};
