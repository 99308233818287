import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Container, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInverseButton from 'src/components/OutlinedInverseButton/outlined-inverse-button';
import AdminLayout from 'src/layouts/admin-layout';

import './current-plan.scss';
import { cancelStripeSubscription, getSubscriptionStatus } from 'src/redux/actions/stripeActions';
import { fetchCurrentBroker } from 'src/redux/actions/brokerActions';

import { OPEN_CHOOSE_PLAN_MODAL } from 'src/redux/reducers/appPermissionReducer';
import { useTitle } from 'react-use';

const CurrentPlan = props => {
  useTitle('Billing Plan - Realvault');

  const { addToast } = useToasts();
  const [cancelSubScriptionLoading, setCancelSubscriptionLoading] = useState(false);

  const dispatch = useDispatch();
  const { currentUser, currentBroker, products, currentSubscription } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
    products: state.stripe.products,
    currentSubscription: state.stripe.currentSubscription,
  }));

  const onCancelSubscription = () => {
    setCancelSubscriptionLoading(true);
    dispatch(
      cancelStripeSubscription({
        broker_id: currentUser?.broker_id,
      })
    )
      .then(() => {
        dispatch(fetchCurrentBroker(currentUser?.broker_id));
        dispatch(
          getSubscriptionStatus({
            user_id: currentUser?.id,
          })
        );
        addToast('Subscrption cancelled successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        setCancelSubscriptionLoading(false);
      })
      .catch(err => {
        addToast('Subscrption cancellation failed!', {
          appearance: 'error',
          autoDismiss: true,
        });
        setCancelSubscriptionLoading(false);
        console.log('ERR => while cancelling subscription');
      });
  };

  useEffect(() => {
    dispatch(
      getSubscriptionStatus({
        user_id: currentUser?.id,
      })
    )
      .then(data => {
        console.log(data, 'getSubscriptionStatus Response here');
      })
      .catch(err => {
        console.log('Something went wrong while fetching stripe products', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPlan = products?.[0]?.plan?.find(plan => plan.id === currentBroker.stripe_plan_id);

  let extraPlan;

  if (currentSubscription?.items?.data?.length === 1) {
    // check for the count in the same object
    extraPlan = {
      ...currentSubscription?.items?.data[0],
      quantity: currentSubscription?.items?.data[0]?.quantity - 1,
    };
  } else {
    extraPlan = currentSubscription?.items?.data?.find(subscriptionItem => {
      return subscriptionItem.plan.id !== currentPlan.id;
    });
  }

  let showCancelPlanButton = false;

  if (currentSubscription?.status === 'active') {
    if (!currentSubscription?.cancel_at) {
      showCancelPlanButton = true;
    }
  }

  const today = moment();
  const subscriptionCancelAt = moment(currentSubscription?.cancel_at * 1000);
  const remaingPlanPeriod = subscriptionCancelAt.diff(today, 'days');

  return (
    <AdminLayout pageTitle="Current Plan">
      <Container>
        <div className="current-plan-container">
          <div className="team-plan-container">
            <div>
              <div className="heading">
                Plan
                {currentSubscription && (
                  <>
                    {currentSubscription?.status === 'active' ? (
                      <span className="active-status">Active</span>
                    ) : (
                      <span className="cancelled-status">Cancelled</span>
                    )}
                  </>
                )}
              </div>
              <div className="strong-text">
                Includes:{' '}
                <span className="normal-text">
                  {currentPlan?.metadata?.max_user === 1 ? 'Individual' : currentPlan?.metadata?.max_user} Users
                </span>
              </div>
              <div className="strong-text">
                Monthly Base Rate: <span className="normal-text">C${(currentPlan?.amount / 100).toFixed(2)}</span>
              </div>
              <p className="plan-info">
                When you exceed your user limit, each addition user will incur charges of C$9.99.
              </p>
            </div>
            <div className="justify-self-end">
              {showCancelPlanButton && (
                <span>
                  {cancelSubScriptionLoading ? (
                    <span className="mr-2 w-16 inline-block">
                      <Loader active size="mini" inline />
                    </span>
                  ) : (
                    <OutlinedInverseButton
                      className="mr-2"
                      style={{
                        border: '1px solid red',
                        color: 'red',
                      }}
                      onClick={onCancelSubscription}
                    >
                      Cancel Plan
                    </OutlinedInverseButton>
                  )}
                </span>
              )}

              <OutlinedInverseButton
                onClick={() =>
                  dispatch({
                    type: OPEN_CHOOSE_PLAN_MODAL,
                  })
                }
              >
                Change Plan
              </OutlinedInverseButton>
            </div>
          </div>

          {/* Upcoming Plan Status */}
          <div className="extra-user-container">
            <div className="heading">Plan Status</div>
            {currentSubscription?.canceled_at ? (
              <React.Fragment>
                <div className="strong-text">
                  Plan Cancelled On :{' '}
                  <span className="normal-text">{moment(currentSubscription?.canceled_at * 1000).format('ll')}</span>
                </div>
                {remaingPlanPeriod >= 0 && (
                  <div className="strong-text">
                    Remaining Days For Current Plan: {<span className="normal-text">{remaingPlanPeriod} days</span>}
                  </div>
                )}

                {remaingPlanPeriod < 0 && (
                  <div className="strong-text">
                    Plan Expired: {<span className="normal-text">{Math.abs(remaingPlanPeriod)} days ago</span>}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="strong-text">Recurring</div>
            )}
          </div>

          <div className="extra-user-container">
            <div className="heading">Extra Users</div>
            <div className="strong-text">
              Extra Users: <span className="normal-text">{extraPlan?.quantity} Users</span>
            </div>
            <div className="strong-text">
              Extra Charges:{' '}
              {extraPlan && (
                <span className="normal-text">
                  C${((extraPlan?.quantity * extraPlan?.plan?.amount) / 100).toFixed(2)}
                </span>
              )}
            </div>
            <div className="manage-users-btn">
              <Link to="/manage-users">
                <OutlinedInverseButton>Manage Users</OutlinedInverseButton>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default CurrentPlan;
