import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Tab, Label } from 'semantic-ui-react';
import BackgroundImage from 'src/components/background-image';
import PropTypes from 'prop-types';

import { addUploadedImage } from 'src/redux/actions/actions';
import FilePicker from 'src/components/file-picker';

class UpdatePhotoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPhotoType: 'web',
      selectedPhotoIndex: null,
      selectedPhotoUrl: null,
      active: false,
      modal: {
        open: false,
      },
    };
  }

  toggleModal = () => this.setState(prevState => ({ modal: { open: !prevState.modal.open } }));

  selectPhoto = (type, index, url = null) => {
    const { photos, uploadedImages } = this.props;

    const upladedImageSelected = url ? url : uploadedImages[index];
    const selectedPhotoUrl = type === 'web' ? photos[index] : upladedImageSelected;
    this.setState({ selectedPhotoType: type, selectedPhotoIndex: index, selectedPhotoUrl: selectedPhotoUrl }, () => {
      if (url) {
        this.closeModal();
      }
    });
  };

  closeModal = () => {
    this.props.updatePhoto(this.state.selectedPhotoUrl);
    this.toggleModal();
  };

  render() {
    const { trigger, photos, uploadedImages, addUploadedImage } = this.props;
    const { modal, selectedPhotoType, selectedPhotoIndex } = this.state;

    const WebImageTab = (
      <>
        {photos.map((picture, index) => (
          <Button
            className="select-photo-btn"
            onClick={() => this.selectPhoto('web', index)}
            primary={selectedPhotoType === 'web' && selectedPhotoIndex === index}
            key={index}
          >
            <BackgroundImage url={picture} width={150} height={110} />
          </Button>
        ))}
      </>
    );

    const LocalImageTab = (
      <>
        {uploadedImages.map((dataUrl, index) => (
          <Button
            className="select-photo-btn"
            onClick={() => this.selectPhoto('local', index)}
            primary={selectedPhotoType === 'local' && selectedPhotoIndex === index}
            key={index}
          >
            <BackgroundImage url={dataUrl} width={150} height={110} />
          </Button>
        ))}
        <FilePicker
          onSelect={async dataUrl => {
            await addUploadedImage(dataUrl);
            this.selectPhoto('local', null, dataUrl);
          }}
          accept="image/*"
        >
          <Button style={{ width: '160px', height: '119px', margin: '3px' }}>UPLOAD</Button>
        </FilePicker>
        <br />
        <br />
        <Label className="info-label">
          <Icon name="info" /> Accepted file formats:<Label.Detail>.jpg .jpeg .png .gif .ico .bmp .webp</Label.Detail>
        </Label>
      </>
    );

    const panes = [
      { menuItem: { icon: 'upload', content: 'UPLOAD NEW IMAGE', key: 'upload-tab' }, render: () => LocalImageTab },
    ];

    if (photos.length > 0) {
      panes.unshift({
        menuItem: { icon: 'file image outline', content: 'LISTING', key: 'listing-tab' },
        render: () => WebImageTab,
      });
    }

    return (
      <Modal
        size="large"
        trigger={trigger(this.toggleModal)}
        className="edit-photo-modal"
        open={modal.open}
        centered={false}
      >
        <Modal.Header>Select a Photo</Modal.Header>
        <Modal.Content>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" size="tiny" onClick={this.toggleModal}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" size="tiny" onClick={this.closeModal} disabled={selectedPhotoIndex == null}>
            <Icon name="checkmark" /> Update Photo
          </Button>
        </Modal.Actions>
        {/* <style jsx global>
          {`
            .edit-photo-modal .select-photo-btn {
              padding: 5px;
              margin: 3px;
            }
            .info-label {
              font-family: 'Lato', Verdana, Tahoma;
            }
          `}
        </style> */}
      </Modal>
    );
  }
}

UpdatePhotoModal.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  updatePhoto: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { uploadedImages } = state;
  return { uploadedImages };
};

export default connect(mapStateToProps, { addUploadedImage })(UpdatePhotoModal);
