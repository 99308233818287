import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import { setSocialTemplates } from 'src/redux/actions/brokerActions';
import { createRequestHeader } from 'src/services/request-headers';
import * as AllSocialTemplates from 'src/templates';
import DynamicPreviewItem from '../dynamic-preview-item';
import './index.scss';

const headline = 'Add Text';

class SocialTemplatePanel extends Component {
  state = {
    socialTemplates: [],
    loading: false,
  };

  componentDidMount() {
    const { id: brokerId } = this.props.brokers.currentBroker;
    const baseURL = process.env.REACT_APP_TEMPLATE_API;
    this.setState({ loading: true });
    let url = `${baseURL}/social-templates/broker/${brokerId}`;
    axios
      .get(url, createRequestHeader())
      .then(({ data }) => {
        this.props.setSocialTemplates(data);
        this.setState({ socialTemplates: data, loading: false });
      })
      .catch(error => {
        // this.props.clearBothListing();
        console.log(error, 'error goes here');
        this.setState({ loading: false });
      });
  }

  render() {
    const { mlsId, type, targetWidth } = this.props;
    const filteredTemplates = this.state.socialTemplates.filter(template => AllSocialTemplates[template.componentId]);

    const socialTemplates = filteredTemplates.filter(template =>
      template?.componentId?.toLowerCase().includes('socialtemplate')
    );
    const facebookTemplates = filteredTemplates.filter(template =>
      template?.componentId?.toLowerCase().includes('facebooktemplate')
    );

    if (this.state.loading) {
      return (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      );
    }

    return (
      <div>
        {/* {socialTemplates.length > 0 && (
          <Header as="h1" className="preview-list-header">
            INSTAGRAM
          </Header>
        )} */}
        <div className="facebook-templates-container">
          {socialTemplates.map((template, index) =>
            AllSocialTemplates[template.componentId] ? (
              <DynamicPreviewItem
                key={template.id}
                title={template.title || `Social Template ${index + 1}`}
                linkTo={`/listing/${mlsId}/facebook/${type}/${template.name}`}
                targetWidth={targetWidth}
                Component={AllSocialTemplates[template.componentId]}
                data={{ headline }}
                defaultURL={template.default_URL}
                pages={template.pages}
              />
            ) : null
          )}
        </div>

        {/* Facebook Templates goes here */}
        {/* {facebookTemplates.length > 0 && (
          <Header as="h1" className="preview-list-header">
            FACEBOOK
          </Header>
        )} */}
        <div className="facebook-templates-container">
          {facebookTemplates.map((template, index) =>
            AllSocialTemplates[template.componentId] ? (
              <DynamicPreviewItem
                key={template.id}
                defaultURL={template.default_URL}
                title={template.title || `Facebook Template ${index + 1}`}
                linkTo={`/listing/${mlsId}/facebook/${type}/${template.name}`}
                targetWidth={targetWidth}
                Component={AllSocialTemplates[template.componentId]}
                data={{ headline }}
                pages={template.pages}
              />
            ) : null
          )}
        </div>
        {!this.props.isTemplatesAreUsedForHardcodedBroker && filteredTemplates.length === 0 && (
          <p className="text-center">No template found!</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brokers: state.brokers,
  currentListing: state.currentListing,
  searchedListing: state.searchedListing,
});

export default connect(mapStateToProps, {
  setSocialTemplates,
})(SocialTemplatePanel);
