import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';

import './WhistlerSocialTemplate7.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_7';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate7 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, image2, image3, image4, address, detail } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, squareFeet } = details;

    const bath = qtyFullBathrooms || 0;
    const sqft = `${squareFeet} SQFT`;

    const largePhotos = photos;

    if (!image1) image1 = largePhotos[0] || PlaceHolderImage1;
    if (!image2) image2 = largePhotos[1] || PlaceHolderImage2;
    if (!image3) image3 = largePhotos[2] || PlaceHolderImage3;
    if (!image4) image4 = largePhotos[3] || PlaceHolderImage4;

    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms}   |   Bath: ${bath}   |   ${sqft}`;

    this.state = {
      headline,
      image1,
      image2,
      image3,
      image4,
      address,
      detail,
    };
  }

  render() {
    const { currentListing } = this.props;
    const { headline, image1, image2, image3, image4, address, detail } = this.state;
    WhistlerSocialTemplate7._cached = { headline, image1, image2, image3, image4, address, detail };

    return (
      <WhistlerSocialTemplate className="whistler-social-7-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div className="details-container">
          <div className="border"></div>
          <div className="content">
            <EditableInput
              self={this}
              className="address"
              fieldName="address"
              placeholder="#123 - 656 W Georgia St Vancouver"
            />
            <EditableInput
              self={this}
              className="detail"
              fieldName="detail"
              placeholder="Bed: X | Bath: X | XXX SQFT"
            />
          </div>
        </div>
        <div className="three-photo-container">
          <EditableImage
            self={this}
            fieldName="image2"
            className="photo"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={1}
          />
          <EditableImage
            self={this}
            fieldName="image3"
            className="photo"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={2}
          />
          <EditableImage
            self={this}
            fieldName="image4"
            className="photo"
            size="cover"
            position={EditableImage.CENTER}
            pictures={currentListing.pictures}
            imgKey={3}
          />
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate7._name = TEMPLATE_NAME;
WhistlerSocialTemplate7._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate7);
