import React from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';

const BaseBrochurePage = ({ Component, scale, pages, defaultURL, footerURL, currentListing }) => {
  return (
    <TemplateLayout
      defaultURL={defaultURL}
      footerURL={footerURL}
      templateKey={Component._name}
      currentListing={currentListing}
    >
      <Scale width={Component._width} height={Component._height} pages={pages} scale={scale}>
        <Component defaultURL={defaultURL} footerURL={footerURL} />
      </Scale>
    </TemplateLayout>
  );
};

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(BaseBrochurePage);
