import React from 'react';
import './listing-info.scss';

import Logo from 'src/images/pemberton/ph-stacked-white-logo.png';

import Image from 'src/components/image';
import Scale from 'src/components/scale';
import ListingAreaType from 'src/components/listing-area-type';
import AgentBranding from 'src/components/agent-branding';
import EditTextModal from 'src/components/edit-text-modal';
import BackgroundImage from 'src/components/background-image';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';

const ListingInfo = ({
  listing,
  moreImages = false,
  hasAgentBanner = false,
  dark = false,
  layout = 'default',
  mergeCurrentListing,
  customMarginParentDiv = '',
  customMarginTopParentDiv = ' ',
  theme,
}) => {
  const themeName = theme || localStorage.getItem('themeClassName');

  let SecondImages = null;
  if (moreImages) {
    SecondImages = (
      <div
        className={`photo-layout-2`}
        style={{ marginTop: customMarginParentDiv, display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          <Image imgKey="3" style={{}} url={listing.photos[3] || PlaceHolderImage3} width={175} height={108} />
        </div>
        <div>
          <Image
            imgKey="4"
            id="four"
            style={{ margin: '0 7.15px' }}
            url={listing.photos[4] || PlaceHolderImage4}
            width={174}
            height={108}
          />
        </div>
        <div>
          <Image imgKey="5" url={listing.photos[5] || PlaceHolderImage5} width={175} height={108} />
        </div>
      </div>
    );
  } else {
    SecondImages = (
      <div className={`photo-layout-2 `} style={{ marginTop: customMarginParentDiv }}>
        <div>
          <Image id="five" imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={266} height={160} />
        </div>
        <div>
          <Image imgKey="4" url={listing.photos[4] || PlaceHolderImage4} width={266} height={160} />
        </div>
      </div>
    );
  }

  return (
    <div className="listing-info">
      <div className="listing-info--header">
        <div className="logo">
          <img src={Logo} width="80" base64="true" alt="logo" />
        </div>
        <div className="listing-intro">
          <ListingAreaType theme={theme} mergeCurrentListing={mergeCurrentListing} listing={listing} />
        </div>
      </div>
      <div className={`photo-layout-1 ${layout}`} style={{ marginTop: customMarginTopParentDiv }}>
        <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={357} height={225} />
        <div className="stacked">
          <Image imgKey="1" url={listing.photos[1] || PlaceHolderImage2} width={175} height={109} />
          <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage3} width={175} height={109} />
        </div>
      </div>
      {SecondImages}
      <div className="" style={{ width: '100%' }}>
        <div className="listing-desc">
          {/*<span className="address">
                        {listing.location.streetAddress}, {listing.location.city}
                    </span>*/}
          <div className="address" style={{ width: 'auto', flex: 1 }}>
            <div>
              <input
                className="mobile-p"
                style={{
                  width: '100%',
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  background: 'inherit',
                  color: themeName === 'green' ? '#112F23' : '#000',
                  fontFamily: 'OvoR',
                  fontWeight: 'bold',
                }}
                value={
                  listing.location.streetAddress.length > 30
                    ? listing.location.streetAddress.slice(0, 30)
                    : listing.location.streetAddress
                }
                onChange={e => {
                  if (e.target.value.length < 30) {
                    mergeCurrentListing({
                      location: {
                        ...listing.location,
                        streetAddress: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>

          <span
            className="price"
            style={{
              fontFamily: 'OvoR',
              color: themeName === 'green' ? '#112F23' : '#000',
            }}
          >
            LIST PRICE $
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                textAlign: 'left',
                width: '75px',
                fontWeight: 'bold',
                fontFamily: 'OvoR',
                color: themeName === 'green' ? '#112F23' : '#000',
              }}
              value={
                listing.price.priceDisplay.length > 11
                  ? listing.price.priceDisplay.slice(0, 11)
                  : listing.price.priceDisplay
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    price: {
                      ...listing.price,
                      priceDisplay: e.target.value,
                    },
                  });
                }
              }}
            />
          </span>
        </div>

        <div className="description">
          <textarea
            className="mobile-p"
            style={{
              width: '100%',
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              height: '110px',
              resize: 'none',
              overflow: 'hidden',
              wordBreak: 'break-word',
              lineHeight: '14px',
            }}
            value={listing.description.length > 860 ? listing.description.slice(0, 860) : listing.description}
            onChange={e => {
              mergeCurrentListing({
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
      {hasAgentBanner ? (
        <div className="agent-banner-container">
          <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
          {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
            <span className="empty-placeholder not-in-pdf"></span>
          )}
          <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <div style={{ position: 'relative', left: '-27%' }}>
                <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
              </div>
            </Scale.Back>
          </div>
        </div>
      ) : (
        <AgentBranding
          agents={listing.agents}
          mergeCurrentListing={mergeCurrentListing}
          theme={themeName === 'green' ? '#112F23' : '#000'}
          font="OvoR"
        />
      )}
    </div>
  );
};

export default ListingInfo;
