import React, { useState, useEffect } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { decode } from 'jsonwebtoken';
import AdminLayout from 'src/layouts/admin-layout';
import { fetchCurrentUser } from 'src/redux/actions/userActions';

import './account-settings.scss';
import { fetchCurrentBroker } from 'src/redux/actions/brokerActions';
import SettingsModal from 'src/components/authorization/SettingsModal';
import EditPasswordModal from 'src/components/EditPasswordModal/edit-password-modal';
import { useTitle } from 'react-use';

const AccountSettings = () => {
  useTitle('Account Settings - Realvault');

  const dispatch = useDispatch();
  const { currentUser, currentBroker } = useSelector(state => ({
    currentUser: state.users.currentUser,
    currentBroker: state.brokers.currentBroker,
  }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('jwt') && decode(localStorage.getItem('jwt'));
    if (user) {
      setLoading(true);
      dispatch(fetchCurrentUser(user?.user_id))
        .then(response => setLoading(false))
        .catch(err => {
          console.log('Failed to fetch the user', err);
          setLoading(false);
        });

      dispatch(fetchCurrentBroker(user?.broker_id))
        .then(response => setLoading(false))
        .catch(err => {
          console.log('Failed to fetch the broker', err);
          setLoading(false);
        });
    }
  }, [dispatch]);

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex justify-center items-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout pageTitle="Account Settings">
      <Container className="account-settings">
        <div className="user-details">
          <h1 className="user-name">{`${currentUser?.first_name} ${currentUser?.last_name}`}</h1>
          <h4 className="company-name">{currentBroker?.name}</h4>
          <div className="user-contact">
            <p>Email: {currentUser?.email}</p>
            <p>Phone: {currentUser?.phone_number}</p>
          </div>
        </div>
        <div className="action-btn-container mt-12">
          <SettingsModal user={currentUser} />
          <div className="mt-10">
            <EditPasswordModal user={currentUser} />
          </div>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default AccountSettings;
