import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import _ from 'src/lodash';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import templateRenderer from 'src/services/template-renderer';
import { createRequestHeader } from 'src/services/request-headers';
import { SEND_TO_PUBLISHER_EVENT, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const SentToPublisher = ({
  defaultURL,
  footerURL,
  templateKey,
  data,
  filename = 'template',
  currentListing = {},
  messageInterface,
  toggleMessage,
}) => {
  const { addToast } = useToasts();

  const handleButtonClick = async event => {
    event.preventDefault();
    track(SEND_TO_PUBLISHER_EVENT, { templateKey, currentListing, data });
    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Sending email to publisher.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
      data: null,
    });

    const { html, width, height, targetWidth, targetHeight } = await templateRenderer.render(
      templateKey,
      { ...data, defaultURL, footerURL },
      {
        useCache: true,
      }
    );
    // NOTE: This publisher ID is Live, Don't user in Development.
    const publisherId = 'boulevard';
    // used.ca, victoria news, real estate victoria
    axios
      .post(
        `/api/publishers/${publisherId}/email`,
        { html, width, height, targetWidth, targetHeight, filename },
        { ...createRequestHeader() }
      )
      .then(({ data: responseData }) => {
        toggleMessage({
          isVisible: false,
          isLoading: false,
          message: { title: null, note: null },
          data: null,
        });
        console.log({ responseData });
        addToast('Ad Request Successfully sent to publisher', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(error => {
        console.log(error, 'Error here');
        addToast(error?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        toggleMessage({
          isVisible: false,
          isLoading: false,
          message: { title: null, note: null },
          data: null,
        });
      });
  };

  return (
    <Button size="tiny" disabled={messageInterface.isVisible} onClick={handleButtonClick}>
      <Icon name="mail" />
      Send to Publisher
    </Button>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(SentToPublisher);
