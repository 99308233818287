import React from 'react';
import { connect, useSelector } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure1.scss';
import { register } from 'src/services/template-renderer';
import Floorplan from 'src/templates/brochures/fragments/macdonald/floorplan';
import ListingInfo from 'src/templates/brochures/fragments/macdonald/listing-info';
import ListingFeature from 'src/templates/brochures/fragments/macdonald/listing-feature';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';

const TEMPLATE_NAME = 'MACDONALD_REALTY_FOLDED_BROCHURE';
const TEMPLATE_WIDTH = 1224;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, mergeCurrentListing, broker, ...rest }) => {
  const { defaultSettings } = useSelector(state => ({
    defaultSettings: state.theme.defaultSettings,
  }));

  return (
    <div className="scaled template mac-template-1 page-1 ui-only-outline">
      {/* <GoogleFontLoader
        fonts={[
          {
            font: defaultSettings.font,
          },
        ]}
      /> */}
      <Floorplan broker={broker} mergeCurrentListing={mergeCurrentListing} listing={listing} />
      <ListingInfo
        broker={broker}
        mergeCurrentListing={mergeCurrentListing}
        listing={listing}
        defaultSettings={defaultSettings}
        agentPhotoCustomMargin={true}
      />
    </div>
  );
};

const Page2 = ({ listing, mergeCurrentListing, broker }) => {
  return (
    <div className="scaled template mac-template-1 page-2 ui-only-outline">
      <ListingFeature broker={broker} mergeCurrentListing={mergeCurrentListing} listing={listing} />
    </div>
  );
};

const MacdonaldBrochure1 = ({ searchedListing, currentListing, mergeCurrentListing, broker }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <>
      <Page1 broker={broker} mergeCurrentListing={customMergeListing} listing={currentListing} />
      <br className="not-in-pdf" />
      <Page2 broker={broker} mergeCurrentListing={customMergeListing} listing={currentListing} />
    </>
  );
};

MacdonaldBrochure1._name = TEMPLATE_NAME;
MacdonaldBrochure1._width = TEMPLATE_WIDTH;
MacdonaldBrochure1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure1,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure1);
