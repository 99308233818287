import React from 'react';
import './window-poster.scss';
import AgentBranding from 'src/components/agent-branding';
import Image from 'src/components/image';
import Scale from 'src/components/scale';
import Logo from 'src/images/macdonald/macdonald-realty-logo.png';
import EditTextModal from 'src/components/edit-text-modal';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import UniversalLegalText from 'src/templates/brochures/fragments/common/universal-legal-text';

const WindowPoster = ({
  listing,
  twoAgents = false,
  mergeCurrentListing,
  customStyleForAgentBrandingBroacher5 = false,
  customLegalMargin = false,
}) => {
  return (
    <div className="mac-window-poster" style={{ position: 'relative' }}>
      <div className="logo-fixed">
        <img src={Logo} width="130" height="106" base64="true" alt="logo" />
      </div>
      <Image imgKey="0" url={listing.photos[0] || PlaceHolderImage1} width={542} height={380} />
      <div className="info-photo-layout">
        <div
          className="listing-desc no-hover"
          style={{
            paddingLeft: '0px',
            paddingRight: '10px',
          }}
        >
          <div className="address">
            <input
              className="mobile-p"
              style={{
                width: '100%',
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontSize: 17,
                fontFamily: "'UbuntuM', Verdana, Tahoma",
                color: 'var(--mac-font-color)',
                lineHeight: '1em',
              }}
              value={
                listing.location.streetAddress.length > 30
                  ? listing.location.streetAddress.slice(0, 30)
                  : listing.location.streetAddress
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="description">
            <textarea
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                width: '100%',
                overflow: 'hidden',
                resize: 'none',
                wordBreak: 'break-word',
                fontFamily: "'UbuntuL', Verdana, Tahoma",
                verticalAlign: 'top',
                margin: '0',
                padding: '0',
                lineHeight: '14px',
              }}
              rows={10}
              value={listing.description.length > 630 ? listing.description.slice(0, 630) : listing.description}
              onChange={e => {
                mergeCurrentListing({
                  description: e.target.value,
                });
              }}
            />

            <div className="details">
              <div
                className="item"
                style={{ display: 'flex', alignItems: 'center', fontFamily: "'UbuntuM', Verdana, Tahoma" }}
              >
                BEDS/BATHS:
                <input
                  className="mobile-p"
                  maxLength={2}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '12px',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                  }}
                  value={listing.details.qtyBedrooms}
                  onChange={e => {
                    if (e.target.value.length < 3) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyBedrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />{' '}
                /{' '}
                <input
                  className="mobile-p"
                  maxLength={2}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '12px',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                  }}
                  value={listing.details.qtyFullBathrooms}
                  onChange={e => {
                    if (e.target.value.length < 3) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyFullBathrooms: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div
                className="item"
                style={{
                  marginRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  fontFamily: "'UbuntuM', Verdana, Tahoma",
                }}
              >
                SQ FT:
                <input
                  className="mobile-p"
                  maxLength={8}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '50px',
                    marginLeft: '5px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                  }}
                  value={listing.details.squareFeet}
                  onChange={e => {
                    if (e.target.value.length < 8) {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          squareFeet: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  fontFamily: "'UbuntuM', Verdana, Tahoma",
                }}
              >
                PRICE: $
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    width: '670px',
                    fontFamily: "'UbuntuM', Verdana, Tahoma",
                  }}
                  maxLength={12}
                  value={
                    listing.price.priceDisplay.length > 11
                      ? listing.price.priceDisplay.slice(0, 11)
                      : listing.price.priceDisplay
                  }
                  onChange={e => {
                    if (e.target.value.length < 11) {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {/*<div className="edit-btn-hover not-in-pdf">
                        <Scale.Back>
                            <EditTextModal activeTab={0} triggerTitle="Edit Details"/>
                        </Scale.Back>
                    </div>*/}
        </div>
        <div className="stacked">
          <Image imgKey="2" url={listing.photos[2] || PlaceHolderImage2} width={175} height={103} />
          <Image imgKey="3" url={listing.photos[3] || PlaceHolderImage3} width={175} height={103} />
        </div>
      </div>
      {twoAgents ? (
        <div
          className="two-agents"
          style={{
            marginLeft: '0',
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateColumns: '50% 50%',
            height: '124px',
          }}
        >
          <AgentBranding
            marginWindow="10px 0"
            customTop="0"
            infoPadding="infoPadding"
            customInfoPadding={true}
            customTopAgent={true}
            customHeightwindow={true}
            customJustifyContent={true}
            customMargn
            mergeCurrentListing={mergeCurrentListing}
            agents={listing.agents}
            showLogo={false}
            customWidthForCoAgent={true}
            agentNameCustomPadding={true}
            activeTab={1}
          />
          <AgentBranding
            coAgent={true}
            customTop="0"
            customInfoPadding={true}
            customTopAgent={true}
            customWidthForCoAgent={true}
            customJustifyContent={true}
            customHeightwindow={true}
            agentNameCustomPadding={true}
            listing={listing.agents}
            mergeCurrentListing={mergeCurrentListing}
            agents={{
              agentImageUrl: listing.agents.coAgentImageUrl,
              agentName: listing.agents.coAgentName,
              agentTitle: listing.agents.coAgentTitle,
              agentPhone: listing.agents.coAgentPhone,
              agentEmail: listing.agents.coAgentEmail,
              agentWebsite: listing.agents.coAgentWebsite,
              agentLogo: listing.agents.coAgentLogo,
            }}
            style={{ width: '65%' }}
            showLogo={false}
            activeTab={2}
          />
        </div>
      ) : (
        <AgentBranding
          // customStyleForAgentBrandingBroacher5={customStyleForAgentBrandingBroacher5}
          customTitlemargin={true}
          mergeCurrentListing={mergeCurrentListing}
          agents={listing.agents}
          showLogo={false}
          customJustifyContent={true}
          customHeightwindow={true}
          customHeightMain={true}
          customTop="0"
          customInfoPadding={true}
        />
      )}
      <div
        className="legal"
        style={{
          position: 'relative',
          left: '0',
          marginTop: '0px',
          bottom: '-10px',
          marginLeft: customLegalMargin ? '122px' : '97px',
        }}
      >
        <UniversalLegalText />
      </div>
    </div>
  );
};

export default WindowPoster;
