import React from 'react';
import { useTitle } from 'react-use';
import { Header } from 'semantic-ui-react';
import DefaultLayout from 'src/layouts/default-layout';

const PageNotFound = () => {
  useTitle('Page Not Found - Realvault');

  return (
    <DefaultLayout>
      <div className="page-center">
        <Header as="h1" className="preview-list-header">
          Page Not Found
        </Header>
      </div>
    </DefaultLayout>
  );
};

export default PageNotFound;
