import React from 'react';
import { connect } from 'react-redux';
import { Loader, Icon, Button } from 'semantic-ui-react';
import CopyContainer from '../CopyContainer/CopyContainer';
import { toggleMessage } from 'src/redux/actions/messageAction';
import './message-wrapper.scss';

const MessageWrapper = props => {
  const handleDismiss = event => {
    event.preventDefault();
    props.toggleMessage({
      isVisible: false,
      isLoading: false,
      message: { title: null, note: null },
      data: null,
    });
  };

  return (
    <>
      {props.messageInterface.isVisible && (
        <div className="message-container">
          <div className="message-wrapper">
            <Button className="close-message" icon onClick={handleDismiss}>
              <Icon name="close" size="large" />
            </Button>
            <div className="message-header">
              {props.messageInterface.message.title && (
                <div className="message-title">{props.messageInterface.message.title}</div>
              )}
            </div>
            <div>
              {props.messageInterface.message.note && (
                <div className="message-note">Note: {props.messageInterface.message.note}</div>
              )}
            </div>
            {props.messageInterface.data && (
              <div className="message-result-container">
                <CopyContainer />
              </div>
            )}
            {props.messageInterface.isLoading && (
              <div className="message-loading">
                <Loader style={{ position: 'relative', top: '15px', display: 'block' }} active />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(MessageWrapper);
