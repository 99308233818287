import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import SocialLayout from 'src/layouts/social-layout';
import MacdonaldFacebookTemplate11 from 'src/templates/facebook/macdonald/MacdonaldFacebookTemplate11';

class MacdonaldFacebook11 extends Component {
  render() {
    const { currentListing, type } = this.props;
    const headline = _.startCase(type);

    return (
      <SocialLayout
        postFacebook={true}
        templateKey={MacdonaldFacebookTemplate11._name}
        type={type}
        listing={currentListing}
      >
        <Scale
          width={MacdonaldFacebookTemplate11._width}
          height={MacdonaldFacebookTemplate11._height}
          scale={0.8}
          style={{ outline: 'grey 1px solid' }}
        >
          <MacdonaldFacebookTemplate11 headline={headline} />
        </Scale>
      </SocialLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldFacebook11);
