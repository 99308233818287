import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal, Form } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';
import './DocumentAddEditModal.scss';
import { track, DOCUMENT_UPLOADED } from 'src/utils/analytics';

function DocumentAddEditModal({ isOpen, onClose, document }) {
  const [formValues, setFormValues] = useState({ id: null, name: null, description: null, file: null });
  const [loading, setLoading] = useState(false);
  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 26214400, // 25 MB
    accept: ['.pdf', '.png', '.jpg', '.jpeg'],
    onDropAccepted: files => handleFileUpload(files),
  });

  useEffect(() => {
    setFormValues({
      ...formValues,
      id: document.id,
      name: document.name,
      description: document.description || '',
    });
  }, [document]);

  const handleFileUpload = files => {
    setFormValues({ ...formValues, name: files[0].name, file: files[0] });
  };

  const handleModalClose = () => {
    setFormValues({ id: null, name: null, description: null, file: null });
    onClose(false);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    for (const formInput of Object.keys(formValues)) {
      if (formValues[formInput] !== null && formInput !== 'id') {
        formData.append(formInput, formValues[formInput]);
      }
    }

    track(DOCUMENT_UPLOADED, { formValues });

    try {
      setLoading(true);

      if (formValues.id) {
        // Update existing document
        await axios.put(
          `${process.env.REACT_APP_API}/document/${formValues.id}`,
          formData,
          createRequestHeader({ 'content-type': 'multipart/form-data' })
        );
      } else {
        // Add new document
        await axios.post(
          `${process.env.REACT_APP_API}/document`,
          formData,
          createRequestHeader({ 'content-type': 'multipart/form-data' })
        );
      }

      onClose(true);
    } catch (error) {
      onClose(false, error);
    } finally {
      setFormValues({ id: null, name: null, description: null, file: null });
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} closeOnDimmerClick={false}>
      <Modal.Header>Upload a File</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {formValues.file || formValues.id ? (
            <Form loading={loading}>
              <Form.Input fluid value={formValues.name} label="Name" disabled={true} />
              <Form.Input
                maxLength="256"
                onChange={e => setFormValues({ ...formValues, description: e.target.value })}
                value={formValues.description}
                label="Description"
              />
            </Form>
          ) : (
            <>
              <p className="modal-message">Upload a file that can be used by all members of your brokerage.</p>
              <p className="modal-message margin-bottom">
                Files have a maximum size of 25MB and must be one of the following types: .pdf, .png, .jpg, or .jpeg.
              </p>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Icon size="big" name="cloud upload" />
                <p className="margin-top">Drag & Drop files here</p>
                <p className="margin-top">or</p>
                <Button color="blue" className="margin-top" onClick={open}>
                  Browse Files
                </Button>
              </div>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => handleModalClose(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button color="green" disabled={!formValues.file && !formValues.id} onClick={uploadFile}>
          <Icon name="check" />
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DocumentAddEditModal;
