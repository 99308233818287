import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { toggleMessage } from 'src/redux/actions/messageAction';
import './copy-container.scss';

const CopyContainer = props => {
  const { addToast } = useToasts();

  const handleClick = event => {
    event.preventDefault();
    navigator.clipboard.writeText(props.messageInterface.data).then(
      function () {
        /* clipboard successfully set */
        addToast('Brouchure link copied to clipboard successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        props.toggleMessage({
          isVisible: false,
          isLoading: false,
          message: { title: null, note: null },
          data: null,
        });
      },
      function (err) {
        console.log(err, 'Failed to copy the link to clipboard!');
        /* clipboard write failed */
        addToast('Failed to copy the link in the clipboard, Please try again!', {
          appearance: 'error',
          autoDismiss: true,
        });
        props.toggleMessage({
          isVisible: false,
          isLoading: false,
          message: { title: null, note: null },
          data: null,
        });
      }
    );
  };

  return (
    <>
      <div className="copy-wrapper">
        <Button className="copy-message-button" icon onClick={handleClick}>
          <Icon name="copy" size="large" />
        </Button>
        <div className="message-result-text">{props.messageInterface.data}</div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { messageInterface } = state;
  return {
    messageInterface,
  };
};

export default connect(mapStateToProps, { toggleMessage })(CopyContainer);
