import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Header, Button, Divider, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PublicLayout from '../layouts/public-layout';
import { useTitle } from 'react-use';

const PasswordReset = props => {
  useTitle('Reset Password - Realvault');

  const token = props.match.params.token || null;
  const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '' });
  const [formError, setFormError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [failureMessage, setFailureMessage] = useState(token === null);

  const passwordError = passwordField => {
    if (!passwords[passwordField] && formError) {
      return { content: 'This is a required field' };
    } else if (passwords[passwordField].length < 8 && formError) {
      return { content: 'Password must be 8 characters long' };
    } else if (passwordField === 'confirmPassword' && passwords.newPassword !== passwords.confirmPassword) {
      return { content: 'Passwords do not match' };
    } else {
      return false;
    }
  };

  const validateForm = () => {
    if (
      !passwords.newPassword ||
      passwords.newPassword.length < 8 ||
      !passwords.confirmPassword ||
      passwords.confirmPassword.length < 8 ||
      passwords.newPassword !== passwords.confirmPassword
    ) {
      setFormError(true);
      return false;
    } else {
      return true;
    }
  };

  const resetPassword = async e => {
    e.preventDefault();
    setSuccessMessage(false);
    setFailureMessage(false);

    if (validateForm()) {
      setDisabled(true);

      try {
        await axios.post(`${process.env.REACT_APP_API}/resetPassword/${token}`, { password: passwords.newPassword });
        setSuccessMessage(true);
      } catch {
        setFailureMessage(true);
      } finally {
        setDisabled(false);
      }
    }
  };

  return (
    <PublicLayout>
      <Header as="h1">New Password</Header>
      <Divider section />
      <div className="background white" style={{ width: '30vw' }}>
        <Form>
          {successMessage && <p className="form-message">Your password has successfully been updated.</p>}
          {failureMessage && (
            <p className="form-message">
              The link to reset your password has expired. Please{' '}
              <Link to="/login" className="underline-link">
                reset your password again.
              </Link>
            </p>
          )}
          <Form.Field>
            <Form.Input
              error={passwordError('newPassword')}
              fluid
              type="password"
              required={true}
              onChange={e => setPasswords({ ...passwords, newPassword: e.target.value })}
              value={passwords.newPassword}
              label="New Password"
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              error={passwordError('confirmPassword')}
              fluid
              type="password"
              required={true}
              onChange={e => setPasswords({ ...passwords, confirmPassword: e.target.value })}
              value={passwords.confirmPassword}
              label="Confirm Password"
            />
          </Form.Field>
          <Button primary disabled={disabled} size="tiny" onClick={resetPassword}>
            Update
          </Button>
          {successMessage && (
            <Link to="/login" className="underline-link ml-20p">
              Success! Please click here to login
            </Link>
          )}
        </Form>
      </div>
    </PublicLayout>
  );
};

const mapStateToProps = state => {
  const { users } = state;
  return { users };
};

export default connect(mapStateToProps, {})(PasswordReset);
