import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ListingGuard from 'src/components/listing-guard';
import PageNotFound from 'src/pages/PageNotFound';
import DefaultLayout from 'src/layouts/default-layout';
import { BROKER_CODES } from 'src/constants/permissions';
import PembertonFacebook1 from './pemberton/PembertonFacebook1';
import PembertonFacebook2 from './pemberton/PembertonFacebook2';
import PembertonFacebook3 from './pemberton/PembertonFacebook3';
import PembertonFacebook4 from './pemberton/PembertonFacebook4';
import PembertonFacebook5 from './pemberton/PembertonFacebook5';
import PembertonFacebook6 from './pemberton/PembertonFacebook6';
import PembertonFacebook7 from './pemberton/PembertonFacebook7';
import PembertonFacebook8 from './pemberton/PembertonFacebook8';
import PembertonFacebook9 from './pemberton/PembertonFacebook9';
import PembertonFacebook10 from './pemberton/PembertonFacebook10';
import PembertonFacebook11 from './pemberton/PembertonFacebook11';
import PembertonFacebook12 from './pemberton/PembertonFacebook12';
import PembertonFacebook13 from './pemberton/PembertonFacebook13';
import PembertonFacebook14 from './pemberton/PembertonFacebook14';
import PembertonFacebook15 from './pemberton/PembertonFacebook15';
import PembertonFacebook16 from './pemberton/PembertonFacebook16';
import PembertonFacebook17 from './pemberton/PembertonFacebook17';
import PembertonFacebook18 from './pemberton/PembertonFacebook18';
import PembertonFacebook19 from './pemberton/PembertonFacebook19';
import PembertonFacebook20 from './pemberton/PembertonFacebook20';
import PembertonFacebook21 from './pemberton/PembertonFacebook21';
import PembertonFacebook22 from './pemberton/PembertonFacebook22';
import PembertonFacebook23 from './pemberton/PembertonFacebook23';
import PembertonFacebook24 from './pemberton/PembertonFacebook24';
import PembertonFacebook25 from './pemberton/PembertonFacebook25';
import PembertonFacebook26 from './pemberton/PembertonFacebook26';
import PembertonFacebook27 from './pemberton/PembertonFacebook27';

import WhistlerSocialPage from './whistler/WhistlerSocialPage';
import MacdonaldFacebook1 from './macdonald/MacdonaldFacebook1';
import MacdonaldFacebook2 from './macdonald/MacdonaldFacebook2';
import MacdonaldFacebook3 from './macdonald/MacdonaldFacebook3';
import MacdonaldFacebook4 from './macdonald/MacdonaldFacebook4';
import MacdonaldFacebook5 from './macdonald/MacdonaldFacebook5';
import MacdonaldFacebook6 from './macdonald/MacdonaldFacebook6';
import MacdonaldFacebook7 from './macdonald/MacdonaldFacebook7';
import MacdonaldFacebook8 from './macdonald/MacdonaldFacebook8';
import MacdonaldFacebook9 from './macdonald/MacdonaldFacebook9';
import MacdonaldFacebook10 from './macdonald/MacdonaldFacebook10';
import MacdonaldFacebook11 from './macdonald/MacdonaldFacebook11';
import MacdonaldFacebook12 from './macdonald/MacdonaldFacebook12';
import MacdonaldFacebook13 from './macdonald/MacdonaldFacebook13';
import MacdonaldFacebook14 from './macdonald/MacdonaldFacebook14';
import MacdonaldFacebook15 from './macdonald/MacdonaldFacebook15';
import MacdonaldFacebook16 from './macdonald/MacdonaldFacebook16';
import MacdonaldFacebook17 from './macdonald/MacdonaldFacebook17';
import MacdonaldFacebook18 from './macdonald/MacdonaldFacebook18';

import WhistlerSocialTemplate1 from 'src/templates/facebook/whistler/WhistlerSocialTemplate1';
import WhistlerSocialTemplate2 from 'src/templates/facebook/whistler/WhistlerSocialTemplate2';
import WhistlerSocialTemplate3 from 'src/templates/facebook/whistler/WhistlerSocialTemplate3';
import WhistlerSocialTemplate4 from 'src/templates/facebook/whistler/WhistlerSocialTemplate4';
import WhistlerSocialTemplate5 from 'src/templates/facebook/whistler/WhistlerSocialTemplate5';
import WhistlerSocialTemplate6 from 'src/templates/facebook/whistler/WhistlerSocialTemplate6';
import WhistlerSocialTemplate7 from 'src/templates/facebook/whistler/WhistlerSocialTemplate7';
import WhistlerSocialTemplate8 from 'src/templates/facebook/whistler/WhistlerSocialTemplate8';
import WhistlerSocialTemplate9 from 'src/templates/facebook/whistler/WhistlerSocialTemplate9';
import WhistlerSocialTemplate10 from 'src/templates/facebook/whistler/WhistlerSocialTemplate10';
import WhistlerSocialTemplate11 from 'src/templates/facebook/whistler/WhistlerSocialTemplate11';
import WhistlerSocialTemplate12 from 'src/templates/facebook/whistler/WhistlerSocialTemplate12';
import WhistlerSocialTemplate13 from 'src/templates/facebook/whistler/WhistlerSocialTemplate13';
import WhistlerSocialTemplate14 from 'src/templates/facebook/whistler/WhistlerSocialTemplate14';
import WhistlerSocialTemplate15 from 'src/templates/facebook/whistler/WhistlerSocialTemplate15';
import WhistlerSocialTemplate16 from 'src/templates/facebook/whistler/WhistlerSocialTemplate16';
import WhistlerSocialTemplate17 from 'src/templates/facebook/whistler/WhistlerSocialTemplate17';
import WhistlerSocialTemplate18 from 'src/templates/facebook/whistler/WhistlerSocialTemplate18';
import WhistlerFacebookTemplate1 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate1';
import WhistlerFacebookTemplate2 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate2';
import WhistlerFacebookTemplate3 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate3';
import WhistlerFacebookTemplate4 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate4';
import WhistlerFacebookTemplate5 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate5';
import WhistlerFacebookTemplate6 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate6';
import WhistlerFacebookTemplate7 from 'src/templates/facebook/whistler/WhistlerFacebookTemplate7';

import * as AllSocialTemplates from 'src/templates/index';
import CommonSocialPage from './CommonSocialPage';
import { useTitle } from 'react-use';
import { SOCIAL_MEDIA_TEMPLATE_SELECTED, track } from 'src/utils/analytics';

const listingExceptions = ['7', '17', '24', '25', '26'];

const renderPemberton = (templateId, type, templates) => {
  switch (templateId) {
    case '1':
      return <PembertonFacebook1 type={type} />;
    case '2':
      return <PembertonFacebook2 type={type} />;
    case '3':
      return <PembertonFacebook3 type={type} />;
    case '4':
      return <PembertonFacebook4 type={type} />;
    case '5':
      return <PembertonFacebook5 type={type} />;
    case '6':
      return <PembertonFacebook6 type={type} />;
    case '7':
      return <PembertonFacebook7 type={type} />;
    case '8':
      return <PembertonFacebook8 type={type} />;
    case '9':
      return <PembertonFacebook9 type={type} />;
    case '10':
      return <PembertonFacebook10 type={type} />;
    case '11':
      return <PembertonFacebook11 type={type} />;
    case '12':
      return <PembertonFacebook12 type={type} />;
    case '13':
      return <PembertonFacebook13 type={type} />;
    case '14':
      return <PembertonFacebook14 type={type} />;
    case '15':
      return <PembertonFacebook15 type={type} />;
    case '16':
      return <PembertonFacebook16 type={type} />;
    case '17':
      return <PembertonFacebook17 type={type} />;
    case '18':
      return <PembertonFacebook18 type={type} />;
    case '19':
      return <PembertonFacebook19 type={type} />;
    case '20':
      return <PembertonFacebook20 type={type} />;
    case '21':
      return <PembertonFacebook21 type={type} />;
    case '22':
      return <PembertonFacebook22 type={type} />;
    case '23':
      return <PembertonFacebook23 type={type} />;
    case '24':
      return <PembertonFacebook24 type={type} />;
    case '25':
      return <PembertonFacebook25 type={type} />;
    case '26':
      return <PembertonFacebook26 type={type} />;
    case '27':
      return <PembertonFacebook27 type={type} />;
    default:
      const componentId = templates.find(st => st.name === templateId)?.componentId;
      const defaultURL = templates.find(st => st.name === templateId)?.default_URL;

      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <CommonSocialPage defaultURL={defaultURL} type={type} Component={AllSocialTemplates[componentId]} />
      );
  }
};

const renderMacdonald = (templateId, type, templates) => {
  switch (templateId) {
    case '1':
      return <MacdonaldFacebook1 type={type} />;
    case '2':
      return <MacdonaldFacebook2 type={type} />;
    case '3':
      return <MacdonaldFacebook3 type={type} />;
    case '4':
      return <MacdonaldFacebook4 type={type} />;
    case '5':
      return <MacdonaldFacebook5 type={type} />;
    case '6':
      return <MacdonaldFacebook6 type={type} />;
    case '7':
      return <MacdonaldFacebook7 type={type} />;
    case '8':
      return <MacdonaldFacebook8 type={type} />;
    case '9':
      return <MacdonaldFacebook9 type={type} />;
    case '10':
      return <MacdonaldFacebook10 type={type} />;
    case '11':
      return <MacdonaldFacebook11 type={type} />;
    case '12':
      return <MacdonaldFacebook12 type={type} />;
    case '13':
      return <MacdonaldFacebook13 type={type} />;
    case '14':
      return <MacdonaldFacebook14 type={type} />;
    case '15':
      return <MacdonaldFacebook15 type={type} />;
    case '16':
      return <MacdonaldFacebook16 type={type} />;
    case '17':
      return <MacdonaldFacebook17 type={type} />;
    case '18':
      return <MacdonaldFacebook18 type={type} />;
    default:
      const componentId = templates.find(st => st.name === templateId)?.componentId;
      const defaultURL = templates.find(st => st.name === templateId)?.default_URL;

      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <CommonSocialPage defaultURL={defaultURL} type={type} Component={AllSocialTemplates[componentId]} />
      );
  }
};

const renderWhistler = (templateId, type, templates) => {
  switch (templateId) {
    case 'w1':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate1} />;
    case 'w2':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate2} />;
    case 'w3':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate3} />;
    case 'w4':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate4} />;
    case 'w5':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate5} />;
    case 'w6':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate6} />;
    case 'w7':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate7} />;
    case 'w8':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate8} />;
    case 'w9':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate9} />;
    case 'w10':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate10} />;
    case 'w11':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate11} />;
    case 'w12':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate12} />;
    case 'w13':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate13} />;
    case 'w14':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate14} />;
    case 'w15':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate15} />;
    case 'w16':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate16} />;
    case 'w17':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate17} />;
    case 'w18':
      return <WhistlerSocialPage type={type} Component={WhistlerSocialTemplate18} />;
    case 'wf1':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate1} />;
    case 'wf2':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate2} />;
    case 'wf3':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate3} />;
    case 'wf4':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate4} />;
    case 'wf5':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate5} />;
    case 'wf6':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate6} />;
    case 'wf7':
      return <WhistlerSocialPage type={type} Component={WhistlerFacebookTemplate7} />;
    default:
      const componentId = templates.find(st => st.name === templateId)?.componentId;
      const defaultURL = templates.find(st => st.name === templateId)?.default_URL;

      return !componentId ? (
        <Redirect to="/dashboard" />
      ) : (
        <CommonSocialPage defaultURL={defaultURL} type={type} Component={AllSocialTemplates[componentId]} />
      );
  }
};

const renderUniversalTemplate = (templateId, type, templates = []) => {
  const defaultURL = templates.find(st => st.name === templateId)?.default_URL;
  const componentId = templates.find(st => st.name === templateId)?.componentId;

  return !componentId ? (
    <Redirect to="/dashboard" />
  ) : (
    <CommonSocialPage
      defaultURL={defaultURL}
      type={type}
      defaultURL={defaultURL}
      Component={AllSocialTemplates[componentId]}
    />
  );
};

const FacebookRoute = props => {
  useTitle('Facebook Template - Realvault');

  const { id: templateId, type, mlsId } = useParams();
  useEffect(() => {
    const templates = props.socialTemplates;
    const template = templates.find(st => st.name === templateId);
    track(SOCIAL_MEDIA_TEMPLATE_SELECTED, { template });
  }, [templateId]);

  return (
    <DefaultLayout>
      {![BROKER_CODES.WHISTLER, BROKER_CODES.MACDONALD_REALTY, BROKER_CODES.PEMBERTON].includes(
        props.brokers.currentBroker?.template_code
      ) ? (
        renderUniversalTemplate(templateId, type, props.socialTemplates)
      ) : (
        <ListingGuard>
          {({ searchedListing, type, templateId, brokers }) => {
            if (!searchedListing && !listingExceptions.includes(templateId)) return <Redirect to={`/dashboard`} />;
            if (brokers.currentBroker?.template_code === BROKER_CODES.PEMBERTON) {
              return renderPemberton(templateId, type, props.socialTemplates);
            } else if (brokers.currentBroker?.template_code === BROKER_CODES.MACDONALD_REALTY) {
              return renderMacdonald(templateId, type, props.socialTemplates);
            } else if (brokers.currentBroker?.template_code === BROKER_CODES.WHISTLER) {
              return renderWhistler(templateId, type, props.socialTemplates);
            } else {
              return <PageNotFound />;
            }
          }}
        </ListingGuard>
      )}
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  const { brokers } = state;
  return { brokers, socialTemplates: brokers.socialTemplates };
};

export default connect(mapStateToProps)(FacebookRoute);
