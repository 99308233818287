import React from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import PasswordReset from '../pages/PasswordReset';
import ResetPasswordPage from 'src/components/authorization/ResetPasswordPage';
import Dashboard from '../pages/dashboard';
import Library from '../pages/Library';
import ListingDashboard from '../pages/ListingDashboard';
import AdvertiseRoute from '../pages/advertise';
import BrochureRoute from '../pages/brochure';
import InstagramRoute from '../pages/instagram';
import FacebookRoute from '../pages/facebook';
import PageNotFound from '../pages/PageNotFound';
import ApplicationAdmin from '../components/admin/ApplicationAdmin';
import Login from '../components/authorization/Login';
import Register from '../components/authorization/Register';
import { store } from '../index';
import ThemeViewer from 'src/pages/ThemeViewer';
import EditTheme from 'src/pages/EditTheme';
import ManageSocialTemplates from 'src/pages/ManageSocialTemplates/manage-social-templates';
import ManagePrintTemplates from 'src/pages/ManagePrintTemplates/manage-print-templates';
import ManageAdvertisingTemplates from 'src/pages/ManageAdvertisingTemplates/manage-advertising-templates';
import DefaultTheme from 'src/pages/DefaultTheme/default-theme';
import ThemeCreator from 'src/pages/ThemeCreator';
import ManageUsers from 'src/pages/ManageUsers/manage-users';
import FeedSettings from 'src/components/admin/FeedSettings';
import AccountSettings from 'src/pages/AccountSettings/account-settings';
import BillingInformation from 'src/pages/Billing/BillingInformation/billing-information';
import BillingHistory from 'src/pages/Billing/BillingHistory/billing-history';
import CurrentPlan from 'src/pages/Billing/CurrentPlan/current-plan';
import OnboardingBroker from 'src/pages/OnboardingBroker/onboarding-broker';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      store.getState().users.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      store.getState().users.isAuthenticated ? (
        <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const PublicOrPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} />} />
);

const OnboardingRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} />} />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect from="/" to="/login" />} />
      <PublicRoute path="/passwordReset/:token?" component={PasswordReset} />
      <PublicRoute path="/reset-password" component={ResetPasswordPage} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/register" component={Register} />

      {/* Onboarding Layout */}
      <PublicOrPrivateRoute path="/onboarding/pricing" component={OnboardingBroker} />

      <PrivateRoute path="/system-admin" component={ApplicationAdmin} />

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/library" component={Library} />
      <PrivateRoute path="/listing/:mlsId?" exact component={ListingDashboard} />
      <PrivateRoute path="/listing/:mlsId/brochure/:id" exact component={BrochureRoute} />
      <PrivateRoute path="/listing/:mlsId/advertise/:id" exact component={AdvertiseRoute} />
      <PrivateRoute path="/listing/:mlsId/facebook/:type/:id" exact component={FacebookRoute} />
      <PrivateRoute path="/listing/:mlsId/instagram/:type/:id" exact component={InstagramRoute} />
      <PrivateRoute exact path="/list-themes" component={ThemeViewer} />
      <PrivateRoute exact path="/edit-theme/:id" component={EditTheme} />

      {/* Admin Routes here */}
      <PrivateRoute exact path="/account/information" component={AccountSettings} />

      <PrivateRoute exact path="/manage-users" component={ManageUsers} />
      <PrivateRoute exact path="/feed-settings" component={FeedSettings} />
      <PrivateRoute exact path="/templates/social" component={ManageSocialTemplates} />
      <PrivateRoute exact path="/templates/print" component={ManagePrintTemplates} />
      <PrivateRoute exact path="/templates/advertising" component={ManageAdvertisingTemplates} />

      <PrivateRoute exact path="/themes/default" component={DefaultTheme} />
      <PrivateRoute exact path="/themes/additional" component={ThemeViewer} />
      <PrivateRoute exact path="/themes/create" component={ThemeCreator} />
      <PrivateRoute exact path="/themes/edit/:id" component={EditTheme} />

      {/* Billing routes */}
      <PrivateRoute exact path="/billing/information" component={BillingInformation} />
      <PrivateRoute exact path="/billing/history" component={BillingHistory} />
      <PrivateRoute exact path="/billing/plan" component={CurrentPlan} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
