import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';
import WhistlerSocialTemplate from 'src/templates/base/WhistlerSocialTemplate';

import './WhistlerSocialTemplate18.scss';

const TEMPLATE_NAME = 'The_Whistler_Real_Estate_Company_18';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class WhistlerSocialTemplate18 extends Component {
  constructor(props) {
    super(props);

    let { content, clients, adjustedHeight } = props;

    this.state = {
      content,
      clients,
      adjustedHeight,
    };
  }

  render() {
    const { content, clients, adjustedHeight } = this.state;

    WhistlerSocialTemplate18._cached = { content, clients };

    return (
      <WhistlerSocialTemplate className="whistler-social-18-wrapper">
        <div className="details-container">
          <div className="testimonial">
            <div className="headline">Testimonial</div>
            <hr />
            <EditableTextarea
              self={this}
              onHeightChange={v => (WhistlerSocialTemplate18._cached.adjustedHeight = v)}
              initialHeight={adjustedHeight}
              className="content"
              fieldName="content"
              placeholder="Lorem ipsum dolor sit amet, adipiscing elit. Nullam lobortis sem vitae nisl scelerisque, nec aliquet leo pharetra. Cras rutrum est et velit eleifend ac at nisi."
            />
            <EditableInput self={this} className="client-name" fieldName="clients" placeholder="Client Name(s)" />
          </div>
        </div>
      </WhistlerSocialTemplate>
    );
  }
}

WhistlerSocialTemplate18._name = TEMPLATE_NAME;
WhistlerSocialTemplate18._width = TEMPLATE_WIDTH;
WhistlerSocialTemplate18._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerSocialTemplate18, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(WhistlerSocialTemplate18);
