import React, { useState } from 'react';
import axios from 'axios';
import { Button, Icon } from 'semantic-ui-react';
import './floorplan.scss';
import Logo from 'src/images/macdonald/macdonald-cmyk-color-logo.png';
import LogoDark from 'src/images/macdonald/macdonald-cmyk-bw-logo.png';
import Image from 'src/components/image';
import Scale from 'src/components/scale';
import EditTextModal from 'src/components/edit-text-modal';
import FilePicker from 'src/components/file-picker';
import BackgroundImage from 'src/components/background-image';
import { connect } from 'react-redux';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import { createRequestHeader } from 'src/services/request-headers';
import UniversalLegalText from 'src/templates/brochures/fragments/common/universal-legal-text';
const Floorplan = ({ listing, mergeCurrentListing, showAgentInfo = true, dark = false }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="mac-floorplan">
      <div className="floorplan-img">
        <Image
          url={listing.floorplanPicture}
          onChange={(newImgUrl, mergeCurrentListing) => {
            mergeCurrentListing({ floorplanPicture: newImgUrl });
          }}
          buttonTitle={'Add Floor Plan IMG'}
          width={540}
          height={showAgentInfo ? 595 : 608}
        />
        <span className="upload-floorplan-pdf">
          <FilePicker
            onSelect={async file => {
              setLoading(true);
              const formData = new FormData();
              formData.append('pdf', file);
              const response = await axios.post('/api/extract-pdf', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                ...createRequestHeader(),
              });
              setLoading(false);
              mergeCurrentListing({ floorplanPicture: response.data.dataUrls[0] });
            }}
            dataUrl={false}
            accept="application/pdf"
          >
            <Scale.Back>
              <Button primary size="tiny" loading={loading}>
                <Icon name="file pdf" /> Upload Floor Plan PDF
              </Button>
            </Scale.Back>
          </FilePicker>
        </span>
      </div>

      {showAgentInfo ? (
        <div className="info">
          <div className="agent-info no-hover">
            <div
              className="intro"
              style={{
                fontFamily: "'UbuntuM',Verdana,Tahoma",
              }}
            >
              For more information, please contact:
            </div>
            <span className="agent-name">
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontSize: '16px',
                  fontFamily: "'UbuntuM', Verdana, Tahoma",
                  color: 'var(--mac-font-color)',
                  lineHeight: '20px',
                }}
                value={
                  listing.agents.agentName.length > 30
                    ? listing.agents.agentName.slice(0, 30)
                    : listing.agents.agentName
                }
                onChange={e => {
                  if (e.target.value.length < 30) {
                    mergeCurrentListing({
                      agents: {
                        ...listing.agents,
                        agentName: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>{' '}
            <span className="agent-phone">
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuL', Verdana, Tahoma",
                }}
                value={
                  listing.agents.agentPhone?.replace(/-/g, '.').length > 15
                    ? listing.agents.agentPhone?.replace(/-/g, '.').slice(0, 15)
                    : listing.agents.agentPhone?.replace(/-/g, '.')
                }
                onChange={e => {
                  if (e.target.value.length < 15) {
                    mergeCurrentListing({
                      agents: {
                        ...listing.agents,
                        agentPhone: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
            <div className="agent-title">
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuL', Verdana, Tahoma",
                }}
                value={
                  listing.agents.agentTitle.length > 50
                    ? listing.agents.agentTitle.slice(0, 50)
                    : listing.agents.agentTitle
                }
                onChange={e => {
                  if (e.target.value.length < 50) {
                    mergeCurrentListing({
                      agents: {
                        ...listing.agents,
                        agentTitle: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
            <div className="agent-contact" style={{ display: 'flex', alignItems: 'baseline' }}>
              <span
                style={{
                  fontFamily: "'UbuntuM', Verdana, Tahoma",
                }}
              >
                E
              </span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  marginRight: '10px',
                  width: '300px',
                  fontFamily: "'UbuntuL', Verdana, Tahoma",
                }}
                value={
                  listing.agents.agentEmail.length > 35
                    ? listing.agents.agentEmail.slice(0, 35)
                    : listing.agents.agentEmail
                }
                onChange={e => {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...listing.agents,
                        agentEmail: e.target.value,
                      },
                    });
                  }
                }}
              />
              {'  '}
              <span
                style={{
                  fontFamily: "'UbuntuM', Verdana, Tahoma",
                }}
              >
                W
              </span>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "'UbuntuL', Verdana, Tahoma",
                }}
                value={
                  listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '').length > 35
                    ? listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '').slice(0, 35)
                    : listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '')
                }
                onChange={e => {
                  if (e.target.value.length < 35) {
                    mergeCurrentListing({
                      agents: {
                        ...listing.agents,
                        agentWebsite: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
            {/*<div className="edit-btn-hover not-in-pdf">
                <Scale.Back>
                  <EditTextModal activeTab={1} triggerTitle="Edit Agent" />
                </Scale.Back>
              </div>*/}
          </div>
          <div className="logo">
            <img src={dark ? LogoDark : Logo} width="85" base64="true" alt="logo" />
          </div>
        </div>
      ) : (
        <div className="agent-banner-container">
          <BackgroundImage url={listing.agents.agentBanner} width={540} height={110} size="contain" />
          {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
            <span className="empty-placeholder not-in-pdf"></span>
          )}
          <div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <div style={{ position: 'relative', left: '-27%' }}>
                <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
              </div>
            </Scale.Back>
          </div>
        </div>
      )}

      <div
        className={`legal ${!showAgentInfo && 'small-margin'}`}
        style={{
          fontFamily: "'UbuntuL', Verdana, Tahoma",
        }}
      >
        <UniversalLegalText
          style={{
            fontFamily: "'UbuntuL', Verdana, Tahoma",
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { mergeCurrentListing })(Floorplan);
