import axios from 'axios';
import { createRequestHeader } from 'src/services/request-headers';

export const handleFetchBroker = (id, storeCurrentBroker) => {
  return axios
    .get(`${process.env.REACT_APP_API}/broker/${id}`, createRequestHeader())
    .then(res => {
      storeCurrentBroker(res.data);
    })
    .catch(error => {
      console.log(error);
    });
};

export const handleFetchUser = (id, setCurrentUser, storeCurrentBroker) => {
  return axios
    .get(`${process.env.REACT_APP_API}/user/${id}`, createRequestHeader())
    .then(res => {
      setCurrentUser(res.data);
      postEventDetails({
        type: 'login',
        time: new Date().toISOString,
        email: res.data.email,
        broker: res.data.broker_id,
      });
      if (res.data.broker_id) {
        handleFetchBroker(res.data.broker_id, storeCurrentBroker);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const postEventDetails = data => {
  return axios
    .post(`${process.env.REACT_APP_TEMPLATE_API}/event`, data)
    .then(res => {
      console.log(data.type);
    })
    .catch(err => {
      console.log(err);
    });
};
