import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from 'src/services/template-renderer';
import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import EditableImage from 'src/components/editable-image';
import EditableTextarea from 'src/components/editable-textarea';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import PlaceholderImage from 'src/images/banner.png';
import { staticBrokerFonts } from 'src/utils/generic-utils';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './SocialTemplate7.scss';

const TEMPLATE_NAME = 'Revault_Social_Template_7';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class SocialTemplate7 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, adjustedHeight, listingDetails, defaultURL } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      address,
      adjustedHeight,
      listingDetails,
      defaultURL,
    };
  }

  render() {
    const { currentListing, appliedTheme, defaultSettings, currentBroker } = this.props;
    const { headline, image1, listingDetails, address, adjustedHeight } = this.state;

    SocialTemplate7._cached = {
      headline,
      image1,
      listingDetails,
      address,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="vw-social-template-7-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <img
          src={this.state.defaultURL || defaultSettings?.squareLogo || PlaceholderImage}
          className="logo-img"
          alt="brand-logo"
        />
        <div
          className="solid-ribbon"
          style={{
            background: appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
          }}
        >
          <ApplyFont
            fontName={appliedTheme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular}
          >
            <EditableTextarea
              self={this}
              onHeightChange={v => (SocialTemplate7._cached.adjustedHeight = v)}
              initialHeight={adjustedHeight}
              className="address"
              fieldName="address"
              rows={1}
              placeholder="#123 - 456 W Georgia St Vancouver"
              style={{
                fontFamily:
                  appliedTheme?.font || defaultSettings?.font || staticBrokerFonts[currentBroker?.id]?.regular,
                color: defaultSettings?.secondary,
              }}
            />
          </ApplyFont>
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

SocialTemplate7._name = TEMPLATE_NAME;
SocialTemplate7._width = TEMPLATE_WIDTH;
SocialTemplate7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: SocialTemplate7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps)(SocialTemplate7);
