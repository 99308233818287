import React, { Component } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Tab, Table, Label, Header } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { storeUsers } from 'src/redux/actions/userActions';
import { storeBrokers } from 'src/redux/actions/brokerActions';
import { createRequestHeader } from 'src/services/request-headers';
import { USER_ROLES } from 'src/constants/permissions';
import RequestTable from 'src/components/admin/RequestTable';
import UserModal from './UserModal';
import DefaultLayout from '../../layouts/default-layout';
import UserTable from 'src/components/admin/UserTable';
import ThemeCreator from 'src/pages/ThemeCreator';
import DefaultSettings from 'src/pages/DefaultTheme/default-theme';
import ManagePrintTemplates from 'src/pages/ManagePrintTemplates/manage-print-templates';
import ManageSocialTemplates from 'src/pages/ManageSocialTemplates/manage-social-templates';
import InviteUser from './InviteUser';
import FeedSettings from './FeedSettings';

import './ApplicationAdmin.scss';

const panes = (props, fetchUsers) => {
  const panes = [
    {
      menuItem: {
        name: 'USERS',
        key: '0',
      },
      render: () => (
        <Tab.Pane>
          <Users {...props} fetchUsers={fetchUsers} />
        </Tab.Pane>
      ),
    },
  ];

  if (props.users?.currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN) {
    panes.push({
      menuItem: { name: 'THEME CREATOR', key: '2' },
      render: () => (
        <Tab.Pane>
          <ThemeCreator {...props} />
        </Tab.Pane>
      ),
    });

    panes.push({
      menuItem: { name: 'DEFAULT THEME', key: '5' },
      render: () => (
        <Tab.Pane>
          <DefaultSettings {...props} />
        </Tab.Pane>
      ),
    });

    panes.push({
      menuItem: { name: 'MANAGE PRINT TEMPLATE', key: '3' },
      render: () => (
        <Tab.Pane>
          <ManagePrintTemplates {...props} />
        </Tab.Pane>
      ),
    });

    panes.push({
      menuItem: { name: 'MANAGE SOCIAL TEMPLATE', key: '4' },
      render: () => (
        <Tab.Pane>
          <ManageSocialTemplates {...props} />
        </Tab.Pane>
      ),
    });
  }

  if (
    props.users?.currentUser?.user_role_id === USER_ROLES.BROKER_ADMIN &&
    props.brokers?.currentBroker?.feed_type?.trim()?.toLowerCase() === 'cre'
  ) {
    panes.push({
      menuItem: { name: 'FEED_SETTINGS', key: '6' },
      render: () => (
        <Tab.Pane>
          <FeedSettings {...props} />
        </Tab.Pane>
      ),
    });
  }

  if (props.users.currentUser.user_role_id === USER_ROLES.SYSTEM_ADMIN) {
    panes.push({
      menuItem: { name: 'BROKERS', key: '1' },
      render: () => (
        <Tab.Pane>
          <Brokers {...props} />
        </Tab.Pane>
      ),
    });
  }

  return panes;
};

const Users = props => {
  let currentBroker;
  if (props.users.currentUser.user_role_id === USER_ROLES.BROKER_ADMIN) {
    currentBroker = props.users.currentUser.broker_id;
  }

  return (
    <div className="tab-content application-admin">
      <Header as="h5" className="user_tally">
        Total Active Users: {props.users?.users?.length}
      </Header>
      <Header as="h5" className="user_tally">
        Pending Users Request: {props.users?.users?.filter(({ user_status_id }) => user_status_id === 1).length}
      </Header>
      <UserModal getUsers={props.fetchUsers} add broker={currentBroker} />
      {[USER_ROLES.BROKER_ADMIN, USER_ROLES.SYSTEM_ADMIN].includes(props.users?.currentUser?.user_role_id) && (
        <InviteUser />
      )}
      {[USER_ROLES.BROKER_ADMIN, USER_ROLES.SYSTEM_ADMIN].includes(props.users?.currentUser?.user_role_id) && (
        <RequestTable users={props.users?.users} getUsers={props.fetchUsers} />
      )}
      <br />
      <UserTable
        users={props.users?.users}
        brokers={props.brokers?.brokers}
        user={props.users.currentUser}
        getUsers={props.fetchUsers}
      />
    </div>
  );
};

const Brokers = props => {
  return (
    <div className="tab-content">
      <Table padded="very">
        <Table.Header compact>
          <Table.Row>
            <Table.HeaderCell>Logo</Table.HeaderCell>
            <Table.HeaderCell>Brokerage</Table.HeaderCell>
            <Table.HeaderCell>Users</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.brokers?.brokers.map(broker => (
            <Table.Row>
              <Table.Cell>
                <div className="logo">
                  <img src={broker.logo.url} width="95" base64="true" alt="logo" />
                </div>
              </Table.Cell>
              <Table.Cell>{broker.name}</Table.Cell>
              <Table.Cell>{broker.user_count}</Table.Cell>
            </Table.Row>
          ))}
          {props.brokers?.brokers.length === 0 && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell>No Active Brokerages</Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export class ApplicationAdmin extends Component {
  componentDidMount() {
    this.handleFetchUsers();
    this.handleFetchBrokers();
  }

  handleFetchUsers = () => {
    return axios
      .get(
        `${process.env.REACT_APP_API}/user?broker_id=${this.props.users?.currentUser.broker_id}`,
        createRequestHeader()
      )
      .then(res => {
        this.props.storeUsers(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleFetchBrokers = () => {
    return axios
      .get(`${process.env.REACT_APP_API}/broker`, createRequestHeader())
      .then(res => {
        this.props.storeBrokers(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleTabChange = (event, tab) => {
    this.props.history.replace(`/system-admin#${tab.activeIndex}`);
  };

  render() {
    const activeTab = this.props.location?.hash?.substring(1) ? this.props.location.hash.substring(1) : 0;
    if (
      this.props.users?.currentUser.user_role_id !== USER_ROLES.SYSTEM_ADMIN &&
      this.props.users?.currentUser.user_role_id !== USER_ROLES.BROKER_ADMIN
    ) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <DefaultLayout>
        <div className="padding--md">
          <Tab
            panes={panes(this.props, this.handleFetchUsers)}
            onTabChange={this.handleTabChange}
            defaultActiveIndex={activeTab}
          ></Tab>
        </div>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => {
  const { users, brokers } = state;
  return { users, brokers };
};
const mapDispatchToProps = dispatch => bindActionCreators({ storeUsers, storeBrokers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationAdmin);
