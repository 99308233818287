import React, { useState } from 'react';
import { Table, Header, Button, Icon } from 'semantic-ui-react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import UserModal from 'src/components/admin/UserModal';
import { STATUS_HASH, PERMISSION_HASH, USER_ROLES } from 'src/constants/permissions';
import { deleteUserById, getAllUsers } from 'src/redux/actions/userActions';
import { getSubscriptionStatus } from 'src/redux/actions/stripeActions';
import { getBrokerTotalUsers } from 'src/redux/actions/stripeActions';

const UserTable = props => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [deleteLoading, setDeleteLoading] = useState('');

  const brokerHash = props.brokers.reduce((map, { id, name }) => ({ [id]: name, ...map }), {});
  const isSystemAdmin = props.user.user_role_id === USER_ROLES.SYSTEM_ADMIN;
  let currentBroker;
  if (props.user.user_role_id === USER_ROLES.BROKER_ADMIN) {
    currentBroker = props.user.broker_id;
  }

  const onDeleteUser = userId => {
    setDeleteLoading(userId);
    dispatch(deleteUserById(userId))
      .then(response => {
        setDeleteLoading('');
        addToast('Agent deleted successfully!', { appearance: 'success', autoDismiss: true });
        dispatch(getAllUsers(props.user?.broker_id));
        dispatch(getBrokerTotalUsers(props.user?.broker_id));
        dispatch(
          getSubscriptionStatus({
            user_id: props.user?.id,
          })
        );
      })
      .catch(error => {
        setDeleteLoading('');
        console.log(error?.response?.data?.error, 'ERR => while deleting user');
        addToast(error?.response?.data?.error, { appearance: 'error', autoDismiss: true });
      });
  };

  return (
    <>
      <Header as="h3">Active Users</Header>
      <Table>
        <Table.Header compact>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            {isSystemAdmin && <Table.HeaderCell>Brokerage</Table.HeaderCell>}
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Registered On</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.users
            ?.filter(({ user_status_id }) => user_status_id !== 1)
            .map(user => (
              <Table.Row>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                  {user.first_name} {user.last_name}
                </Table.Cell>
                <Table.Cell>{user.phone_number}</Table.Cell>
                {isSystemAdmin && <Table.Cell>{brokerHash[user.broker_id]}</Table.Cell>}
                <Table.Cell>{PERMISSION_HASH[user.user_role_id]}</Table.Cell>
                <Table.Cell>{STATUS_HASH[user.user_status_id]}</Table.Cell>
                <Table.Cell>{format(new Date(user.created_at), 'mmm dd, yyy')}</Table.Cell>
                <Table.Cell textAlign="right">
                  <UserModal user={user} edit editPassword getUsers={props.getUsers} broker={currentBroker} />
                  <UserModal user={user} edit getUsers={props.getUsers} broker={currentBroker} />
                  <Button
                    className="mt-5 w-full"
                    size="tiny"
                    onClick={() => onDeleteUser(user.id)}
                    floated="right"
                    color="black"
                    loading={user.id === deleteLoading}
                  >
                    <span>
                      <Icon name="user delete" /> Delete User
                    </span>
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          {props.users?.filter(({ user_status_id }) => user_status_id !== 1).length === 0 && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell>No Active Users</Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default UserTable;
