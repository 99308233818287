import React from 'react';
import './AllWhistlerFacebookTemplatesContainer.scss';
import { Header } from 'semantic-ui-react';

import PreviewItem from 'src/components/preview-item';
import WhistlerFacebookTemplate1 from './WhistlerFacebookTemplate1';
import WhistlerFacebookTemplate2 from './WhistlerFacebookTemplate2';
import WhistlerFacebookTemplate3 from './WhistlerFacebookTemplate3';
import WhistlerFacebookTemplate4 from './WhistlerFacebookTemplate4';
import WhistlerFacebookTemplate5 from './WhistlerFacebookTemplate5';
import WhistlerFacebookTemplate6 from './WhistlerFacebookTemplate6';
import WhistlerFacebookTemplate7 from './WhistlerFacebookTemplate7';

const AllWhistlerFacebookTemplatesContainer = ({
  mlsId,
  type,
  headline = 'Add Text',
  targetWidth,
  initialRouteParam = '',
}) => {
  return (
    <div>
      {/* <Header as="h1" className="preview-list-header">
        FACEBOOK
      </Header> */}
      <div className="whistler-facebook-templates-container">
        <PreviewItem
          title="Whistler Facebook 1"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf1`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate1}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 2"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf2`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate2}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 3"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf3`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate3}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 4"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf4`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate4}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 5"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf5`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate5}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 6"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf6`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate6}
          data={{ headline }}
        />
        <PreviewItem
          title="Whistler Facebook 7"
          linkTo={`${initialRouteParam}${mlsId}/facebook/${type}/wf7`}
          targetWidth={targetWidth}
          Component={WhistlerFacebookTemplate7}
          data={{ headline }}
        />
      </div>
    </div>
  );
};

export default AllWhistlerFacebookTemplatesContainer;
