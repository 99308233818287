import React from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { GENERATE_AI_TEXT, track } from 'src/utils/analytics';
import { toggleMessage } from 'src/redux/actions/messageAction';

const AITextGenerationButton = ({ currentListing, messageInterface, toggleMessage, isSocial }) => {
  const { addToast } = useToasts();

  const handleButtonClick = event => {
    event.preventDefault();

    outputAiText();
    // Add Tracking Analytics ??
    toggleMessage({
      isVisible: true,
      isLoading: true,
      message: {
        title: 'Generating AI text.',
        note: 'This can take a minute or two depending on your internet connection.',
      },
      data: null,
    });
  };

  const outputAiText = async () => {
    try {
      const { details, location, price, description } = currentListing;
      const messages = isSocial
        ? [
            {
              role: 'system',
              content: 'You are a knowledgeable and experienced realtor, with the best social media marketing ability.',
            },
            // {
            //   role: 'user',
            //   content: `imagine you are a realtor writing a social media post for a listing with ${
            //     details.qtyBedrooms
            //   } bedrooms and ${details.qtyFullBathrooms} bathrooms in ${location.streetAddress}, ${location.city}, ${
            //     location.stateCode
            //   }. Here are the description: ${description}. Here are the attributes: ${JSON.stringify(
            //     price
            //   )}, ${JSON.stringify(details)}`,
            // },
            {
              role: 'user',
              content: `Generate a nice formated social media post for our brochure listing (max 300 characters).
                Here are the description: ${description}.
                bedrooms: ${details.qtyBedrooms} bathrooms: ${details.qtyFullBathrooms}.
                Location details: ${location.streetAddress}, ${location.city}, ${location.stateCode}
                price: ${price.priceDisplay}`,
            },
          ]
        : [
            {
              role: 'system',
              content: 'You are a knowledgeable and experienced realtor',
            },
            {
              role: 'user',
              content: `Generate a description for our brochure listing (max 250 characters).
                Here are the description: ${description}.
                bedrooms: ${details.qtyBedrooms} bathrooms: ${details.qtyFullBathrooms}.
                Location details: ${location.streetAddress}, ${location.city}, ${location.stateCode}
                price: ${price.priceDisplay}`,
            },
          ];

      const res = await axios.post(
        `${process.env.REACT_APP_OPEN_AI_URL}`,
        {
          model: 'gpt-3.5-turbo',
          messages,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_SECRET_ACCESS_KEY}`,
          },
        }
      );

      const generatedText = res.data.choices[0].message.content;

      track(GENERATE_AI_TEXT, { content: res.data.choices[0].message.content });
      toggleMessage({
        isVisible: true,
        isLoading: false,
        message: { title: 'Here is AI Generated Text.', note: 'Copy and paste into Listing.' },
        data: generatedText,
      });
    } catch (error) {
      addToast('Failed to generate AI text, Please try again!', {
        appearance: 'error',
        autoDismiss: true,
      });
      toggleMessage({
        isVisible: false,
        isLoading: false,
        message: { title: null, note: null },
        data: null,
      });
    }
  };

  return (
    <Button color="green" disabled={messageInterface.isVisible} onClick={handleButtonClick}>
      Generate AI Text
    </Button>
  );
};

const mapStateToProps = state => {
  const { currentListing, messageInterface } = state;
  return { currentListing, messageInterface };
};

export default connect(mapStateToProps, { toggleMessage })(AITextGenerationButton);
