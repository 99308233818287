import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scale from 'src/components/scale';
import TemplateLayout from 'src/layouts/template-layout';
import MacdonaldBrochure5 from 'src/templates/brochures/macdonald/MacdonaldBrochure5';

class MacdonaldBrochurePage5 extends Component {
  render() {
    const { currentListing } = this.props;

    return (
      <TemplateLayout templateKey={MacdonaldBrochure5._name} currentListing={currentListing}>
        <Scale width={MacdonaldBrochure5._width} height={MacdonaldBrochure5._height} pages={1} scale={1.5}>
          <MacdonaldBrochure5 />
        </Scale>
      </TemplateLayout>
    );
  }
}

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(MacdonaldBrochurePage5);
