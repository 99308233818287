import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import GoogleFontLoader from 'react-google-font-loader';

const FontPreview = ({ name }) => {
  return (
    <>
      <Header as="h5">Font Preview:</Header>
      <Card fluid header="Option 1">
        <div style={{ padding: 16 }}>
          {!name ? (
            <p>The quick brown fox jumped over the lazy dog</p>
          ) : (
            <>
              <GoogleFontLoader
                fonts={[
                  {
                    font: name,
                    weights: [400],
                  },
                ]}
              />
              <p style={{ fontFamily: name }}>The quick brown fox jumped over the lazy dog</p>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default FontPreview;
