import React, { useState } from 'react';
import axios from 'axios';
import { Button, Icon } from 'semantic-ui-react';
import './universal-floorplan.scss';

import Image from 'src/components/image';
import Scale from 'src/components/scale';
import EditTextModal from 'src/components/edit-text-modal';
import FilePicker from 'src/components/file-picker';
import { connect } from 'react-redux';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import { createRequestHeader } from 'src/services/request-headers';

import EditableImageV2 from 'src/components/editable-image-v2';

const UniversalFloorplan = ({
  headerLogo,
  footerLogo,
  listing,
  mergeCurrentListing,
  dark = false,
  showHeader = true,
  showFooter = true,
  theme,
  font,
  activeTab = 1,

}) => {
  const [loading, setLoading] = useState(false);
  const themeName = theme || localStorage.getItem('themeClassName');

  return (
    <div className="floorplan">
      {showHeader && (
        <div className="listing-info--header" style={{ backgroundColor: theme }}>
          <div className="logo--white">
            <EditableImageV2
              fieldName="logo"
              className="logo"
              alt="Brand Logo"
              size="contain"
              style={{ width: 95, height: 65, position: 'relative' }}
              position={EditableImageV2.CENTER}
              buttonSize={'tiny'}
              buttonText={''}
              src={headerLogo  }
              onUpdate={url => {
                const pictures = [...listing.pictures];
                pictures[103] = url;
                mergeCurrentListing({
                  pictures,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="floorplan-img">
        <Image
          url={listing.floorplanPicture}
          onChange={(newImgUrl, mergeCurrentListing) => {
            mergeCurrentListing({ floorplanPicture: newImgUrl });
          }}
          buttonTitle={'Add Floor Plan IMG'}
          width={540}
          height={showHeader ? 530 : 595}
        />
        <span className="upload-floorplan-pdf">
          <FilePicker
            onSelect={async file => {
              setLoading(true);
              const formData = new FormData();
              formData.append('pdf', file);
              const response = await axios.post('/api/extract-pdf', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                ...createRequestHeader(),
              });
              setLoading(false);
              mergeCurrentListing({
                floorplanPicture: response.data.dataUrls[0],
                floorplanPictureTwo: response.data.dataUrls[1]
                  ? response.data.dataUrls[1]
                  : listing.floorplanPictureTwo,
              });
            }}
            dataUrl={false}
            accept="application/pdf"
          >
            <Scale.Back>
              <Button primary size="tiny" loading={loading}>
                <Icon name="file pdf" /> Upload Floor Plan PDF
              </Button>
            </Scale.Back>
          </FilePicker>
        </span>
      </div>

      <div className="info">
        <div className="agent-info no-hover">      
          <div className="intro" style={{ fontFamily: font }}>
            For more information, please contact:
          </div>
          <div className="edit-btn-hover not-in-pdf" style={{ marginLeft: '0px'}}>
          <Scale.Back>
            <EditTextModal activeTab={activeTab} triggerTitle="Edit Agent" />
          </Scale.Back>
        </div>

          <div>
          <span className="agent-name">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font,
              }}
              value={
                listing.agents.agentName.length > 30 ? listing.agents.agentName.slice(0, 30) : listing.agents.agentName
              }
              onChange={e => {
                if (e.target.value.length < 30) {
                  mergeCurrentListing({
                    agents: {
                      ...listing.agents,
                      agentName: e.target.value,
                    },
                  });
                }
              }}
            />
          </span></div>{' '}
         
          <span className="agent-phone">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font,
              }}
              value={
                listing.agents.agentPhone?.replace(/-/g, '.').length > 15
                  ? listing.agents.agentPhone?.replace(/-/g, '.').slice(0, 15)
                  : listing.agents.agentPhone?.replace(/-/g, '.')
              }
              onChange={e => {
                if (e.target.value.length < 15) {
                  mergeCurrentListing({
                    agents: {
                      ...listing.agents,
                      agentPhone: e.target.value,
                    },
                  });
                }
              }}
            />
          </span>
         
          <div className="agent-title">
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font,
              }}
              value={
                listing.agents.agentTitle.length > 50
                  ? listing.agents.agentTitle.slice(0, 50)
                  : listing.agents.agentTitle
              }
              onChange={e => {
                if (e.target.value.length < 50) {
                  mergeCurrentListing({
                    agents: {
                      ...listing.agents,
                      agentTitle: e.target.value,
                    },
                  });
                }
              }}
            />
       
          </div>
          <div className="agent-contact" style={{ fontFamily: font }}>
            <span style={{ fontFamily: font }}>E</span>
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font,
              }}
              value={
                listing.agents.agentEmail.length > 35
                  ? listing.agents.agentEmail.slice(0, 35)
                  : listing.agents.agentEmail
              }
              onChange={e => {
                if (e.target.value.length < 35) {
                  mergeCurrentListing({
                    agents: {
                      ...listing.agents,
                      agentEmail: e.target.value,
                    },
                  });
                }
              }}
            />

            {'  '}
            <span style={{ fontFamily: font }}>W</span>
            <input
              className="mobile-p"
              style={{
                border: 'none',
                boxShadow: 'none',
                outline: 'none',
                fontFamily: font,
              }}
              value={
                listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '').length > 35
                  ? listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '').slice(0, 35)
                  : listing.agents.agentWebsite?.replace(/https:\/\/|http:\/\//, '')
              }
              onChange={e => {
                if (e.target.value.length < 35) {
                  mergeCurrentListing({
                    agents: {
                      ...listing.agents,
                      agentWebsite: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          {/*<div className="edit-btn-hover not-in-pdf">
            <Scale.Back>
              <EditTextModal activeTab={1} triggerTitle="Edit Agent" />
            </Scale.Back>
          </div>*/}
        </div>
        <div className="logo">
          <EditableImageV2
            fieldName="logo"
            className="logo"
            alt="Brand Logo"
            size="contain"
            style={{ width: themeName === 'black' ? 95 : 85, height: 65, position: 'relative' }}
            position={EditableImageV2.CENTER}
            buttonSize={'tiny'}
            buttonText={''}
            src={listing.agents.pictures?.[103]  || footerLogo  }
            onUpdate={url => {
              const pictures = [];
              pictures[103] = url;
              mergeCurrentListing({
                agents: {
                  ...listing.agents,
                  pictures,
                 },
              });
            }}
          />
        </div>
      </div>
      <div className="legal">
        This communication is not intended to cause or induce breach of an existing agency agreement. Although this
        information has been received from sources deemed reliable, we assume no responsibility for its accuracy, and
        without offering advice, make this submission to prior sale or lease, change in price or terms, and withdrawal
        without notice.
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalFloorplan);
