import React, { useState, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { isFileLarge } from 'src/utils/generic-utils';
import logo from 'src/images/logo/revault-icon-blue.png';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';
import { OldTemplates } from '../../constants/oldTemplates';

const AVATAR_HEIGHT = 250;
const AVATAR_WIDTH = 250;

function s3Fetch(url) {
  return fetch(url);
  // , { header: { Origin: `${window.location.origin}` } }
}

const CropperModal = ({
  currentBroker,
  open = false,
  setOpen = () => {},
  image,
  onApply = () => {},
  loading = false,
  removeLoading = false,
  onRemovePhoto = () => {},
}) => {
  let token = localStorage.getItem('jwt');
  let [templateId, settemplateId] = useState(localStorage.getItem('templateId'));

  let decodedToken;
  if (!token) {
    token = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
    decodedToken = token;
  } else {
    decodedToken = jwt.decode(token);
  }

  const logoRef = useRef();
  const avatarEditorRef = useRef();
  const { addToast } = useToasts();
  const [loadingApply, setloadingApply] = useState(false);
  const localBasicDetails = decodedToken;
  const [logoList, setLogoList] = useState([logo]);
  const [scale, setScale] = useState(1.2);
  const [rotate, setRotate] = useState(0);
  const [selectedImg, setselectedImg] = useState(null);
  const [oneImg, setOneImg] = useState(null);
  const changeBrokerageLogo = event => {
    const files = event.target.files;
    let fileArray = Object.values(files);
    let temp = [...logoList];
    fileArray.forEach(file => {
      let selected = false;
      if (!isFileLarge(file.size, 2)) {
        addToast('File size exceeds than maximum 2mb limit!', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      if (temp.length >= 3) {
        addToast('Can not added more images', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      if (temp.length === 0) {
        setselectedImg(file);
        selected = true;
      }
      temp.push({ selected: selected, url: URL.createObjectURL(file), raw: file });
    });
    setLogoList(temp);
  };

  useEffect(() => {
    if (currentBroker && currentBroker.template_code) {
      let tempId = currentBroker.template_code;
      settemplateId(tempId);
      if (!OldTemplates.includes(tempId)) {
        async function getLogo() {
          let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
          return resp?.data?.logo;
        }

        getLogo().then(res => {
          if (res) {
            let newArr = [...res];
            let tempArr = newArr.filter(v => {
              if (v.selected === true) {
                toDataUrl(v.url, function (data) {
                  setselectedImg(data);
                });
                return v;
              }
            });
            if (newArr.length >= 1) {
              toDataUrl(newArr[0].url, function (data) {
                setselectedImg(data);
              });
            }

            setLogoList(newArr);
          }
        });
      } else {
        let temp = [{ url: logo, selected: true }];
        setselectedImg(logo);
        setLogoList(temp);
      }
    }
  }, [currentBroker, localBasicDetails.broker_id]);

  useEffect(() => {
    if (!image.trim().startsWith('data')) {
      toDataUrl(image, function (data) {
        setOneImg(data);
      });
    } else {
      setOneImg(image);
    }
  }, [image]);

  async function toDataUrl(url, callback) {
    // return;
    const data = await fetch(url);

    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        callback(base64data);
      };
    });
  }

  const selectImg = id => {
    let temp = [...logoList];
    let newArray = temp.map((v, index) => {
      if (index === id && !v.selected) {
        v.selected = true;
        toDataUrl(v.url, function (data) {
          setselectedImg(data);
        });
      } else {
        v.selected = false;
      }
      return v;
    });
    setLogoList(newArray);
  };

  const onRemoveLogo = id => {
    return Swal.fire({
      title: 'Remove Image',
      text: 'Do you want to remove this image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E74942',
      customClass: { container: 'realvault-swal' },
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        let temp = [...logoList];
        if (temp.length >= 1) {
          let newArr = temp.filter((v, i) => {
            if (i !== id) return v;
          });
          setLogoList(newArr);
        }
        return false;
      }
      return false;
    });
  };

  const onSaveCroppedImage = async () => {
    setloadingApply(true);
    let arr = [];
    let tempArr = logoList.filter(a => {
      if (a.selected === true) {
        return a;
      }
    });

    for (const v of logoList) {
      if (v.raw) {
        let formData = new FormData();
        formData.append('file', v.raw);
        formData.append('broker_id', localBasicDetails?.broker_id);
        formData.append('user_id', localBasicDetails?.user_id);
        let resp = await axios.post(`${process.env.REACT_APP_API}/file-upload-onboarding`, formData, {
          Origin: `${window.location.origin}`,
          'content-type': 'multipart/form-data',
        });
        v.url = resp.data.file_public_url;
      }

      arr.push({ url: v.url, selected: v.selected });
    }

    if (tempArr.length === 0) {
      addToast('Please Select the image', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    let resBody = {
      logo: arr,
      broker_id: localBasicDetails?.broker_id,
    };
    if (avatarEditorRef.current) {
      if (selectedImg) {
        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
        const imageDataURI = avatarEditorRef.current.getImageScaledToCanvas().toDataURL();
        let res = await fetch(imageDataURI);
        let data = await res.blob();
        onApply(data, imageDataURI);
        let formData = new FormData();
        formData.append('file', data);
        formData.append('broker_id', localBasicDetails?.broker_id);
        formData.append('user_id', localBasicDetails?.user_id);
        axios
          .post(`${process.env.REACT_APP_API}/file-upload-onboarding`, formData, {
            Origin: `${window.location.origin}`,
            'content-type': 'multipart/form-data',
          })
          .then(async res => {
            arr.forEach(v => {
              if (v.selected === true) {
                v.url = res.data.file_public_url;
              }
            });
            try {
              let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, resBody);
              if (saveRes.statusText === 'OK' && saveRes.status === 200) {
                addToast('Settings changed successfully!', {
                  appearance: 'success',
                  autoDismiss: true,
                });
              }
            } catch (e) {
              addToast(e?.response?.data?.error, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          });
        setloadingApply(false);
      } else {
        s3Fetch(image)
          .then(res => res.blob())
          .then(async data => {
            onApply(data);
            try {
              let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, resBody);
              if (saveRes.statusText === 'OK' && saveRes.status === 200) {
                addToast('Settings changed successfully!', {
                  appearance: 'success',
                  autoDismiss: true,
                });
              }
            } catch (e) {
              addToast(e?.response?.data?.error, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
            setloadingApply(false);
          });
      }
    } else {
      try {
        let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, resBody);
        if (saveRes.statusText === 'OK' && saveRes.status === 200) {
          addToast('Settings changed successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (e) {
        addToast(e?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
      <Modal.Header>Change Brokerage Image</Modal.Header>
      <Modal.Content className="logo-content" image>
        {!OldTemplates.includes(templateId) ? (
          <div className="logo-img-grid">
            {logoList.map((v, i) => {
              return (
                <div key={i} className={`select-img-wrap ${v.selected ? 'img-selected-wrap' : ''}`}>
                  <img
                    src={v.url}
                    onClick={() => {
                      selectImg(i);
                    }}
                  />
                  <button
                    onClick={() => {
                      onRemoveLogo(i);
                    }}
                    className="remove-img"
                    type="button"
                  >
                    <i className="bx bx-x"></i>
                  </button>
                  {v.selected ? (
                    <div className="select-img-button">
                      <i className="bx bx-check"></i>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
        <div>
          {/* <Image size="medium" src={photo.preview || image} className="mx-auto" /> */}
          <div className="p-1 border-2 border-grey-600">
            <AvatarEditor
              ref={avatarEditorRef}
              image={OldTemplates.includes(templateId) ? oneImg : selectedImg || selectedImg || logo}
              width={AVATAR_WIDTH}
              height={AVATAR_HEIGHT}
              scale={scale}
              rotate={rotate}
              border={20}
            />
          </div>
          <p className="text-xs font-semibold text-center">Drag to reposition photo</p>
        </div>
        <Modal.Description className="pl-8">
          {/* Zoom */}
          <div className="mb-2">
            <Header as="h5">Zoom: ({`${scale}`})</Header>
            <input
              className="w-full"
              type="range"
              onChange={e => setScale(+e.target.value)}
              value={scale}
              min={1}
              max={2}
              step={0.1}
            />
          </div>
          {/* Rotate */}
          <div className="mb-2">
            <Header as="h5">Rotate: ({`${rotate} deg`})</Header>
            <input
              className="w-full"
              type="range"
              onChange={e => setRotate(+e.target.value)}
              value={rotate}
              min={0}
              max={360}
              step={1}
            />
          </div>
          <div className="mb-2">
            <Header as="h5">Side Rotate: ({`${rotate} deg`})</Header>
            <div>
              <span
                className="mr-8 cursor-pointer"
                onClick={() =>
                  setRotate(prev => {
                    if (prev >= 90) {
                      return +prev - 90;
                    }
                    return prev;
                  })
                }
              >
                <Icon name="undo" size={'small'} className="mr-8" />
                <span className="font-semibold text-gray-600">Rotate left</span>
              </span>
              <span
                className="cursor-pointer"
                onClick={() =>
                  setRotate(prev => {
                    if (prev <= 270) {
                      return +prev + 90;
                    }
                    return prev;
                  })
                }
              >
                <Icon name="redo" size={'small'} className="mr-8" />
                <span className="font-semibold text-gray-600">Rotate Right</span>
              </span>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {!OldTemplates.includes(templateId) ? (
          <Button secondary onClick={() => logoRef.current.click()}>
            Upload Photo
          </Button>
        ) : (
          ''
        )}

        <Button onClick={onSaveCroppedImage} positive loading={loadingApply}>
          <Icon name="check" /> Apply
        </Button>
      </Modal.Actions>
      <input type="file" onChange={changeBrokerageLogo} ref={logoRef} accept=".png,.jpg,.jpeg" hidden />
    </Modal>
  );
};

export default CropperModal;
