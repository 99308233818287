import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Container, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllSocialTemplates,
  getSocialTemplatesByBrokerId,
  addBrokerSocialTemplate,
  removeBrokerSocialTemplate,
} from 'src/redux/actions/brokerActions';
import * as AllSocialTemplates from 'src/templates';
import './manage-social-templates.scss';
import Resizable from 'src/components/resizable';
import PreviewItem from 'src/components/preview-item';
import AdminLayout from 'src/layouts/admin-layout';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Image } from 'semantic-ui-react';
import { OldTemplates } from '../../constants/oldTemplates';
import { setAllTemplatesToBrokerId } from 'src/redux/actions/brokerActions';
import { useTitle } from 'react-use';

const headline = 'Add Text';

const ManageSocialTemplates = () => {
  useTitle('Manage Social Templates - Realvault');

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { currentBroker, allBrokerSocialTemplates, allowedBrokerSocialTemplates } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    allBrokerSocialTemplates: state.brokers.allBrokerSocialTemplates,
    allowedBrokerSocialTemplates: state.brokers.allowedBrokerSocialTemplates,
  }));

  const [allowedImages, setAllowedImages] = useState(allowedBrokerSocialTemplates);
  let templateId = localStorage.getItem('templateId');
  let token = localStorage.getItem('jwt');
  let decodedToken;
  if (!token) {
    token = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
    decodedToken = token;
  } else {
    decodedToken = jwt.decode(token);
  }
  const localBasicDetails = decodedToken;

  const [loading, setLoading] = useState(false);
  const [allowedTemplateId, setAllowedTemplateId] = useState([]);
  const [imgArray, setimgArray] = useState([]);

  useEffect(() => {
    setLoading(true);
    // get all print templates
    dispatch(getAllSocialTemplates())
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERR => while fetching all print templates');
        setLoading(false);
      });
    // get brokers print template (allowed)
    dispatch(getSocialTemplatesByBrokerId(currentBroker.id))
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERR => while fetching all print templates');
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allBrokerSocialTemplates.length > 0) {
      const allowedTemplateIds = allowedBrokerSocialTemplates.map(template => template.name);
      const allowedTemplateIdsForBroker = allBrokerSocialTemplates
        .filter(template => allowedTemplateIds.includes(template.name))
        .map(template => template.id);
      setAllowedTemplateId(allowedTemplateIdsForBroker);
      if (allowedBrokerSocialTemplates && allowedBrokerSocialTemplates.length > 0) {
        setAllowedImages([...allowedBrokerSocialTemplates]);
      }
    }
  }, [allBrokerSocialTemplates, allowedBrokerSocialTemplates]);

  useEffect(() => {
    if (!OldTemplates.includes(templateId)) {
      async function getLogo() {
        let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
        return resp?.data?.logo;
      }

      getLogo().then(res => {
        if (res) {
          let newArr = [...res];
          setimgArray(newArr);
        }
      });
    }
  }, [localBasicDetails.broker_id, templateId]);

  const onToggleTemplate = (checked, templateId) => {
    if (checked) {
      // call add api
      setAllowedTemplateId(prev => prev.concat(templateId));
      dispatch(
        addBrokerSocialTemplate(currentBroker.id, {
          id: templateId,
        })
      )
        .then(() => {
          // setLoading(true);

          addToast('Print Template linked to broker successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });

          // // get all print templates
          // dispatch(getAllSocialTemplates())
          //   .then(() => {
          //     setLoading(false);
          //   })
          //   .catch(err => {
          //     console.log('ERR => while fetching all print templates');
          //     setLoading(false);
          //   });
          // get brokers print template (allowed)
          dispatch(getSocialTemplatesByBrokerId(currentBroker.id))
            .then(() => {
              // setLoading(false);
            })
            .catch(err => {
              console.log('ERR => while fetching all print templates');
              // setLoading(false);
            });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        })
        .catch(err => {
          console.log('ERR => while adding the template to broker');
        });
    } else {
      // call remove api
      setAllowedTemplateId(prev => prev.filter(tempId => tempId !== templateId));
      dispatch(
        removeBrokerSocialTemplate(currentBroker.id, {
          id: templateId,
        })
      )
        .then(() => {
          addToast('Print Template removed from broker successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
        })
        .catch(err => {
          console.log('ERR => while removing the template from broker');
        });
    }
  };

  const setDefaultImage = (templateId, url, type, isDefault) => {
    if (!isDefault) {
      let data = {
        type: type,
        default_URL: url,
        id: templateId,
      };

      axios
        .post(`${process.env.REACT_APP_TEMPLATE_API}/defaultImage/${localBasicDetails?.broker_id}`, data, {
          'content-type': 'application/json',
        })
        .then(res => {
          if (res.data.status === true) {
            let temp = [...allowedImages];

            temp.forEach(v => {
              if (v.id === templateId) {
                v.default_URL = url;
              }
            });
            setAllowedImages(temp);
            addToast('Settings changed successfully!', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else if (
            res.data.status === false &&
            res.data.message === 'Not with New flow first set all templates default URL'
          ) {
            dispatch(
              setAllTemplatesToBrokerId(localBasicDetails?.broker_id, {
                default_URL: data.default_URL,
              })
            ).then(ress => {
              axios
                .post(`${process.env.REACT_APP_TEMPLATE_API}/defaultImage/${localBasicDetails?.broker_id}`, data, {
                  'content-type': 'application/json',
                })
                .then(res => {
                  if (res.data.status === true) {
                    let temp = [...allowedImages];

                    temp.forEach(v => {
                      if (v.id === templateId) {
                        v.default_URL = url;
                      }
                    });
                    setAllowedImages(temp);
                    addToast('Settings changed successfully!', {
                      appearance: 'success',
                      autoDismiss: true,
                    });
                  }
                });
            });
          }
        });
    } else {
      addToast('Image already set to default', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <div className="flex justify-center items-center">
            <Loader active={loading} />
          </div>
        </Container>
      </AdminLayout>
    );
  }

  const cached = ManageSocialTemplates._cached || {};

  return (
    <AdminLayout pageTitle="Manage Social Media Templates">
      <Container>
        <div className="white template-container main-table-wrap">
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Template Name</Table.HeaderCell>
                <Table.HeaderCell className="text-center">Preview Template</Table.HeaderCell>
                <Table.HeaderCell>Use template</Table.HeaderCell>
                <Table.HeaderCell>Logos Available</Table.HeaderCell>
                <Table.HeaderCell>Use Logo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {allBrokerSocialTemplates.length > 0 && !loading && (
              <Table.Body>
                {allBrokerSocialTemplates
                  .filter(template => AllSocialTemplates[template.componentId])
                  .map(template => (
                    <Table.Row key={template.id}>
                      <Table.Cell>{template.title || '---'}</Table.Cell>
                      <Table.Cell>
                        <Resizable {...cached} className="preview-list-container">
                          {({ widthCol6, widthCol4, widthCol3 }) => {
                            ManageSocialTemplates._cached = {
                              widthCol6,
                              widthCol4,
                              widthCol3,
                            };
                            return (
                              <PreviewItem
                                title={template.title}
                                targetWidth={widthCol6}
                                Component={AllSocialTemplates[template.componentId]}
                                data={{ headline }}
                                pages={template.pages}
                              />
                            );
                          }}
                        </Resizable>
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          toggle
                          checked={allowedTemplateId.includes(template.id)}
                          onChange={(event, { checked }) => onToggleTemplate(checked, template.id)}
                        />
                      </Table.Cell>
                      {!OldTemplates.includes(templateId) ? (
                        <>
                          <Table.Cell>
                            <div className="logo-wrapper">
                              {imgArray.map((img, i) => {
                                return (
                                  <div className="img-wrap-preview">
                                    <Image
                                      style={i === 0 ? { marginLeft: 0 } : {}}
                                      key={i}
                                      className="uploaded-image"
                                      src={img.url}
                                      size="small"
                                      bordered
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              className="checkbox-wrap"
                              style={{
                                display: allowedTemplateId.includes(template.id) ? 'flex' : 'none',
                              }}
                            >
                              {imgArray.map((img, i) => {
                                return (
                                  <>
                                    <Checkbox
                                      toggle
                                      checked={
                                        allowedImages.some(v => {
                                          if (v.id === template.id) {
                                            return v.default_URL === img.url;
                                          }
                                        })
                                          ? true
                                          : false
                                      }
                                      onChange={(event, { checked }) => {
                                        let defURL = '';
                                        allowedImages.map(v => {
                                          if (v.id === template.id) {
                                            defURL = v.default_URL;
                                            return v.default_URL === img.url;
                                          }
                                        });
                                        setDefaultImage(template.id, img.url, 'social', img.url === defURL);
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          </Table.Cell>
                        </>
                      ) : (
                        ''
                      )}
                    </Table.Row>
                  ))}
              </Table.Body>
            )}

            {allBrokerSocialTemplates.length === 0 && !loading && (
              <div className="flex justify-center items-center">
                <div className="p-20">
                  <p>No social template available yet. Contact System Administrator</p>
                </div>
              </div>
            )}
          </Table>
        </div>
      </Container>
    </AdminLayout>
  );
};

export default ManageSocialTemplates;
