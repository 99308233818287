import { SET_CURRENT_DOCUMENT, CLEAR_CURRENT_DOCUMENT, STORE_DOCUMENTS } from '../../constants/action-types';

export const setCurrentDocument = document => ({
  type: SET_CURRENT_DOCUMENT,
  payload: {
    document,
  },
});

export const clearCurrentCurrent = document => ({
  type: CLEAR_CURRENT_DOCUMENT,
  payload: null,
});

export const storeDocuments = documents => ({
  type: STORE_DOCUMENTS,
  payload: { documents },
});
