import React from 'react';
import { connect } from 'react-redux';
import 'src/templates/brochures/reset.css';
import { register } from 'src/services/template-renderer';
import UniversalFloorplan from 'src/templates/brochures/fragments/common/universal-floorplan';
import PlaceholderImage from 'src/images/banner.png';
import UniversalListingInfo from 'src/templates/brochures/fragments/common/universal-listing-info';
import UniversalListingFeature from 'src/templates/brochures/fragments/common/universal-listing-feature';
import _ from 'src/lodash';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import { staticBrokerFonts } from 'src/utils/generic-utils';
import logoPlaceholder from '../../images/vantagewest/logo-placeholder.png';
import './UniversalPembertonBrochure1.scss';
import ApplyFont from 'src/components/FontPreview/ApplyFont';

const TEMPLATE_NAME = 'FOLDED_BROCHURE';
const TEMPLATE_WIDTH = 1224;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, theme, font, mergeCurrentListing, defaultSettings, defaultURL, footerURL }) => {
  const headerLogo = defaultURL || listing.brandLogo ||  defaultSettings?.squareLogo || PlaceholderImage;
  const footerLogo = footerURL || listing.brandLogo ||  defaultSettings?.squareLogo || PlaceholderImage;

  return (
    <div className={`scaled template pem-template-1 ${theme} page-1 ui-only-outline`}>
      <UniversalFloorplan
        headerLogo={headerLogo}
        footerLogo={footerLogo}
        mergeCurrentListing={mergeCurrentListing}
        listing={listing}
        theme={theme}
        font={font}
        dark
      />
      <UniversalListingInfo
        headerLogo={headerLogo}
        footerLogo={footerLogo}
        mergeCurrentListing={mergeCurrentListing}
        listing={listing}
        theme={theme}
        font={font}
      />
    </div>
  );
};

const Page2 = ({ listing, theme, font, mergeCurrentListing }) => {
  return (
    <div className={`scaled template pem-template-1 ${theme} page-2 ui-only-outline`}>
      <UniversalListingFeature mergeCurrentListing={mergeCurrentListing} listing={listing} theme={theme} font={font} />
    </div>
  );
};

const UniversalPembertonBrochure1 = ({
  theme,
  searchedListing,
  currentListing,
  themeClassName,
  mergeCurrentListing,
  ...rest
}) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
       mergeCurrentListing(newListing);
  };
  // const theme = themeClassName || localStorage.getItem('themeClassName');

  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11 = logoPlaceholder,
    address,
  } = rest;

  UniversalPembertonBrochure1._cached = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    address,
    appliedTheme: rest.appliedTheme,
    defaultSettings: rest.defaultSettings,
    staticBrokerFonts,
    currentBroker: rest.currentBroker,
  };
   

  return (
    <div className="universalPemberton1">
      <ApplyFont
        fontName={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
      <Page1
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
        defaultURL={rest.defaultURL}
        footerURL={rest.footerURL}
      />
      <br className="not-in-pdf" />
      <Page2
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        theme={rest?.appliedTheme?.backgroundColor || rest.defaultSettings?.primaryColor}
        font={
          rest?.appliedTheme?.font || rest.defaultSettings?.font || staticBrokerFonts[rest.currentBroker?.id]?.regular
        }
      />
    </div>
  );
};

UniversalPembertonBrochure1._name = TEMPLATE_NAME;
UniversalPembertonBrochure1._width = TEMPLATE_WIDTH;
UniversalPembertonBrochure1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: UniversalPembertonBrochure1,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const {
    searchedListing,
    currentListing,
    theme,
    brokers: { currentBroker },
  } = state;
  return {
    searchedListing,
    currentListing,
    appliedTheme: theme.appliedTheme,
    currentBroker,
    defaultSettings: theme.defaultSettings,
  };
};

export default connect(mapStateToProps, { mergeCurrentListing })(UniversalPembertonBrochure1);
