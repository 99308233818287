import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'src/lodash';
import Scale from 'src/components/scale';
import { formatCurrencyNoDecimal } from 'src/utils/format-number';

import './preview-list.scss';

const PreviewItem = ({
  headerURL,
  footerURL,
  defaultURL,
  title,
  linkTo,
  Component,
  targetWidth,
  targetHeight,
  data,
  pages = 1,
  outline = true,
  price = null,
  retailPrice = null,
}) => {
  title = title || _.titleCase(Component._name);

  return (
    <div className="social-thumbnail noselect">
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 24 }}>
        <div style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif", textAlign: 'left' }}>
          <Header as="h3" style={{ textAlign: 'left', marginBottom: 10 }}>
            {title}
          </Header>
          {retailPrice && (
            <div style={{ textDecoration: 'line-through' }}>Retail Rate {formatCurrencyNoDecimal(retailPrice)}</div>
          )}
          {price && (
            <div style={{ fontSize: 18, fontWeight: 900, marginTop: 4 }}>
              RealVault Price Only {formatCurrencyNoDecimal(price)}
            </div>
          )}
        </div>
      </div>
      <Link to={linkTo} style={{ minWidth: '1px', minHeight: '1px' }}>
        <Scale
          width={Component._width}
          height={Component._height}
          targetWidth={targetWidth}
          targetHeight={targetHeight}
          pages={pages}
          style={{ outline: outline ? 'lightgrey 5px solid' : 'none', margin: 'auto' }}
        >
          <Component {...data} footerURL={footerURL} defaultURL={headerURL ? headerURL : defaultURL} />
        </Scale>
        <div className="social-thumbnail-wrapper"></div>
      </Link>
    </div>
  );
};

export default PreviewItem;
