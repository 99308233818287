import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/LPI_Horizontal_RGB_Color.png';
import placeholderLogo from 'src/images/whistler/Luxury_Portfolio_Black.png';
import WSLogo from 'src/images/whistler/Stacked_Wordmark_Black.png';
import facebook from 'src/images/whistler/facebook.png';
import twitter from 'src/images/whistler/twitter.png';
import instagram from 'src/images/whistler/Instagram.png';
import pinterest from 'src/images/whistler/Pinterest.png';
import EditableImage from 'src/components/editable-image';
import { register } from 'src/services/template-renderer';
import BackgroundImage from 'src/components/background-image';
import Scale from 'src/components/scale';
import AgentBranding from 'src/components/agent-branding';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
import EditTextModal from 'src/components/edit-text-modal';
import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import PlaceHolderImage6 from 'src/images/BlankTemplateImages/Image6.jpg';
import PlaceHolderImage7 from 'src/images/BlankTemplateImages/Image7.jpg';

import './WhistlerBrochure7.scss';
import WhistlerBrochureTemplate from 'src/templates/base/WhistlerBrochureTemplate';

const TEMPLATE_NAME = 'Luxury Portfolio Brochure';
const TEMPLATE_WIDTH = 1056;
const TEMPLATE_HEIGHT = 1635;
const TARGET_TEMPLATE_WIDTH = 1056;
const TARGET_TEMPLATE_HEIGHT = 1635;

const Address = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className="address">
      <input
        className="mobile-p uppercase"
        style={{
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        }}
        value={
          listing.location.streetAddress.length > 40
            ? listing.location.streetAddress.slice(0, 40)
            : listing.location.streetAddress
        }
        onChange={e => {
          if (e.target.value.length < 40) {
            mergeCurrentListing({
              location: {
                ...listing.location,
                streetAddress: e.target.value,
              },
            });
          }
        }}
      />
      <div className="details">
        <input
          type="text"
          className="mobile-p uppercase city"
          style={{
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
          }}
          value={listing.location.city}
          onChange={e => {
            if (e.target.value.length < 40) {
              mergeCurrentListing({
                location: {
                  ...listing.location,
                  city: e.target.value,
                },
              });
            }
          }}
        />
        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>|</span>
        <input
          className="mobile-p uppercase"
          style={{
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
          }}
          value={listing.price.priceDisplay}
          onChange={e => {
            if (e.target.value.length < 40) {
              mergeCurrentListing({
                price: {
                  ...listing.price,
                  priceDisplay: e.target.value,
                },
              });
            }
          }}
        />
      </div>
      <div className="details">
        <input
          className="mobile-p"
          style={{
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
            width: '300px',
          }}
          value={listing.agents.agentWebsite}
          onChange={e => {
            if (e.target.value.length < 55) {
              mergeCurrentListing({
                agents: {
                  ...listing.agents,
                  agentWebsite: e.target.value,
                },
              });
            }
          }}
        />
      </div>
    </div>
  );
};

const Page1 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-1 ui-only-outline`}>
      <div className="half-page--top-1 rotate-180">
        <div className="hero-image">
          <EditableImage
            self={self}
            fieldName="image1"
            className="hero-image"
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={0}
          />
        </div>
        <div className="hero-image-logo">
          <img src={placeholderLogo} width="100%" height="100%" base64="true" alt="logo" />
        </div>
        <div className="footer-wrapper inline-flex around">
          <div className="logo left">
            <img src={WSLogo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
          <div className="center">
            <Address mergeCurrentListing={mergeCurrentListing} self={self} listing={listing} />
          </div>
          <div className="logo right">
            <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
          </div>
        </div>
      </div>
      <div className="half-page--bottom-1 inline-flex">
        <div className="bottom-right">
          <EditableImage
            self={self}
            fieldName="image2"
            className="listing-image"
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={1}
          />
        </div>
        <div>
          <div className="page-one-image-container inline-flex">
            <EditableImage
              self={self}
              fieldName="image3"
              className="listing-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={2}
            />
            <EditableImage
              self={self}
              fieldName="image4"
              className="listing-image"
              size="cover"
              position={EditableImage.CENTER}
              pictures={listing.pictures}
              imgKey={3}
            />
          </div>
          <div className="content-container">
            <div className="content-container--wrapper">
              <div className="section-one">
                <input
                  className="mobile-p uppercase"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    outline: 'none',
                  }}
                  value={
                    listing.location.streetAddress.length > 40
                      ? listing.location.streetAddress.slice(0, 40)
                      : listing.location.streetAddress
                  }
                  onChange={e => {
                    mergeCurrentListing({
                      location: {
                        ...listing.location,
                        streetAddress: e.target.value,
                      },
                    });
                  }}
                />
                <div className="details">
                  <input
                    type="text"
                    className="mobile-p uppercase"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    value={listing.location.city}
                    onChange={e => {
                      mergeCurrentListing({
                        location: {
                          ...listing.location,
                          city: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="inline-flex">
                    <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>|</span>
                    <input
                      type="text"
                      className="mobile-p"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        width: '100px',
                      }}
                      value={listing.price.priceDisplay}
                      onChange={e => {
                        mergeCurrentListing({
                          price: {
                            ...listing.price,
                            priceDisplay: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="inline-flex">
                    <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>|</span>
                    <input
                      className="mobile-p"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        width: '250px',
                      }}
                      value={listing.agents.agentWebsite}
                      onChange={e => {
                        if (e.target.value.length < 55) {
                          mergeCurrentListing({
                            agents: {
                              ...listing.agents,
                              agentWebsite: e.target.value,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />

                <div className="desc-wrapper">
                  <div className="description">
                    <textarea
                      type="text"
                      className="mobile-p"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        width: '100%',
                        height: '150px',
                        resize: 'none',
                        wordBreak: 'break-word',
                        lineHeight: '14px',
                      }}
                      value={listing.description}
                      onChange={e => {
                        mergeCurrentListing({
                          description: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="section-two">
                  <AgentBranding
                    mergeCurrentListing={mergeCurrentListing}
                    agents={listing.agents}
                    showLogo={false}
                    alternateStyle
                  />
                </div>
                <hr />
                <div className="section-three">
                  <div>
                    <img src={placeholderLogo} width="100%" height="100%" base64="true" alt="logo" />
                  </div>
                  <div>
                    <img src={WSLogo} width="100%" height="100%" base64="true" alt="logo" />
                  </div>
                  <div>
                    <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
                  </div>
                </div>
                <hr />
                <div className="section-four">
                  <div className="inline-flex between social">
                    <p>luxuryportfolio.com</p>
                    <div className="social-list">
                      <p>@luxuryportfolio</p>
                      <img src={facebook} width="6%" height="6%" base64="true" alt="facebook" />
                      <img src={twitter} width="6%" height="6%" base64="true" alt="twitter" />
                      <img src={instagram} width="6%" height="6%" base64="true" alt="instagram" />
                      <img src={pinterest} width="6%" height="6%" base64="true" alt="pinterest" />
                    </div>
                  </div>
                  <div className="legal">
                    © 2020 Luxury Portfolio International® Offering is subject to errors, omissions, change of price, or
                    withdrawal without notice. All information considered reliable; however, it has been supplied by
                    third parties and should not be relied on as accurate or complete. Equal Housing Opportunity.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page2 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-2 ui-only-outline`}>
      <div className="half-page--top-2">
        <EditableImage
          self={self}
          fieldName="image5"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
        />
      </div>
      <div className="half-page--bottom-2">
        <div className="inline-flex">
          <EditableImage
            self={self}
            fieldName="image6"
            className="left-top"
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={5}
          />
          <div className="right-top">
            <div className="content-wrapper">
              <input
                className="mobile-p uppercase"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  outline: 'none',
                }}
                value={listing.location.streetAddress}
                onChange={e => {
                  mergeCurrentListing({
                    location: {
                      ...listing.location,
                      streetAddress: e.target.value,
                    },
                  });
                }}
              />
              <div className="details">
                <input
                  type="text"
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  value={listing.location.city}
                  onChange={e => {
                    mergeCurrentListing({
                      location: {
                        ...listing.location,
                        city: e.target.value,
                      },
                    });
                  }}
                />
                <div className="inline-flex">
                  <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>|</span>
                  <input
                    type="text"
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      width: '100px',
                    }}
                    value={listing.price.priceDisplay}
                    onChange={e => {
                      mergeCurrentListing({
                        price: {
                          ...listing.price,
                          priceDisplay: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="inline-flex">
                  <span
                    style={{ paddingRight: '5px', paddingLeft: '5px' }}
                    className={listing.details.qtyBedrooms ? '' : 'not-in-pdf'}
                  >
                    |
                  </span>
                  <input
                    type="text"
                    className="mobile-p"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      outline: 'none',
                      width: '250px',
                    }}
                    value={listing.details.qtyBedrooms}
                    onChange={e => {
                      mergeCurrentListing({
                        details: {
                          ...listing.details,
                          qtyBedrooms: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="desc-wrapper">
                <div className="description">
                  <textarea
                    className="mobile-p"
                    style={
                      listing.agents.agentBanner !== null
                        ? {
                            border: 'none',
                            boxShadow: 'none',
                            outline: 'none',
                            width: '100%',
                            height: '210px',
                            overflow: 'hidden',
                            resize: 'none',
                            wordBreak: 'break-word',
                            lineHeight: '14px',
                          }
                        : {
                            border: 'none',
                            boxShadow: 'none',
                            outline: 'none',
                            width: '100%',
                            height: '400px',
                            overflow: 'hidden',
                            resize: 'none',
                            wordBreak: 'break-word',
                            lineHeight: '14px',
                          }
                    }
                    value={listing.alternateDescription}
                    onChange={e => {
                      mergeCurrentListing({
                        alternateDescription: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="agent-optional-logo-container">
                <BackgroundImage url={listing.agents.agentBanner} size="contain" height={115.2} width={460.8} />
                {(listing.agents.agentBanner === undefined || listing.agents.agentBanner.length < 5) && (
                  <span className="empty-placeholder not-in-pdf"></span>
                )}
                <div className="edit-btn-hover not-in-pdf">
                  <Scale.Back>
                    <div>
                      <EditTextModal activeTab={1} triggerTitle="Add Banner 7.5 x 1.50 inches" />
                    </div>
                  </Scale.Back>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inline-flex">
          <EditableImage
            self={self}
            fieldName="image7"
            className="left-bottom"
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={6}
          />
          <EditableImage
            self={self}
            fieldName="image8"
            className="right-bottom"
            size="cover"
            position={EditableImage.CENTER}
            pictures={listing.pictures}
            imgKey={7}
          />
        </div>
      </div>
    </div>
  );
};

class WhistlerBrochure7 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, image3, image4, image5, image6, image7, image8 } = props;

    const { photos, description, location, agents } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;
    if (!image6) image6 = photos[5] || PlaceHolderImage6;
    if (!image7) image7 = photos[6] || PlaceHolderImage7;
    if (!image8) image8 = photos[7] || PlaceHolderImage1;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      location,
      description,
      agents,
    };
  }

  render() {
    const { currentListing, mergeCurrentListing } = this.props;
    const { image1, image2, image3, image4, image5 } = this.state;
    WhistlerBrochure7._cached = { image1, image2, image3, image4, image5 };
    let customMergeListing = obj => {
      const newListing = _.omit({ ...currentListing, ...obj }, true);
      mergeCurrentListing(newListing);
    };
    return (
      <WhistlerBrochureTemplate className="whistler-brochure-7-wrapper">
        <Page1 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page2 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
      </WhistlerBrochureTemplate>
    );
  }
}

WhistlerBrochure7._name = TEMPLATE_NAME;
WhistlerBrochure7._width = TEMPLATE_WIDTH;
WhistlerBrochure7._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerBrochure7, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(WhistlerBrochure7);
