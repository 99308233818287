import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/brand-logo-color.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import './FacebookTemplate1.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_1';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate1 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address } = props;

    const { pictures, location } = currentListing;

    const { streetAddress, city } = location;

    if (!image1) image1 = pictures[0];
    if (!address) address = `${streetAddress}, ${city}`;

    this.state = {
      headline,
      image1,
      address,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1, address } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate1._cached = { headline, image1, address };

    return (
      <div className={`facebook-1-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        <EditableTextarea self={this} className="address" fieldName="address" placeholder="Street Address" />
        <div className="logo">
          <img src={Logo} width="80%" height="80%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

FacebookTemplate1._name = TEMPLATE_NAME;
FacebookTemplate1._width = TEMPLATE_WIDTH;
FacebookTemplate1._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate1, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate1);
