import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal, Form, Grid } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { updateCustomerAddress } from 'src/redux/actions/stripeActions';

import OutlinedInverseButton from '../OutlinedInverseButton/outlined-inverse-button';
import COUNTRIES from 'src/utils/countries.json';
import './edit-billing-contact-info.scss';

const EditBillingContactInfo = ({ defaultValues, customerId }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { currentUser } = useSelector(state => ({
    currentUser: state.users.currentUser,
  }));

  const [formValues, setFormValues] = useState({
    full_name: '',
    company_name: '',
    email: '',
    phone_number: '',
    street: '',
    city: '',
    province: '',
    postal_code: '',
    country: '',
  });

  const [open, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formError, setFormError] = useState(false);

  const [provinces, setProvinces] = useState([]);

  const handleValidations = async () => {
    if (
      !formValues.full_name ||
      !formValues.email ||
      !formValues.phone_number ||
      !formValues.street ||
      !formValues.city ||
      !formValues.province ||
      !formValues.postal_code ||
      !formValues.country
    ) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      // call api
      dispatch(
        updateCustomerAddress({
          name: formValues.full_name,
          customer_id: customerId,
          email: formValues.email,
          phone_number: formValues.phone_number,
          address: {
            line1: formValues.street,
            line2: '',
            city: formValues.city,
            country: formValues.country,
            state: formValues.province,
            postal_code: formValues.postal_code,
          },
          user_id: currentUser.id,
        })
      )
        .then(() => {
          setLoading(false);
          setDisabled(false);
          setOpenModal(false);
          addToast('Billing details updated successfully!', {
            appearance: 'success',
            autoDismiss: true,
          });
        })
        .catch(err => {
          setLoading(false);
          setDisabled(false);
          setOpenModal(false);
          addToast('Failed to update billing details!', {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log('ERR => while updating customer address!');
        });
    }
  };

  useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      setFormValues(defaultValues);
      const country = COUNTRIES.find(country => country.iso2 === defaultValues.country);
      setProvinces(
        country?.states.map(state => ({
          key: state.id,
          value: state.state_code,
          text: state.name,
        })) || []
      );
    }
  }, [defaultValues]);

  return (
    <Modal
      centered
      open={open}
      onClose={() => setOpenModal(false)}
      trigger={
        <OutlinedInverseButton onClick={() => setOpenModal(true)}>Edit Billing Contact Info</OutlinedInverseButton>
      }
      size="small"
    >
      <Modal.Header>Edit Billing Contact Information</Modal.Header>
      <Modal.Content>
        <Form className="edit-billing-contact-info">
          <div className="grid-row">
            <Form.Input
              error={!formValues.full_name && formError ? { content: 'This is a required field' } : false}
              fluid
              required={true}
              placeholder="Full Name"
              onChange={e => setFormValues({ ...formValues, full_name: e.target.value })}
              value={formValues.full_name}
              label="Full Name"
            />
          </div>
          <Form.Input
            error={!formValues.email && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="Email"
            onChange={e => setFormValues({ ...formValues, email: e.target.value })}
            value={formValues.email}
            label="Email"
          />
          <Form.Input
            error={!formValues.phone_number && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="Phone Number"
            onChange={e => setFormValues({ ...formValues, phone_number: e.target.value })}
            value={formValues.phone_number}
            label="Phone Number"
          />
          <Form.Input
            error={!formValues.street && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="Street"
            onChange={e => setFormValues({ ...formValues, street: e.target.value })}
            value={formValues.street}
            label="Street"
          />
          <div className="grid-row">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    error={!formValues.city && formError ? { content: 'This is a required field' } : false}
                    fluid
                    required={true}
                    placeholder="City"
                    onChange={e => setFormValues({ ...formValues, city: e.target.value })}
                    value={formValues.city}
                    label="City"
                  />{' '}
                </Grid.Column>
                <Grid.Column>
                  <Form.Dropdown
                    required={true}
                    error={!formValues.province && formError ? { content: 'This is a required field' } : false}
                    placeholder="Province"
                    fluid
                    search
                    selection
                    options={provinces}
                    value={formValues.province}
                    label="Province"
                    onChange={(e, data) => setFormValues({ ...formValues, province: data.value })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className="grid-row">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    error={!formValues.postal_code && formError ? { content: 'This is a required field' } : false}
                    fluid
                    required={true}
                    placeholder="Postal Code"
                    onChange={e => setFormValues({ ...formValues, postal_code: e.target.value })}
                    value={formValues.postal_code}
                    label="Postal Code"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Dropdown
                    required={true}
                    error={!formValues.country && formError ? { content: 'This is a required field' } : false}
                    placeholder="Select Country"
                    fluid
                    search
                    selection
                    options={COUNTRIES.map(country => ({
                      key: country.iso2,
                      value: country.iso2,
                      text: country.name,
                    }))}
                    value={formValues.country}
                    label="Select Country"
                    onChange={(e, data) => {
                      const country = COUNTRIES.find(country => country.iso2 === data.value);
                      setProvinces(
                        country?.states.map(state => ({
                          key: state.id,
                          value: state.state_code,
                          text: state.name,
                        })) || []
                      );
                      setFormValues({ ...formValues, country: data.value });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" size="tiny" onClick={() => setOpenModal(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          color="green"
          size="tiny"
          disabled={disabled}
          loading={loading}
          onClick={async event => {
            event.preventDefault();
            handleValidations();
          }}
        >
          <Icon name="checkmark" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditBillingContactInfo;
