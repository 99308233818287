import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure2.scss';
import { register } from 'src/services/template-renderer';
import Floorplan from 'src/templates/brochures/fragments/macdonald/floorplan';
import ListingInfo from 'src/templates/brochures/fragments/macdonald/listing-info';
import ListingFeature from 'src/templates/brochures/fragments/macdonald/listing-feature';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';

const TEMPLATE_NAME = 'MACDONALD_REALTY_FOLDED_BROCHURE_WITH_CUSTOM_AGENT_BANNER';
const TEMPLATE_WIDTH = 1224;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ listing, mergeCurrentListing }) => {
  return (
    <div className="scaled template template-dark mac-template-2 page-1 ui-only-outline">
      <Floorplan mergeCurrentListing={mergeCurrentListing} dark={true} listing={listing} showAgentInfo={false} />
      <ListingInfo mergeCurrentListing={mergeCurrentListing} listing={listing} dark={true} hasAgentBanner={true} />
    </div>
  );
};

const Page2 = ({ listing, mergeCurrentListing, broker }) => {
  return (
    <div className="scaled template mac-template-2 template-dark page-2 ui-only-outline">
      <ListingFeature broker={broker} dark={true} mergeCurrentListing={mergeCurrentListing} listing={listing} />
    </div>
  );
};

const MacdonaldBrochure2 = ({ searchedListing, currentListing, mergeCurrentListing, broker }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <>
      <Page1 mergeCurrentListing={customMergeListing} listing={currentListing} />
      <br className="not-in-pdf" />
      <Page2 mergeCurrentListing={customMergeListing} listing={currentListing} broker={broker} />
    </>
  );
};

MacdonaldBrochure2._name = TEMPLATE_NAME;
MacdonaldBrochure2._width = TEMPLATE_WIDTH;
MacdonaldBrochure2._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure2,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure2);
