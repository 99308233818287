import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/whistler/Wordmark_horizontal_white.png';
import EditableImage from 'src/components/editable-image';
import { register } from 'src/services/template-renderer';
import AgentBranding from 'src/components/agent-branding';
import { formatCurrencyNoDecimal } from 'src/utils/format-number';
import Scale from 'src/components/scale';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';
import PlaceHolderImage2 from 'src/images/BlankTemplateImages/Image2.jpg';
import PlaceHolderImage3 from 'src/images/BlankTemplateImages/Image3.jpg';
import PlaceHolderImage4 from 'src/images/BlankTemplateImages/Image4.jpg';
import PlaceHolderImage5 from 'src/images/BlankTemplateImages/Image5.jpg';
import _ from 'src/lodash';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import './WhistlerBrochure2.scss';
import WhistlerBrochureTemplate from 'src/templates/base/WhistlerBrochureTemplate';

const TEMPLATE_NAME = '2PG – Agent & Co-Agent Headshot';
const TEMPLATE_WIDTH = 612;
const TEMPLATE_HEIGHT = 792;
const TARGET_TEMPLATE_WIDTH = 1632;
const TARGET_TEMPLATE_HEIGHT = 1056;

const Page1 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-1 ui-only-outline`}>
      <div className="hero-image">
        <EditableImage
          self={self}
          fieldName="image1"
          className="hero-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={0}
        />
      </div>
      <div className="content-wrapper no-hover">
        <div className="address">
          <input
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
            }}
            value={
              listing.location.streetAddress.length > 40
                ? listing.location.streetAddress.slice(0, 40)
                : listing.location.streetAddress
            }
            onChange={e => {
              if (e.target.value.length < 40) {
                mergeCurrentListing({
                  location: {
                    ...listing.location,
                    streetAddress: e.target.value,
                  },
                });
              }
            }}
          />
        </div>
        <div className="description">
          <textarea
            className="mobile-p"
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '100%',
              height: '125px',
              overflow: 'hidden',
              resize: 'none',
              wordBreak: 'break-word',
              lineHeight: '15px',
            }}
            value={listing.description.length > 741 ? listing.description.slice(0, 741) : listing.description}
            onChange={e => {
              mergeCurrentListing({
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="footer">
        <div className="logo">
          <img src={Logo} width="100%" height="100%" base64="true" alt="logo" />
        </div>
      </div>
    </div>
  );
};

const Page2 = ({ self, listing, mergeCurrentListing }) => {
  return (
    <div className={`scaled template page-2 ui-only-outline`}>
      <div className="image-container">
        <EditableImage
          self={self}
          fieldName="image2"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={1}
        />
        <EditableImage
          self={self}
          fieldName="image3"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={2}
        />
        <EditableImage
          self={self}
          fieldName="image4"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={3}
        />
        <EditableImage
          self={self}
          fieldName="image5"
          className="listing-image"
          size="cover"
          position={EditableImage.CENTER}
          pictures={listing.pictures}
          imgKey={4}
        />
      </div>
      <div className="features-container no-hover">
        <div className="features-list">
          <div className="feature">
            Price:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={15}
                value={
                  formatCurrencyNoDecimal(listing.price.priceDisplay).length > 15
                    ? formatCurrencyNoDecimal(listing.price.priceDisplay).slice(0, 15)
                    : formatCurrencyNoDecimal(listing.price.priceDisplay)
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      price: {
                        ...listing.price,
                        priceDisplay: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          {listing.isMLSVisible && (
            <div className="feature">
              WLS Id:
              <span style={{ width: '150px', marginLeft: '6px' }}>
                <input
                  className="mobile-p"
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  maxLength={30}
                  value={listing.mlsId.length > 30 ? listing.mlsId.slice(0, 30) : listing.mlsId}
                  onChange={e => {
                    if (e.target.value.length <= 30) {
                      mergeCurrentListing({
                        mlsId: e.target.value,
                      });
                    }
                  }}
                />
              </span>
            </div>
          )}
          <div className="feature">
            Bedrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.details.qtyBedrooms.length > 15
                    ? listing.details.qtyBedrooms.slice(0, 15)
                    : listing.details.qtyBedrooms
                }
                maxLength={15}
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyBedrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Bathrooms:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details.qtyFullBathrooms.length > 15
                    ? listing.details.qtyFullBathrooms.slice(0, 15)
                    : listing.details.qtyFullBathrooms
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        qtyFullBathrooms: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Year Built:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.details.yearBuilt.length > 15
                    ? listing.details.yearBuilt.slice(0, 15)
                    : listing.details.yearBuilt
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        yearBuilt: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Sub-area:
            <span style={{ width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                maxLength={30}
                value={
                  listing.details.neighborhood.length > 30
                    ? listing.details.neighborhood.slice(0, 30)
                    : listing.details.neighborhood
                }
                onChange={e => {
                  if (e.target.value.length <= 30) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        neighborhood: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Maint. Fee:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                value={
                  listing.details.strataFee.length > 15
                    ? listing.details.strataFee.slice(0, 15)
                    : listing.details.strataFee
                }
                maxLength={15}
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        strataFee: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Gross Taxes:
            <span style={{ display: 'flex', width: '150px', marginLeft: '6px' }}>
              $
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                }}
                maxLength={15}
                value={
                  listing.details?.grossTax?.length > 15
                    ? listing.details?.grossTax.slice(0, 15)
                    : listing.details?.grossTax
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        grossTax: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Floor Area:
            <span style={{ display: 'flex', whiteSpace: 'nowrap', width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '75%',
                  marginLeft: '3px',
                }}
                maxLength={15}
                value={
                  listing.details.squareFeet.length > 15
                    ? listing.details.squareFeet.slice(0, 15)
                    : listing.details.squareFeet
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        squareFeet: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
          <div className="feature">
            Total Lot Size:
            <span style={{ display: 'flex', whiteSpace: 'nowrap', width: '150px', marginLeft: '6px' }}>
              <input
                className="mobile-p"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                  width: '75%',
                }}
                maxLength={15}
                value={
                  listing.details.lotSizeTotal.length > 15
                    ? listing.details.lotSizeTotal.slice(0, 15)
                    : listing.details.lotSizeTotal
                }
                onChange={e => {
                  if (e.target.value.length <= 15) {
                    mergeCurrentListing({
                      details: {
                        ...listing.details,
                        lotSizeTotal: e.target.value,
                      },
                    });
                  }
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="two-agent-container">
        <AgentBranding mergeCurrentListing={mergeCurrentListing} agents={listing.agents} showLogo={false} />
        <AgentBranding
          coAgent={true}
          listing={listing.agents}
          mergeCurrentListing={mergeCurrentListing}
          agents={{
            agentImageUrl: listing.agents.coAgentImageUrl,
            agentName: listing.agents.coAgentName,
            agentTitle: listing.agents.coAgentTitle,
            agentPhone: listing.agents.coAgentPhone,
            agentEmail: listing.agents.coAgentEmail,
            agentWebsite: listing.agents.coAgentWebsite,
            agentLogo: listing.agents.coAgentLogo,
          }}
          showLogo={false}
          activeTab={2}
        />
      </div>
    </div>
  );
};

class WhistlerBrochure8 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, image1, image2, image3, image4, image5, address } = props;

    const { photos, description, location } = currentListing;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!image2) image2 = photos[1] || PlaceHolderImage2;
    if (!image3) image3 = photos[2] || PlaceHolderImage3;
    if (!image4) image4 = photos[3] || PlaceHolderImage4;
    if (!image5) image5 = photos[4] || PlaceHolderImage5;
    if (!address) address = `${location.streetAddress}, ${location.city}`;

    this.state = {
      image1,
      image2,
      image3,
      image4,
      image5,
      address,
      description,
    };
  }

  render() {
    const { currentListing, mergeCurrentListing } = this.props;
    const { image1, address } = this.state;
    WhistlerBrochure8._cached = { image1, address };
    let customMergeListing = obj => {
      const newListing = _.omit({ ...currentListing, ...obj }, true);
      mergeCurrentListing(newListing);
    };

    return (
      <WhistlerBrochureTemplate className="whistler-brochure-2-wrapper">
        <Page1 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
        <br className="not-in-pdf" />
        <Page2 mergeCurrentListing={customMergeListing} self={this} listing={currentListing} />
      </WhistlerBrochureTemplate>
    );
  }
}

WhistlerBrochure8._name = TEMPLATE_NAME;
WhistlerBrochure8._width = TEMPLATE_WIDTH;
WhistlerBrochure8._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: WhistlerBrochure8, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(WhistlerBrochure8);
