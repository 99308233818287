import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import UpdatePhotoModal from './update-photo-modal';
import PropTypes from 'prop-types';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import Scale from 'src/components/scale';
import _ from 'lodash';
import './image.scss';

const Image = ({
  searchedListing,
  currentListing,
  mergeCurrentListing,
  imgKey,
  url,
  onChange,
  buttonTitle = 'Change',
  width,
  height,
  id,
  margin = '',
  style = {},
}) => {
  const handlePhotoChange = newImgUrl => {
    if (onChange) return onChange(newImgUrl, mergeCurrentListing);
    currentListing.photos[imgKey] = newImgUrl;
    mergeCurrentListing({ photos: currentListing.photos });
  };

  return (
    <div className="image-container">
      <div
        className="listing-photo"
        id={id}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundImage: 'url(' + url + ')',
          margin: `${margin}`,
          ...style,
        }}
      >
        {(url === undefined || url.length < 5) && <span className="empty-placeholder not-in-pdf"></span>}
      </div>

      <UpdatePhotoModal
        photos={_.get(searchedListing, 'pictures', [])}
        updatePhoto={handlePhotoChange}
        trigger={handleClick => (
          <Scale.Back className="upload-button" style={{ opacity: '0 !important' }}>
            <Button primary size="tiny" onClick={handleClick}>
              <Icon name="edit" />
              {buttonTitle}
            </Button>
          </Scale.Back>
        )}
      />
    </div>
  );
};

Image.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(Image);
