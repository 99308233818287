import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'src/lodash';
import Scale from 'src/components/scale';

import './preview-list.scss';

const DynamicPreviewItem = ({
  linkTo,
  Component,
  targetWidth,
  targetHeight,
  data,
  pages = 1,
  outline = true,
  defaultURL,
}) => {
  return (
    <div className="social-thumbnail noselect">
      <Link to={linkTo} style={{ minWidth: '1px', minHeight: '1px' }}>
        <Scale
          width={Component._width}
          height={Component._height}
          targetWidth={targetWidth}
          targetHeight={targetHeight}
          pages={pages}
          style={{ outline: outline ? 'lightgrey 5px solid' : 'none', margin: 'auto' }}
        >
          <Component {...data} defaultURL={defaultURL} />
        </Scale>
        <div className="social-thumbnail-wrapper"></div>
      </Link>
    </div>
  );
};

export default DynamicPreviewItem;
