import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlaceholderImage from 'src/images/banner.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import EditableTextarea from 'src/components/editable-textarea';
import { register } from 'src/services/template-renderer';

import VantageWestSocialTemplate from 'src/templates/base/VantageWestSocialTemplate';
import ApplyFont from 'src/components/FontPreview/ApplyFont';
import { staticBrokerFonts } from 'src/utils/generic-utils';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate3.scss';

const TEMPLATE_NAME = 'Facebook_Template_3';
const TEMPLATE_WIDTH = 1200;
const TEMPLATE_HEIGHT = 628;

class VantageWestFacebookTemplate3 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;

    const { qtyBedrooms, qtyFullBathrooms } = details;
    const bath = qtyFullBathrooms || 0;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;
    if (!address) address = `${streetAddress}, ${city}`;
    if (!listingDetails) listingDetails = `Bed: ${qtyBedrooms}  |  Bath: ${bath}`;

    this.state = {
      headline,
      image1,
      address,
      listingDetails,
      adjustedHeight,
      adjustedHeightAdr,
    };
  }

  render() {
    const { currentListing, defaultSettings, appliedTheme, currentBroker } = this.props;
    const { headline, image1, address, listingDetails, adjustedHeight, adjustedHeightAdr } = this.state;
    VantageWestFacebookTemplate3._cached = {
      headline,
      image1,
      address,
      listingDetails,
      appliedTheme,
      defaultSettings,
      currentBroker,
      staticBrokerFonts,
    };

    return (
      <VantageWestSocialTemplate className="vw-social-3-wrapper">
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          position={EditableImage.CENTER}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <div
          className="overlay"
          style={{
            backgroundColor: this.props.appliedTheme?.backgroundColor
              ? `${this.props.appliedTheme?.backgroundColor}a8`
              : `${defaultSettings?.primaryColor}a8`,
          }}
        ></div>
        <div className="card-div">
          <ApplyFont fontName={this.props.appliedTheme?.font || defaultSettings?.font}>
            <EditableTextarea
              self={this}
              onHeightChange={v => (VantageWestFacebookTemplate3._cached.adjustedHeight = v)}
              initialHeight={adjustedHeight}
              className="headline"
              fieldName="headline"
              placeholder="Headline"
              rows={1}
              style={{
                fontFamily:
                  this.props.appliedTheme?.font ||
                  defaultSettings?.font ||
                  staticBrokerFonts[currentBroker?.id]?.medium,
                color: this.props.appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
              }}
            />
            <hr
              className="hr"
              style={{
                borderTopColor: this.props.appliedTheme?.backgroundColor || defaultSettings?.primaryColor,
              }}
            />
            <EditableTextarea
              self={this}
              onHeightChange={v => (VantageWestFacebookTemplate3._cached.adjustedHeightAdr = v)}
              initialHeight={adjustedHeightAdr}
              className="address"
              fieldName="address"
              placeholder="#123 - 456 W Georgia St Vancouver"
              rows={1}
              style={{
                fontFamily:
                  this.props.appliedTheme?.font ||
                  defaultSettings?.font ||
                  staticBrokerFonts[currentBroker?.id]?.regular,
              }}
            />
            <EditableInput
              self={this}
              className="listingDetails"
              fieldName="listingDetails"
              placeholder="Bed | Bath"
              style={{
                fontFamily:
                  this.props.appliedTheme?.font ||
                  defaultSettings?.font ||
                  staticBrokerFonts[currentBroker?.id]?.regular,
              }}
            />
          </ApplyFont>
          <img
            src={this.props.defaultURL || defaultSettings?.horizontalLogo || PlaceholderImage}
            className="brand-logo"
            alt="brand-logo"
          />
        </div>
      </VantageWestSocialTemplate>
    );
  }
}

VantageWestFacebookTemplate3._name = TEMPLATE_NAME;
VantageWestFacebookTemplate3._width = TEMPLATE_WIDTH;
VantageWestFacebookTemplate3._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: VantageWestFacebookTemplate3, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const {
    currentListing,
    brokers: { currentBroker },
  } = state;
  return {
    currentListing,
    appliedTheme: state.theme.appliedTheme,
    defaultSettings: state.theme.defaultSettings,
    currentBroker,
  };
};

export default connect(mapStateToProps)(VantageWestFacebookTemplate3);
