import React from 'react';
import { connect } from 'react-redux';
import '../reset.css';
import './MacdonaldBrochure8.scss';
import { register } from 'src/services/template-renderer';
import HorizontalWindowPoster from 'src/templates/brochures/fragments/macdonald/horizontal-window-poster';
import { mergeCurrentListing } from 'src/redux/actions/actions';
import _ from 'src/lodash';
const TEMPLATE_NAME = 'MACDONALD_REALTY_HORIZONTAL_WINDOW_SHEET_2_AGENTS_CO-LISTING';
const TEMPLATE_WIDTH = 792;
const TEMPLATE_HEIGHT = 612;
const TARGET_TEMPLATE_WIDTH = 1056;
const TARGET_TEMPLATE_HEIGHT = 816;

const MacdonaldBrochure8 = ({ searchedListing, currentListing, mergeCurrentListing }) => {
  let customMergeListing = obj => {
    const newListing = _.omit({ ...currentListing, ...obj }, true);
    mergeCurrentListing(newListing);
  };
  return (
    <div className="template mac-template-8 ui-only-outline" style={{ paddingTop: '0px' }}>
      <HorizontalWindowPoster
        mergeCurrentListing={customMergeListing}
        listing={currentListing}
        twoAgents={true}
        marginRightTitle="10px"
      />
    </div>
  );
};

MacdonaldBrochure8._name = TEMPLATE_NAME;
MacdonaldBrochure8._width = TEMPLATE_WIDTH;
MacdonaldBrochure8._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, {
  Component: MacdonaldBrochure8,
  width: TEMPLATE_WIDTH,
  height: TEMPLATE_HEIGHT,
  targetWidth: TARGET_TEMPLATE_WIDTH,
  targetHeight: TARGET_TEMPLATE_HEIGHT,
});

const mapStateToProps = state => {
  const { searchedListing, currentListing } = state;
  return { searchedListing, currentListing };
};

export default connect(mapStateToProps, { mergeCurrentListing })(MacdonaldBrochure8);
