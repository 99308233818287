import React, { useState, useEffect } from 'react';
import { Container, Loader, Button, List } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import AddSettingsModal from 'src/components/AddSettingsModal/add-settings-modal';
import { getDefaultSettings } from 'src/redux/actions/theme-creator';
import './default-theme.scss';
import AdminLayout from 'src/layouts/admin-layout';
import OutlinedButton from 'src/components/OutlinedButton/outlined-button';
import UploadBrandImages from '../../components/UploadBrandImages/UploadBrandImages';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useToasts } from 'react-toast-notifications';
import { isFileLarge } from 'src/utils/generic-utils';
import Swal from 'sweetalert2';
import { OldTemplates } from '../../constants/oldTemplates';
import { useTitle } from 'react-use';

const backgroundCellStyles = {
  display: 'flex',
  alignItems: 'center',
};

const DefaultTheme = () => {
  useTitle('Default Theme - Realvault');

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imgPreviewArray, setimgPreviewArray] = useState([]);
  const [prevArray, setPrevArray] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [btnloading, setBtnLoading] = useState(false);
  const [showAddSettingsModal, setShowAddSettingsModal] = useState(false);
  const { addToast } = useToasts();
  let token = localStorage.getItem('jwt');
  let templateId = localStorage.getItem('templateId');
  let decodedToken;
  if (!token) {
    token = JSON.parse(localStorage.getItem('broker_onboarding_basic_details'));
    decodedToken = token;
  } else {
    decodedToken = jwt.decode(token);
  }
  const localBasicDetails = decodedToken;

  const { currentBroker, defaultSettings } = useSelector(state => ({
    currentBroker: state.brokers.currentBroker,
    defaultSettings: state.theme.defaultSettings,
  }));

  useEffect(() => {
    setLoading(true);
    dispatch(getDefaultSettings(currentBroker.id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLogo = (type, event) => {
    const files = event.target.files;
    let fileArray = Object.values(files);
    let temp = [...imgPreviewArray];

    setPrevArray([...imgPreviewArray]);
    fileArray.forEach(file => {
      if (!isFileLarge(file.size, 2)) {
        addToast('File size exceeds than maximum 2mb limit!', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      if (temp.length >= 3) {
        addToast('Can not added more images', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      temp.push({
        preview: URL.createObjectURL(file),
        url: URL.createObjectURL(file),
        raw: file,
      });
    });

    setimgPreviewArray(temp);
  };

  const onRemoveLogo = (id, bypass = false) => {
    if (!bypass) {
      return Swal.fire({
        title: 'Remove Image',
        text: 'Do you want to remove this image?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E74942',
        customClass: { container: 'realvault-swal' },
        reverseButtons: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          let temp = [...imgPreviewArray];
          setPrevArray([...imgPreviewArray]);
          let newArr = temp.filter((v, i) => {
            if (i !== id) return v;
          });

          setimgPreviewArray(newArr);
          return false;
        }
        return false;
      });
    } else {
      let temp = [...imgPreviewArray];
      setPrevArray([...imgPreviewArray]);
      let newArr = temp.filter((v, i) => {
        if (i !== id) return v;
      });
      setimgPreviewArray(newArr);
    }
  };

  useEffect(() => {
    if (!OldTemplates.includes(templateId)) {
      async function getLogo() {
        let resp = await axios.get(`${process.env.REACT_APP_TEMPLATE_API}/logo/${localBasicDetails?.broker_id}`);
        return resp?.data?.logo;
      }

      getLogo().then(res => {
        if (res) {
          let newArr = [...res];
          setimgPreviewArray(newArr);
          setImgs(newArr);
        }
      });
    }
  }, []);

  const onChangeSettings = async () => {
    setBtnLoading(true);

    let arr = [];
    let imgFiles = [...imgPreviewArray];
    if (imgFiles && imgFiles.length > 0) {
      for (const v of imgFiles) {
        if (v.raw) {
          let formData = new FormData();
          formData.append('file', v.raw);
          formData.append('broker_id', localBasicDetails?.broker_id);
          formData.append('user_id', localBasicDetails?.user_id);
          let resp = await axios.post(`${process.env.REACT_APP_API}/file-upload-onboarding`, formData, {
            'content-type': 'multipart/form-data',
            Origin: `${window.location.origin}`,
          });
          arr.push(resp.data.file_public_url);
        } else {
          arr.push(v.url);
        }
      }
    }

    let objArr = [];
    if (arr.length > 0) {
      objArr = arr.map((v, i) => {
        let selected = false;
        if (i === 0) {
          selected = true;
        }
        return { url: v, selected };
      });
    }

    let resBody = {
      logo: objArr,
      broker_id: localBasicDetails?.broker_id,
    };

    try {
      let saveRes = await axios.post(`${process.env.REACT_APP_TEMPLATE_API}/saveLogo`, resBody);
      if (saveRes.statusText === 'OK' && saveRes.status === 200) {
        addToast('Settings changed successfully!', {
          appearance: 'success',
          autoDismiss: true,
        });
        if (arr.length >= 1) {
          localStorage.setItem('defaultImg', arr[0]);
        }
        setBtnLoading(false);
        setShowAddSettingsModal(false);
        setImgs(objArr);
      }
    } catch (e) {
      setBtnLoading(false);
      addToast(e?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
      setShowAddSettingsModal(false);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center">
          <Loader active={loading} />
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      pageTitle="Manage Brand"
      rightContent={
        defaultSettings &&
        !loading && <OutlinedButton onClick={() => setShowAddSettingsModal(true)}>Update Settings</OutlinedButton>
      }
    >
      <Container className="default-theme">
        <div className="mt-12 white">
          {defaultSettings && !loading && (
            <List divided relaxed>
              <List.Item>
                <List.Icon name="eye dropper" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="a" className="list-header">
                    Primary Color
                  </List.Header>
                  <List.Description as="a">
                    <div style={backgroundCellStyles}>
                      <div
                        style={{
                          backgroundColor: defaultSettings.primaryColor,
                          height: 16,
                          width: 16,
                          border: '1px solid #ccc',
                          marginRight: 8,
                        }}
                      ></div>
                      {defaultSettings.primaryColor}
                    </div>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="font" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="a" className="list-header">
                    Font
                  </List.Header>
                  <List.Description as="a">{defaultSettings.font || '--'}</List.Description>
                </List.Content>
              </List.Item>

              {!OldTemplates.includes(templateId) ? (
                <List.Item>
                  <List.Icon name="image" size="large" verticalAlign="middle" />
                  <List.Content style={{ paddingLeft: 0 }}>
                    <List.Header as="a" className="list-header">
                      Logos
                    </List.Header>
                    <UploadBrandImages imgs={imgs} />
                  </List.Content>
                </List.Item>
              ) : (
                ''
              )}
            </List>
          )}

          {!defaultSettings && !loading && (
            <div className="flex items-center justify-center">
              <div>
                <p>No default settings found. Add default settings</p>
                <div className="mt-4 text-center">
                  <Button onClick={() => setShowAddSettingsModal(true)}>Add Default Settings</Button>
                </div>
              </div>
            </div>
          )}
        </div>
        {showAddSettingsModal && (
          <AddSettingsModal
            btnLoading={btnloading}
            setBtnLoading={setBtnLoading}
            open={showAddSettingsModal}
            close={() => {
              setShowAddSettingsModal(false);
            }}
            initialValues={defaultSettings}
            onChangeSettings={onChangeSettings}
            imgPreviewArray={imgPreviewArray}
            setimgPreviewArray={setimgPreviewArray}
            onRemoveLogo={onRemoveLogo}
            setLogo={setLogo}
            prevImgArray={prevArray}
          />
        )}
      </Container>
    </AdminLayout>
  );
};

export default DefaultTheme;
