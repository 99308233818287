import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'src/images/pemberton/ph-stacked-green-logo.png';
import EditableImage from 'src/components/editable-image';
import EditableInput from 'src/components/editable-input';
import { register } from 'src/services/template-renderer';

import PlaceHolderImage1 from 'src/images/BlankTemplateImages/Image1.jpg';

import './FacebookTemplate4.scss';

const TEMPLATE_NAME = 'PEMBERTON_HOLMES_4';
const TEMPLATE_WIDTH = 1080;
const TEMPLATE_HEIGHT = 1080;

class FacebookTemplate4 extends Component {
  constructor(props) {
    super(props);

    let { currentListing, headline, image1, address, detail } = props;

    const { photos, location, details } = currentListing;

    const { streetAddress, city } = location;
    const { qtyBedrooms, qtyFullBathrooms, qtyHalfBathrooms, squareFeet } = details;

    const bath = qtyFullBathrooms || 0 + qtyHalfBathrooms || 0;
    const sqft = `${squareFeet} SQFT`;

    if (!image1) image1 = photos[0] || PlaceHolderImage1;

    if (!address) address = `${streetAddress}, ${city}`;
    if (!detail) detail = `Bed: ${qtyBedrooms}   |   Bath: ${bath}   |   ${sqft}`;

    this.state = {
      headline,
      image1,
      address,
      detail,
    };
  }

  render() {
    const { currentListing, themeClassName } = this.props;
    const { headline, image1, address, detail } = this.state;
    const theme = themeClassName || localStorage.getItem('themeClassName');
    FacebookTemplate4._cached = { headline, image1, address, detail };

    return (
      <div className={`facebook-4-wrapper ${theme}`}>
        <EditableImage
          self={this}
          fieldName="image1"
          className="image1"
          size="cover"
          opacity={0.55}
          position={EditableImage.TOP}
          pictures={currentListing.pictures}
          imgKey={0}
        />
        <EditableInput self={this} className="headline" fieldName="headline" placeholder="Headline" />
        <hr />
        <EditableInput self={this} className="address" fieldName="address" placeholder="Street Address" />
        <EditableInput self={this} className="detail" fieldName="detail" placeholder="Bed: X | Bath: X | XXX SQFT" />
        <div className="logo">
          <img src={Logo} width="60%" height="60%" base64="true" alt="logo" />
        </div>
      </div>
    );
  }
}

FacebookTemplate4._name = TEMPLATE_NAME;
FacebookTemplate4._width = TEMPLATE_WIDTH;
FacebookTemplate4._height = TEMPLATE_HEIGHT;

register(TEMPLATE_NAME, { Component: FacebookTemplate4, width: TEMPLATE_WIDTH, height: TEMPLATE_HEIGHT });

const mapStateToProps = state => {
  const { currentListing } = state;
  return { currentListing };
};

export default connect(mapStateToProps)(FacebookTemplate4);
