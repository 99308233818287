import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import { Button, Icon, Modal, Form } from 'semantic-ui-react';
import { SYSTEM_ADMIN } from 'src/constants/permissions';
import { createRequestHeader } from 'src/services/request-headers';

import { setCurrentUser } from 'src/redux/actions/userActions';
import { setCurrentBroker, storeBrokers } from 'src/redux/actions/brokerActions';
import OutlinedInverseButton from '../OutlinedInverseButton/outlined-inverse-button';

const propTypes = {
  edit: PropTypes.bool,
  user: PropTypes.object,
  editPassword: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
};

const defaultProps = { edit: false, user: {}, editPassword: false };

const SettingsModal = props => {
  const { addToast } = useToasts();
  const [formValues, setFormValues] = useState({
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    password: '',
    user_role_id: props.user.user_role_id,
    user_status_id: props.user.user_status_id,
    requested_broker: props.user.requested_broker,
    broker_id: props.user.broker_id,
    phone_number: props.user.phone_number,
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/broker`, createRequestHeader())
      .then(res => {
        props.storeBrokers(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [props.loading]);

  const handleValidations = async () => {
    if (!formValues.first_name || !formValues.last_name || !formValues.email) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      return axios
        .put(`${process.env.REACT_APP_API}/user/${props.user.id}`, formValues, createRequestHeader())
        .then(async res => {
          props.setCurrentUser(res.data);
          if (props.user.broker_id !== formValues.broker_id && props.user.user_role_id === SYSTEM_ADMIN) {
            window.location.href = '/dashboard';
          }
          addToast(`Successfully updated your contact information, ${formValues.first_name}`, {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
          setOpenModal(false);
        })
        .catch(error => {
          addToast(error.response ? error.response.data.error : 'Error', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
        });
    }
  };

  const handlePasswordValidation = async () => {
    if (!formValues.password || formValues.password.length < 8) {
      setFormError(true);
    } else {
      setLoading(true);
      setDisabled(true);
      return axios
        .put(`${process.env.REACT_APP_API}/user/${props.user.id}`, formValues, createRequestHeader())
        .then(res => {
          props.setCurrentUser(res.data);
          addToast(`Successfully reset your password, ${formValues.first_name}`, {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoading(false);
          setDisabled(false);
          setSecondOpen(false);
        })
        .catch(error => {
          addToast(error.message, { appearance: 'error', autoDismiss: true });
          setLoading(false);
          setDisabled(false);
        });
    }
  };

  const handleFetchBroker = (id, storeCurrentBroker) => {
    return axios
      .get(`${process.env.REACT_APP_API}/broker/${id}`, createRequestHeader())
      .then(res => {
        storeCurrentBroker(res.data);
        setOpenModal(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const passwordError = () => {
    if (!formValues.password && formError) {
      return { content: 'This is a required field' };
    } else if (formValues.password?.length < 8 && formError) {
      return { content: 'Password must be 8 characters long' };
    } else {
      return false;
    }
  };

  const brokerages = props.brokers?.brokers?.map(({ name, id }) => ({ key: id, value: id, text: name }));
  return (
    <Modal
      dimmer={'blurrying'}
      size="large"
      trigger={
        <Button className="ui button" onClick={() => setOpenModal(true)}>
          Edit Contact Info
        </Button>
      }
      open={open}
      centered={false}
    >
      <Modal.Header>Update User Information</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            error={!formValues.first_name && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="First Name"
            onChange={e => setFormValues({ ...formValues, first_name: e.target.value })}
            value={formValues.first_name}
            label="First Name"
          />
          <Form.Input
            error={!formValues.last_name && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="Last Name"
            onChange={e => setFormValues({ ...formValues, last_name: e.target.value })}
            value={formValues.last_name}
            label="Last Name"
          />
          <Form.Input
            fluid
            required={false}
            placeholder="Phone Number"
            onChange={e => setFormValues({ ...formValues, phone_number: e.target.value })}
            value={formValues.phone_number}
            label="Phone Number"
          />
          <Form.Input
            error={!formValues.email && formError ? { content: 'This is a required field' } : false}
            fluid
            required={true}
            placeholder="Email"
            onChange={e => setFormValues({ ...formValues, email: e.target.value })}
            value={formValues.email}
            label="Email"
            disabled
          />
          {props.user.user_role_id === SYSTEM_ADMIN && (
            <Form.Select
              error={!formValues.broker_id && formError ? { content: 'This is a required field' } : false}
              fluid
              required={true}
              label="Brokerage"
              options={brokerages}
              value={formValues.broker_id}
              placeholder="Select Brokerage"
              onChange={(e, { value }) => setFormValues({ ...formValues, broker_id: value })}
            />
          )}
          <Button onClick={() => setSecondOpen(true)} icon="pencil">
            Edit Password
          </Button>
        </Form>
        <Modal onClose={() => setSecondOpen(false)} open={secondOpen} size="small">
          <Modal.Header>Edit Password</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Input
                error={passwordError()}
                fluid
                type="password"
                required={true}
                placeholder="Password"
                onChange={e => setFormValues({ ...formValues, password: e.target.value })}
                value={formValues.password}
                label="Password"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" size="tiny" onClick={() => setSecondOpen(false)}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              icon="check"
              content="Save"
              size="tiny"
              color="green"
              onClick={async event => {
                event.preventDefault();
                handlePasswordValidation();
              }}
            />
          </Modal.Actions>
        </Modal>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" size="tiny" onClick={() => setOpenModal(false)}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          color="green"
          size="tiny"
          disabled={disabled}
          loading={loading}
          onClick={async event => {
            event.preventDefault();
            delete formValues.password;
            handleValidations();
          }}
        >
          <Icon name="checkmark" />
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

SettingsModal.propTypes = propTypes;
SettingsModal.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { users, brokers } = state;
  return { users, brokers };
};

export default withRouter(connect(mapStateToProps, { setCurrentUser, setCurrentBroker, storeBrokers })(SettingsModal));
